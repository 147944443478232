import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as f, useContext as K, useEffect as X } from "react";
import { a as le, B as L, S as te, c as j, D as ae, e as ee, T as xe, M as Z, F as we, d as Ie, b as be } from "./index.esm-DT3FM8Og.js";
import { F as ce } from "./provider-DPPRdC-N.js";
import { r as S } from "./sentry-CbZOQ4Ya.js";
import { o as Te } from "./redirection-rPP3A4Qb.js";
import { i as se, B as G, C as q, m as ne, E as Be, X as Oe, o as $e, H as Ve } from "./utils-DbnU7rHI.js";
import { u as re } from "./useConfiguration-B-lyaBB9.js";
import { i as a } from "./index-Bb24DwqC.js";
import { S as ge, C as ze } from "./StatusMessage-PK7bu2oh.js";
import { ErrorPage as ie } from "./ErrorPage.js";
import { R as He, F as je } from "./react-final-form.es-ZoroCK1B.js";
import { f as Je, r as Ue } from "./validators-BArvL7cB.js";
import { B as We } from "./BankAccountListing-maa4N6b9.js";
import { AppContext as Xe } from "./AppContext.js";
const z = {
  CREATED: {
    value: "Pending",
    message_type: "alert",
    icon: "Alert"
  },
  RECEIVED: {
    value: "Processing",
    message_type: "alert",
    icon: "Alert"
  },
  SUBMITTED: {
    value: "Processing",
    message_type: "alert",
    icon: "Alert"
  },
  APPROVED: {
    value: "Approved",
    message_type: "success",
    icon: "successInfo"
  },
  REJECTED: {
    value: "Rejected",
    message_type: "error",
    icon: "Error"
  },
  CANCELLED: {
    value: "Cancelled",
    message_type: "error",
    icon: "Error"
  }
};
function Ye({ currentMandateId: l, investorProfile: w }) {
  const [A, $] = f(""), [k, M] = f(""), [g, p] = f("initial"), { fpapi: F } = K(ce), [R, D] = f({}), [C, r] = f({}), [H, h] = f(!1), { tenantConfig: I, banks: J } = re(), U = I == null ? void 0 : I.fallbackLogo;
  X(() => {
    B();
  }, []);
  const B = async () => {
    var E;
    h(!0);
    let s = {}, u = {};
    try {
      s = await F.fpClient.mandates().fetch(l), u = await F.fpClient.bank_accounts().fetchAll({
        profile: w == null ? void 0 : w.id
      });
      const T = (E = u == null ? void 0 : u.data) == null ? void 0 : E.filter((c) => (c == null ? void 0 : c.old_id) === (s == null ? void 0 : s.bank_account_id));
      $(a._({
        id: "y/ttrn"
      })), M("successInfo"), r(T[0]), D(s);
    } catch (T) {
      p("error"), h(!1), S(T);
    }
    h(!1);
  }, y = (s) => {
    var u;
    return /* @__PURE__ */ e.jsx(te, {
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-full",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "JVRi6u"
              }),
              value: `${C == null ? void 0 : C.bank_name} (*****${C == null ? void 0 : C.account_number.slice(-4)})`,
              logo: J[(u = C == null ? void 0 : C.ifsc_code) == null ? void 0 : u.slice(0, 4)] || U
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "OzNl8x"
              }),
              value: s ? z[s.mandate_status].value : "-",
              icon: s ? z[s.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "nlXCcY"
              }),
              value: "NACH"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "Zp6kam"
              }),
              value: `₹ ${q(s.mandate_limit)}`
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "0OJ5oD"
              }),
              value: ne(new Date(s.valid_from), "dd-MMM-yyyy")
            })
          })
        ]
      })
    }, s.id);
  };
  function _() {
    switch (g) {
      case "initial":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ge, {
              title: A,
              iconName: k,
              customClass: "mt-8"
            }),
            !se(R) && y(R),
            /* @__PURE__ */ e.jsx(L, {
              fullwidth: !0,
              onClick: () => window.location.href = `${window.location.origin + G}/mandates`,
              children: a._({
                id: "yk7/Xz"
              })
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(ie, {
          title: a._({
            id: "uWhFK2"
          }),
          description: a._({
            id: "NHwYHE"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ge, {
              title: A,
              iconName: k,
              customClass: "mt-8"
            }),
            !se(R) && y(R),
            /* @__PURE__ */ e.jsx(L, {
              fullwidth: !0,
              onClick: () => window.location.href = `${window.location.origin + G}/mandates`,
              children: a._({
                id: "yk7/Xz"
              })
            })
          ]
        });
    }
  }
  return H ? /* @__PURE__ */ e.jsx(le, {
    variant: "fullpage"
  }) : _();
}
function Ke({ investorProfile: l, onCompletion: w, isPartner: A }) {
  const [$, k] = f(null), [M, g] = f(null), [p, F] = f(null), [R, D] = f("initial"), { fpapi: C } = K(ce), { tenantConfig: r, banks: H } = re(), h = r == null ? void 0 : r.fallbackLogo;
  X(() => {
    J();
  }, [
    M
  ]), X(() => {
    I();
  }, []);
  const I = async () => {
    try {
      if (l != null && l.id) {
        D("loading");
        const _ = await C.fpClient.bank_accounts().fetchAll({
          profile: l.id
        });
        g(_.data), D("initial");
      }
    } catch (_) {
      D("initial"), window.debug.error(_), S(_);
    }
  }, J = async () => {
    M != null && M.length && F(M[0]);
  };
  function U({ mandate_limit: _ }) {
    var E, T;
    const s = p.old_id, u = {
      mandate_type: "E_MANDATE",
      mandate_limit: _,
      bank_account_id: s
    };
    (E = r == null ? void 0 : r.app_constants) != null && E.paymentGateway && (u.provider_name = (T = r == null ? void 0 : r.app_constants) == null ? void 0 : T.paymentGateway), C.fpClient.mandates().create(u).then((c) => {
      w({
        success: !0,
        mandate: {
          id: c.id,
          provider_name: u.provider_name
        }
      });
    }).catch((c) => {
      k(!0), w({
        success: !1
      }), S(c);
    });
  }
  const B = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      l ? /* @__PURE__ */ e.jsx(ae, {
        type: "text",
        text: a._({
          id: "xEu7ys"
        })
      }) : null,
      /* @__PURE__ */ e.jsx(He, {
        onSubmit: U,
        subscription: {
          submitting: !0,
          values: !0
        },
        validate: (_) => {
          const s = {};
          return _.mandate_limit && _.mandate_limit > 1e6 && (s.mandate_limit = a._({
            id: "ajSlbF"
          })), s;
        },
        children: (_) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: _.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ee, {
                  message: "Bank account"
                }),
                /* @__PURE__ */ e.jsx(ae, {
                  type: "borderedNotes",
                  text: p ? `${p.bank_name} - ****${p == null ? void 0 : p.account_number.slice(-4)}` : "No verified bank found",
                  logo: p ? H[p == null ? void 0 : p.ifsc_code.slice(0, 4)] : h,
                  children: /* @__PURE__ */ e.jsx(ze, {
                    check: !A,
                    children: /* @__PURE__ */ e.jsx(L, {
                      variant: "text",
                      onClick: () => {
                        D("listing_bankaccount");
                      },
                      children: a._({
                        id: "ShFidp"
                      })
                    })
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ee, {
                  message: a._({
                    id: "A59Kth"
                  })
                }),
                /* @__PURE__ */ e.jsx(je, {
                  name: "auth_mode",
                  initialValue: "NACH(Electronic)",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (s) => /* @__PURE__ */ e.jsx(e.Fragment, {
                    children: /* @__PURE__ */ e.jsx(xe, {
                      id: s.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: s.input.name,
                      value: s.input.value,
                      disabled: !0,
                      onChange: s.input.onChange
                    })
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ee, {
                  message: a._({
                    id: "Zp6kam"
                  })
                }),
                /* @__PURE__ */ e.jsx(je, {
                  name: "mandate_limit",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: Je(Ue),
                  children: (s) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(xe, {
                        id: s.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: s.input.name,
                        value: Be(s.input.value),
                        onChange: (u) => s.input.onChange(Oe(u.target.value)),
                        onInput: (u) => {
                          u.target.value = u.target.value.replace($e("0-9"), ""), u.target.value.length > 7 && (u.target.value = u.target.value.substring(0, 7));
                        },
                        prefix: "₹",
                        placeholder: `${q(25e3)}`,
                        status: s.meta.error && s.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      s.meta.error && s.meta.touched && /* @__PURE__ */ e.jsx(Z, {
                        type: "error",
                        variant: "field",
                        title: s.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(we, {
              children: /* @__PURE__ */ e.jsx(L, {
                fullwidth: !0,
                variant: p ? "secondary" : "disabled",
                children: a._({
                  id: "bM8Qcn"
                })
              })
            })
          ]
        })
      }),
      $ ? /* @__PURE__ */ e.jsx(Z, {
        type: "error",
        variant: "field",
        title: "Cannot create mandate"
      }) : null
    ]
  }), y = () => {
    switch (R) {
      case "initial":
        return B();
      case "loading":
        return /* @__PURE__ */ e.jsx(le, {
          variant: "fullpage"
        });
      case "listing_bankaccount":
        return /* @__PURE__ */ e.jsx(We, {
          investorProfile: l,
          handleBankSelection: async (_) => {
            F(_), I();
          },
          removeTitle: !0
        });
      default:
        return B();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: y()
  });
}
function Ze() {
  return window;
}
const Ge = (l) => {
  const w = Ze();
  return new w.Razorpay({
    key: l,
    // logo, displayed in the payment processing popup
    image: "https://i.imgur.com/n5tjHFD.png"
  });
};
function mt({ investorProfile: l, handleMandateSelection: w, status: A, limit: $, isPartner: k, mandate_id: M }) {
  const { fpapi: g } = K(ce), { tenantConfig: p, banks: F } = re(), { AppState: { tenant: R } } = K(Xe), D = p == null ? void 0 : p.fallbackLogo, [C, r] = f("initial"), [H, h] = f(!1), [I, J] = f(a._({
    id: "3Ep5Oa"
  })), [U, B] = f({
    index: 0,
    obj: null
  }), [y, _] = f(null), [s, u] = f(!0), [E, T] = f([]), c = M == null ? void 0 : M.split(",").map(Number), [W, Ce] = f([]), [oe, de] = f(!1), [ue, me] = f(!1), [ye, Q] = f(!1), Y = {
    APPROVED: 1,
    CREATED: 2,
    SUBMITTED: 3,
    CANCELLED: 4,
    REJECTED: 5,
    RECEIVED: 6
  };
  X(() => {
    (async () => {
      if (c) {
        const t = await g.fpClient.mandates().fetch(c[0]);
        t && (t.mandate_status.toUpperCase() === "CREATED" || t.mandate_status.toUpperCase() === "RECEIVED") && Q(!0);
      }
    })();
  }, [
    c
  ]), X(() => {
    se(l) || V();
  }, [
    l
  ]);
  const V = () => {
    h(!0), g.fpClient.bank_accounts().fetchAll({
      profile: l == null ? void 0 : l.id
    }).then((t) => {
      const n = t.data;
      if (!n.length) {
        h(!1);
        return;
      }
      const x = n.map((m) => m.old_id);
      g.fpClient.mandates().fetchAll({
        bank_account_id: x.join(","),
        size: 100
      }).then((m) => {
        const o = m.mandates;
        if (o.sort((N, b) => new Date(b.created_at).valueOf() - new Date(N.created_at).valueOf()), o.length > 0) {
          J(a._({
            id: "dke4pV"
          }));
          let N = 0;
          n.forEach((i) => {
            o.forEach((d) => {
              d.bank_account_id === i.old_id && (d.bank = {
                bank_name: i.bank_name,
                number: i.account_number,
                ifsc: i.ifsc_code
              }), d.mandate_status.toLowerCase() === "approved" && N++;
            });
          }), N > 10 && u(!1);
          let b = [], v = [];
          if (w) {
            if (o == null || o.forEach((i) => {
              i.mandate_status.toLowerCase() === "approved" || i.mandate_status.toLowerCase() === "created" || i.mandate_status.toLowerCase() === "submitted" ? b.push(i) : v.push(i);
            }), A != null && A.length) {
              const i = [], d = [];
              b.forEach((O) => {
                A.includes(O.mandate_status.toLowerCase()) ? i.push(O) : d.push(O);
              }), b = [
                ...i
              ], v = [
                ...v,
                ...d
              ];
            }
            if ($) {
              const i = [], d = [];
              b.forEach((O) => {
                O.mandate_limit >= $ ? i.push(O) : d.push(O);
              }), b = [
                ...i
              ], v = [
                ...v,
                ...d
              ];
            }
          } else c && c.length ? v = o.filter((i) => c.includes(i.id)) : o.forEach((i) => v.push(i));
          b.length && (b = b.sort((i, d) => Y[i.mandate_status] - Y[d.mandate_status] || i.mandate_limit - d.mandate_limit), B({
            index: 0,
            obj: b[0]
          })), v.length && (v = v.sort((i, d) => Y[i.mandate_status] - Y[d.mandate_status] || i.mandate_limit - d.mandate_limit)), T(b), Ce(v), h(!1);
        } else
          w && r("create_mandate"), h(!1);
      }).catch((m) => {
        h(!1), S(m);
      });
    }).catch((t) => {
      h(!1), S(t);
    });
  }, ve = async () => {
    try {
      const t = await g.fpClient.email_addresses().fetchAll({
        profile: l == null ? void 0 : l.id
      }), n = await g.fpClient.phone_numbers().fetchAll({
        profile: l == null ? void 0 : l.id
      });
      return {
        emailData: t,
        phoneData: n
      };
    } catch (t) {
      window.debug.log(t), S(t);
    }
  }, fe = (t) => {
    const n = t == null ? void 0 : t.mandate_status, x = (t == null ? void 0 : t.provider_name) === "BILLDESK";
    if (!(k || M))
      return n === "CREATED" ? {
        title: /* @__PURE__ */ e.jsx("div", {
          className: "text-center",
          children: k ? a._({
            id: "VT9pGb"
          }) : a._({
            id: "VNWQ6H"
          })
        }),
        buttonText: k ? a._({
          id: "HfQVqg"
        }) : a._({
          id: "ITf1WC"
        })
      } : n === "APPROVED" && !x ? {
        title: "",
        buttonText: a._({
          id: "LSCJk2"
        })
      } : n === "SUBMITTED" ? {
        title: /* @__PURE__ */ e.jsx("div", {
          className: "text-center",
          children: k ? a._({
            id: "OYc5eB"
          }) : a._({
            id: "Hm8Ax+"
          })
        })
      } : void 0;
  }, ke = () => {
    c && (window.location.href = window.location.origin + `/mandates?id=${c[0]}`), V(), r("initial"), Q(!1);
  }, Ee = () => {
    c && (window.location.href = window.location.origin + `/mandates?id=${c[0]}`), V(), r("initial");
  };
  function Me(t, n) {
    n === "BILLDESK" ? Te(t == null ? void 0 : t.token_url, "mandate-billdesk", ke, Ee) : ve().then((x) => {
      var v, i;
      const { emailData: m, phoneData: o } = x, N = {
        amount: 0,
        currency: "INR",
        method: "emandate",
        bank: "ICIC",
        email: (v = m == null ? void 0 : m.data[0]) == null ? void 0 : v.email,
        contact: (i = o == null ? void 0 : o.data[0]) == null ? void 0 : i.number,
        order_id: t.sdk_options.order_id,
        customer_id: t.sdk_options.customer_id
      }, b = Ge(t.sdk_options.key);
      b.createPayment(N), b.on("payment.success", function(d) {
        g.submitPayment(t.sdk_options.callback_url, d.razorpay_payment_id, d.razorpay_order_id, d.razorpay_signature).then(() => {
          c && (window.location.href = window.location.origin + `/mandates?id=${c[0]}`), V(), r("initial"), Q(!1);
        });
      }), b.on("payment.error", function(d) {
        g.submitPaymentError(t.sdk_options.callback_url, "error", d.error.code, d.error.description).then(() => {
          c && (window.location.href = window.location.origin + `/mandates?id=${c[0]}`), V(), r("initial");
        });
      });
    });
  }
  function pe(t) {
    var x;
    h(!0);
    const n = {
      mandate_id: t == null ? void 0 : t.id
    };
    ((x = p == null ? void 0 : p.app_constants) == null ? void 0 : x.paymentGateway) === "BILLDESK" && (n.payment_postback_url = `${window.location.origin + G}/redirection`), g.fpClient.mandates().authorize(n).then((m) => {
      Me(m, t == null ? void 0 : t.provider_name);
    }).catch((m) => {
      window.debug.error(m), h(!1), S(m);
    });
  }
  function Ne(t) {
    t.success ? (r("initial"), k ? V() : pe(t == null ? void 0 : t.mandate)) : r("error");
  }
  function Ae(t) {
    B({
      index: t.target.value,
      obj: E[t.target.value]
    });
  }
  const De = async (t) => {
    try {
      h(!0), _(t);
      const n = await g.fpClient.mf_investment_accounts().fetchAll({
        investor: l.pan
      }), o = (await g.fpClient.mf_purchase_plans().fetchAll({
        mf_investment_account: n.data[0].id
      })).data.filter((N) => Number(N.payment_source) === t.id);
      o != null && o.length ? de(!oe) : me(!ue), h(!1);
    } catch (n) {
      window.debug.error(n), h(!1), S(n);
    }
  }, Se = async () => {
    try {
      await g.fpClient.mandates().cancel(y == null ? void 0 : y.id), r("mandate_cancel_status");
    } catch (t) {
      window.debug.error(t), S(t);
    }
  }, P = (t) => {
    const n = t.mandate_status;
    n === "APPROVED" ? De(t) : (n === "CREATED" || n === "SUBMITTED") && (k ? window.open(Ve(R) + `/mandates?id=${t == null ? void 0 : t.id}`, "_blank", "noopener=true") : pe(t));
  }, Le = (t) => {
    const n = E.concat(W).filter((x) => x.id === t);
    n && P(n[0]);
  }, Fe = (t) => /* @__PURE__ */ e.jsx(L, {
    variant: "secondary",
    fullwidth: !0,
    onClick: () => {
      window.location.href = window.location.origin + G + `/plans-list?mandate_id=${t}`;
    },
    children: a._({
      id: "g+sYqK"
    })
  }), he = (t, n) => {
    var x, m, o, N;
    return /* @__PURE__ */ e.jsx(te, {
      footer: n && fe(t),
      onClick: () => P(t),
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "JVRi6u"
              }),
              value: `${(x = t.bank) == null ? void 0 : x.bank_name} (*****${(m = t.bank) == null ? void 0 : m.number.slice(-4)})`,
              logo: F[(N = (o = t == null ? void 0 : t.bank) == null ? void 0 : o.ifsc) == null ? void 0 : N.slice(0, 4)] || D
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "OzNl8x"
              }),
              value: t ? z[t.mandate_status].value : "-",
              icon: t ? z[t.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "nlXCcY"
              }),
              value: "NACH"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "Zp6kam"
              }),
              value: `₹ ${q(t.mandate_limit)}`
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(j, {
              label: a._({
                id: "0OJ5oD"
              }),
              value: ne(new Date(t.valid_from), "dd-MMM-yyyy")
            })
          })
        ]
      })
    }, t.id);
  }, _e = () => l ? H ? /* @__PURE__ */ e.jsx(le, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs("div", {
    className: "pb-20",
    children: [
      !c && /* @__PURE__ */ e.jsx(ae, {
        type: "text",
        text: I
      }),
      w && E.length ? (
        // Mandate With Select property
        /* @__PURE__ */ e.jsx(Ie, {
          variant: "selectable",
          selected: 0,
          onChange: Ae,
          children: E.map((t) => {
            var n, x, m, o;
            return /* @__PURE__ */ e.jsx(te, {
              footer: fe(t),
              onClick: () => P(t),
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-mr-2 -ml-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-full pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "JVRi6u"
                      }),
                      value: `${(n = t.bank) == null ? void 0 : n.bank_name} (*****${(x = t.bank) == null ? void 0 : x.number.slice(-4)})`,
                      logo: F[(o = (m = t == null ? void 0 : t.bank) == null ? void 0 : m.ifsc) == null ? void 0 : o.slice(0, 4)] || D
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "OzNl8x"
                      }),
                      value: t ? z[t.mandate_status].value : "-",
                      icon: t ? z[t.mandate_status].icon : "",
                      iconPlacement: "left"
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "nlXCcY"
                      }),
                      value: "NACH"
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "Zp6kam"
                      }),
                      value: `₹ ${q(t.mandate_limit)}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(j, {
                      label: a._({
                        id: "0OJ5oD"
                      }),
                      value: ne(new Date(t.valid_from), "dd-MMM-yyyy")
                    })
                  })
                ]
              })
            }, t.id);
          })
        })
      ) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      W.length ? /* @__PURE__ */ e.jsx(e.Fragment, {
        children: W.map((t) => he(t, !0))
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      !W.length && !E.length ? /* @__PURE__ */ e.jsx(ie, {
        title: a._({
          id: "cVDNPn"
        }),
        description: a._({
          id: "qkY58T"
        }),
        showDetails: !1,
        showAction: !1
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      /* @__PURE__ */ e.jsxs(be, {
        title: a._({
          id: "C7VNHg"
        }),
        isOpen: oe,
        toggleModal: () => {
          de(!1);
        },
        contentHeightMax: !1,
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "pb-5",
            children: a._({
              id: "aUwXDf"
            })
          }),
          Fe(y == null ? void 0 : y.id)
        ]
      }),
      /* @__PURE__ */ e.jsx(be, {
        title: a._({
          id: "th6zPi"
        }),
        variant: "fullPage",
        isOpen: ue,
        toggleModal: () => {
          me(!1);
        },
        contentHeightMax: !1,
        children: y ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            he(y, !1),
            /* @__PURE__ */ e.jsx(Z, {
              type: "important",
              variant: "flash",
              title: a._({
                id: "tFTUWJ"
              }),
              customClass: "mb-5"
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-1",
              children: /* @__PURE__ */ e.jsx(L, {
                variant: "primary",
                type: "submit",
                fullwidth: !0,
                onClick: Se,
                children: a._({
                  id: "H/E6fZ"
                })
              })
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      }),
      c && ye && !k && (W.length || E.length) ? /* @__PURE__ */ e.jsx(L, {
        variant: "primary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: () => Le(c[0]),
        children: a._({
          id: "gnQNf6"
        })
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
      /* @__PURE__ */ e.jsxs(we, {
        children: [
          w ? /* @__PURE__ */ e.jsx("div", {
            className: "mb-4",
            children: /* @__PURE__ */ e.jsx(L, {
              variant: E.length ? "primary" : "disabled",
              fullwidth: !0,
              onClick: (t) => {
                t.preventDefault(), w(U.obj);
              },
              children: a._({
                id: "H/E6fZ"
              })
            })
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
          c ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : s ? /* @__PURE__ */ e.jsx("div", {
            className: "mb-4",
            children: /* @__PURE__ */ e.jsx(L, {
              variant: "secondary",
              fullwidth: !0,
              onClick: () => {
                r("create_mandate");
              },
              children: a._({
                id: "EkzoDS"
              })
            })
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
        ]
      })
    ]
  }) : /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Z, {
      type: "important",
      variant: "flash",
      title: a._({
        id: "uUf3a6"
      }),
      customClass: "mb-5"
    })
  }), Re = () => {
    switch (C) {
      case "initial":
        return _e();
      case "create_mandate":
        return /* @__PURE__ */ e.jsx(Ke, {
          investorProfile: l,
          onCompletion: (t) => {
            Ne(t);
          },
          isPartner: k
        });
      case "mandate_cancel_status":
        return /* @__PURE__ */ e.jsx(Ye, {
          currentMandateId: y.id,
          investorProfile: l
        });
      case "error":
        return /* @__PURE__ */ e.jsx(ie, {
          title: a._({
            id: "ygb3I+"
          }),
          description: a._({
            id: "02oCtw"
          }),
          showAction: !0,
          actionText: a._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            r("initial");
          }
        });
      default:
        return _e();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: Re()
  });
}
export {
  mt as M,
  z as m,
  Ge as r
};
