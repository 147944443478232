import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as r, useState as n, useEffect as m } from "react";
import { AppContext as E } from "./AppContext.js";
import { F as W } from "./provider-DPPRdC-N.js";
import { LoginContext as _ } from "./LoginContext.js";
import { P as v, a as I } from "./index.esm-DT3FM8Og.js";
import { i as e } from "./index-Bb24DwqC.js";
import { r as T } from "./sentry-CbZOQ4Ya.js";
import { W as D } from "./Welcome-CCMlcQ8T.js";
import { ErrorPage as G } from "./ErrorPage.js";
import { K as U } from "./KycRequestStatus-pSvjGxxw.js";
import { A as $ } from "./Auth-tZKY7y12.js";
function Y({ kycRequestId: d }) {
  const { fpapi: p } = r(W), { loggedInUser: g } = r(_), { AppState: { showWelcomePage: c }, setShowWelcomePage: w } = r(E), [h, y] = n(null), [x, a] = n(!0), [l, s] = n(""), [k, u] = n(f), j = [
    {
      step: 1,
      content: e._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: e._({
        id: "4a8XFv"
      })
    }
  ];
  m(() => {
    (async () => g === void 0 ? (a(!1), s("login")) : (await S(d), a(!1)))();
  }, []);
  async function S(L) {
    try {
      const i = await p.fpClient.kyc_requests().fetch(L);
      y(i), s("kycRequestStatus");
    } catch (i) {
      s("invalidKycRequestId"), T(i);
      return;
    }
  }
  m(() => {
    u(f());
  }, [
    c
  ]);
  function f() {
    return c ? "welcome" : "kycStatus";
  }
  function R() {
    w(!1), u("kycStatus");
  }
  const o = `${window.location.origin}/kyc`, A = () => {
    window.location.href = o;
  }, q = () => {
    window.location.href = o;
  }, C = () => {
    window.location.href = o;
  }, F = () => {
    window.location.href = o;
  }, P = () => {
    if (l)
      switch (l) {
        default:
        case "login":
          return /* @__PURE__ */ t.jsx(t.Fragment, {
            children: /* @__PURE__ */ t.jsx($, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              showTestPan: {
                kycNonCompliant: !0
              }
            })
          });
        case "kycRequestStatus":
          return /* @__PURE__ */ t.jsx(U, {
            kycRequestObject: h,
            handlePendingAction: A,
            handleEsignAction: q,
            handleRejectedAction: C,
            handleExpiredAction: F
          });
        case "invalidKycRequestId":
          return /* @__PURE__ */ t.jsx(G, {
            title: e._({
              id: "/GPAi2"
            }),
            description: e._({
              id: "QVTe/2"
            }),
            showDetails: !1,
            showAction: !1
          });
      }
  };
  function K() {
    return k === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(v, {
          title: e._({
            id: "ki70re"
          })
        }),
        /* @__PURE__ */ t.jsx(D, {
          onAck: R,
          welcomeSteps: j
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: x ? /* @__PURE__ */ t.jsx(I, {
        variant: "fullpage"
      }) : /* @__PURE__ */ t.jsx(t.Fragment, {
        children: P()
      })
    });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: K()
  });
}
export {
  Y as KycStatus
};
