import { j as a } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as be, useRef as V, useState as w, useEffect as R, useMemo as xe } from "react";
import { R as je, F } from "./react-final-form.es-ZoroCK1B.js";
import { a as Fe, P as Ce, D as A, e as q, T as N, M as g, i as ve, j as pe, B as we, f as qe, h as k } from "./index.esm-DT3FM8Og.js";
import { r as U, f as $, c as P, g as D, h as ee, i as Ee, j as Xe, s as Ne } from "./validators-BArvL7cB.js";
import { u as I, v as Ue, w as ye, o as J, j as ae, s as Se, t as Be, x as Le, y as Te } from "./utils-DbnU7rHI.js";
import { F as Me } from "./provider-DPPRdC-N.js";
import { r as E } from "./sentry-CbZOQ4Ya.js";
import { C as te } from "./CopyButton-B9meldQw.js";
import { i as n } from "./index-Bb24DwqC.js";
function Ge({ investorProfile: i, onCompletion: y, disableBankVerification: ne = !1, kycDetails: l, needsCancelledCheque: Z, bankAccountType: z = "savings", isGroupInvestment: re, isPartner: O, formInitialValues: d, handleGoBack: K, hideTitle: ce = !1, formError: Q, folioDetails: x }) {
  var H;
  const { fpapi: f } = be(Me), S = V(null), [B, ue] = w([]), [W, ie] = w(null), [Y, C] = w(null), [le, _] = w(!1), [L, se] = w({
    cancelled_cheque: null
  }), v = V({
    cancelled_cheque: !1
  }), T = "123451199", M = "123454567", X = {}, j = [
    {
      label: n._({
        id: "yPXMA2"
      }),
      value: "current"
    },
    {
      label: n._({
        id: "LnQorC"
      }),
      value: "nre"
    },
    {
      label: n._({
        id: "5sZEJD"
      }),
      value: "nro"
    },
    {
      label: n._({
        id: "qLI80o"
      }),
      value: "savings"
    }
  ];
  R(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const me = async (t) => {
    const e = await f.fpClient.files().fetch(t), r = e.url, c = await (await fetch(r)).blob();
    return {
      file: new File([
        c
      ], e == null ? void 0 : e.filename, {
        type: c.type
      }),
      fileObj: e
    };
  };
  R(() => {
    (async () => {
      if (i != null && i.id)
        try {
          _(!0);
          const { data: t } = await f.fpClient.bank_accounts().fetchAll({
            profile: i.id
          });
          ue(t);
        } catch (t) {
          window.debug.error(t), C(n._({
            id: "J56bGZ"
          })), E(t);
        } finally {
          _(!1);
        }
    })();
  }, [
    i == null ? void 0 : i.id
  ]), R(() => {
    (async () => {
      var t;
      try {
        _(!0);
        const e = ((t = l == null ? void 0 : l.bank_account) == null ? void 0 : t.proof) || (d == null ? void 0 : d.cancelled_cheque);
        if (e) {
          const r = await me(e);
          ie(r.file), se({
            cancelled_cheque: r.fileObj
          });
        }
      } catch (e) {
        window.debug.error(e), C(n._({
          id: "J56bGZ"
        })), E(e);
      } finally {
        _(!1);
      }
    })();
  }, [
    (H = l == null ? void 0 : l.bank_account) == null ? void 0 : H.proof,
    d == null ? void 0 : d.cancelled_cheque
  ]);
  const de = xe(() => {
    var e, r, m, c;
    let t = {
      ifsc: null,
      account_number: null,
      account_number_again: null,
      cancelled_cheque: W,
      type: j[I(j, z)]
    };
    if (x) {
      const u = (r = (e = x == null ? void 0 : x.payout_details) == null ? void 0 : e[0]) == null ? void 0 : r.bank_account;
      t.ifsc = u == null ? void 0 : u.ifsc, t.account_number = u == null ? void 0 : u.number, t.account_number_again = u == null ? void 0 : u.number, t.type = j[I(j, u == null ? void 0 : u.account_type)] || t.type;
    }
    if (l) {
      const u = {
        ifsc_code: {
          initFieldName: "ifsc",
          initFieldValue: ((m = l == null ? void 0 : l.bank_account) == null ? void 0 : m.ifsc_code) || (t == null ? void 0 : t.ifsc)
        },
        account_number: {
          initFieldName: "account_number",
          initFieldValue: ((c = l == null ? void 0 : l.bank_account) == null ? void 0 : c.account_number) || (t == null ? void 0 : t.account_number)
        }
      }, s = Ue(u, l);
      t.account_number_again = (s == null ? void 0 : s.account_number) || t.account_number_again, t = {
        ...t,
        ...s
      };
    }
    return d && (t = {
      ...t,
      ifsc: d.ifsc_code,
      account_number: d.account_number,
      account_number_again: d.account_number
    }), t;
  }, [
    d,
    W,
    x
  ]), oe = {
    "image/jpeg": 1,
    "image/png": 1,
    "image/jpg": 1,
    "image/heic": 1,
    "application/pdf": 1
  };
  function G(t) {
    const { profile: e, primary_account_holder_name: r, account_number: m, type: c, ifsc_code: u } = t ?? {};
    return `${e ?? ""}${r ?? ""}${m ?? ""}${c ?? ""}${u ?? ""}`.toLowerCase();
  }
  const _e = (t, e) => e == null ? void 0 : e.payout_details.some((r) => {
    var m;
    return ((m = r == null ? void 0 : r.bank_account) == null ? void 0 : m.number) == (t == null ? void 0 : t.account_number);
  });
  async function he({ ifsc: t, account_number: e, cancelled_cheque: r, type: m }) {
    var u;
    _(!0);
    const c = {
      profile: i == null ? void 0 : i.id,
      primary_account_holder_name: i == null ? void 0 : i.name,
      account_number: e,
      ifsc_code: t,
      type: m == null ? void 0 : m.value
    };
    if (Z && r)
      try {
        C(null);
        const s = r, o = "cancelled_cheque", h = s.type.split("/")[1];
        let b = null;
        if ((u = v == null ? void 0 : v.current) != null && u[o])
          b = L == null ? void 0 : L.cancelled_cheque;
        else {
          const p = new FormData();
          p.append("file", s, `${e}_${o}.${h}`), p.append("purpose", o), b = await f.fpClient.files().create(p);
        }
        c.cancelled_cheque = b.id;
      } catch (s) {
        _(!1), C(n._({
          id: "RPD/bK"
        })), window.debug.log(s), E(s);
        return;
      }
    if (i)
      try {
        const s = G(c), o = B == null ? void 0 : B.find((p) => G(p) === s);
        let h = o, b = !0;
        _e(c, x) && (b = !1), o ? c != null && c.cancelled_cheque ? h = await f.fpClient.bank_accounts().update({
          id: o == null ? void 0 : o.id,
          cancelled_cheque: c == null ? void 0 : c.cancelled_cheque
        }) : b = !1 : h = await f.fpClient.bank_accounts().create(c), !ne && b && await f.fpClient.bank_account_verifications().create({
          bank_account: h == null ? void 0 : h.id
        }), y({
          success: !0,
          bankAccount: h
        });
      } catch (s) {
        window.debug.error(s), C(n._({
          id: "EkWk74"
        })), y({
          success: !1,
          bankAccount: s
        }), E(s);
      } finally {
        _(!1);
      }
    else
      y({
        success: !0,
        bankAccount: c
      }), _(!1);
  }
  const ge = Le(async (t) => {
    if (S.current.change("ifsc_branch_name", void 0), !t)
      return n._({
        id: "7rkFUv"
      });
    if (t && !/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(t))
      return n._({
        id: "puUofk"
      });
    try {
      const e = await f.fpClient.master_data().fetchIfscCodes(t);
      S.current.change("ifsc_branch_name", `${Te(e == null ? void 0 : e.bank_name)}, ${ae(e.branch_name)}`);
      return;
    } catch (e) {
      return E(e), n._({
        id: "aIhTt6"
      });
    }
  }), fe = () => re ? n._({
    id: "Ol3oy2"
  }) : O ? n._({
    id: "ZL/hhl"
  }) : n._({
    id: "zUm8oD"
  });
  return /* @__PURE__ */ a.jsxs(a.Fragment, {
    children: [
      le && /* @__PURE__ */ a.jsx(Fe, {
        variant: "fullpage"
      }),
      ce ? /* @__PURE__ */ a.jsx(a.Fragment, {}) : /* @__PURE__ */ a.jsxs(a.Fragment, {
        children: [
          /* @__PURE__ */ a.jsx(Ce, {
            title: n._({
              id: "wQXcyz"
            }),
            icon: K ? "Back" : null,
            onClick: K
          }),
          /* @__PURE__ */ a.jsx(A, {
            type: "text",
            text: fe()
          })
        ]
      }),
      /* @__PURE__ */ a.jsx(je, {
        initialValues: de,
        onSubmit: he,
        mutators: {
          mutateField: ye
        },
        validate: (t) => {
          const e = {};
          return t.ifsc || (e.ifsc = n._({
            id: "7rkFUv"
          })), t.account_number || (e.account_number = n._({
            id: "7rkFUv"
          })), t.account_number_again || (e.account_number_again = n._({
            id: "7rkFUv"
          })), t.account_number !== t.account_number_again && (e.account_number_again = n._({
            id: "tq0aVc"
          })), e;
        },
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (t) => (S.current = t.form, /* @__PURE__ */ a.jsx(a.Fragment, {
          children: /* @__PURE__ */ a.jsxs("form", {
            onSubmit: t.handleSubmit,
            children: [
              /* @__PURE__ */ a.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ a.jsx(q, {
                    message: n._({
                      id: "JzJ/t2"
                    })
                  }),
                  /* @__PURE__ */ a.jsx(F, {
                    name: "type",
                    validate: U,
                    validateFields: [],
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (e) => {
                      var r;
                      return /* @__PURE__ */ a.jsxs(a.Fragment, {
                        children: [
                          /* @__PURE__ */ a.jsx(N, {
                            name: e.input.name,
                            value: (r = j[I(j, z)]) == null ? void 0 : r.label,
                            disabled: !0,
                            id: e.input.name,
                            autoComplete: "off"
                          }),
                          e.meta.error && e.meta.touched && /* @__PURE__ */ a.jsx(g, {
                            type: "error",
                            variant: "field",
                            title: e.meta.error
                          })
                        ]
                      });
                    }
                  })
                ]
              }),
              X.bank_account ? null : /* @__PURE__ */ a.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ a.jsx(q, {
                    message: n._({
                      id: "llxwO0"
                    })
                  }),
                  /* @__PURE__ */ a.jsx(F, {
                    name: "ifsc",
                    validate: ge,
                    parse: (e) => e.toUpperCase(),
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (e) => /* @__PURE__ */ a.jsxs(a.Fragment, {
                      children: [
                        /* @__PURE__ */ a.jsx(N, {
                          id: e.input.name,
                          autoComplete: "off",
                          name: e.input.name,
                          onChange: (r) => {
                            const m = r.target.selectionStart, c = r.target;
                            window.requestAnimationFrame(() => {
                              c.selectionStart = m, c.selectionEnd = m;
                            }), e.input.onChange(r.target.value);
                          },
                          onBlur: () => {
                            e.input.onChange(e.input.value.trim());
                          },
                          onInput: (r) => {
                            r.target.value = r.target.value.replace(J("A-Za-z0-9"), "");
                          },
                          value: e.input.value,
                          placeholder: n._({
                            id: "6UdBTV"
                          }),
                          maxLength: 11,
                          status: e.meta.error && e.meta.touched ? "error" : void 0,
                          autoFocus: !0
                        }),
                        e.meta.error && e.meta.touched && /* @__PURE__ */ a.jsx(g, {
                          type: "error",
                          variant: "field",
                          title: e.meta.error
                        })
                      ]
                    })
                  }),
                  /* @__PURE__ */ a.jsx(F, {
                    name: "ifsc_branch_name",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (e) => /* @__PURE__ */ a.jsx(a.Fragment, {
                      children: e.input.value && /* @__PURE__ */ a.jsx(g, {
                        type: "success",
                        variant: "field",
                        title: e.input.value
                      })
                    })
                  })
                ]
              }),
              X.bank_account ? null : /* @__PURE__ */ a.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ a.jsx(q, {
                    message: n._({
                      id: "+Y8YJk"
                    })
                  }),
                  /* @__PURE__ */ a.jsx(F, {
                    name: "account_number",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: $(U, ee(7), D(16), P(n._({
                      id: "q17d/W"
                    }))),
                    children: (e) => /* @__PURE__ */ a.jsxs(a.Fragment, {
                      children: [
                        /* @__PURE__ */ a.jsx(N, {
                          id: e.input.name,
                          autoComplete: "off",
                          name: e.input.name,
                          onChange: e.input.onChange,
                          value: e.input.value,
                          placeholder: n._({
                            id: "CjEOh3"
                          }),
                          variant: "inputSecure",
                          onInput: (r) => {
                            r.target.value = r.target.value.replace(J("0-9"), "");
                          },
                          onBlur: () => {
                            e.input.onChange(e.input.value.trim());
                          },
                          maxLength: 16,
                          status: e.meta.error && e.meta.touched ? "error" : void 0
                        }),
                        /* @__PURE__ */ a.jsx(ve, {
                          variant: "note",
                          message: i || l ? n._({
                            id: "TJwogM",
                            values: {
                              0: ae((i == null ? void 0 : i.name) || (l == null ? void 0 : l.name))
                            }
                          }) : n._({
                            id: "6cR+p0"
                          }),
                          status: "warning"
                        }),
                        e.meta.error && e.meta.touched && /* @__PURE__ */ a.jsx(g, {
                          type: "error",
                          variant: "field",
                          title: e.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              X.bank_account ? null : /* @__PURE__ */ a.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ a.jsx(q, {
                    message: n._({
                      id: "F/uxfF"
                    })
                  }),
                  /* @__PURE__ */ a.jsx(F, {
                    name: "account_number_again",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    validate: $(U, ee(7), D(16), P(n._({
                      id: "fUpujA"
                    }))),
                    children: (e) => /* @__PURE__ */ a.jsxs(a.Fragment, {
                      children: [
                        /* @__PURE__ */ a.jsx(N, {
                          id: e.input.name,
                          autoComplete: "off",
                          name: e.input.name,
                          onChange: e.input.onChange,
                          value: e.input.value,
                          placeholder: n._({
                            id: "+c3wnX"
                          }),
                          onInput: (r) => {
                            r.target.value = r.target.value.replace(J("0-9"), "");
                          },
                          onBlur: () => {
                            e.input.onChange(e.input.value.trim());
                          },
                          maxLength: 16,
                          status: e.meta.error && e.meta.touched ? "error" : void 0
                        }),
                        e.meta.error && e.meta.touched && /* @__PURE__ */ a.jsx(g, {
                          type: "error",
                          variant: "field",
                          title: e.meta.error
                        })
                      ]
                    })
                  })
                ]
              }),
              X.bank_account ? null : Z && /* @__PURE__ */ a.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ a.jsx(q, {
                    message: n._({
                      id: "EtZCNu"
                    })
                  }),
                  /* @__PURE__ */ a.jsx(A, {
                    type: "labelDesc",
                    text: n._({
                      id: "lHonbg"
                    })
                  }),
                  /* @__PURE__ */ a.jsx(F, {
                    name: "cancelled_cheque",
                    validate: $(O ? Ne : U, Xe(5, n._({
                      id: "Jw6xX2"
                    })), Ee(oe, n._({
                      id: "ULNkDS"
                    }))),
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0,
                      pristine: !0
                    },
                    validateFields: [],
                    children: (e) => {
                      var r;
                      return v.current = {
                        ...v.current,
                        cancelled_cheque: ((r = e == null ? void 0 : e.meta) == null ? void 0 : r.pristine) ?? !1
                      }, /* @__PURE__ */ a.jsxs(a.Fragment, {
                        children: [
                          /* @__PURE__ */ a.jsx(pe, {
                            value: e.input.value,
                            inputName: "cancelled_cheque",
                            handleFile: e.input.onChange
                          }),
                          e.meta.error && e.meta.touched ? /* @__PURE__ */ a.jsx(g, {
                            type: "error",
                            variant: "field",
                            title: e.meta.error
                          }) : /* @__PURE__ */ a.jsx(g, {
                            type: "helper",
                            variant: "field",
                            title: n._({
                              id: "OIjqUC"
                            })
                          })
                        ]
                      });
                    }
                  })
                ]
              }),
              Y || Q ? /* @__PURE__ */ a.jsx(g, {
                type: "error",
                variant: "field",
                title: Y || Q
              }) : null,
              /* @__PURE__ */ a.jsx(we, {
                type: "submit",
                fullwidth: !0,
                onClick: () => {
                  const e = Object.keys(t.errors);
                  e.length && Se(e[0]);
                },
                customClass: "mb-4",
                children: n._({
                  id: "45cMrb"
                })
              }),
              Be.isStagingOrSandbox && /* @__PURE__ */ a.jsxs(qe, {
                title: "Use the below bank account format in sandbox",
                customClass: "mt-4",
                children: [
                  /* @__PURE__ */ a.jsx(k, {
                    title: "For successful digital verification replace X with any numbers",
                    label: "XXXXX1199",
                    customClass: "mb-3",
                    children: /* @__PURE__ */ a.jsx(te, {
                      value: T,
                      onClick: () => {
                        t.form.mutators.mutateField("account_number", T), t.form.mutators.mutateField("account_number_again", T);
                      }
                    })
                  }),
                  /* @__PURE__ */ a.jsx(k, {
                    title: "For digital verification failure replace X with any numbers",
                    label: "XXXXX4567",
                    customClass: "mb-3",
                    children: /* @__PURE__ */ a.jsx(te, {
                      value: M,
                      onClick: () => {
                        t.form.mutators.mutateField("account_number", M), t.form.mutators.mutateField("account_number_again", M);
                      }
                    })
                  })
                ]
              })
            ]
          })
        }))
      })
    ]
  });
}
export {
  Ge as B
};
