import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as le, useState as p, useEffect as k } from "react";
import { a as ae, P as K, e as _e, j as Ce, M as w, B as M, D as we, F as Ie, d as Ee, S as D, c as v } from "./index.esm-DT3FM8Og.js";
import { B as Fe } from "./BankAccountAddition-DEtYWKqe.js";
import { R as ye, F as Ne } from "./react-final-form.es-ZoroCK1B.js";
import { f as Le, i as ve, n as Be, r as qe } from "./validators-BArvL7cB.js";
import { F as re } from "./provider-DPPRdC-N.js";
import { j as Te } from "./utils-DbnU7rHI.js";
import { r as I } from "./sentry-CbZOQ4Ya.js";
import { u as Oe } from "./useInterval-9AWKrr3w.js";
import { u as Pe } from "./useConfiguration-B-lyaBB9.js";
import { i as u } from "./index-Bb24DwqC.js";
function De({ bankAccount: l, onCompletion: i }) {
  const { fpapi: j } = le(re), V = {
    "image/jpeg": 1,
    "image/png": 1,
    "image/jpg": 1,
    "application/pdf": 1
  }, [B, E] = p(null), [q, F] = p(null), [c, o] = p(!1), _ = (a) => !l || !a ? !1 : !(l != null && l.cancelled_cheque) && (a == null ? void 0 : a.status) === "completed" && (a == null ? void 0 : a.reason) === "digital_verification" && (a == null ? void 0 : a.confidence) === "zero";
  k(() => {
    (async () => {
      try {
        if (!l) {
          i({
            success: !1,
            error: {
              reason: "NO_BANK_OBJECT"
            }
          });
          return;
        }
        const a = await j.fpClient.bank_account_verifications().fetchAll({
          bank_accounts: [
            l == null ? void 0 : l.id
          ]
        });
        _(a.data[0]) ? F(a.data[0]) : i({
          success: !1,
          error: {
            reason: "NO_VERIFICATION"
          }
        });
      } catch (a) {
        window.debug.log(a), I(a), i({
          success: !1,
          error: {}
        });
      }
    })();
  }, []);
  async function b({ cancelled_cheque: a }) {
    const m = {
      id: l == null ? void 0 : l.id
    }, f = l == null ? void 0 : l.account_number;
    try {
      E(!1), o(!0);
      const n = a, y = "cancelled_cheque", R = n.type.split("/")[1], g = new FormData();
      g.append("file", n, `${f}_${y}.${R}`), g.append("purpose", y);
      const $ = await j.fpClient.files().create(g);
      m.cancelled_cheque = $.id, o(!1);
    } catch (n) {
      o(!1), E(!0), window.debug.log(n), I(n);
      return;
    }
    try {
      o(!0);
      const n = await j.fpClient.bank_accounts().update(m);
      o(!1), i({
        success: !0,
        data: n
      });
    } catch (n) {
      window.debug.error(n), E(!0), o(!1), i({
        success: !1,
        error: (n == null ? void 0 : n.data) || n
      }), I(n);
    }
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      c && /* @__PURE__ */ e.jsx(ae, {
        variant: "fullpage"
      }),
      q && l && /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          /* @__PURE__ */ e.jsx(K, {
            title: "Add cancelled cheque"
          }),
          /* @__PURE__ */ e.jsx(K, {
            variant: "sub",
            title: "Please provide a cancelled cheque copy so that we can verify your bank account.",
            customClass: "mb-4"
          }),
          /* @__PURE__ */ e.jsx(ye, {
            onSubmit: b,
            validate: () => ({}),
            subscription: {
              submitting: !0,
              values: !0
            },
            children: (a) => /* @__PURE__ */ e.jsxs("form", {
              onSubmit: a.handleSubmit,
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-2 mt-2",
                  children: [
                    /* @__PURE__ */ e.jsx(_e, {
                      message: "Cancelled cheque"
                    }),
                    /* @__PURE__ */ e.jsx(Ne, {
                      name: "cancelled_cheque",
                      validate: Le(qe, Be(5, "File size should be less than 5 MB"), ve(V, "Format not supported!")),
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validateFields: [],
                      children: (m) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(Ce, {
                            inputName: "cancelled_cheque",
                            handleFile: m.input.onChange
                          }),
                          m.meta.error && m.meta.touched && /* @__PURE__ */ e.jsx(w, {
                            type: "error",
                            variant: "field",
                            title: m.meta.error
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(w, {
                  type: "important",
                  variant: "field",
                  title: `Cancelled cheque should belong to ${Te(l == null ? void 0 : l.primary_account_holder_name)}`,
                  customClass: "mb-10"
                }),
                B ? /* @__PURE__ */ e.jsx(w, {
                  type: "error",
                  variant: "field",
                  title: "Cannot add cancelled cheque"
                }) : null,
                /* @__PURE__ */ e.jsx(M, {
                  fullwidth: !0,
                  children: "Add cancelled cheque"
                })
              ]
            })
          })
        ]
      })
    ]
  });
}
function Ze({ investorProfile: l, handleBankSelection: i, handleSkipBankAddition: j, removeTitle: V, customPageTitle: B, customPageSubtitle: E, alertMessage: q }) {
  const { fpapi: F } = le(re), { tenantConfig: c, banks: o } = Pe(), _ = c == null ? void 0 : c.fallbackLogo, b = E || u._({
    id: "fCc+oz"
  }), a = {
    digital_verification: !1,
    expiry: !0
  }, [m, f] = p("initial"), [n, y] = p({
    index: 0,
    obj: null
  }), [R, g] = p(!1), [$, Y] = p(null), [r, ne] = p({
    FAILED: [],
    PENDING: [],
    COMPLETE: [],
    NOVERIFICATION: []
  });
  k(() => {
    (async () => await N())();
  }, []);
  const de = 5 * 60 * 1e3, S = (c == null ? void 0 : c.app_constants.bank_short_poll_interval) || 3e4, ie = (c == null ? void 0 : c.app_constants.bank_long_poll_interval) || 18e4, [G, Z] = p(!0), [H, J] = p(S);
  Oe(() => {
    (async () => await N())();
  }, G ? H : null), k(() => {
    if (G) {
      H !== S && J(S);
      const t = setTimeout(() => {
        J(ie);
      }, de);
      return () => clearInterval(t);
    } else
      return;
  }, [
    G
  ]);
  const ce = (t, s) => (t == null ? void 0 : t.cancelled_cheque) && (s == null ? void 0 : s.status) === "completed" && (s == null ? void 0 : s.reason) === "digital_verification" && (s == null ? void 0 : s.confidence) === "zero", oe = (t, s) => !(t != null && t.cancelled_cheque) && // no cancelled cheque
  (s == null ? void 0 : s.status) === "completed" && // verification status is complete
  (s == null ? void 0 : s.reason) === "digital_verification" && // reason is digital_verification
  (s == null ? void 0 : s.confidence) === "zero", N = async () => {
    g(!0);
    const t = await ue();
    if (t) {
      const s = t.map((d) => {
        const T = d.identifier ? String(d.identifier) : String(d.id);
        return F.fpClient.bank_account_verifications().fetchAll({
          bank_accounts: [
            T
          ]
        });
      });
      Promise.allSettled(s).then((d) => {
        const T = [], te = [], L = [], O = [];
        d.forEach((C, z) => {
          if (C.status === "fulfilled") {
            const { data: U } = C.value, x = U[0], h = {
              ...t[z]
            };
            x ? (h.verification = x, x.status === "completed" ? ce(h, x) || oe(h, x) ? L.push(h) : O.push(h) : x.status === "pending" ? L.push(h) : x.status === "failed" && te.push(h)) : T.push(h);
          } else C.status === "rejected" && window.debug.log(C);
        });
        const P = (C) => C.sort((z, U) => Number(new Date(U.updated_at)) - Number(new Date(z.updated_at)));
        ne({
          FAILED: [
            ...P(te)
          ],
          PENDING: [
            ...P(L)
          ],
          COMPLETE: [
            ...P(O)
          ],
          NOVERIFICATION: [
            ...P(T)
          ]
        }), i && O.length && y({
          index: 0,
          obj: O[0]
        }), L.length ? Z(!0) : Z(!1), g(!1);
      }).catch((d) => {
        window.debug.log(d), g(!1), I(d);
      });
    }
  }, ue = async () => {
    try {
      if (l != null && l.id)
        return (await F.fpClient.bank_accounts().fetchAll({
          profile: l == null ? void 0 : l.id
        })).data;
    } catch (t) {
      window.debug.error(t), I(t);
    }
  }, pe = ({ success: t }) => {
    (async () => (t && await N(), f("initial")))();
  }, me = (t) => {
    y({
      index: t.target.value,
      obj: r.COMPLETE[t.target.value]
    });
  }, W = (t) => {
    const s = t.identifier ? String(t.identifier) : String(t.id);
    F.fpClient.bank_account_verifications().create({
      bank_account: s
    }).then(() => {
      N();
    }).catch((d) => {
      window.debug.log(d), I(d);
    });
  }, he = ({ success: t }) => {
    (async () => t ? (await N(), Y(null), f("initial")) : (Y(null), f("initial")))();
  }, fe = () => V ? "" : B || (i ? r.COMPLETE.length ? u._({
    id: "a8BKSX"
  }) : u._({
    id: "+acdIM"
  }) : ""), X = () => {
    var t, s;
    return (t = r == null ? void 0 : r.COMPLETE) != null && t.length ? (s = r == null ? void 0 : r.COMPLETE) == null ? void 0 : s.map((d) => /* @__PURE__ */ e.jsx(D, {
      children: /* @__PURE__ */ e.jsx("div", {
        children: /* @__PURE__ */ e.jsxs("div", {
          children: [
            /* @__PURE__ */ e.jsx(v, {
              value: `${d.bank_name}`,
              logo: o[d.ifsc_code.slice(0, 4)] || _
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pl-8",
              children: /* @__PURE__ */ e.jsx("p", {
                children: `(*****${d.account_number.slice(-4)})`
              })
            })
          ]
        })
      })
    }, d.id)) : /* @__PURE__ */ e.jsx(e.Fragment, {});
  }, Q = () => {
    var t;
    return (t = r == null ? void 0 : r.PENDING) != null && t.length ? r.PENDING.map((s) => /* @__PURE__ */ e.jsx(D, {
      disabled: !0,
      footer: {
        title: "Digital verification will take time depending on your bank. Once we verify successfully, you can start using this account.",
        bgColor: "blue"
      },
      children: /* @__PURE__ */ e.jsx("div", {
        children: /* @__PURE__ */ e.jsxs("div", {
          children: [
            /* @__PURE__ */ e.jsx(v, {
              value: `${s.bank_name}`,
              logo: o[s.ifsc_code.slice(0, 4)] || _
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "pl-8",
              children: [
                /* @__PURE__ */ e.jsx("p", {
                  children: `(*****${s.account_number.slice(-4)})`
                }),
                /* @__PURE__ */ e.jsx(w, {
                  type: "important",
                  variant: "field",
                  title: "Processing",
                  loader: !0
                })
              ]
            })
          ]
        })
      })
    }, s.id)) : /* @__PURE__ */ e.jsx(e.Fragment, {});
  }, A = () => {
    var t;
    return (t = r == null ? void 0 : r.NOVERIFICATION) != null && t.length ? r.NOVERIFICATION.map((s) => /* @__PURE__ */ e.jsx(D, {
      disabled: !0,
      footer: {
        buttonText: u._({
          id: "GBjj3c"
        }),
        bgColor: "red"
      },
      onClick: () => W(s),
      children: /* @__PURE__ */ e.jsxs("div", {
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            children: [
              /* @__PURE__ */ e.jsx(v, {
                value: `${s.bank_name}`,
                logo: o[s.ifsc_code.slice(0, 4)] || _
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "pl-8",
                children: /* @__PURE__ */ e.jsx("p", {
                  children: `(*****${s.account_number.slice(-4)})`
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx("div", {
            children: /* @__PURE__ */ e.jsx(v, {
              value: u._({
                id: "aC7LoR"
              }),
              icon: "Alert"
            })
          })
        ]
      })
    }, s.id)) : /* @__PURE__ */ e.jsx(e.Fragment, {});
  }, ee = () => {
    var t;
    return (t = r == null ? void 0 : r.FAILED) != null && t.length ? r.FAILED.map((s) => /* @__PURE__ */ e.jsx(D, {
      disabled: !0,
      footer: a[s.verification.reason] ? {
        title: u._({
          id: "YLtTww"
        }),
        buttonText: u._({
          id: "SZnrL8"
        }),
        bgColor: "red"
      } : {
        title: u._({
          id: "1NmBpY"
        }),
        bgColor: "red"
      },
      onClick: a[s.verification.reason] ? () => W(s) : void 0,
      children: /* @__PURE__ */ e.jsx("div", {
        children: /* @__PURE__ */ e.jsxs("div", {
          children: [
            /* @__PURE__ */ e.jsx(v, {
              value: `${s.bank_name}`,
              logo: o[s.ifsc_code.slice(0, 4)] || _
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "pl-8",
              children: [
                /* @__PURE__ */ e.jsx("p", {
                  children: `(*****${s.account_number.slice(-4)})`
                }),
                /* @__PURE__ */ e.jsx(w, {
                  type: "alert",
                  variant: "field",
                  title: "Account could not be verified digitally",
                  iconVisibility: !1
                })
              ]
            })
          ]
        })
      })
    }, s.id)) : /* @__PURE__ */ e.jsx(e.Fragment, {});
  }, ge = () => /* @__PURE__ */ e.jsxs(Ee, {
    variant: "selectable",
    selected: n.index,
    onChange: me,
    children: [
      X(),
      Q(),
      A(),
      ee()
    ]
  }), xe = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      X(),
      Q(),
      A(),
      ee()
    ]
  }), se = () => l ? R ? /* @__PURE__ */ e.jsx(ae, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs("div", {
    className: "pb-48",
    children: [
      /* @__PURE__ */ e.jsx(K, {
        title: fe()
      }),
      /* @__PURE__ */ e.jsx(we, {
        type: "text",
        text: b
      }),
      q && /* @__PURE__ */ e.jsx(w, {
        type: "alert",
        variant: "flash",
        title: q,
        customClass: "mb-5"
      }),
      i ? ge() : xe(),
      /* @__PURE__ */ e.jsxs(Ie, {
        children: [
          r.COMPLETE.length < 5 && /* @__PURE__ */ e.jsx("div", {
            className: "mb-2",
            children: /* @__PURE__ */ e.jsx(M, {
              variant: "secondary",
              fullwidth: !0,
              onClick: () => {
                f("add-bank");
              },
              children: u._({
                id: "+SkPoa"
              })
            })
          }),
          j ? /* @__PURE__ */ e.jsx("div", {
            className: "mb-2",
            children: /* @__PURE__ */ e.jsx(M, {
              variant: "secondary",
              fullwidth: !0,
              onClick: j,
              children: u._({
                id: "US4vxF"
              })
            })
          }) : null,
          i ? /* @__PURE__ */ e.jsx(M, {
            variant: r.COMPLETE.length ? "primary" : "disabled",
            fullwidth: !0,
            onClick: () => {
              i && i(n.obj);
            },
            children: u._({
              id: "H/E6fZ"
            })
          }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
        ]
      })
    ]
  }) : /* @__PURE__ */ e.jsx(e.Fragment, {}), je = () => {
    switch (m) {
      case "initial":
        return se();
      case "add-bank":
        return /* @__PURE__ */ e.jsx(Fe, {
          investorProfile: l,
          onCompletion: (t) => {
            pe(t);
          },
          disableBankVerification: !1
        });
      case "collect-cancelled-cheque":
        return /* @__PURE__ */ e.jsx(De, {
          bankAccount: $,
          onCompletion: (t) => {
            he(t);
          }
        });
      default:
        return se();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: je()
  });
}
export {
  Ze as B
};
