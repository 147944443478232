import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as k, useState as u, useEffect as T } from "react";
import { AppContext as $ } from "./AppContext.js";
import { u as ee } from "./useConfiguration-B-lyaBB9.js";
import { F as te } from "./provider-DPPRdC-N.js";
import { LoginContext as ne } from "./LoginContext.js";
import { P as se, a as ae } from "./index.esm-DT3FM8Og.js";
import { r as C } from "./sentry-CbZOQ4Ya.js";
import { b as oe, c as re, i as ie } from "./utils-DbnU7rHI.js";
import { i as l } from "./index-Bb24DwqC.js";
import { W as ce } from "./Welcome-CCMlcQ8T.js";
import { ErrorPage as D } from "./ErrorPage.js";
import { P as le, a as fe, b as me } from "./PurchasePlanConsent-1E5SElFh.js";
import { P as F } from "./Product-lMhZHKhI.js";
import { O as pe } from "./OrderStatus-B-hp1hY6.js";
import { A as de } from "./Auth-tZKY7y12.js";
function ue({ order_id: A }) {
  const { fpapi: c } = k(te), { loggedInUser: w } = k(ne), { AppState: { showWelcomePage: E }, setShowWelcomePage: M } = k($), [h, y] = u(null), [f, b] = u(null), [g, O] = u(), [R, d] = u(!1), [x, I] = u(null), [N, o] = u("payment"), [q, P] = u(null), { tenantConfig: _, amcs: H } = ee(), G = _ == null ? void 0 : _.fallbackLogo, Q = _ == null ? void 0 : _.showSchemeLogo, [U, W] = u(v), V = [
    {
      step: 1,
      content: l._({
        id: "oSiV7/"
      })
    },
    {
      step: 2,
      content: l._({
        id: "Lk2Fhh"
      })
    }
  ];
  T(() => {
    (async () => {
      var s, n;
      if (!w)
        o("login");
      else {
        try {
          d(!0);
          const i = await c.getInvestorProfile((s = w == null ? void 0 : w.profile) == null ? void 0 : s.fp_identifier);
          if (I(i), oe(A) === "Purchase plan") {
            const a = await c.fpClient.mf_purchase_plans().fetch(A);
            if (await L(a == null ? void 0 : a.scheme), b(a), (a == null ? void 0 : a.state) === "active" || (a == null ? void 0 : a.state) === "completed") {
              const m = await z(a);
              if (m) {
                const r = await await c.fpClient.payments().fetchAll({
                  amc_order_ids: m == null ? void 0 : m.old_id
                });
                (n = r == null ? void 0 : r.payments) != null && n.length && P(r == null ? void 0 : r.payments[0]);
              }
              o("order_status");
            } else
              o("purchase_plan_consent");
            d(!1);
            return;
          }
          const t = await c.fpClient.mf_purchases().fetch(A);
          if (y(t), await L(t.scheme), t != null && t.plan) {
            const a = await c.fpClient.mf_purchase_plans().fetch(t == null ? void 0 : t.plan);
            b(a);
          }
          if (t.state === "confirmed" || t.state === "submitted" || t.state === "successful") {
            o("payment_completed"), d(!1);
            return;
          } else if (t.state === "pending")
            o("payment"), d(!1);
          else if (t.state === "failed" && t.failure_code === "payment_failure") {
            const a = await c.fpClient.mf_purchases().retry(t.id);
            y(a), o("payment"), d(!1);
          } else {
            o("payment_error"), d(!1);
            return;
          }
        } catch (i) {
          o("error"), d(!1), C(i);
          return;
        }
        d(!1);
      }
    })();
  }, []);
  const z = async (s) => {
    var j, S;
    let n;
    try {
      n = await c.fpClient.mf_purchases().fetchAll({
        plan: s == null ? void 0 : s.id
      });
    } catch (p) {
      window.debug.log(p), C(p);
    }
    if (!((j = n == null ? void 0 : n.data) != null && j.length))
      return null;
    let i;
    (S = n == null ? void 0 : n.data) != null && S.length && (i = n.data.filter((p) => p.state === "confirmed" || p.state === "successful" || p.state === "submitted" || p.state === "pending").sort((p, Z) => new Date(p.scheduled_on).getTime() - new Date(Z.scheduled_on).getTime())[0]);
    const t = new Date(s == null ? void 0 : s.created_at), a = new Date(new Date(t).setDate(t.getDate() + 1)), m = new Date(new Date(t).setDate(t.getDate() + re(t))), r = new Date(i == null ? void 0 : i.scheduled_on);
    return r.toDateString() === t.toDateString() || r.toDateString() === a.toDateString() || r.toDateString() === m.toDateString() ? i : null;
  };
  async function L(s) {
    try {
      const n = await c.fpClient.master_data().fetchScheme(s);
      B(n);
    } catch (n) {
      window.debug.log(n), C(n);
    }
  }
  function B(s) {
    const n = Q ? {
      ...s,
      schemeLogo: H[s == null ? void 0 : s.amc_id] || G
    } : {
      ...s
    };
    O(n);
  }
  async function J({ success: s, isWindowBlocked: n, paymentId: i, error: t }) {
    var a, m;
    if (i)
      try {
        const r = await c.fpClient.payments().fetch(Number(i));
        if (P(r), f != null && f.id) {
          const S = await c.fpClient.mf_purchase_plans().fetch(f == null ? void 0 : f.id);
          b(S);
        }
        const j = await c.fpClient.mf_purchases().fetch(h.id);
        y(j);
      } catch (r) {
        window.debug.log(r), o("error"), C(r);
      }
    s ? o("order_status") : t && ((m = (a = t == null ? void 0 : t.data) == null ? void 0 : a.error) == null ? void 0 : m.message) === "consent_not_captured" ? o(h.plan ? "purchase_plan_consent" : "purchase_consent") : o(t && n ? "order_status_error" : "order_status");
  }
  T(() => {
    W(v());
  }, [
    E
  ]);
  function v() {
    return "payment";
  }
  function K() {
    M(!1), W("payment");
  }
  const X = () => {
    switch (N) {
      default:
      case "login":
        return /* @__PURE__ */ e.jsx(de, {
          redirectURL: window.location.href,
          allowRegistration: !1,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !1,
            kycNonCompliant: !1
          }
        });
      case "payment":
        return !ie(h) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(me, {
            investorProfile: x,
            mf_purchase: h,
            onCompletion: J,
            scheme: g
          })
        });
      case "payment_completed":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(F, {
              scheme: g
            }),
            /* @__PURE__ */ e.jsx(D, {
              title: l._({
                id: "pMWyvp"
              }),
              description: l._({
                id: "heQwEb"
              }),
              showDetails: !1,
              showAction: !1
            })
          ]
        });
      case "payment_error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(F, {
              scheme: g
            }),
            /* @__PURE__ */ e.jsx(D, {
              title: l._({
                id: "DwyPtq"
              }),
              description: l._({
                id: "om4LRf"
              }),
              showDetails: !1,
              showAction: !1
            })
          ]
        });
      case "order_status":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(F, {
              scheme: g
            }),
            /* @__PURE__ */ e.jsx(pe, {
              investorProfile: x,
              scheme: g,
              mf_order: f || h,
              payment: q
            })
          ]
        });
      case "order_status_error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(F, {
              scheme: g
            }),
            /* @__PURE__ */ e.jsx(D, {
              title: l._({
                id: "sS+OUW"
              }),
              showAction: !0,
              actionText: l._({
                id: "RvsoP+"
              }),
              actionHandler: () => {
                o("payment");
              }
            })
          ]
        });
      case "purchase_consent":
        return /* @__PURE__ */ e.jsx(fe, {
          investorProfile: x,
          id: h.id,
          onSuccess: () => o("payment"),
          action: "confirm"
        });
      case "purchase_plan_consent":
        return /* @__PURE__ */ e.jsx(le, {
          investorProfile: x,
          id: f.id,
          onSuccess: async (s, n) => {
            await L(n.scheme), y(n), o("payment");
          },
          action: "confirm"
        });
      case "error":
        return /* @__PURE__ */ e.jsx(D, {
          title: l._({
            id: "6gcOn5"
          }),
          description: l._({
            id: "whqIka"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: "Retry",
          actionHandler: () => o("payment")
        });
    }
  };
  function Y() {
    return U === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(se, {
          title: l._({
            id: "6MLcer"
          })
        }),
        /* @__PURE__ */ e.jsx(ce, {
          onAck: K,
          welcomeSteps: V
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: R ? /* @__PURE__ */ e.jsx(ae, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: X()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: Y()
  });
}
const We = ue;
export {
  We as ManagePayments
};
