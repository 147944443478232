import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as m, useState as d, useEffect as j } from "react";
import { AppContext as F } from "./AppContext.js";
import { F as R } from "./provider-DPPRdC-N.js";
import { LoginContext as T } from "./LoginContext.js";
import { P as x, a as v } from "./index.esm-DT3FM8Og.js";
import { r as h } from "./sentry-CbZOQ4Ya.js";
import { e as W, B as U } from "./utils-DbnU7rHI.js";
import { i as o } from "./index-Bb24DwqC.js";
import { W as B } from "./Welcome-CCMlcQ8T.js";
import { ErrorPage as k } from "./ErrorPage.js";
import { S as G, a as K, P as X, b as Z, E as q, c as z } from "./StopPlanStatus-BkRF4C-k.js";
import { A as H } from "./Auth-tZKY7y12.js";
function ie({ id: l, type: c, editField: p }) {
  const { fpapi: f } = m(R), { loggedInUser: S } = m(T), { AppState: { showWelcomePage: _ }, setShowWelcomePage: C } = m(F), [P, a] = d(g), [s, r] = d(null), [y, i] = d(!1), O = [
    {
      step: 1,
      content: o._({
        id: "Kk3P1W"
      })
    },
    {
      step: 2,
      content: o._({
        id: "dSpPvn"
      })
    }
  ];
  j(() => {
    (async () => {
      if (S === void 0)
        a("login");
      else {
        const t = W(l);
        if (t === "mf_purchase_plan")
          await E(l);
        else if (t === "mf_redemption")
          await A(l);
        else if (t === "mf_switch_plan")
          await L(l);
        else {
          a("invalidOrderId");
          return;
        }
        const n = w();
        a(n);
      }
    })();
  }, []), j(() => {
    a(g());
  }, [
    _
  ]);
  async function E(t) {
    try {
      i(!0);
      const n = await f.fpClient.mf_purchase_plans().fetch(t);
      r(n);
    } catch (n) {
      h(n);
    } finally {
      i(!1);
    }
  }
  async function A(t) {
    try {
      i(!0);
      const n = await f.fpClient.mf_redemption_plans().fetch(t);
      r(n);
    } catch (n) {
      h(n);
    } finally {
      i(!1);
    }
  }
  async function L(t) {
    try {
      i(!0);
      const n = await f.fpClient.mf_switch_plans().fetch(t);
      r(n);
    } catch (n) {
      h(n);
    } finally {
      i(!1);
    }
  }
  function w() {
    return c === "edit" ? "edit_plan" : c === "pause" ? "pause_plan" : c === "stop_plan" ? "stop_plan" : "edit_plan";
  }
  function g() {
    return _ ? "welcome" : w();
  }
  function b() {
    C(!1), a("mandate");
  }
  function u() {
    window.location.href = window.origin + U + "/plans-list";
  }
  const I = () => {
    switch (P) {
      default:
      case "login":
        return /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(H, {
            redirectURL: window.location.href,
            allowRegistration: !1,
            showTestPan: {
              onboarded: !0
            }
          })
        });
      case "edit_plan":
        return /* @__PURE__ */ e.jsx(z, {
          currentPlanObject: s,
          editField: p == null ? void 0 : p.split(","),
          handleEditCompletion: (t) => {
            r(t), a("edit_plan_finished");
          }
        });
      case "edit_plan_finished":
        return /* @__PURE__ */ e.jsx(q, {
          editOrder: s,
          handleGoToPlansList: u
        });
      case "pause_plan":
        return /* @__PURE__ */ e.jsx(Z, {
          currentPlanObject: s,
          handlePauseCompletion: (t) => {
            r(t), a("pause_plan_finished");
          }
        });
      case "pause_plan_finished":
        return /* @__PURE__ */ e.jsx(X, {
          pauseOrder: s,
          handleGoToPlansList: u
        });
      case "stop_plan":
        return /* @__PURE__ */ e.jsx(K, {
          currentPlanObject: s,
          handleStopCompletion: (t) => {
            r(t), a("stop_plan_finished");
          }
        });
      case "stop_plan_finished":
        return /* @__PURE__ */ e.jsx(G, {
          currentPlanObject: s,
          handleGoToPlansList: u
        });
      case "invalidOrderId":
        return /* @__PURE__ */ e.jsx(k, {
          title: o._({
            id: "BdCXGw"
          }),
          description: o._({
            id: "mZ44RL"
          }),
          showDetails: !1,
          showAction: !1
        });
    }
  };
  function D() {
    return P === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(x, {
          title: o._({
            id: "OugmwP"
          })
        }),
        /* @__PURE__ */ e.jsx(B, {
          onAck: b,
          welcomeSteps: O
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: y ? /* @__PURE__ */ e.jsx(v, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          /* @__PURE__ */ e.jsx(x, {
            title: o._({
              id: "OugmwP"
            })
          }),
          I()
        ]
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: D()
  });
}
export {
  ie as PlanUpdate
};
