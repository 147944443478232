import { j as n } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as ke, useState as B, useEffect as Ne, useLayoutEffect as Ce, Children as we } from "react";
import { D as Fe, S as L, c as o, a as De } from "./index.esm-DT3FM8Og.js";
import { u as Se } from "./useConfiguration-B-lyaBB9.js";
import { F as ze } from "./provider-DPPRdC-N.js";
import { r as X } from "./sentry-CbZOQ4Ya.js";
import { f, i as $, h as M, j as p, k as Ae, l as Ie, m as Re } from "./utils-DbnU7rHI.js";
import { i as r } from "./index-Bb24DwqC.js";
function Be({ folio: D, investorProfile: a, investment_accounts: A }) {
  var G;
  const { fpapi: g } = ke(ze), { tenantConfig: I, banks: v, amcs: O } = Se(), J = I == null ? void 0 : I.fallbackLogo, [j, P] = B([]), [e, ee] = B(null), [R, ne] = B([]), _ = A == null ? void 0 : A[0];
  Ne(() => {
    (async () => {
      var c;
      try {
        const l = await g.fpClient.master_data().fetchAmcs();
        if (!f(l == null ? void 0 : l.amcs) && !$(D)) {
          const i = (c = l == null ? void 0 : l.amcs) == null ? void 0 : c.filter((x) => x.amc_code === D.amc);
          !f(i) && ne(i[0]);
        }
      } catch (l) {
        window.debug.log(l, "Error");
      }
    })();
  }, [
    D,
    a
  ]);
  const ae = (c) => {
    const l = [];
    for (const i in c)
      if (c[i]) {
        const x = c[i].toString(), y = Ie(x);
        l.push(` ${y.trim()}`);
      }
    return l.join();
  }, se = {
    upto_1lakh: "0 - 1 Lakh",
    above_1lakh_upto_5lakh: "1 - 5 Lakhs",
    above_5lakh_upto_10lakh: "5 - 10 Lakhs",
    above_10lakh_upto_25lakh: "10 - 25 Lakhs",
    above_25lakh_upto_1cr: "25 Lakhs - 1 Crore",
    above_1cr: "Above 1 Crore"
  }, le = (c) => c === "pep_exposed" ? "Yes" : c === "pep_related" ? "Related" : "No", te = async () => {
    var c, l;
    if ((c = _ == null ? void 0 : _.folio_defaults) != null && c.payout_bank_account)
      return g.fpClient.bank_accounts().fetch((l = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : l.payout_bank_account);
    {
      const { data: i } = await g.fpClient.bank_accounts().fetchAll({
        profile: a == null ? void 0 : a.id
      });
      return i[(i == null ? void 0 : i.length) - 1];
    }
  }, ce = async () => {
    var c, l, i, x, y, k;
    try {
      const b = ((c = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : c.communication_email_address) && g.fpClient.email_addresses().fetch((l = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : l.communication_email_address), C = ((i = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : i.communication_mobile_number) && g.fpClient.phone_numbers().fetch((x = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : x.communication_mobile_number), N = ((y = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : y.communication_address) && g.fpClient.addresses().fetch((k = _ == null ? void 0 : _.folio_defaults) == null ? void 0 : k.communication_address), S = (a == null ? void 0 : a.id) && g.fpClient.related_parties().fetchAll({
        profile: a == null ? void 0 : a.id
      }), z = te(), m = await Promise.all([
        b,
        C,
        N,
        S,
        z
      ]);
      return {
        email: m == null ? void 0 : m[0],
        phone: m == null ? void 0 : m[1],
        address: m == null ? void 0 : m[2],
        nominees: m == null ? void 0 : m[3].data,
        bankAccount: m == null ? void 0 : m[4]
      };
    } catch (b) {
      return b;
    }
  };
  Ce(() => {
    (async () => {
      var c, l, i, x, y, k, b, C, N, S, z, m;
      try {
        const { countries: w } = await g.fpClient.master_data().fetchCountries();
        P(w);
        let d;
        if (D)
          d = {
            ...D
          };
        else if (a) {
          const { email: h, address: t, phone: F, nominees: s, bankAccount: u } = await ce();
          d = {
            id: null,
            amc: null,
            number: null,
            primary_investor_pan: _ == null ? void 0 : _.primary_investor_pan,
            secondary_investor_pan: null,
            third_investor_pan: null,
            holding_pattern: _ == null ? void 0 : _.holding_pattern,
            primary_investor_name: a == null ? void 0 : a.name,
            secondary_investor_name: null,
            third_investor_name: null,
            primary_investor_dob: a == null ? void 0 : a.date_of_birth,
            secondary_investor_dob: null,
            third_investor_dob: null,
            primary_investor_gender: a == null ? void 0 : a.gender,
            secondary_investor_gender: null,
            third_investor_gender: null,
            primary_investor_tax_status: "individual",
            primary_investor_occupation: a == null ? void 0 : a.occupation,
            guardian_name: null,
            guardian_gender: null,
            guardian_pan: null,
            guardian_dob: null,
            guardian_relationship: null,
            nominee1: s != null && s[0] ? {
              name: s[0].name,
              dob: s[0].date_of_birth,
              relationship: s[0].relationship,
              guardian: s[0].guardian_name,
              guardian_relationship: null
            } : null,
            nominee1_allocation_percentage: s != null && s.length ? 100 / (s == null ? void 0 : s.length) : null,
            nominee2: s != null && s[1] ? {
              name: s[1].name,
              dob: s[1].date_of_birth,
              relationship: s[1].relationship,
              guardian: s[1].guardian_name,
              guardian_relationship: null
            } : null,
            nominee2_allocation_percentage: s != null && s.length ? 100 / (s == null ? void 0 : s.length) : null,
            nominee3: s != null && s[2] ? {
              name: s[2].name,
              dob: s[2].date_of_birth,
              relationship: s[2].relationship,
              guardian: s[2].guardian_name,
              guardian_relationship: null
            } : null,
            nominee3_allocation_percentage: s != null && s.length ? 100 / (s == null ? void 0 : s.length) : null,
            payout_details: [
              {
                scheme_code: null,
                bank_account: u ? {
                  name: u == null ? void 0 : u.primary_account_holder_name,
                  number: u == null ? void 0 : u.account_number,
                  account_type: u == null ? void 0 : u.type,
                  ifsc: u == null ? void 0 : u.ifsc_code,
                  bank_name: u == null ? void 0 : u.bank_name
                } : {}
              }
            ],
            contact_details: [
              {
                address: {
                  line1: t == null ? void 0 : t.line1,
                  line2: t == null ? void 0 : t.line2,
                  line3: t == null ? void 0 : t.line3,
                  city: t == null ? void 0 : t.city,
                  state: t == null ? void 0 : t.state,
                  postal_code: t == null ? void 0 : t.postal_code,
                  country_name: M(w, t == null ? void 0 : t.country),
                  country_ansi_code: t == null ? void 0 : t.country
                },
                email: h == null ? void 0 : h.email,
                mobile: F == null ? void 0 : F.number,
                type: "correspondence"
              }
            ],
            no_other_tax_residences: ((c = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : c.country) === "IN",
            email_addresses: [
              h == null ? void 0 : h.email
            ],
            mobile_numbers: [
              F == null ? void 0 : F.number
            ],
            country_of_birth: M(w, t == null ? void 0 : t.country),
            primary_investor_residential_status: "Resident Indian",
            source_of_wealth: p(a == null ? void 0 : a.source_of_wealth),
            income: se[a == null ? void 0 : a.income_slab]
          };
        }
        if (!((x = (i = (l = d == null ? void 0 : d.payout_details) == null ? void 0 : l[0]) == null ? void 0 : i.bank_account) != null && x.bank_name))
          try {
            const h = ((b = (k = (y = d == null ? void 0 : d.payout_details) == null ? void 0 : y[0]) == null ? void 0 : k.bank_account) == null ? void 0 : b.ifsc) && await g.fpClient.master_data().fetchIfscCodes((N = (C = d == null ? void 0 : d.payout_details[0]) == null ? void 0 : C.bank_account) == null ? void 0 : N.ifsc), t = {
              ...(S = d == null ? void 0 : d.payout_details) == null ? void 0 : S[0],
              bank_account: {
                ...(m = (z = d == null ? void 0 : d.payout_details) == null ? void 0 : z[0]) == null ? void 0 : m.bank_account,
                bank_name: h == null ? void 0 : h.bank_name
              }
            };
            d = {
              ...d,
              payout_details: [
                {
                  ...t
                }
              ]
            };
          } catch (h) {
            X(h);
          }
        ee(d);
      } catch (w) {
        window.debug.error(w), X(w);
      }
    })();
  }, [
    D,
    a
  ]);
  const ie = () => {
    const c = {}, l = {
      PAN: e && e.primary_investor_pan,
      Name: p(e == null ? void 0 : e.primary_investor_name),
      "Date of birth": e != null && e.primary_investor_dob ? Re(new Date(e == null ? void 0 : e.primary_investor_dob), "dd-MMM-yyyy") : null,
      Gender: p(e == null ? void 0 : e.primary_investor_gender),
      "Country of birth": e == null ? void 0 : e.country_of_birth,
      "Are you politically exposed?": a ? le(a == null ? void 0 : a.pep_details) : void 0,
      "Resident status": e == null ? void 0 : e.primary_investor_residential_status,
      "Second investor name": p(e.secondary_investor_name),
      "Second investor PAN": e.secondary_investor_pan,
      "Third investor name": p(e.third_investor_name),
      "Third investor PAN": e.third_investor_pan
    };
    for (const i in l)
      l[i] && (c[i] = l[i]);
    return c;
  }, re = () => {
    const c = {}, l = {
      "Tax status": p(e.primary_investor_tax_status),
      Occupation: p(e == null ? void 0 : e.primary_investor_occupation),
      "Source of wealth": p(e == null ? void 0 : e.source_of_wealth),
      Income: e == null ? void 0 : e.income
    };
    for (const i in l)
      l[i] && (c[i] = l[i]);
    return c;
  }, _e = () => {
    var i, x, y, k, b, C;
    const c = {}, l = {
      "First country of residence": M(j, (i = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : i.country),
      "First tax identification number": (x = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : x.taxid_number,
      "Second country of residence": M(j, (y = a == null ? void 0 : a.second_tax_residency) == null ? void 0 : y.country),
      "Second tax identification number": (k = a == null ? void 0 : a.second_tax_residency) == null ? void 0 : k.taxid_number,
      "Third country of residence": M(j, (b = a == null ? void 0 : a.third_tax_residency) == null ? void 0 : b.country),
      "Third tax identification number": (C = a == null ? void 0 : a.third_tax_residency) == null ? void 0 : C.taxid_number
    };
    for (const N in l)
      l[N] && (c[N] = l[N]);
    return c;
  }, de = {
    Name: p(e == null ? void 0 : e.guardian_name),
    Relationship: p(e == null ? void 0 : e.guardian_relationship),
    "Date of birth": e == null ? void 0 : e.guardian_dob,
    Gender: p(e == null ? void 0 : e.guardian_gender),
    PAN: e == null ? void 0 : e.guardian_pan
  }, me = () => /* @__PURE__ */ n.jsx(T, {
    title: r._({
      id: "5S80EC"
    }),
    details: ie()
  }), ue = () => /* @__PURE__ */ n.jsx(T, {
    title: r._({
      id: "Ipj2tP"
    }),
    details: {
      "Mode of holding": p(e == null ? void 0 : e.holding_pattern) || null
    }
  }), oe = () => /* @__PURE__ */ n.jsx(T, {
    title: r._({
      id: "Cu4OzE"
    }),
    details: re()
  }), pe = () => /* @__PURE__ */ n.jsx(T, {
    title: r._({
      id: "3aeTAD"
    }),
    details: _e()
  }), xe = () => {
    var c, l, i, x, y, k, b, C, N, S, z, m, w, d, h, t, F, s, u, E, V, Y, q, H, W, K, Z, Q, U;
    return !$((c = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : c.bank_account) && ((i = (l = e == null ? void 0 : e.payout_details[0]) == null ? void 0 : l.bank_account) != null && i.bank_name) ? /* @__PURE__ */ n.jsx(L, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ n.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: a ? r._({
            id: "6ZvJaV"
          }) : r._({
            id: "inuwv2"
          })
        })
      },
      children: /* @__PURE__ */ n.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "TkdqW3"
              }),
              value: `${(k = (y = (x = e == null ? void 0 : e.payout_details) == null ? void 0 : x[0]) == null ? void 0 : y.bank_account) == null ? void 0 : k.bank_name} -
    ****${(S = (N = (C = (b = e == null ? void 0 : e.payout_details) == null ? void 0 : b[0]) == null ? void 0 : C.bank_account) == null ? void 0 : N.number) == null ? void 0 : S.slice(-4)}`,
              logo: (w = (m = (z = e == null ? void 0 : e.payout_details) == null ? void 0 : z[0]) == null ? void 0 : m.bank_account) != null && w.ifsc && v[(F = (t = (h = (d = e == null ? void 0 : e.payout_details) == null ? void 0 : d[0]) == null ? void 0 : h.bank_account) == null ? void 0 : t.ifsc) == null ? void 0 : F.slice(0, 4)] || J
            })
          }),
          /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "JzJ/t2"
              }),
              value: (E = (u = (s = e == null ? void 0 : e.payout_details) == null ? void 0 : s[0]) == null ? void 0 : u.bank_account) != null && E.account_type ? p((q = (Y = (V = e == null ? void 0 : e.payout_details) == null ? void 0 : V[0]) == null ? void 0 : Y.bank_account) == null ? void 0 : q.account_type) : "-"
            })
          }),
          /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: "IFSC",
              value: (K = (W = (H = e == null ? void 0 : e.payout_details) == null ? void 0 : H[0]) == null ? void 0 : W.bank_account) != null && K.ifsc ? (U = (Q = (Z = e == null ? void 0 : e.payout_details) == null ? void 0 : Z[0]) == null ? void 0 : Q.bank_account) == null ? void 0 : U.ifsc : "-"
            })
          })
        ]
      })
    }) : null;
  }, he = () => {
    var c, l, i, x, y, k, b, C, N, S, z, m, w, d, h, t, F, s, u, E;
    return !f(e == null ? void 0 : e.mobile_numbers) && ((c = e == null ? void 0 : e.mobile_numbers) != null && c[0]) || !f(e == null ? void 0 : e.contact_details) && ((x = (i = (l = e == null ? void 0 : e.contact_details) == null ? void 0 : l[0]) == null ? void 0 : i.address) != null && x.line1) || !f(e == null ? void 0 : e.email_addresses) && ((y = e.email_addresses) != null && y[0]) ? /* @__PURE__ */ n.jsx(L, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ n.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: "Contact details"
        })
      },
      children: /* @__PURE__ */ n.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          !f(e.mobile_numbers) && ((k = e.mobile_numbers) != null && k[0]) ? /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "0VsDgp"
              }),
              value: e.mobile_numbers[0]
            })
          }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
          !f(e == null ? void 0 : e.contact_details) && ((C = (b = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : b.address) != null && C.line1) ? /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "FBMtAv"
              }),
              value: ae({
                addressLine1: (S = (N = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : N.address) == null ? void 0 : S.line1,
                addressLine2: (m = (z = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : z.address) == null ? void 0 : m.line2,
                addressLine3: (d = (w = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : w.address) == null ? void 0 : d.line3,
                state: (t = (h = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : h.address) == null ? void 0 : t.state,
                country: (s = (F = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : F.address) == null ? void 0 : s.country_name,
                postal_code: (E = (u = e == null ? void 0 : e.contact_details[0]) == null ? void 0 : u.address) == null ? void 0 : E.postal_code
              })
            })
          }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
          !f(e.email_addresses) && e.email_addresses[0] ? /* @__PURE__ */ n.jsx("div", {
            className: "mb-3 basis-full pr-2 pl-2",
            children: /* @__PURE__ */ n.jsx(o, {
              label: r._({
                id: "oyjr24"
              }),
              value: e.email_addresses[0]
            })
          }) : /* @__PURE__ */ n.jsx(n.Fragment, {})
        ]
      })
    }) : /* @__PURE__ */ n.jsx(n.Fragment, {});
  }, ye = () => /* @__PURE__ */ n.jsx(L, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ n.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: r._({
          id: "EPAVJA"
        })
      })
    },
    children: e.nominee1 ? /* @__PURE__ */ n.jsxs(n.Fragment, {
      children: [
        /* @__PURE__ */ n.jsxs("section", {
          className: "mb-2",
          children: [
            /* @__PURE__ */ n.jsx("h4", {
              className: "bg-primary-20 text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color rounded-md border border-solid px-1 pb-1",
              children: r._({
                id: "rDqvmv"
              })
            }),
            /* @__PURE__ */ n.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "uR3xiz"
                    }),
                    value: e.nominee1 ? p(e.nominee1.name) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "pI9t3O"
                    }),
                    value: e.nominee1 ? p(e.nominee1.relationship) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "92x2v3"
                    }),
                    value: e.nominee1_allocation_percentage ? `${Math.floor(e.nominee1_allocation_percentage)}` : "-"
                  })
                })
              ]
            })
          ]
        }),
        e.nominee2 && /* @__PURE__ */ n.jsxs("section", {
          className: "mb-2",
          children: [
            /* @__PURE__ */ n.jsx("h4", {
              className: "bg-primary-20 text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color rounded-md border border-solid px-1 pb-1",
              children: r._({
                id: "uuNdFd"
              })
            }),
            /* @__PURE__ */ n.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "uR3xiz"
                    }),
                    value: e.nominee2 ? p(e.nominee2.name) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "pI9t3O"
                    }),
                    value: e.nominee2 ? p(e.nominee2.relationship) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "92x2v3"
                    }),
                    value: e.nominee2_allocation_percentage ? `${Math.floor(e.nominee2_allocation_percentage)}` : "-"
                  })
                })
              ]
            })
          ]
        }),
        e.nominee3 && /* @__PURE__ */ n.jsxs("section", {
          className: "mb-2",
          children: [
            /* @__PURE__ */ n.jsx("h4", {
              className: "bg-primary-20 text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color rounded-md border border-solid px-1 pb-1",
              children: r._({
                id: "lb72I6"
              })
            }),
            /* @__PURE__ */ n.jsxs("div", {
              className: "-mr-2 -ml-2 flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3  basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "uR3xiz"
                    }),
                    value: e.nominee3 ? p(e.nominee3.name) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "YPSxBA"
                    }),
                    value: e.nominee3 ? p(e.nominee3.relationship) : "-"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(o, {
                    label: r._({
                      id: "92x2v3"
                    }),
                    value: e.nominee3_allocation_percentage ? `${Math.ceil(e.nominee3_allocation_percentage)}` : "-"
                  })
                })
              ]
            })
          ]
        })
      ]
    }) : /* @__PURE__ */ n.jsx("p", {
      className: "text-size-14 border-neutral-10 dark:border-neutral-dark-10 dark:bg-primary-40 text-base-color px-1 pb-1",
      children: r._({
        id: "mks8ng"
      })
    })
  }), be = () => /* @__PURE__ */ n.jsx(L, {
    customClass: "mb-4",
    header: {
      title: /* @__PURE__ */ n.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: "Demat account"
      })
    },
    children: /* @__PURE__ */ n.jsxs("div", {
      className: "-mr-2 -ml-2 flex flex-wrap items-center",
      children: [
        /* @__PURE__ */ n.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ n.jsx(o, {
            label: r._({
              id: "e3lc0/"
            }),
            value: e != null && e.dp_id ? e == null ? void 0 : e.dp_id : "-"
          })
        }),
        /* @__PURE__ */ n.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ n.jsx(o, {
            label: r._({
              id: "JRKgov"
            }),
            value: e != null && e.client_id ? e == null ? void 0 : e.client_id : "-"
          })
        })
      ]
    })
  }), ge = () => /* @__PURE__ */ n.jsx(T, {
    title: r._({
      id: "R2NauH"
    }),
    details: de
  });
  return e ? /* @__PURE__ */ n.jsxs("div", {
    className: "mb-8",
    children: [
      /* @__PURE__ */ n.jsx(Fe, {
        type: "text",
        text: D ? "" : r._({
          id: "xnP1PY"
        })
      }),
      D || !$(R) ? /* @__PURE__ */ n.jsx(L, {
        customClass: "mb-4",
        children: /* @__PURE__ */ n.jsxs("div", {
          className: "-mr-2 -ml-2 flex flex-wrap items-center",
          children: [
            D ? /* @__PURE__ */ n.jsx("div", {
              className: "mb-3 basis-full pr-2 pl-2",
              children: /* @__PURE__ */ n.jsx(o, {
                label: r._({
                  id: "0c+2Wu"
                }),
                value: e.number
              })
            }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
            $(R) ? /* @__PURE__ */ n.jsx(n.Fragment, {}) : /* @__PURE__ */ n.jsx("div", {
              className: "mb-3 basis-full pr-2 pl-2",
              children: /* @__PURE__ */ n.jsx(o, {
                label: r._({
                  id: "bisISz"
                }),
                value: Ae(R == null ? void 0 : R.name),
                logo: O[R == null ? void 0 : R.amc_id] || J
              })
            })
          ]
        })
      }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
      me(),
      ue(),
      oe(),
      a && ((a == null ? void 0 : a.first_tax_residency) || (a == null ? void 0 : a.second_tax_residency) || (a == null ? void 0 : a.third_tax_residency)) && ((G = a == null ? void 0 : a.first_tax_residency) == null ? void 0 : G.country) !== "IN" && pe(),
      xe(),
      he(),
      ye(),
      (e == null ? void 0 : e.guardian_pan) && ge(),
      e != null && e.dp_id || e != null && e.client_id ? be() : /* @__PURE__ */ n.jsx(n.Fragment, {})
    ]
  }) : /* @__PURE__ */ n.jsx(De, {
    variant: "fullpage"
  });
}
const T = ({ title: D, details: a }) => /* @__PURE__ */ n.jsx(L, {
  customClass: "mb-4",
  header: {
    title: /* @__PURE__ */ n.jsx("span", {
      className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
      children: D
    })
  },
  children: /* @__PURE__ */ n.jsx("div", {
    className: "-mr-2 -ml-2 flex flex flex-wrap items-center",
    children: we.toArray(Object.keys(a).map((A) => Array.isArray(a[A]) ? a[A].map((g) => {
      const I = {
        ...g
      };
      return I == null || delete I.title, // eslint-disable-next-line react/jsx-key
      /* @__PURE__ */ n.jsx(T, {
        title: g == null ? void 0 : g.title,
        details: I
      });
    }) : /* @__PURE__ */ n.jsx("div", {
      className: "mb-3 basis-1/2 pr-2 pl-2",
      children: /* @__PURE__ */ n.jsx(o, {
        label: A,
        value: a[A] ? a[A] : "-"
      })
    })))
  })
});
export {
  Be as FolioDetails
};
