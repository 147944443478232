import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as f, useContext as _e, useEffect as le, useRef as Te, useMemo as ze } from "react";
import { a as ge, P as ie, c as oe, R as Ve, S as De, B as xe, D as Ce, M as be, s as $e, b as ye, e as He, F as Je } from "./index.esm-DT3FM8Og.js";
import { u as ke } from "./useConfiguration-B-lyaBB9.js";
import { F as pe } from "./provider-DPPRdC-N.js";
import { r as V } from "./sentry-CbZOQ4Ya.js";
import { a7 as Ae, f as Ue, C as he, B as Xe, i as re, Z as Ke } from "./utils-DbnU7rHI.js";
import { u as Le, O as Ne } from "./OtpForm-CTvReVn2.js";
import { i as r } from "./index-Bb24DwqC.js";
import { NotFoundPage as Qe } from "./NotFoundPage.js";
import { P as fe } from "./Product-lMhZHKhI.js";
import { O as Ee, r as we, M as Be } from "./OrderStatus-B-hp1hY6.js";
import { m as Fe, M as Ie, r as Ye } from "./MandateListing-BBhvpNKP.js";
import { o as Ze } from "./redirection-rPP3A4Qb.js";
import { C as Ge, S as Re } from "./StatusMessage-PK7bu2oh.js";
import { B as qe } from "./BankAccountListing-maa4N6b9.js";
import { T as Pe } from "./Tnc-T_JHncav.js";
import { ErrorPage as ve } from "./ErrorPage.js";
import { f as et, r as tt } from "./validators-BArvL7cB.js";
import { R as st, F as nt } from "./react-final-form.es-ZoroCK1B.js";
import { LoginContext as at } from "./LoginContext.js";
import { M as rt } from "./MandateSelector-C4LLIBCy.js";
function it({ scheme: w, investorProfile: h, mf_purchase: M, onCompletion: k }) {
  var ue, E;
  const { tenantConfig: D, banks: Q } = ke(), B = D == null ? void 0 : D.fallbackLogo, b = M != null && M.amount ? M.amount : 0, [_, g] = f(null), [y, v] = f(!0), [Y, c] = f(!1), [A, F] = f("initial"), [o, $] = f(!1), [i, ee] = f(null), [l, s] = f(null), { fpapi: p } = _e(pe), [x, te] = f([]);
  let W = ((ue = D == null ? void 0 : D.app_constants) == null ? void 0 : ue.paymentMethods) || [];
  W = b >= 1e5 ? W : W == null ? void 0 : W.filter((a) => a !== "mandate");
  const [U, se] = f((W || [])[0]), [me, ce] = f(!1), L = Ae(W), [u, O] = f(""), [I, R] = f(""), X = (E = D == null ? void 0 : D.app_constants) == null ? void 0 : E.paymentGateway;
  le(() => {
    Z();
  }, []), le(() => {
    de(), T();
  }, [
    _
  ]);
  const Z = async () => {
    try {
      if (h != null && h.id) {
        ce(!0);
        const a = await p.fpClient.bank_accounts().fetchAll({
          profile: h.id
        });
        g(a.data), ce(!1);
      }
    } catch (a) {
      ce(!1), window.debug.error(a), V(a);
    }
  }, de = async () => {
    const a = _ == null ? void 0 : _.map((K) => K.old_id);
    Ue(a) || p.fpClient.mandates().fetchAll({
      bank_account_id: a == null ? void 0 : a.join(","),
      size: 100
    }).then((K) => {
      const n = K.mandates;
      if (n.sort((j, S) => new Date(S.created_at).valueOf() - new Date(j.created_at).valueOf()), n.length > 0) {
        _ == null || _.forEach((m) => {
          n == null || n.forEach((q) => {
            q.bank_account_id === m.old_id && (q.bank = {
              bank_name: m.bank_name,
              ifsc: m.ifsc_code,
              number: m.account_number
            });
          });
        });
        const j = [];
        n.forEach((m) => {
          m.mandate_status === "APPROVED" && j.push(m);
        });
        const S = j.filter((m) => m.mandate_limit >= b);
        j.length && S[0] ? s(S[0]) : s(null);
      }
    });
  }, T = async () => (_ != null && _.length && (te(_), ee(_[0])), _), t = async () => {
    try {
      const a = await p.fpClient.email_addresses().fetchAll({
        profile: h == null ? void 0 : h.id
      }), K = await p.fpClient.phone_numbers().fetchAll({
        profile: h == null ? void 0 : h.id
      });
      return {
        emailData: a,
        phoneData: K
      };
    } catch (a) {
      window.debug.log(a), V(a);
    }
  }, d = (a) => {
    a.stopPropagation(), v(!y);
  }, C = (a) => (a == null ? void 0 : a.mandate_status) !== "APPROVED" ? r._({
    id: "n8egj/"
  }) : (a == null ? void 0 : a.mandate_limit) < b ? r._({
    id: "1go36W"
  }) : "", N = (a) => {
    k({
      success: !0,
      isWindowBlocked: !1,
      paymentId: a
    });
  }, H = (a) => {
    k({
      success: !1,
      isWindowBlocked: !0,
      paymentId: a
    });
  }, J = (a) => {
    k({
      success: !0,
      isWindowBlocked: !1,
      paymentId: a
    });
  };
  function z(a) {
    a.preventDefault();
    const K = i.old_id;
    if (U === "mandate") {
      const n = C(l);
      O(n), n || (F("initiated"), p.fpClient.payments().createNachPayment({
        mandate_id: l.id,
        amc_order_ids: [
          M.old_id
        ]
      }).then((j) => {
        k({
          success: !0,
          isWindowBlocked: !1,
          paymentId: j.id
        });
      }).catch((j) => {
        var S, m;
        V(j), j && ((m = (S = j == null ? void 0 : j.data) == null ? void 0 : S.error) == null ? void 0 : m.message) === "consent_not_captured" ? k({
          success: !1,
          isWindowBlocked: !1,
          error: j
        }) : (R("Something went wrong"), F("initial"));
      }));
    } else
      X && X === "BILLDESK" ? (F("initiated"), p.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          M.old_id
        ],
        method: U,
        bank_account_id: K,
        payment_postback_url: `${window.location.origin + Xe}/redirection`,
        provider_name: "BILLDESK"
      }).then((n) => {
        Ze(n == null ? void 0 : n.token_url, "payment-billdesk", () => N(n == null ? void 0 : n.id), () => H(n == null ? void 0 : n.id), () => J(n == null ? void 0 : n.id));
      }).catch((n) => {
        var j, S;
        V(n), n && ((S = (j = n == null ? void 0 : n.data) == null ? void 0 : j.error) == null ? void 0 : S.message) === "consent_not_captured" ? k({
          success: !1,
          isWindowBlocked: !1,
          error: n
        }) : (R("Something went wrong"), F("initial"));
      })) : (F("initiated"), p.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          M.old_id
        ],
        method: U,
        bank_account_id: K
      }).then((n) => {
        t().then((j) => {
          var Me, Oe;
          const { emailData: S, phoneData: m } = j, q = n.id, ae = n.sdk_options.razorpay;
          let Se = null;
          for (const P in ae.method)
            ae.method[P] && (Se = P);
          const We = {
            amount: ae.amount,
            currency: "INR",
            email: (Me = S == null ? void 0 : S.data[0]) == null ? void 0 : Me.email,
            contact: (Oe = m == null ? void 0 : m.data[0]) == null ? void 0 : Oe.number,
            method: Se,
            bank: ae.bank_code,
            order_id: ae.order_id
          }, je = Ye(ae.key);
          je.on("payment.success", function(P) {
            p.submitPayment(ae.callback_url, P.razorpay_payment_id, P.razorpay_order_id, P.razorpay_signature).then(() => {
              k({
                success: !0,
                isWindowBlocked: !1,
                paymentId: q
              });
            });
          }), je.on("payment.error", function(P) {
            p.submitPaymentError(ae.callback_url, "error", P.error.code, P.error.description).then(() => {
              k({
                success: !1,
                isWindowBlocked: !1,
                paymentId: q
              });
            });
          }), je.createPayment(We);
        });
      }).catch((n) => {
        var j, S;
        V(n), n && ((S = (j = n == null ? void 0 : n.data) == null ? void 0 : j.error) == null ? void 0 : S.message) === "consent_not_captured" ? k({
          success: !1,
          isWindowBlocked: !1,
          error: n
        }) : (R("Something went wrong"), F("initial"));
      }));
  }
  const ne = () => {
    var a, K, n, j, S;
    return /* @__PURE__ */ e.jsx(e.Fragment, {
      children: me ? /* @__PURE__ */ e.jsx(ge, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          /* @__PURE__ */ e.jsx(Ge, {
            check: M.plan,
            children: /* @__PURE__ */ e.jsx(ie, {
              title: r._({
                id: "LgSBEi"
              })
            })
          }),
          w && /* @__PURE__ */ e.jsx(fe, {
            scheme: w
          }),
          /* @__PURE__ */ e.jsx(oe, {
            label: `${M.plan ? r._({
              id: "/DJBcB"
            }) : r._({
              id: "KqeLJ1"
            })}`,
            value: `₹ ${he(b)}`,
            direction: "row",
            border: !0,
            customClass: "mb-7"
          }),
          /* @__PURE__ */ e.jsx("form", {
            onSubmit: z,
            className: "flex flex-1 flex-col",
            children: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-1 flex-col justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-8",
                      children: /* @__PURE__ */ e.jsxs("div", {
                        children: [
                          /* @__PURE__ */ e.jsx(ie, {
                            variant: "sub",
                            title: r._({
                              id: "pCX9tn"
                            }),
                            customClass: "mb-3"
                          }),
                          /* @__PURE__ */ e.jsx(Ve, {
                            variant: "iconBased",
                            name: "radioname",
                            checked: W.indexOf(U),
                            group: L,
                            onChange: (m, q) => {
                              se(q.value), O("");
                            }
                          })
                        ]
                      })
                    }),
                    U === "mandate" && b >= 1e5 ? /* @__PURE__ */ e.jsxs("div", {
                      className: "mb-8 mt-4",
                      children: [
                        /* @__PURE__ */ e.jsx(ie, {
                          variant: "sub",
                          title: r._({
                            id: "tJ7I/4"
                          })
                        }),
                        l ? /* @__PURE__ */ e.jsx(De, {
                          children: /* @__PURE__ */ e.jsxs("div", {
                            className: "flex flex-wrap items-center justify-between",
                            children: [
                              /* @__PURE__ */ e.jsx("div", {
                                className: "w-full",
                                children: /* @__PURE__ */ e.jsx(oe, {
                                  label: r._({
                                    id: "L/u9Dz"
                                  }),
                                  value: l ? `${(a = l == null ? void 0 : l.bank) == null ? void 0 : a.bank_name} - ****${(K = l == null ? void 0 : l.bank) == null ? void 0 : K.number.slice(-4)}` : "-",
                                  logo: Q[(j = (n = l == null ? void 0 : l.bank) == null ? void 0 : n.ifsc) == null ? void 0 : j.slice(0, 4)] || B
                                })
                              }),
                              /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(oe, {
                                  label: r._({
                                    id: "x4Fb5/"
                                  }),
                                  value: l ? `₹${he(l == null ? void 0 : l.mandate_limit)}` : "-"
                                })
                              }),
                              /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(oe, {
                                  label: r._({
                                    id: "MhEf8P"
                                  }),
                                  value: l ? Fe[l.mandate_status].value : "-",
                                  icon: l ? Fe[l.mandate_status].icon : "",
                                  iconPlacement: "left"
                                })
                              }),
                              /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(xe, {
                                  variant: "text",
                                  onClick: (m) => {
                                    m.preventDefault(), m.stopPropagation(), $(!0);
                                  },
                                  children: r._({
                                    id: "ShFidp"
                                  })
                                })
                              })
                            ]
                          })
                        }) : /* @__PURE__ */ e.jsx(e.Fragment, {
                          children: /* @__PURE__ */ e.jsx(Ce, {
                            type: "notes",
                            text: r._({
                              id: "o+Qbf0"
                            }),
                            children: /* @__PURE__ */ e.jsx(xe, {
                              variant: "text",
                              onClick: (m) => {
                                m.preventDefault(), m.stopPropagation(), $(!0);
                              },
                              children: r._({
                                id: "uhQPR2"
                              })
                            })
                          })
                        }),
                        u && /* @__PURE__ */ e.jsx("div", {
                          children: /* @__PURE__ */ e.jsx(be, {
                            type: "error",
                            variant: "field",
                            title: u,
                            customClass: ""
                          })
                        })
                      ]
                    }) : /* @__PURE__ */ e.jsxs("div", {
                      children: [
                        /* @__PURE__ */ e.jsx(ie, {
                          variant: "sub",
                          title: r._({
                            id: "bu9VTL"
                          }),
                          customClass: "mb-3"
                        }),
                        /* @__PURE__ */ e.jsx(Ce, {
                          type: i ? "borderedNotes" : "notes",
                          // text="ICICI BANK (*****9872)"
                          text: i ? `${i.bank_name} - ****${i.account_number.slice(-4)}` : r._({
                            id: "Hgankl"
                          }),
                          logo: i ? Q[(S = i == null ? void 0 : i.ifsc_code) == null ? void 0 : S.slice(0, 4)] : B
                        })
                      ]
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsxs($e, {
                      id: "terms-id",
                      checked: y,
                      onChange: d,
                      customClass: "mb-3",
                      children: [
                        r._({
                          id: "PFNsn4"
                        }),
                        /* @__PURE__ */ e.jsx("span", {
                          children: " "
                        }),
                        /* @__PURE__ */ e.jsx("a", {
                          onClick: (m) => {
                            m.preventDefault(), m.stopPropagation(), c(!0);
                          },
                          className: "text-size-14",
                          children: r._({
                            id: "863m1W"
                          })
                        })
                      ]
                    }),
                    I && /* @__PURE__ */ e.jsx(be, {
                      type: "error",
                      variant: "field",
                      title: "Something went wrong"
                    }),
                    /* @__PURE__ */ e.jsx(xe, {
                      variant: y && x.length ? "primary" : "disabled",
                      fullwidth: !0,
                      customClass: "mb-8",
                      type: "submit",
                      children: r._({
                        id: "AIDv2f",
                        values: {
                          0: he(b)
                        }
                      })
                    })
                  ]
                })
              ]
            })
          }),
          /* @__PURE__ */ e.jsx(ye, {
            variant: "fullPage",
            title: r._({
              id: "s4HNy/"
            }),
            isOpen: Y,
            toggleModal: c,
            contentHeightMax: !0,
            children: /* @__PURE__ */ e.jsx(Pe, {
              disableBackButton: !0
            })
          }),
          /* @__PURE__ */ e.jsx(ye, {
            variant: "fullPage",
            title: r._({
              id: "XeOA76"
            }),
            isOpen: o,
            toggleModal: () => {
              $(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(Ie, {
              investorProfile: h,
              status: [
                "approved"
              ],
              limit: b,
              handleMandateSelection: (m) => {
                const q = C(m);
                O(q), s(m), $(!1);
              }
            })
          })
        ]
      })
    });
  }, G = () => {
    switch (A) {
      case "initial":
        return ne();
      case "initiated":
        return /* @__PURE__ */ e.jsx(ge, {
          variant: "fullpage"
        });
      case "listing_bankaccount":
        return /* @__PURE__ */ e.jsx(qe, {
          investorProfile: h,
          handleBankSelection: (a) => {
            ee(a), te([
              a
            ]), F("initial"), Z();
          }
        });
      default:
        return ne();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: G()
  });
}
function Ft({ investorProfile: w, id: h, showSchemeDetails: M = !0, isGroupInvestment: k = !1, action: D, onSuccess: Q }) {
  const { fpapi: B } = _e(pe), [b, _] = f(!1), [g, y] = f(""), [v, Y] = f(), [c, A] = f(null), { tenantConfig: F, amcs: o } = ke(), $ = F == null ? void 0 : F.fallbackLogo, i = F == null ? void 0 : F.showSchemeLogo, [ee, l] = f({}), { collectedAcquisition: s, initiateSendOTP: p } = Le();
  le(() => {
    x();
  }, []);
  const x = async () => {
    var I, R;
    const L = h == null ? void 0 : h.split(",");
    _(!0);
    let u, O;
    try {
      if (u = await B.fpClient.mf_purchases().fetch(h.split(",")[0]), O = !re(u) && await B.fpClient.payments().fetchAll({
        amc_order_ids: u == null ? void 0 : u.old_id
      }), M) {
        const Z = await B.fpClient.master_data().fetchScheme(u.scheme);
        te(Z);
      }
      A(u), u.state === "pending" ? y("consent") : y("consent_not_required");
      const X = {
        given_for_object: u == null ? void 0 : u.object,
        action: D,
        given_for: L
      };
      await p(X), _(!1);
    } catch (X) {
      window.debug.log(X, "error"), !re(u) && (u == null ? void 0 : u.state) !== "pending" && ((I = O == null ? void 0 : O.payments) != null && I.length) ? (l((R = O == null ? void 0 : O.payments) == null ? void 0 : R[0]), y("finished")) : y("error"), _(!1);
    }
  }, te = (L) => {
    const u = i ? {
      ...L,
      schemeLogo: o[L == null ? void 0 : L.amc_id] || $
    } : {
      ...L
    };
    Y(u);
  }, W = async () => {
    var L, u, O, I, R, X, Z, de;
    if (_(!0), Q)
      try {
        const T = await B.fpClient.mf_purchases().update({
          id: c == null ? void 0 : c.id,
          consent: {
            email: (L = s == null ? void 0 : s.acquisition_details) == null ? void 0 : L.email,
            isd_code: (u = s == null ? void 0 : s.acquisition_details) == null ? void 0 : u.isd,
            mobile: (O = s == null ? void 0 : s.acquisition_details) == null ? void 0 : O.mobile_number
          }
        });
        Q(T), _(!1);
      } catch {
        y("error"), _(!1);
      }
    else
      try {
        const T = await B.fpClient.mf_purchases().update({
          id: c == null ? void 0 : c.id,
          consent: {
            email: (I = s == null ? void 0 : s.acquisition_details) == null ? void 0 : I.email,
            isd_code: (R = s == null ? void 0 : s.acquisition_details) == null ? void 0 : R.isd,
            mobile: (X = s == null ? void 0 : s.acquisition_details) == null ? void 0 : X.mobile_number
          }
        });
        A(T), y("payment"), _(!1);
      } catch (T) {
        if (T && ((de = (Z = T == null ? void 0 : T.data) == null ? void 0 : Z.error) == null ? void 0 : de.message) === "failed to update investor consent, already exists") {
          y("payment"), _(!1);
          return;
        }
        y("error"), _(!1);
      }
  }, U = () => /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsx(ie, {
        title: r._({
          id: "ol5JJc"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ e.jsx(De, {
        customClass: "mb-8",
        children: /* @__PURE__ */ e.jsxs("div", {
          className: "-mr-2 -ml-2  flex flex-wrap items-center",
          children: [
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(oe, {
                label: r._({
                  id: "4Zu577"
                }),
                value: c != null && c.amount ? `₹ ${he(c == null ? void 0 : c.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(oe, {
                label: r._({
                  id: "rs9/UI"
                }),
                value: "One time"
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ e.jsx(oe, {
                label: r._({
                  id: "cXcPd1"
                }),
                value: c != null && c.folio_number ? c == null ? void 0 : c.folio_number : "New folio"
              })
            })
          ]
        })
      })
    ]
  }), se = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(ie, {
        title: r._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ e.jsx(fe, {
        scheme: v
      })
    ]
  });
  function me({ success: L, isWindowBlocked: u, paymentId: O }) {
    (async () => {
      if (O)
        try {
          const I = await B.fpClient.payments().fetch(Number(O));
          l(I);
          const R = await B.fpClient.mf_purchases().fetch(c.id);
          A(R);
        } catch (I) {
          window.debug.log(I), V(I);
        }
      y(L && !u || !L && !u ? "finished" : "finished_error");
    })();
  }
  return b ? /* @__PURE__ */ e.jsx(ge, {
    variant: "fullpage"
  }) : (() => {
    switch (g) {
      case "consent":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            M && se(),
            U(),
            /* @__PURE__ */ e.jsx(Ne, {
              handleSubmit: W,
              pageTitle: r._({
                id: "s/ORP1"
              }),
              isGroupInvestment: k,
              investorProfile: w,
              collectAcquisition: s
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            M && se(),
            U(),
            /* @__PURE__ */ e.jsx(Re, {
              title: r._({
                id: "8Erf1o"
              }),
              description: r._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            M && se(),
            U(),
            /* @__PURE__ */ e.jsx(ve, {
              title: r._({
                id: "sS+OUW"
              }),
              showAction: !0,
              actionText: r._({
                id: "RvsoP+"
              }),
              actionHandler: () => {
                y("payment");
              }
            })
          ]
        });
      case "payment":
        return !re(w) && !re(c) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(fe, {
              scheme: v
            }),
            /* @__PURE__ */ e.jsx(it, {
              investorProfile: w,
              mf_purchase: c,
              onCompletion: me
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished":
        return !re(v) && !re(c) ? /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(fe, {
              scheme: v
            }),
            /* @__PURE__ */ e.jsx(Ee, {
              investorProfile: w,
              scheme: v,
              mf_order: c,
              payment: ee,
              isGroupInvestment: k
            })
          ]
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "not_allowed":
        return /* @__PURE__ */ e.jsx(Qe, {});
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function ot({ mfPurchasePlan: w, handleSetupMandateCompletion: h, partner: M, groupRecurringDateObject: k, isFirstInstallmentPayment: D, isGroupInvestment: Q }) {
  const { loggedInUser: B } = _e(at), { fpapi: b } = _e(pe), [_, g] = f(!1), [y, v] = f("initial"), [Y, c] = f(!1), [A, F] = f(null), o = Te(null), $ = ze(() => ({
    mandate: null
  }), []);
  le(() => {
    i();
  }, []);
  const i = async () => {
    var p;
    try {
      g(!0);
      const x = await b.getInvestorProfile((p = B == null ? void 0 : B.profile) == null ? void 0 : p.fp_identifier);
      F(x), g(!1);
    } catch (x) {
      window.debug.error(x), V(x);
    }
  }, ee = async ({ mandate: p }) => {
    g(!0);
    try {
      let x = {};
      x = {
        id: w == null ? void 0 : w.id,
        payment_method: "mandate",
        payment_source: p == null ? void 0 : p.id
      }, await b.fpClient.mf_purchase_plans().update(x), h(), g(!1);
    } catch (x) {
      g(!1), v("error"), V(x);
    }
  };
  function l() {
    return /* @__PURE__ */ e.jsxs("div", {
      className: "pb-6",
      children: [
        we(w, M, k, Q, D),
        /* @__PURE__ */ e.jsx(st, {
          onSubmit: ee,
          initialValues: $,
          validate: () => ({}),
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (p) => /* @__PURE__ */ e.jsxs("form", {
            id: "editOrderForm",
            onSubmit: p.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(He, {
                    message: r._({
                      id: "lFCwug"
                    })
                  }),
                  /* @__PURE__ */ e.jsx(nt, {
                    name: "mandate",
                    validate: et(tt),
                    children: (x) => (o.current = x, /* @__PURE__ */ e.jsxs(e.Fragment, {
                      children: [
                        /* @__PURE__ */ e.jsx(rt, {
                          mandate: x.input.value,
                          handleMandateModalChange: (te) => {
                            te.preventDefault(), c(!Y);
                          }
                        }),
                        x.meta.error && x.meta.touched && /* @__PURE__ */ e.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ e.jsx(be, {
                            type: "error",
                            variant: "field",
                            title: x.meta.error
                          })
                        })
                      ]
                    }))
                  })
                ]
              }),
              /* @__PURE__ */ e.jsx(Je, {
                children: /* @__PURE__ */ e.jsx("div", {
                  className: "mb-4 pt-1",
                  children: /* @__PURE__ */ e.jsx(xe, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: r._({
                      id: "2iVSoY"
                    })
                  })
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ e.jsx(ye, {
          variant: "fullPage",
          title: "Select mandate",
          isOpen: Y,
          toggleModal: () => {
            c(!1);
          },
          contentHeightMax: !0,
          children: /* @__PURE__ */ e.jsx(Ie, {
            investorProfile: A,
            handleMandateSelection: (p) => {
              var x;
              c(!1), (x = o == null ? void 0 : o.current) == null || x.input.onChange(p);
            },
            limit: w == null ? void 0 : w.amount,
            isPartner: !1
          })
        })
      ]
    });
  }
  function s() {
    switch (y) {
      case "initial":
        return l();
      case "error":
        return /* @__PURE__ */ e.jsx(ve, {
          title: r._({
            id: "rz8jSA"
          }),
          description: r._({
            id: "RxIw8D"
          })
        });
      default:
        return l();
    }
  }
  return _ ? /* @__PURE__ */ e.jsx(ge, {
    variant: "fullpage"
  }) : s();
}
function Dt({ investorProfile: w, id: h, showSchemeDetails: M = !0, isGroupInvestment: k = !1, onSuccess: D, action: Q, isFirstInstallmentPayment: B }) {
  const { fpapi: b } = _e(pe), [_, g] = f(!1), [y, v] = f("setup_mandate"), [Y, c] = f(), [A, F] = f(null), [o, $] = f(null), { tenantConfig: i, amcs: ee, banks: l } = ke(), { collectedAcquisition: s, initiateSendOTP: p } = Le(), [x, te] = f(), [W, U] = f(null), se = i == null ? void 0 : i.fallbackLogo, me = (t) => {
    var d, C, N, H, J, z;
    if (k) {
      const ne = (C = (d = i == null ? void 0 : i.fundList) == null ? void 0 : d.find((G) => G.isin === (t == null ? void 0 : t.scheme))) == null ? void 0 : C.nfo;
      return ((N = i == null ? void 0 : i.groupInvestment) == null ? void 0 : N.days) && ((H = i == null ? void 0 : i.groupInvestment) == null ? void 0 : H.cutOffDays) && Ke({
        salaryDay: (J = i == null ? void 0 : i.groupInvestment) == null ? void 0 : J.days[0],
        salaryCutoffDay: (z = i == null ? void 0 : i.groupInvestment) == null ? void 0 : z.cutOffDays[0],
        NFO: ne,
        purchaseDate: t.created_at
      });
    }
  };
  le(() => {
    y === "consent" ? u() : y === "setup_mandate" && L();
  }, [
    y
  ]), le(() => {
    (async () => {
      g(!0);
      try {
        await Promise.all([
          ce(),
          Z()
        ]);
      } finally {
        g(!1);
      }
    })();
  }, [
    o
  ]);
  const ce = async () => {
    if (!re(o))
      try {
        const t = await (b == null ? void 0 : b.fpClient.partners().fetchById(o == null ? void 0 : o.partner));
        te(t);
      } catch (t) {
        V(t);
      }
  }, L = async () => {
    try {
      g(!0);
      const t = await b.fpClient.mf_purchase_plans().fetch(h);
      if (M) {
        const d = await b.fpClient.master_data().fetchScheme(t == null ? void 0 : t.scheme);
        O(d);
      }
      $(t), (t == null ? void 0 : t.state) === "active" || (t == null ? void 0 : t.state) === "completed" ? v("success") : t != null && t.payment_source && (t == null ? void 0 : t.payment_method) === "mandate" && v("consent");
    } catch (t) {
      window.debug.log(t), g(!1), v("error"), V(t);
    } finally {
      g(!1);
    }
  }, u = async () => {
    const t = h == null ? void 0 : h.split(",");
    try {
      g(!0);
      const d = await b.fpClient.mf_purchase_plans().fetch(h), C = {
        given_for_object: d == null ? void 0 : d.object,
        action: Q,
        given_for: t
      };
      await p(C), $(d), v("consent"), F(me(d)), g(!1);
    } catch (d) {
      window.debug.log(d), g(!1), v("error"), V(d);
    }
  }, O = (t) => {
    const d = i != null && i.showSchemeLogo ? {
      ...t,
      schemeLogo: ee[t == null ? void 0 : t.amc_id] || (i == null ? void 0 : i.fallbackLogo)
    } : {
      ...t
    };
    c(d);
  }, I = (t) => {
    const d = new Date(t);
    return d.getDay() === 5 ? 3 : d.getDay() === 6 ? 2 : 1;
  }, R = async (t) => {
    let d;
    try {
      d = await b.fpClient.mf_purchases().fetchAll({
        plan: t == null ? void 0 : t.id
      });
    } catch (G) {
      window.debug.log(G), V(G);
    }
    if (!d.data.length)
      return;
    const C = d.data[0], N = t != null && t.created_at ? new Date(t == null ? void 0 : t.created_at) : /* @__PURE__ */ new Date(), H = new Date(new Date(N).setDate(N.getDate() + 1)), J = new Date(new Date(N).setDate(N.getDate() + I(N))), z = new Date(C == null ? void 0 : C.scheduled_on);
    if (new Date(C == null ? void 0 : C.created_at).toDateString() === N.toDateString() || z.toDateString() === H.toDateString() || z.toDateString() === J.toDateString())
      return C;
  }, X = async () => {
    var t, d, C, N, H, J, z, ne, G, ue;
    if (D) {
      g(!0);
      try {
        await b.fpClient.mf_purchase_plans().update({
          id: o == null ? void 0 : o.id,
          consent: {
            email: (t = s == null ? void 0 : s.acquisition_details) == null ? void 0 : t.email,
            isd_code: (d = s == null ? void 0 : s.acquisition_details) == null ? void 0 : d.isd,
            mobile: (C = s == null ? void 0 : s.acquisition_details) == null ? void 0 : C.mobile_number
          }
        });
        const E = await R(o);
        D(!0, E), g(!1);
      } catch (E) {
        if (E && ((H = (N = E == null ? void 0 : E.data) == null ? void 0 : N.error) == null ? void 0 : H.message) === "consent is already exists for the plan") {
          const a = await R(o);
          D(!0, a), g(!1);
          return;
        }
        v("error"), g(!1);
      }
    } else {
      g(!0);
      try {
        const E = await b.fpClient.mf_purchase_plans().update({
          id: o == null ? void 0 : o.id,
          consent: {
            email: (J = s == null ? void 0 : s.acquisition_details) == null ? void 0 : J.email,
            isd_code: (z = s == null ? void 0 : s.acquisition_details) == null ? void 0 : z.isd,
            mobile: (ne = s == null ? void 0 : s.acquisition_details) == null ? void 0 : ne.mobile_number
          }
        });
        $(E), v("success"), g(!1);
      } catch (E) {
        if (E && ((ue = (G = E == null ? void 0 : E.data) == null ? void 0 : G.error) == null ? void 0 : ue.message) === "consent is already exists for the plan") {
          v("success"), g(!1);
          return;
        }
        v("error"), g(!1);
      }
    }
  }, Z = async () => {
    w && (o == null ? void 0 : o.payment_method) === "mandate" && (o != null && o.payment_source) && b.fpClient.bank_accounts().fetchAll({
      profile: w == null ? void 0 : w.id
    }).then(async (t) => {
      var H, J, z;
      const d = t == null ? void 0 : t.data;
      if (!d.length)
        return;
      const N = {
        ...await b.fpClient.mandates().fetch(o == null ? void 0 : o.payment_source),
        bank: {
          bank_name: (H = d[0]) == null ? void 0 : H.bank_name,
          number: (J = d[0]) == null ? void 0 : J.account_number,
          ifsc: (z = d[0]) == null ? void 0 : z.ifsc_code
        }
      };
      U(N);
    }).catch((t) => {
      V(t);
    });
  }, de = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(ie, {
        title: r._({
          id: "u6vc2m"
        })
      }),
      /* @__PURE__ */ e.jsx(fe, {
        scheme: Y
      })
    ]
  }), T = () => {
    switch (y) {
      case "setup_mandate":
        return /* @__PURE__ */ e.jsx(ot, {
          mfPurchasePlan: o,
          handleSetupMandateCompletion: () => v("consent"),
          partner: x,
          groupRecurringDateObject: A,
          isGroupInvestment: k,
          isFirstInstallmentPayment: B
        });
      case "consent":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            we(o, x, A, k, B),
            /* @__PURE__ */ e.jsx(Be, {
              mandate: W,
              banks: l,
              fallbackLogo: se
            }),
            /* @__PURE__ */ e.jsx(Ne, {
              handleSubmit: X,
              pageTitle: r._({
                id: "nvtnXn"
              }),
              investorProfile: w,
              isGroupInvestment: k,
              collectAcquisition: s
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            we(o, x, A, k, B),
            /* @__PURE__ */ e.jsx(Be, {
              mandate: W,
              banks: l,
              fallbackLogo: se
            }),
            /* @__PURE__ */ e.jsx(Re, {
              title: r._({
                id: "8Erf1o"
              }),
              description: r._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(ve, {
          title: r._({
            id: "kCTQHV"
          }),
          description: r._({
            id: "oahseM"
          }),
          showDetails: !1
        });
      case "success":
        return /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(Ee, {
            investorProfile: w,
            scheme: Y,
            mf_order: o
          })
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  };
  return _ ? /* @__PURE__ */ e.jsx(ge, {
    variant: "fullpage"
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      M && de(),
      T()
    ]
  });
}
export {
  Dt as P,
  Ft as a,
  it as b
};
