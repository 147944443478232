import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as oe, useState as n, useEffect as ae, useCallback as ne, useLayoutEffect as pe } from "react";
import { a as ce, c as o, M as ge, S as T, D as ve, d as we, F as _e, B, b as Q } from "./index.esm-DT3FM8Og.js";
import { F as de } from "./provider-DPPRdC-N.js";
import { r as Se } from "./sentry-CbZOQ4Ya.js";
import { i as l } from "./index-Bb24DwqC.js";
import { u as me } from "./useConfiguration-B-lyaBB9.js";
import { i as Ne, f as Y, m as ye, k as R, E as Ce, C as D, j as re } from "./utils-DbnU7rHI.js";
import { u as Me } from "./useScrollToBottomRef-CqDzM0dm.js";
import { ErrorPage as Z } from "./ErrorPage.js";
import { FolioDetails as De } from "./FolioDetails.js";
function Fe({ folio_number: S }) {
  const { fpapi: c } = oe(de), [N, F] = n(!1), [i, K] = n({}), [h, y] = n({}), [C, z] = n({}), [X, b] = n("initial"), { amcs: J, tenantConfig: v } = me(), U = v == null ? void 0 : v.fallbackLogo, [q, W] = n(null), H = Me(q), [L, w] = n(!1), [E, k] = n(10);
  ae(() => {
    (async () => {
      if (!S) {
        b("error");
        return;
      }
      F(!0);
      try {
        const s = await c.fpClient.other_reports().transaction_list({
          folio_number: S
        });
        if (!Ne(s.data) && !Y(s.data.rows)) {
          K(s.data);
          const a = {};
          a.columns = s.data.columns, a.rows = s.data.rows.slice(0, E), O(a), y(a);
        }
        F(!1);
      } catch {
        b("error"), F(!1);
      }
    })();
  }, []);
  const O = async (s) => {
    const a = /* @__PURE__ */ new Set();
    s == null || s.rows.forEach((r) => {
      var f;
      a.add(r[(f = s == null ? void 0 : s.columns) == null ? void 0 : f.indexOf("isin")]);
    });
    const m = [];
    a.forEach((r) => {
      const f = c.fpClient.master_data().fetchScheme(r);
      m.push(f);
    }), Promise.all(m).then((r) => {
      z(r);
    });
  };
  ae(() => {
    var s, a;
    if (H.hasScrolledToBottom && ((s = h == null ? void 0 : h.rows) == null ? void 0 : s.length) < ((a = i == null ? void 0 : i.rows) == null ? void 0 : a.length)) {
      const m = E + 10;
      w(!0);
      const r = {};
      r.columns = i.columns, r.rows = h.rows.concat(i.rows.slice(E, m)), O(r), y(r), k((f) => f + 10), w(!1);
    }
  }, [
    H.hasScrolledToBottom
  ]);
  const u = (s) => {
    if (Y(C))
      return "";
    {
      const a = C.filter((m) => {
        var r;
        return m.isin === s[(r = i == null ? void 0 : i.columns) == null ? void 0 : r.indexOf("isin")];
      });
      return Y(a) ? "" : a[0];
    }
  }, A = (s) => s === "redemption" ? "Withdrawal" : s === "switch_in" ? "Switch in" : s === "switch_out" ? "Switch out" : "Purchase", V = ({ transaction: s }) => {
    var a, m, r, f, $, I, P, M;
    return /* @__PURE__ */ e.jsx(T, {
      customClass: "mb-4",
      header: {
        title: /* @__PURE__ */ e.jsx("span", {
          className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
          children: l._({
            id: "moFZxg",
            values: {
              0: ye(new Date(s[(a = i == null ? void 0 : i.columns) == null ? void 0 : a.indexOf("traded_on")]), "dd-MMM-yyyy")
            }
          })
        })
      },
      children: /* @__PURE__ */ e.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center",
        children: [
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 w-full pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: l._({
                id: "XTJZw8"
              }),
              value: u(s) ? R(u(s).name) : "-",
              logo: u(s) ? J[u(s).amc_id] || U : ""
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: l._({
                id: "8kF3Wn"
              }),
              value: A(s[(m = i == null ? void 0 : i.columns) == null ? void 0 : m.indexOf("type")]) ?? "-"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: l._({
                id: "KqeLJ1"
              }),
              value: s[(r = i == null ? void 0 : i.columns) == null ? void 0 : r.indexOf("amount")] ? `₹ ${Ce(s[(f = i == null ? void 0 : i.columns) == null ? void 0 : f.indexOf("amount")])}` : "-"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: l._({
                id: "6ssn5V"
              }),
              value: s[($ = i == null ? void 0 : i.columns) == null ? void 0 : $.indexOf("units")] ? D(s[(I = i == null ? void 0 : i.columns) == null ? void 0 : I.indexOf("units")], 2) : "-"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "mb-3 basis-1/2 pr-2 pl-2",
            children: /* @__PURE__ */ e.jsx(o, {
              label: l._({
                id: "tagv+z",
                values: {
                  0: A(s[(P = i == null ? void 0 : i.columns) == null ? void 0 : P.indexOf("type")])
                }
              }),
              value: `₹ ${D(s[(M = i == null ? void 0 : i.columns) == null ? void 0 : M.indexOf("traded_at")], 2)}` ?? "-"
            })
          })
        ]
      })
    });
  }, G = () => {
    var s;
    return Y(i == null ? void 0 : i.rows) ? /* @__PURE__ */ e.jsx(Z, {
      title: l._({
        id: "WOxLnB"
      }),
      description: l._({
        id: "meKOVc"
      })
    }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(o, {
          label: l._({
            id: "cXcPd1"
          }),
          value: S,
          background: !0,
          customClass: "mb-2"
        }),
        (s = h == null ? void 0 : h.rows) == null ? void 0 : s.map((a, m) => /* @__PURE__ */ e.jsx("div", {
          ref: W,
          children: /* @__PURE__ */ e.jsx(V, {
            transaction: a
          })
        }, m)),
        L ? /* @__PURE__ */ e.jsx("div", {
          className: "flex justify-center",
          children: /* @__PURE__ */ e.jsx(ge, {
            type: "important",
            variant: "field",
            title: "Loading..",
            loader: !0,
            customClass: "mb-5 "
          })
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      ]
    });
  };
  function g() {
    switch (X) {
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "initial":
        return G();
      case "error":
        return /* @__PURE__ */ e.jsx(Z, {
          title: l._({
            id: "w7WdjZ"
          })
        });
    }
  }
  return N ? /* @__PURE__ */ e.jsx(ce, {
    variant: "fullpage"
  }) : g();
}
function Ve({ investorProfile: S, scheme_details: c, amc_code: N, amcId: F, isPartner: i, allSelectable: K, description: h, handleFolioSelection: y, handleFolioCreation: C, workflow: z, assistedWorkflowPAN: X }) {
  const [b, J] = n([]), [v, U] = n([]), [q, W] = n(!1), [H, L] = n("initial"), [w, E] = n(null), [k, O] = n(!1), [u, A] = n({
    index: 0,
    obj: null
  }), [V, G] = n(null), { fpapi: g } = oe(de), { tenantConfig: s, amcs: a } = me(), m = s == null ? void 0 : s.fallbackLogo, r = s == null ? void 0 : s.showSchemeLogo, [f, $] = n(""), [I, P] = n(!1), [M, ee] = n(!1), ue = z && z === "withdraw" ? "You can only withdraw from folios where redeemable amount is greater than zero." : `${i ? "Investor's" : "Your"} existing folios with mutual funds`, se = ne((t, x) => {
    const j = [];
    t.folios.forEach((d) => {
      d.schemes.forEach((le) => {
        le.isin === c.isin && (j[d.folio_number] = le);
      });
    }), E(j);
    const _ = [], p = [];
    return x.data.forEach((d) => {
      j[d.number] && (K || j[d.number].holdings.redeemable_units > 0 ? _.push(d) : p.push(d));
    }), {
      foliosWithSelect: _,
      foliosWithoutSelect: p
    };
  }, [
    c
  ]), te = ne((t) => {
    const x = [];
    return t.data.forEach((j) => {
      j.amc === N && x.push(j);
    }), {
      foliosWithSelect: x,
      foliosWithoutSelect: []
    };
  }, [
    N
  ]);
  pe(() => {
    async function t() {
      try {
        W(!0);
        const x = await g.fpClient.mf_investment_accounts().fetchAll({
          investor: i ? X : S.pan
        }), j = await g.fpClient.investor_reports().fetchHoldings({
          investment_account_id: x.data[0].old_id
        }), _ = await g.fpClient.mf_folios().fetchAll({
          mf_investment_account: x.data[0].id
        });
        let p = {};
        if (c) {
          const d = await g.fpClient.master_data().fetchScheme(c.isin);
          G(d), p = se(j, _);
        } else if (N) {
          p = te(_);
          const d = await g.fpClient.master_data().fetchAmcs(F);
          $(d == null ? void 0 : d.name);
        } else
          p = {
            foliosWithSelect: _.data,
            foliosWithoutSelect: []
          };
        A({
          index: 0,
          obj: p.foliosWithSelect[0]
        }), J(p.foliosWithSelect), U(p.foliosWithoutSelect), W(!1);
      } catch (x) {
        window.debug.error(x), W(!1), L("error"), Se(x);
      }
    }
    t();
  }, [
    N,
    te,
    se,
    g,
    S,
    c
  ]);
  function fe() {
    return /* @__PURE__ */ e.jsx(Q, {
      variant: "fullPage",
      title: l._({
        id: "Fuk8S8"
      }),
      isOpen: k,
      contentHeightMax: !0,
      toggleModal: () => {
        O(!1), L("initial");
      },
      children: /* @__PURE__ */ e.jsx(De, {
        folio: u.obj,
        isPartner: i
      })
    });
  }
  function he() {
    var t;
    return /* @__PURE__ */ e.jsx(Q, {
      variant: "fullPage",
      title: l._({
        id: "tsYSsE"
      }),
      isOpen: M,
      contentHeightMax: !0,
      toggleModal: () => {
        ee(!1);
      },
      children: /* @__PURE__ */ e.jsx(Fe, {
        folio_number: (t = u == null ? void 0 : u.obj) == null ? void 0 : t.number
      })
    });
  }
  function xe() {
    return /* @__PURE__ */ e.jsxs(Q, {
      title: l._({
        id: "ZymVOR"
      }),
      isOpen: I,
      contentHeightMax: !0,
      toggleModal: () => {
        P(!1);
      },
      children: [
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-4",
          children: [
            /* @__PURE__ */ e.jsx(B, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (t) => {
                t.preventDefault(), ee(!M);
              },
              customClass: "flex-1",
              children: l._({
                id: "BJQWc+"
              })
            }),
            M && he()
          ]
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-6",
          children: [
            /* @__PURE__ */ e.jsx(B, {
              variant: "secondary",
              fullwidth: !0,
              onClick: (t) => {
                t.preventDefault(), O(!k);
              },
              customClass: "flex-1",
              children: l._({
                id: "wuVzYo"
              })
            }),
            k && fe()
          ]
        })
      ]
    });
  }
  const be = () => {
    let t = "";
    return h ? t = h : f ? t = `Your existing folios with ${R(f)}` : t = ue, l._({
      id: "cIO4IZ",
      values: {
        message: t
      }
    });
  };
  function ie() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ve, {
          type: "text",
          text: be()
        }),
        c && V && /* @__PURE__ */ e.jsx("div", {
          children: /* @__PURE__ */ e.jsx(o, {
            label: l._({
              id: "x4tqge"
            }),
            value: R(c.name),
            background: !0,
            customClass: "mb-2",
            logo: r ? a[V.amc_id] || m : ""
          })
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "mb-44",
          children: [
            /* @__PURE__ */ e.jsx(we, {
              onChange: (t) => {
                A({
                  index: t.target.value,
                  obj: b[t.target.value]
                });
              },
              variant: "selectable",
              selected: u.index,
              children: b.length ? b.map((t) => /* @__PURE__ */ e.jsx(T, {
                children: /* @__PURE__ */ e.jsxs("div", {
                  className: "flex flex-wrap items-center",
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: l._({
                          id: "cXcPd1"
                        }),
                        value: t.number
                      })
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: l._({
                          id: "+Thrcm"
                        }),
                        value: re(t.holding_pattern)
                      })
                    }),
                    c && /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: l._({
                          id: "ez01Pf"
                        }),
                        value: `₹ ${D(w[t.number].invested_value.amount, 2)}`
                      })
                    }),
                    c && /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: l._({
                          id: "//hmdU"
                        }),
                        value: `₹ ${D(w[t.number].market_value.redeemable_amount, 2)}`
                      })
                    }),
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-3 basis-1/2 pr-2 pl-2",
                      children: /* @__PURE__ */ e.jsx(o, {
                        label: l._({
                          id: "kYuKEz"
                        }),
                        value: t.dp_id ? "Demat" : "Physical"
                      })
                    })
                  ]
                })
              }, t.number)) : /* @__PURE__ */ e.jsx(e.Fragment, {})
            }),
            v.length ? v.map((t) => /* @__PURE__ */ e.jsx(T, {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-mr-2 -ml-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: l._({
                        id: "cXcPd1"
                      }),
                      value: t.number
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: l._({
                        id: "+Thrcm"
                      }),
                      value: re(t.holding_pattern)
                    })
                  }),
                  c && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: l._({
                        id: "ez01Pf"
                      }),
                      value: `₹ ${D(w[t.number].invested_value.amount, 2)}`
                    })
                  }),
                  c && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: l._({
                        id: "//hmdU"
                      }),
                      value: `₹ ${D(w[t.number].market_value.redeemable_amount, 2)}`
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(o, {
                      label: l._({
                        id: "kYuKEz"
                      }),
                      value: t.dp_id ? "Demat" : "Physical"
                    })
                  })
                ]
              })
            }, t.number)) : /* @__PURE__ */ e.jsx(e.Fragment, {})
          ]
        }),
        !b.length && !v.length && /* @__PURE__ */ e.jsx(Z, {
          title: l._({
            id: "0i/U4g"
          }),
          description: l._({
            id: "jVCje2"
          })
        }),
        /* @__PURE__ */ e.jsxs(_e, {
          children: [
            y ? /* @__PURE__ */ e.jsx("div", {
              className: "mb-4",
              children: /* @__PURE__ */ e.jsx(B, {
                variant: b.length ? "primary" : "disabled",
                fullwidth: !0,
                onClick: (t) => {
                  t.preventDefault(), y && y(u.obj);
                },
                children: l._({
                  id: "H/E6fZ"
                })
              })
            }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
            C ? /* @__PURE__ */ e.jsx("div", {
              className: "mb-4",
              children: /* @__PURE__ */ e.jsx(B, {
                variant: "secondary",
                fullwidth: !0,
                onClick: (t) => {
                  t.preventDefault(), C && C(u.obj);
                },
                text: "Create new folio",
                children: l._({
                  id: "+fCelk"
                })
              })
            }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
            /* @__PURE__ */ e.jsx(B, {
              variant: b.length ? "secondary" : "disabled",
              fullwidth: !0,
              onClick: (t) => {
                t.preventDefault(), P(!I);
              },
              children: l._({
                id: "f9pW3a"
              })
            })
          ]
        }),
        xe()
      ]
    });
  }
  function je() {
    switch (H) {
      case "initial":
        return ie();
      case "error":
        return /* @__PURE__ */ e.jsx(Z, {
          title: l._({
            id: "x1yMIE"
          }),
          description: l._({
            id: "RxIw8D"
          })
        });
      default:
        return ie();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: q ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: je()
    })
  });
}
export {
  Ve as F
};
