import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as K, useState as i, useEffect as $, useRef as be, useCallback as me } from "react";
import { a as B, S as it, c as z, w as ot, B as te, x as Oe, y as De, z as Z, P as U, D as Ee, T as Pe, I as Te, M as ue, b as ct, A as lt, e as ye, k as Se } from "./index.esm-DT3FM8Og.js";
import { AppContext as Ae } from "./AppContext.js";
import { u as fe } from "./useConfiguration-B-lyaBB9.js";
import { F as Y } from "./provider-DPPRdC-N.js";
import { i as o } from "./index-Bb24DwqC.js";
import { r as P } from "./sentry-CbZOQ4Ya.js";
import { C as X } from "./StatusMessage-PK7bu2oh.js";
import { O as dt, I as mt } from "./Investment-DsbF9_Qe.js";
import { LoginContext as Me } from "./LoginContext.js";
import { i as A, k as ut, n as ft, C as ht, al as Fe, am as He, o as ze, an as ie, ao as Ce, ap as ke, z as pt, aq as _t, M as gt, B as Ie, j as Ge, f as xt, ar as wt } from "./utils-DbnU7rHI.js";
import { c as jt } from "./checkMigratedUser-D-TLMNz-.js";
import { p as vt } from "./partiallyOnboardMigratedUser-Do2b2Ofg.js";
import { ErrorPage as J } from "./ErrorPage.js";
import { P as bt } from "./PartnerLogin-D2kCurmZ.js";
import { K as yt } from "./KycCheckStatus-DlzH9NoH.js";
import { O as St } from "./OtpForm-CTvReVn2.js";
import { P as ne } from "./Product-lMhZHKhI.js";
import { O as Ct } from "./OrderStatus-B-hp1hY6.js";
import { FolioDetails as kt } from "./FolioDetails.js";
import { W as It } from "./Welcome-CCMlcQ8T.js";
import { FundList as Lt } from "./FundList.js";
function Nt({ createdOrder: t, orderRequest: m, investorProfile: a, handleOrderOTPConfirmation: w }) {
  const { fpapi: j } = K(Y), [_, p] = i(!1), S = async () => {
    p(!0);
    try {
      const d = await j.fpClient.email_addresses().fetchAll({
        profile: a == null ? void 0 : a.id
      }), r = d == null ? void 0 : d.data[0], f = await j.fpClient.phone_numbers().fetchAll({
        profile: a == null ? void 0 : a.id
      }), c = f == null ? void 0 : f.data[0];
      return p(!1), {
        emailData: r,
        phoneData: c
      };
    } catch (d) {
      window.debug.log(d), P(d);
    }
  }, M = async () => {
    const d = A(t) ? m : t, { emailData: r, phoneData: f } = await S();
    if (p(!0), (d == null ? void 0 : d.object) === "mf_redemption")
      try {
        const c = await j.fpClient.mf_redemptions().update({
          id: d.id,
          state: "confirmed",
          consent: {
            email: r.email,
            isd_code: f.isd,
            mobile: f.number
          }
        });
        w(c);
      } catch (c) {
        window.debug.log(c), P(c);
      }
    else if ((d == null ? void 0 : d.object) === "mf_switch")
      try {
        const c = await j.fpClient.mf_switches().update({
          id: d.id,
          state: "confirmed",
          consent: {
            email: r.email,
            isd_code: f.isd,
            mobile: f.number
          }
        });
        w(c);
      } catch (c) {
        window.debug.log(c), P(c);
      }
    else if ((d == null ? void 0 : d.object) === "mf_switch_plan") {
      let c = {
        ...m
      };
      delete c.object, c = {
        ...c,
        consent: {
          email: r.email,
          isd_code: f.isd,
          mobile: f.number
        }
      };
      try {
        const v = await j.fpClient.mf_switch_plans().create(c);
        w(v);
      } catch (v) {
        window.debug.log(v), P(v);
      }
    } else if ((d == null ? void 0 : d.object) === "mf_redemption_plan") {
      let c = {
        ...m
      };
      delete c.object, c = {
        ...c,
        consent: {
          email: r.email,
          isd_code: f.isd,
          mobile: f.number
        }
      };
      try {
        const v = await j.fpClient.mf_redemption_plans().create(c);
        w(v);
      } catch (v) {
        window.debug.log(v), P(v);
      }
    }
    p(!1);
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: _ ? /* @__PURE__ */ e.jsx(B, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(St, {
      handleSubmit: M,
      pageTitle: o._({
        id: "s/ORP1"
      }),
      investorProfile: a
    })
  });
}
function Ot({ createdOrder: t, orderRequest: m, investorProfile: a, onConfirmation: w }) {
  const { tenantConfig: j, amcs: _ } = fe(), p = j == null ? void 0 : j.fallbackLogo, S = j == null ? void 0 : j.showSchemeLogo, [M, d] = i(!1), [r, f] = i(null), [c, v] = i("initial"), { fpapi: D } = K(Y), b = A(t) ? m : t, L = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  $(() => {
    async function N() {
      d(!0);
      try {
        const u = await D.fpClient.master_data().fetchScheme(b.switch_out_scheme), O = S ? {
          ...u,
          schemeLogo: _[u == null ? void 0 : u.amc_id] || p
        } : {
          ...u
        };
        f(O), v("initial"), d(!1);
      } catch (u) {
        v("error"), P(u);
      }
    }
    N();
  }, [
    D,
    t,
    m,
    b,
    S,
    _,
    p
  ]);
  const F = async (N) => {
    w(N);
  };
  return M ? /* @__PURE__ */ e.jsx(B, {
    variant: "fullpage"
  }) : (() => {
    switch (c) {
      case "initial":
        return /* @__PURE__ */ e.jsxs("div", {
          style: {
            marginTop: "-20px"
          },
          children: [
            /* @__PURE__ */ e.jsx(it, {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-ml-2 -mr-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-full pl-2 pr-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      label: o._({
                        id: "6Xqu8f"
                      }),
                      value: r && /* @__PURE__ */ e.jsxs("div", {
                        className: "flex",
                        children: [
                          (r == null ? void 0 : r.schemeLogo) && /* @__PURE__ */ e.jsx("img", {
                            src: r == null ? void 0 : r.schemeLogo,
                            alt: "logo",
                            className: L
                          }),
                          /* @__PURE__ */ e.jsx("div", {
                            className: "flex flex-col",
                            children: /* @__PURE__ */ e.jsx("div", {
                              children: `${ut(r.name)}`
                            })
                          })
                        ]
                      })
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      label: o._({
                        id: "fezFD7"
                      }),
                      value: ft(r == null ? void 0 : r.investment_option)
                    })
                  }),
                  b.amount && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      label: o._({
                        id: "KqeLJ1"
                      }),
                      value: b.amount ? `₹ ${ht(b.amount, 2)}` : "-"
                    })
                  }),
                  b.units && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      label: o._({
                        id: "6ssn5V"
                      }),
                      value: b.units ? b.units : "-"
                    })
                  }),
                  b.folio_number && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      label: o._({
                        id: "cXcPd1"
                      }),
                      value: b.folio_number ? b.folio_number : "-"
                    })
                  }),
                  !b.amount && !b.units && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(z, {
                      label: o._({
                        id: "RBIA5y"
                      }),
                      value: "Switch All"
                    })
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx(Nt, {
              investorProfile: a,
              createdOrder: t,
              orderRequest: m,
              handleOrderOTPConfirmation: F
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(J, {
          title: o._({
            id: "R9c5Jl"
          }),
          description: o._({
            id: "UFXd5p"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "order_error":
        return /* @__PURE__ */ e.jsx(J, {
          title: o._({
            id: "SOfRak"
          }),
          description: o._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: o._({
            id: "wy5cp6"
          }),
          actionHandler: () => v("initial")
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function Dt({ folio: t, handleTransact: m, handleViewFolioDetails: a }) {
  return /* @__PURE__ */ e.jsxs("div", {
    className: "flex items-center justify-around",
    children: [
      /* @__PURE__ */ e.jsx(X, {
        check: !!(t != null && t.dp_id),
        children: /* @__PURE__ */ e.jsx(ot, {
          placement: "bottom-start",
          content: "Currently, transactions are not allowed in Demat folios",
          size: "sm",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            size: "sm",
            onClick: () => m(t),
            disabled: !0,
            children: "Transact"
          })
        })
      }),
      /* @__PURE__ */ e.jsx(X, {
        check: !(t != null && t.dp_id),
        children: /* @__PURE__ */ e.jsx(te, {
          variant: "text",
          size: "sm",
          onClick: () => m(t),
          children: "Transact"
        })
      }),
      /* @__PURE__ */ e.jsx(te, {
        variant: "text",
        size: "sm",
        onClick: () => a(t),
        children: "View details"
      })
    ]
  });
}
const Et = ({ folio: t, handleTransact: m, handleViewFolioDetails: a }) => /* @__PURE__ */ e.jsx("div", {
  className: "mb-4",
  children: /* @__PURE__ */ e.jsx(Oe, {
    footer: /* @__PURE__ */ e.jsx(Dt, {
      folio: t,
      handleTransact: m,
      handleViewFolioDetails: a
    }),
    children: /* @__PURE__ */ e.jsxs(De, {
      children: [
        /* @__PURE__ */ e.jsx(Z, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(z, {
            label: "Folio number",
            value: (t == null ? void 0 : t.number) ?? "-"
          })
        }),
        /* @__PURE__ */ e.jsx(Z, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(z, {
            label: "Primary investor name",
            value: (t == null ? void 0 : t.primary_investor_name) ?? "-"
          })
        })
      ]
    })
  })
});
function $e(t, m) {
  const [a, w] = i(t);
  return $(() => {
    const j = setTimeout(() => {
      w(t);
    }, m);
    return () => {
      clearTimeout(j);
    };
  }, [
    t,
    m
  ]), a;
}
const Pt = (t) => t ? /^[A-Za-z\s]+$/.test(t) : !1, Tt = (t) => t ? /^[a-zA-Z]{3}[pcPC][a-zA-Z][0-9]{4}[a-zA-Z]$/.test(t) : !1;
function At({ licenseCode: t, handleTransact: m }) {
  const { fpapi: a } = K(Y), [w, j] = i([]), [_, p] = i(!1), [S, M] = i(null), [d, r] = i(""), f = $e(d, 800), [c, v] = i(!1), [D, b] = i(), [L, F] = i(null), G = async (u, O) => {
    var W;
    const h = {
      license_code: u
    };
    Tt(O) ? h.primary_investor_pan = O == null ? void 0 : O.toUpperCase() : Pt(O) ? h.primary_investor_name = O : h.folio_number = O;
    const H = await ((W = a == null ? void 0 : a.fpClient) == null ? void 0 : W.mf_folios().fetchAll(He(h)));
    return H == null ? void 0 : H.data;
  };
  $(() => {
    (async () => {
      try {
        if (p(!0), M(null), F(null), !t)
          throw new Error("License code is required");
        const u = await G(t, f == null ? void 0 : f.trim());
        if (!(u != null && u.length)) {
          F({
            title: "No folios found",
            description: "No folios match your search criteria. Try searching with a different folio number, PAN or name."
          });
          return;
        }
        j(u);
      } catch (u) {
        window.debug.error(u), M({
          title: "Error fetching data",
          description: Fe(u),
          showAction: !0,
          actionText: "Retry",
          actionHandler: () => window.location.reload()
        }), P(u);
      } finally {
        p(!1);
      }
    })();
  }, [
    t,
    f
  ]);
  const N = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(U, {
        title: "Invest"
      }),
      /* @__PURE__ */ e.jsx(Ee, {
        type: "text",
        text: o._({
          id: "f6NNiy"
        })
      }),
      /* @__PURE__ */ e.jsx(Pe, {
        type: "text",
        placeholder: "Search by folio number, PAN, or name",
        value: d,
        onChange: (u) => r(u.target.value),
        suffix: /* @__PURE__ */ e.jsx(Te, {
          name: "Search"
        }),
        maxLength: 250,
        onInput: (u) => {
          u.target.value = u.target.value.replace(ze("A-Za-z0-9 "), "");
        },
        autoFocus: !0,
        theme: {
          bgColorClass: "bg-neutral-0"
        },
        customClass: "mb-4"
      }),
      /* @__PURE__ */ e.jsx(X, {
        check: !!L,
        children: /* @__PURE__ */ e.jsx(ue, {
          type: "alert",
          variant: "spot",
          title: L == null ? void 0 : L.title,
          content: /* @__PURE__ */ e.jsx("p", {
            children: L == null ? void 0 : L.description
          })
        })
      }),
      /* @__PURE__ */ e.jsx(X, {
        check: !!(w != null && w.length && !L),
        children: w == null ? void 0 : w.map((u) => /* @__PURE__ */ e.jsx(Et, {
          folio: u,
          handleTransact: m,
          handleViewFolioDetails: (O) => {
            b(O), v(!0);
          }
        }, u.number))
      }),
      /* @__PURE__ */ e.jsx(ct, {
        variant: "fullPage",
        title: o._({
          id: "Fuk8S8"
        }),
        isOpen: c,
        contentHeightMax: !0,
        toggleModal: () => {
          v(!1);
        },
        children: /* @__PURE__ */ e.jsx(kt, {
          folio: D,
          isPartner: !0
        })
      })
    ]
  });
  return S ? /* @__PURE__ */ e.jsx(J, {
    ...S
  }) : _ ? /* @__PURE__ */ e.jsx(B, {
    variant: "fullpage"
  }) : N();
}
const Mt = ({ scheme: t, handleSchemeSelection: m }) => /* @__PURE__ */ e.jsx("div", {
  className: "mb-4",
  children: /* @__PURE__ */ e.jsx(Oe, {
    footer: /* @__PURE__ */ e.jsx("div", {
      className: "flex items-center justify-around",
      children: /* @__PURE__ */ e.jsx(te, {
        variant: "text",
        size: "sm",
        onClick: () => m(t),
        children: "Transact"
      })
    }),
    children: /* @__PURE__ */ e.jsxs(De, {
      children: [
        /* @__PURE__ */ e.jsx(Z, {
          span: 12,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(z, {
            label: "Name",
            value: ie(t.name)
          })
        }),
        /* @__PURE__ */ e.jsx(Z, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(z, {
            label: "ISIN",
            value: ie(t == null ? void 0 : t.isin)
          })
        }),
        /* @__PURE__ */ e.jsx(Z, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(z, {
            label: "Type",
            value: ie(t == null ? void 0 : t.plan_type)
          })
        }),
        /* @__PURE__ */ e.jsx(Z, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(z, {
            label: "Category",
            value: ie(t == null ? void 0 : t.fund_category)
          })
        })
      ]
    })
  })
}), Le = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Growth",
    value: "GROWTH"
  },
  {
    label: "IDCW - Payout",
    value: "DIV_PAYOUT"
  },
  {
    label: "IDCW - Reinvestment",
    value: "DIV_REINVESTMENT"
  }
], Ne = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Equity",
    value: "Equity"
  },
  {
    label: "Debt",
    value: "Debt"
  },
  {
    label: "Liquid",
    value: "Liquid"
  },
  {
    label: "Others",
    value: "Others"
  }
];
function Ft({ onFundSelection: t }) {
  const { fpapi: m } = K(Y), a = K(Me), [w, j] = i([]), [_, p] = i(!1), [S, M] = i(null), [d, r] = i(0), [f, c] = i(!1), [v, D] = i(void 0), [b, L] = i({
    investmentOption: Le[0],
    fundCategory: Ne[0]
  }), F = be(null), G = be(null), N = $e(v, 800);
  $(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const u = (l) => {
    var I, R;
    if ((I = l == null ? void 0 : l.toLowerCase()) != null && I.startsWith("arn"))
      return "Regular";
    if ((R = l == null ? void 0 : l.toLowerCase()) != null && R.startsWith("ina"))
      return "Direct";
  }, O = me(async (l, I) => {
    var se, oe, ae;
    const ee = {
      page: l,
      size: 20,
      investment_option: I.investmentOption.value,
      fund_category: (se = I.fundCategory) == null ? void 0 : se.value,
      name: (oe = I == null ? void 0 : I.name) == null ? void 0 : oe.toLowerCase(),
      plan_type: u(a == null ? void 0 : a.loggedInPartnerLicenceCode)
    }, T = await (m == null ? void 0 : m.fpClient.master_data().fetchSchemes(He(ee)));
    if (!(T != null && T.fund_schemes))
      throw new Error("Failed to fetch schemes");
    return {
      schemes: (ae = T == null ? void 0 : T.fund_schemes) == null ? void 0 : ae.filter((q) => (q == null ? void 0 : q.purchase_allowed) || (q == null ? void 0 : q.sip_allowed)),
      isLastPage: T.last
    };
  }, [
    m,
    a == null ? void 0 : a.loggedInPartnerLicenceCode
  ]), h = me(async (l = !1) => {
    if (!(_ || f && !l))
      try {
        p(!0);
        const I = l ? 0 : d, { schemes: R, isLastPage: ee } = await O(I, b);
        j((T) => l ? R : [
          ...T,
          ...R
        ]), r((T) => l ? 1 : T + 1), c(ee);
      } catch (I) {
        window.debug.error(I), M({
          title: "Error fetching data",
          description: Fe(I)
        }), P(I);
      } finally {
        p(!1);
      }
  }, [
    _,
    f,
    d,
    b,
    O
  ]), H = me((l) => {
    L((I) => ({
      ...I,
      ...l
    })), j([]), r(0), c(!1);
  }, []);
  $(() => {
    h(!0);
  }, [
    b
  ]), $(() => {
    const l = new IntersectionObserver((I) => {
      I[0].isIntersecting && !f && !_ && h();
    }, {
      threshold: 0.5
    });
    return F.current = l, G.current && l.observe(G.current), () => {
      l && l.disconnect();
    };
  }, [
    f,
    _,
    h
  ]), $(() => {
    N != null && H({
      name: N == null ? void 0 : N.trim()
    });
  }, [
    N
  ]);
  const W = () => w.length > 0 ? w.map((l, I) => /* @__PURE__ */ e.jsx("div", {
    ref: I === w.length - 1 ? G : null,
    children: /* @__PURE__ */ e.jsx(Mt, {
      scheme: l,
      handleSchemeSelection: t
    })
  }, l.isin)) : _ ? null : /* @__PURE__ */ e.jsx(lt, {
    children: /* @__PURE__ */ e.jsx(ue, {
      type: "helper",
      variant: "field",
      title: o._({
        id: "HwCVf3"
      })
    })
  }), y = () => _ && /* @__PURE__ */ e.jsx("div", {
    style: {
      display: "flex",
      justifyContent: "center",
      padding: "20px"
    },
    children: /* @__PURE__ */ e.jsx(B, {})
  }), V = () => f && w.length > 0 && /* @__PURE__ */ e.jsx(ue, {
    type: "helper",
    variant: "field",
    title: o._({
      id: "vn1YrE"
    })
  }), Q = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Pe, {
        type: "text",
        placeholder: "Search by scheme name",
        value: v,
        onChange: (l) => D(l.target.value),
        maxLength: 250,
        suffix: /* @__PURE__ */ e.jsx(Te, {
          name: "Search"
        }),
        theme: {
          bgColorClass: "bg-neutral-0"
        },
        onInput: (l) => {
          l.target.value = l.target.value.replace(ze("A-Za-z0-9- "), "");
        },
        customClass: "mb-2"
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-2",
        children: [
          /* @__PURE__ */ e.jsx(ye, {
            message: "Investment option"
          }),
          /* @__PURE__ */ e.jsx(Se, {
            value: b.investmentOption,
            onChange: (l) => l && H({
              investmentOption: l
            }),
            options: Le,
            placeholder: o._({
              id: "ND8Qfv"
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-2",
        children: [
          /* @__PURE__ */ e.jsx(ye, {
            message: "Fund category"
          }),
          /* @__PURE__ */ e.jsx(Se, {
            value: b.fundCategory,
            onChange: (l) => l && H({
              fundCategory: l
            }),
            options: Ne,
            placeholder: o._({
              id: "wGq+bf"
            })
          })
        ]
      })
    ]
  });
  return S ? /* @__PURE__ */ e.jsx(J, {
    ...S
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(U, {
        title: o._({
          id: "8f2dQh"
        })
      }),
      /* @__PURE__ */ e.jsx(Ee, {
        type: "text",
        text: o._({
          id: "LyK3CS"
        })
      }),
      Q(),
      W(),
      y(),
      V()
    ]
  });
}
const Ht = (t, m) => {
  const a = m === "mf_purchase_plan" ? "Starting SIP in" : "Investing in";
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      a,
      " ",
      t != null && t.primary_investor_name ? `${Ge(t.primary_investor_name)}'s folio` : "",
      /* @__PURE__ */ e.jsx("span", {
        className: "text-neutral-60 text-size-14 relative bottom-px ml-1 font-normal normal-case",
        children: `( ${t == null ? void 0 : t.number} )`
      })
    ]
  });
};
function zt({ switch_out: t, isGroupInvestment: m, isPartner: a }) {
  var ge, xe, we;
  const { AppState: { tenant: w } } = K(Ae), [j, _] = i(() => sessionStorage.getItem("investment_workflow_stage") || "order"), [p, S] = i(), [M, d] = i({}), [r, f] = i(() => {
    const s = sessionStorage.getItem("investment_workflow_order_id");
    return s ? JSON.parse(s) : null;
  }), [c, v] = i(null), [D, b] = i(null), [L, F] = i([]), [G, N] = i(!1), [u, O] = i("purchase"), { fpapi: h } = K(Y), { loggedInPartner: H, loggedInPartnerLicenceCode: W } = K(Me), { tenantConfig: y, amcs: V } = fe(), Q = (ge = y == null ? void 0 : y.groupInvestment) == null ? void 0 : ge.allowedEmailDomains, [l, I] = i(null);
  (xe = c == null ? void 0 : c.annexure) == null || xe.isonboarded;
  const R = ((we = y == null ? void 0 : y.app_constants) == null ? void 0 : we.kyc_refetch_interval_in_months) || 3, [ee, T] = i(!1), [he, se] = i(), [oe, ae] = i(!1), [q, Be] = i(!1), [Ke, ce] = i(!1), [Ve, le] = i("FOLIO_LISTING"), [g, We] = i(Ce.get()), [C, pe] = i(ke.get()), Re = y == null ? void 0 : y.fallbackLogo;
  $(() => {
    (async () => a && await Ue())();
  }, [
    g == null ? void 0 : g.primary_investor_pan,
    C
  ]);
  const Ue = async () => {
    if (!A(g) && A(C) && le("SCHEME_LISTING"), !A(g) && !A(C) && ce(!0), !H)
      N(!0);
    else if (g != null && g.primary_investor_pan && C) {
      if (h.fetchUser(g == null ? void 0 : g.primary_investor_pan, w).then((s) => {
        h.fpClient.investor_profiles().fetchAll({
          pan: g == null ? void 0 : g.primary_investor_pan
        }).then((n) => {
          const k = n == null ? void 0 : n.data[0];
          k ? (jt(s == null ? void 0 : s[0], h).then(async (x) => {
            ae(x.isMigratedUser), x.isMigratedUser && (await vt(h, s[0], x == null ? void 0 : x.migratedMfiaList, x == null ? void 0 : x.migratedFolioList), tt(s == null ? void 0 : s[0]).then((E) => {
              E != null && E.status && Be(!0);
            }));
          }), h.fpClient.mf_investment_accounts().fetchAll({
            investor: g == null ? void 0 : g.primary_investor_pan
          }).then((x) => {
            b(k), F(x.data), v(s[0]), Je(x == null ? void 0 : x.data[0]), m && h.fpClient.email_addresses().fetchAll({
              profile: k == null ? void 0 : k.id
            }).then((E) => {
              var je;
              if (!(Q ? (je = E == null ? void 0 : E.data) == null ? void 0 : je.some((de) => {
                var ve;
                return Q[(ve = de == null ? void 0 : de.email) == null ? void 0 : ve.split("@")[1]];
              }) : !1)) {
                _("invalid_email_domain");
                return;
              }
            });
          })) : N(!0);
        }).catch((n) => {
          window.debug.error(n), P(n);
        }).finally(() => {
          N(!0);
        });
      }), !(C != null && C.active) || !(C != null && C.purchase_allowed) && !(C != null && C.sip_allowed)) {
        _("purchase_not_allowed");
        return;
      } else A(r) ? _("order") : _("finished");
      await qe();
    } else
      N(!0);
  };
  async function qe() {
    if (!r)
      return;
    const s = r.object;
    if (s === "mf_purchase")
      try {
        const n = await h.fpClient.mf_purchases().fetch(r == null ? void 0 : r.id);
        S(n);
      } catch (n) {
        window.debug.log(n), P(n);
      }
    else if (s === "mf_switch")
      try {
        const n = await h.fpClient.mf_switches().fetch(r == null ? void 0 : r.id);
        S(n), O("switch");
      } catch (n) {
        window.debug.log(n), P(n);
      }
    else if (s === "mf_purchase_plan")
      try {
        const n = await h.fpClient.mf_purchase_plans().fetch(r == null ? void 0 : r.id);
        S(n);
      } catch (n) {
        window.debug.log(n), P(n);
      }
    else if (s === "mf_switch_plan")
      try {
        const n = await h.fpClient.mf_switch_plans().fetch(r == null ? void 0 : r.id);
        S(n);
      } catch (n) {
        window.debug.error(n), P(n);
      }
  }
  const Je = async (s) => {
    try {
      const n = await h.fpClient.investor_reports().fetchHoldings({
        investment_account_id: s == null ? void 0 : s.old_id
      });
      se(n);
    } catch (n) {
      window.debug.log(n, "Error");
    }
  };
  async function Qe(s, n, k) {
    if (T(k), !k || !k.orderId) {
      _("finished"), S(n), sessionStorage.setItem("investment_workflow_stage", "finished"), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
        ...s,
        object: n.object
      }));
      return;
    }
    N(!1);
    try {
      const x = await h.fpClient.mf_purchases().fetch(k.orderId);
      sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
        id: x.id,
        old_id: x.old_id,
        object: x.object
      })), S(n);
    } catch (x) {
      window.debug.log(x), P(x);
    }
    _("finished"), sessionStorage.setItem("investment_workflow_stage", "finished"), N(!0);
  }
  function Ze(s) {
    const { mf_order: n, isGenerateFirstInstallment: k, shouldOnboardMigratedUser: x } = s, E = {
      id: n.id,
      old_id: n.old_id
    };
    if (f(E), wt.set(k ?? !1), x) {
      S(n), _("onboard_migrated_user");
      return;
    }
    n.object === "mf_purchase" && (S(n), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...E,
      object: n.object
    })), _("finished"), sessionStorage.setItem("investment_workflow_stage", "finished")), (n.object === "mf_switch" || n.object === "mf_switch_plan") && (S(n), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...E,
      object: n.object
    })), _("finished"), sessionStorage.setItem("investment_workflow_stage", "finished")), n.object === "mf_purchase_plan" && (T(k), Qe(E, n, k));
  }
  function Xe() {
    const s = sessionStorage.getItem("investment_workflow_order_id"), n = s ? JSON.parse(s) : null;
    sessionStorage.removeItem("investment_workflow_stage"), sessionStorage.removeItem("investment_workflow_order_id"), S(null), d({}), _("order"), n.object === "mf_purchase" || n.object === "mf_purchase_plan" ? O("purchase") : O("switch");
  }
  const Ye = (s) => {
    const n = {
      id: s.id,
      old_id: s.old_id
    };
    f(n), S(s), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...n,
      object: s.object
    })), _("finished"), sessionStorage.setItem("investment_workflow_stage", "finished");
  }, et = () => {
    var s;
    (s = h == null ? void 0 : h.userAuthService) == null || s.initiateLogout(window.location.href).then((n) => {
      window.location.href = n.userState;
    });
  }, tt = async (s) => {
    const n = pt(s == null ? void 0 : s.annexure);
    if (n != null && n.kycCheckId) {
      const k = await h.fpClient.kyc_checks().fetch(n == null ? void 0 : n.kycCheckId);
      return k.status && !_t(k.updated_at, R) ? k : gt(k.updated_at) ? await h.fpClient.kyc_checks().refetch(n == null ? void 0 : n.kycCheckId) : k;
    } else {
      const k = s == null ? void 0 : s.id, x = await h.fpClient.kyc_checks().create({
        pan: s == null ? void 0 : s.username.toUpperCase()
      }), E = {
        ...n,
        kycCheckId: x.id
      }, re = await h.updateUser(k, E, null, w);
      if (re)
        return x;
      throw re;
    }
  }, _e = (s) => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      "Investing in",
      " ",
      s != null && s.primary_investor_name ? `${Ge(s.primary_investor_name)}'s folio` : "",
      /* @__PURE__ */ e.jsx("span", {
        className: "text-neutral-60 text-size-14 relative bottom-px ml-1 font-normal normal-case",
        children: `( ${s == null ? void 0 : s.number} )`
      })
    ]
  }), nt = (s) => {
    const n = A(V) ? {
      ...s
    } : {
      ...s,
      schemeLogo: V[s == null ? void 0 : s.amc_id] || Re
    };
    pe(n), ke.set(n);
  }, st = () => !A(C) && !xt(L) && /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(U, {
        title: _e(g)
      }),
      /* @__PURE__ */ e.jsx(ne, {
        scheme: C
      }),
      /* @__PURE__ */ e.jsx(dt, {
        scheme: C,
        switch_out: t,
        investment_account_id: L == null ? void 0 : L[0].id,
        investment_accounts: L,
        defaultInvestmentType: u,
        investorProfile: D,
        isGroupInvestment: m,
        onSuccess: Ze,
        isPartner: a,
        user: c,
        holdings: he,
        assistedWorkflowPAN: g == null ? void 0 : g.primary_investor_pan,
        assistedWorkflowFolioNumber: g == null ? void 0 : g.number
      })
    ]
  });
  function at() {
    switch (j) {
      default:
        return /* @__PURE__ */ e.jsx(B, {
          variant: "fullpage"
        });
      case "order":
        return st();
      case "finished":
        return !A(C) && !A(p) && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(U, {
              title: Ht(g, p == null ? void 0 : p.object)
            }),
            /* @__PURE__ */ e.jsx(ne, {
              scheme: C
            }),
            /* @__PURE__ */ e.jsx(Ct, {
              investorProfile: D,
              scheme: C,
              mf_order: p,
              isGroupInvestment: m,
              handleInvestAgain: Xe,
              isPartner: a,
              isFotOrder: ee
            })
          ]
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(U, {
              title: _e(g)
            }),
            /* @__PURE__ */ e.jsx(ne, {
              scheme: C
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "flex flex-1 items-center",
              children: /* @__PURE__ */ e.jsx(J, {
                title: o._({
                  id: "9VGPlF"
                }),
                description: o._({
                  id: "INP47b"
                }),
                showDetails: !1,
                showAction: !0,
                actionText: o._({
                  id: "L2gqm2"
                }),
                actionHandler: () => {
                  pe(null), ce(!1), le("SCHEME_LISTING");
                }
              })
            })
          ]
        });
      case "switch_confirmation":
        return !A(C) && (!A(p) || !A(M)) && /* @__PURE__ */ e.jsx(Ot, {
          createdOrder: p,
          orderRequest: M,
          investorProfile: D,
          onConfirmation: Ye
        });
      case "invalid_email_domain":
        return /* @__PURE__ */ e.jsx(J, {
          title: o._({
            id: "N98vRu"
          }),
          description: o._({
            id: "y8Ur5q"
          }),
          showDetails: !1,
          actionText: o._({
            id: "+2LBoP"
          }),
          showAction: !0,
          actionHandler: et
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(Gt, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Ie}/onboarding?isPartner=${a}&redirectURL=${encodeURIComponent(`${window.location.origin + Ie}/assisted-checkout/?isin=${C == null ? void 0 : C.isin}&isPartner=${a}`)}`;
          }
        });
      case "kyc_non_compliant":
        return l && /* @__PURE__ */ e.jsx(yt, {
          kycCheckObject: l,
          customMessage: {
            unverified: o._({
              id: "V2UMyt"
            })
          }
        });
    }
  }
  const rt = () => {
    switch (Ve) {
      case "FOLIO_LISTING":
        return /* @__PURE__ */ e.jsx(At, {
          licenseCode: W,
          handleTransact: (s) => {
            We(s), Ce.set(s), le("SCHEME_LISTING");
          }
        });
      case "SCHEME_LISTING":
        return /* @__PURE__ */ e.jsx(Ft, {
          onFundSelection: (s) => {
            nt(s), ce(!0);
          }
        });
      default:
        return /* @__PURE__ */ e.jsx(B, {
          variant: "fullpage"
        });
    }
  };
  return a ? /* @__PURE__ */ e.jsx(e.Fragment, {
    children: G ? H ? Ke ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: at()
    }) : rt() : /* @__PURE__ */ e.jsx(bt, {
      context: `${window.location.pathname}${window.location.search}`
    }) : /* @__PURE__ */ e.jsx(B, {
      variant: "fullpage"
    })
  }) : /* @__PURE__ */ e.jsx(B, {
    variant: "fullpage"
  });
}
const Gt = ({ onContinue: t }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(U, {
      title: o._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => t(),
        children: "Continue"
      })
    })
  ]
});
function $t({ isin: t, switch_out: m, isPartner: a }) {
  const { fpapi: w } = K(Y), { AppState: { showWelcomePage: j }, setShowWelcomePage: _ } = K(Ae), { tenantConfig: p, amcs: S } = fe(), M = p == null ? void 0 : p.fallbackLogo, d = p == null ? void 0 : p.showSchemeLogo, [r, f] = i(""), [c, v] = i(""), [D, b] = i(), [L, F] = i(!1), G = a ? [
    {
      step: 1,
      content: o._({
        id: "q/FBy6"
      })
    },
    {
      step: 2,
      content: o._({
        id: "8KJEuv"
      })
    },
    {
      step: 3,
      content: o._({
        id: "AKwgbv"
      })
    },
    {
      step: 4,
      content: o._({
        id: "Nuos0E"
      })
    }
  ] : [
    {
      step: 1,
      content: o._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: o._({
        id: "v4fm2D"
      })
    },
    {
      step: 3,
      content: o._({
        id: "nKQKlx"
      })
    }
  ];
  $(() => {
    t && (F(!0), w.semiPublicFpClient.master_data().fetchScheme(t).then((y) => {
      N(y);
    }).catch((y) => {
      F(!1), window.debug.error(y), P(y);
    }));
  }, [
    t
  ]);
  function N(y) {
    if (!y)
      v("schemeNotFound");
    else {
      const V = d ? {
        ...y,
        schemeLogo: S[y == null ? void 0 : y.amc_id] || M
      } : {
        ...y
      };
      b(V);
    }
    F(!1);
  }
  $(() => {
    v(u()), f(u());
  }, [
    j
  ]);
  function u() {
    return j && !a ? "welcome" : a || t ? "investment" : "fund_list";
  }
  function O() {
    _(!1), v("investment"), f("investment");
  }
  function h(y) {
    const V = new URLSearchParams(window.location.search);
    V.set("isin", y);
    const Q = V.toString();
    window.location.href = window.location.origin + window.location.pathname + `?${Q}`;
  }
  function H() {
    switch (r) {
      case "investment":
        return /* @__PURE__ */ e.jsx(zt, {
          // scheme={scheme}
          isGroupInvestment: !1,
          switch_out: m ?? "",
          isPartner: a ?? !1
        });
      default:
        return /* @__PURE__ */ e.jsx(B, {
          variant: "fullpage"
        });
    }
  }
  function W() {
    if (c === "welcome")
      return /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          /* @__PURE__ */ e.jsx(U, {
            title: D ? o._({
              id: "Qx20fI"
            }) : o._({
              id: "FhpR0a"
            })
          }),
          /* @__PURE__ */ e.jsx(ne, {
            scheme: D
          }),
          /* @__PURE__ */ e.jsx(It, {
            onAck: O,
            welcomeSteps: G
          })
        ]
      });
    if (c === "fund_list")
      return /* @__PURE__ */ e.jsx(Lt, {
        onFundSelection: h
      });
    if (c === "investment")
      return /* @__PURE__ */ e.jsx(e.Fragment, {
        children: /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(U, {
              title: o._({
                id: "Qx20fI"
              })
            }),
            /* @__PURE__ */ e.jsx(ne, {
              scheme: D
            }),
            /* @__PURE__ */ e.jsx(mt, {
              scheme: D,
              switch_out: m
            })
          ]
        })
      });
    if (c === "schemeNotFound")
      return /* @__PURE__ */ e.jsx(J, {
        title: o._({
          id: "/+1l7D"
        }),
        description: o._({
          id: "H8AXWD"
        }),
        showDetails: !1,
        showAction: !1
      });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: L ? /* @__PURE__ */ e.jsx(B, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(X, {
          check: !!a,
          children: H()
        }),
        /* @__PURE__ */ e.jsx(X, {
          check: !a,
          children: W()
        })
      ]
    })
  });
}
const fn = $t;
export {
  fn as Checkout
};
