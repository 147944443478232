import { j as i } from "./jsx-runtime-C8rcB1Lr.js";
import { i as w } from "./index-Bb24DwqC.js";
import { L as B, a as M } from "./index.esm-DT3FM8Og.js";
import { useState as t, useEffect as S, useMemo as X } from "react";
import { ErrorPage as H } from "./ErrorPage.js";
import { g as p } from "./utils-DbnU7rHI.js";
import { s as C, r as V } from "./sentry-CbZOQ4Ya.js";
import { C as $ } from "./useConfiguration-B-lyaBB9.js";
const f = () => process.env.REACT_APP_CONFIG_URL, K = ({ children: T, userType: r, override: A = !1, externalTenantConfig: y = null, externalAmcs: P = null, externalBanks: R = null }) => {
  const [m, _] = t(y), [d, E] = t(R), [g, x] = t(P), [n, N] = t(null), [v, h] = t(!1);
  S(() => {
    (async () => {
      var u;
      if (A) {
        h(!0);
        return;
      }
      try {
        const e = await (await fetch(`${f()}/dashboard/config`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Tenant-Name": p()
          }
        })).json(), a = document.createElement("link");
        a.rel = "stylesheet", window.location.hostname === "localhost" ? a.href = "/default.css" : a.href = e.theme, document.getElementsByTagName("head")[0].appendChild(a);
        const s = document.getElementById("favicon");
        s && window.location.hostname === "localhost" ? s.href = "/logo-favicon.ico" : s !== null && (s.href = e.favicon);
        const l = [], k = fetch(`${f()}/dashboard/assets/banks_config`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Tenant-Name": p()
          }
        });
        if (l.push(k), e != null && e.showSchemeLogo) {
          const o = fetch(`${f()}/dashboard/assets/amcs_config`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-Tenant-Name": p()
            }
          });
          l.push(o);
        }
        const D = (await Promise.all(l)).map((o) => o ? o.json() : null), [I, j] = await Promise.all(D);
        if (r === "EMPLOYER") {
          const o = await fetch("/employer_config", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          });
          e.employer = await o.json();
        }
        if (r === "INTERNAL_ADMIN") {
          const o = await fetch("/internal_admin_config", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          });
          if (!o.ok) {
            N({
              title: w._({
                id: "Qw6fIi"
              }),
              description: w._({
                id: "94jjGc"
              }),
              showDetails: !1,
              showAction: !1
            });
            return;
          }
          e.internal_admin = await o.json();
        }
        if (r === "ADMIN") {
          const o = await fetch("/admin_config", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          });
          e.admin = await o.json();
        }
        j && x(j), E(I), _(e), C({
          tagName: "tenant",
          tagValue: (u = e == null ? void 0 : e.fp) == null ? void 0 : u.tenant
        }), C({
          tagName: "app",
          tagValue: process.env.REACT_APP_TYPE
        }), h(!0);
      } catch (c) {
        window.debug.log(c), V(c);
      }
    })();
  }, []);
  const L = X(() => ({
    tenantConfig: m,
    amcs: g,
    banks: d
  }), [
    m,
    g,
    d
  ]), b = () => n ? /* @__PURE__ */ i.jsx(H, {
    title: n.title,
    description: n.description,
    reason: n.reason,
    showDetails: n.showDetails,
    showAction: n.showAction,
    actionText: n.actionText,
    actionHandler: n.actionHandler,
    customClass: "pt-20 px-8"
  }) : v ? /* @__PURE__ */ i.jsx($.Provider, {
    value: L,
    children: T
  }) : /* @__PURE__ */ i.jsx(M, {
    variant: "fullpage"
  });
  return /* @__PURE__ */ i.jsx(B, {
    variant: "plain",
    children: b()
  });
};
export {
  K as C
};
