import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as L, useState as l, useLayoutEffect as ot, useEffect as it } from "react";
import { AppContext as nt } from "./AppContext.js";
import { F as st } from "./provider-DPPRdC-N.js";
import { LoginContext as rt } from "./LoginContext.js";
import { P as m, a as at, b as lt, D as ct } from "./index.esm-DT3FM8Og.js";
import { u as dt } from "./useConfiguration-B-lyaBB9.js";
import { r as ft } from "./sentry-CbZOQ4Ya.js";
import { r as ut } from "./MandateListing-BBhvpNKP.js";
import { d as z, i as b, B as h } from "./utils-DbnU7rHI.js";
import { i as n } from "./index-Bb24DwqC.js";
import { o as pt } from "./redirection-rPP3A4Qb.js";
import { W as mt } from "./Welcome-CCMlcQ8T.js";
import { A as ht } from "./Auth-tZKY7y12.js";
import { P as _t, a as gt } from "./PlansListing-CNY3V8Gn.js";
import { ErrorPage as wt } from "./ErrorPage.js";
import { E as xt, P as jt, S as yt } from "./StopPlanStatus-BkRF4C-k.js";
function Tt({ isGroupInvestment: j, mandate_id: W }) {
  var M;
  const { loggedInUser: d } = L(rt), { AppState: { showWelcomePage: v, tenant: B }, setShowWelcomePage: J } = L(nt), { fpapi: s } = L(st), [y, T] = l({}), [K, D] = l(C), [N, u] = l(!1), [r, A] = l({}), [U, _] = l("initial"), [k, P] = l(!1), [g, G] = l({}), [H, Pt] = l(!1), [R, Y] = l({}), Z = [
    {
      step: 1,
      content: n._({
        id: "1ddmub"
      })
    },
    {
      step: 2,
      content: n._({
        id: "2l2YJx"
      })
    },
    {
      step: 3,
      content: n._({
        id: "05nr9e"
      })
    }
  ], { tenantConfig: p } = dt(), F = (M = p == null ? void 0 : p.groupInvestment) == null ? void 0 : M.allowedEmailDomains;
  ot(() => {
    s && d && (u(!0), s.fetchUser(d.profile.preferred_username, B).then((o) => {
      var e;
      if (o[0].annexure.isonboarded && o[0].annexure.isonboarded === "false") {
        u(!1);
        return;
      }
      s.getInvestorProfile((e = d == null ? void 0 : d.profile) == null ? void 0 : e.fp_identifier).then((i) => {
        i && s.fpClient.mf_investment_accounts().fetchAll({
          investor: i.pan
        }).then((c) => {
          const w = c.data.filter((f) => f.primary_investor === d.profile.fp_identifier);
          if (!w) {
            u(!1);
            return;
          }
          j && s.fpClient.email_addresses().fetchAll({
            profile: i == null ? void 0 : i.id
          }).then((f) => {
            var a;
            if (!((a = f == null ? void 0 : f.data) == null ? void 0 : a.some((E) => {
              var O;
              return F && F[(O = E == null ? void 0 : E.email) == null ? void 0 : O.split("@")[1]];
            }))) {
              _("invalid_email_domain"), u(!1);
              return;
            }
          }), T(i), Y(w[0]), u(!1);
        });
      });
    }));
  }, [
    H
  ]);
  const $ = (o) => {
    window.location.href = window.location.origin + h + "/status/mandate?id=" + o;
  }, q = (o) => {
    window.location.href = window.location.origin + h + "/status/mandate?id=" + o;
  }, Q = async (o, e) => {
    var i;
    if ((e == null ? void 0 : e.provider_name) === "BILLDESK")
      pt(o == null ? void 0 : o.token_url, "mandate-billdesk", () => $(e == null ? void 0 : e.id), () => q(e == null ? void 0 : e.id));
    else
      try {
        const { emailData: c, phoneData: w } = await s.fetchEmailAndPhone(y), f = {
          amount: 0,
          currency: "INR",
          method: "emandate",
          bank: "ICIC",
          email: c.email,
          contact: w.number,
          order_id: (i = o.sdk_options) == null ? void 0 : i.order_id,
          customer_id: o.sdk_options.customer_id
        }, x = ut(o.sdk_options.key);
        x.createPayment(f), x.on("payment.success", function(a) {
          s.submitPayment(o.sdk_options.callback_url, a.razorpay_payment_id, a.razorpay_order_id, a.razorpay_signature).then(() => {
            window.location.href = window.location.origin + h + "/status/mandate?id=" + (e == null ? void 0 : e.id);
          });
        }), x.on("payment.error", function(a) {
          s.submitPaymentError(o.sdk_options.callback_url, "error", a.error.code, a.error.description).then(() => {
            window.location.href = window.location.origin + h + "/status/mandate?id=" + (e == null ? void 0 : e.id);
          });
        });
      } catch (c) {
        window.debug.log(c.message), ft(c);
      }
  };
  function I(o) {
    var i;
    u(!0);
    const e = {
      mandate_id: o == null ? void 0 : o.id
    };
    ((i = p == null ? void 0 : p.app_constants) == null ? void 0 : i.paymentGateway) === "BILLDESK" && (e.payment_postback_url = `${window.location.origin + h}/redirection`), s.fpClient.mandates().authorize(e).then(async (c) => {
      Q(c, o);
    });
  }
  const S = () => {
    _("initial");
  }, V = () => /* @__PURE__ */ t.jsx(lt, {
    variant: "fullPage",
    title: n._({
      id: "JD6ntr",
      values: {
        0: z(g == null ? void 0 : g.id)
      }
    }),
    contentHeightMax: !0,
    isOpen: k,
    toggleModal: () => {
      P(!1);
    },
    children: /* @__PURE__ */ t.jsx(_t, {
      investorProfile: y,
      currentPlanObject: g,
      handleAuthorize: I,
      onModifySuccess: (o, e) => {
        P(!1), A(o), _(e);
      },
      isGroupInvestment: j
    })
  });
  function X() {
    var o;
    switch (U) {
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "initial":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: n._({
                id: "2yU70/"
              })
            }),
            /* @__PURE__ */ t.jsx(ct, {
              type: "text",
              text: n._({
                id: "t9eDJH"
              })
            }),
            /* @__PURE__ */ t.jsx(gt, {
              investorProfile: y,
              isGroupInvestment: j,
              mandate_id: W,
              mfInvestmentAccount: R,
              onModifySuccess: (e, i) => {
                A(e), _(i);
              },
              togglePlanDetailsModal: (e) => {
                P(!k), G(e);
              },
              handleAuthorize: I
            })
          ]
        });
      case "stop_finished":
        return !b(r) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: n._({
                id: "RGK6hg"
              })
            }),
            /* @__PURE__ */ t.jsx(yt, {
              currentPlanObject: r,
              handleGoToPlansList: S
            })
          ]
        });
      case "pause_finished":
        return !b(r) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: n._({
                id: "hNtwJw",
                values: {
                  0: (o = z(r == null ? void 0 : r.plan)) == null ? void 0 : o.toLowerCase()
                }
              })
            }),
            /* @__PURE__ */ t.jsx(jt, {
              pauseOrder: r,
              handleGoToPlansList: S
            })
          ]
        });
      case "edit_finished":
        return !b(r) && /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: n._({
                id: "LBzlW2"
              })
            }),
            /* @__PURE__ */ t.jsx(xt, {
              editOrder: r,
              handleGoToPlansList: S
            })
          ]
        });
      case "invalid_email_domain":
        return /* @__PURE__ */ t.jsx(wt, {
          title: n._({
            id: "ISwZlJ"
          }),
          description: n._({
            id: "lwG6FE"
          }),
          showDetails: !1
        });
    }
  }
  it(() => {
    D(C());
  }, [
    v
  ]);
  function C() {
    return v ? "welcome" : "plansList";
  }
  function tt() {
    J(!1), D("plansList");
  }
  function et() {
    return K === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(m, {
          title: n._({
            id: "SynB8s"
          })
        }),
        /* @__PURE__ */ t.jsx(mt, {
          onAck: tt,
          welcomeSteps: Z
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx("div", {
        className: "mb-20",
        children: N ? /* @__PURE__ */ t.jsx(at, {
          variant: "fullpage"
        }) : d ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            X(),
            V()
          ]
        }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: n._({
                id: "SynB8s"
              })
            }),
            /* @__PURE__ */ t.jsx(ht, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              showTestPan: {
                onboarded: !0
              }
            })
          ]
        })
      })
    });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: et()
  });
}
export {
  Tt as ManagePlansList
};
