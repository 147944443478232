const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const TENANT_ENV = process.env?.REACT_APP_TENANT_ENV;

const tenantEnv = {
  isStaging: TENANT_ENV === "staging",
  isSandbox: TENANT_ENV === "sandbox",
  isProduction: TENANT_ENV === "production",
  isStagingOrSandbox: ["staging", "sandbox"].includes(TENANT_ENV),
};

const capitalWords = [
  "Dhfl",
  "Lic",
  "Icici",
  "Sbi",
  "Boi",
  "Axa",
  "Elss",
  "Idfc",
  "Hdfc",
  "L&t",
  "Dsp",
  "Uti",
];

const formatNumber = (num, decimals = 0) => {
  const number = Number(num);
  if (num) {
    return number
      .toLocaleString("en-IN", {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimals,
        style: "currency",
        currency: "INR",
      })
      .split("₹")[1];
  } else {
    return 0;
  }
};

const formatSchemeName = (name) => {
  if (name) {
    name = name.replace("-", "");
    name = name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
      .join(" ");
    for (const element of capitalWords) {
      name = name.replace(element, element.toUpperCase());
    }
    name = name.trim();
  }
  return name;
};

const formatInvestmentOption = (name) => {
  if (name) {
    name = name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
      .join(" ");
    name = name.replace("Div", "Dividend");
    name = name.trim();
  }
  return name;
};

const isEmptyObject = (obj) => {
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    return Object.keys(obj).length === 0;
  } else {
    return true;
  }
};

const isEmptyArray = (arr) => {
  if (Array.isArray(arr) && arr.length) {
    return false;
  } else {
    return true;
  }
};

const extractAttributes = (obj) => {
  /* const attributes = {};
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    Object.keys(obj).map((attribute) => {
      attributes[attribute] = obj[attribute][0];
      return null;
    });
    return attributes;
  } else {
    return attributes;
  } */
  return obj;
};

/**
 * @method toISOStringWithTZ - Convert input date object into ISO8601 string with time zone
 * @param date DateObject
 * USECASE: Factor in TimeZone while converting date object to ISO8601 string
 */
const toISOStringWithTZ = (date) => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num) => {
    return (num < 10 ? "0" : "") + num;
  };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};

const formatDate = (d, format) => {
  switch (format) {
    case "yyyy-mm-dd": // 2022-10-28
      if (Object.prototype.toString.call(d) === "[object Date]") {
        // return d.toISOString().split("T")[0];
        return toISOStringWithTZ(d).split("T")[0];
      } else {
        // return new Date().toISOString().split("T")[0];
        return toISOStringWithTZ(new Date()).split("T")[0];
      }
    case "dd-MMM-yyyy": // 28-Nov-2022
      if (Object.prototype.toString.call(d) === "[object Date]") {
        const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        const month = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
        const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
        return `${day}-${month}-${year}`;
      } else {
        const date = new Date();
        const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
        const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
        const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
        return `${day}-${month}-${year}`;
      }
    case "dd-MM-yyyy":
      if (!d) {
        return undefined;
      }

      if (Object.prototype.toString.call(d) === "[object Date]") {
        const dateStr = d.toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata",
        });

        return dateStr.replaceAll("/", "-");
      } else {
        const dateObj = new Date(d);

        const dateStr = dateObj.toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata",
        });

        return dateStr.replaceAll("/", "-");
      }
    default:
      if (Object.prototype.toString.call(d) === "[object Date]") {
        return toISOStringWithTZ(d).split("T")[0];
      } else {
        return toISOStringWithTZ(new Date()).split("T")[0];
      }
  }
};

const memoize = (fn) => {
  let lastArg;
  let lastResult;
  return (arg) => {
    if (arg !== lastArg) {
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

function toTitleCase(str = undefined) {
  if (!str) {
    return undefined;
  }

  const spacedStr = str.split(" ");
  if (spacedStr.length) {
    const spacesTrim = spacedStr.filter((word) => word);
    const result = spacesTrim
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return result;
  }
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}

const uniqueId = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

function floatSafeRemainder(val, step) {
  if (!val || !step) return 0;
  const valDecCount = (val.toString().split(".")[1] || "").length;
  const stepDecCount = (step.toString().split(".")[1] || "").length;
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  const valInt = parseInt(parseFloat(val).toFixed(decCount).replace(".", ""));
  const stepInt = parseInt(parseFloat(step).toFixed(decCount).replace(".", ""));
  return (valInt % stepInt) / Math.pow(10, decCount);
}

const formatINR = (num) => {
  const number = Number(num);
  if (number) {
    const formattedNumber = number
      .toLocaleString("en-IN", {
        // maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
      })
      .split("₹")[1];
    return formattedNumber;
  } else {
    return "";
  }
};

const unFormatINR = (num) => {
  const withoutCommas = num.replace(/,/g, "");
  return withoutCommas;
};

const formatAmount = (value) => {
  return formatINR(unFormatINR(value));
};

const getPaymentOptions = (methods) => {
  let paymentList = [];
  methods.forEach((item) => {
    switch (item) {
      case "netbanking":
        paymentList.push({
          option: "Netbanking",
          icon: "Netbanking",
          value: "netbanking",
          label: "Netbanking",
        });
        break;
      case "upi":
        paymentList.push({
          option: "UPI",
          icon: "Upi",
          value: "upi",
          label: "UPI",
        });
        break;
      case "salary":
        paymentList.push({
          option: "Netbanking",
          icon: "Netbanking",
          value: "salary",
          label: "Salary",
        });
        break;
      case "mandate":
        paymentList.push({
          option: "mandate",
          icon: "Emandate",
          value: "mandate",
          label: "Auto Pay",
        });
        break;
      default:
    }
  });
  return paymentList;
};

const getOrdinalDayFormat = (day) => {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

const formatCustomDateLabel = (date, frequency) => {
  if (date !== null) {
    let currentDate = new Date(date);
    let day = currentDate.getDate();
    let month = currentDate.toLocaleString("default", { month: "long" });
    let weekDay = currentDate.toLocaleString("en-us", { weekday: "long" });
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    if (frequency === "daily") {
      return getOrdinalDayFormat(day) + " onwards";
    } else if (frequency === "monthly") {
      return getOrdinalDayFormat(day) + " of every month";
    } else if (frequency === "quarterly" || frequency === "yearly" || frequency === "half-yearly") {
      return getOrdinalDayFormat(day) + ` of the ${month}`;
    } else if (frequency === "day_in_a_week") {
      return `Every ${getOrdinalDayFormat(weekDays.indexOf(weekDay))} day of week`;
    } else if (frequency === "day_in_a_fortnight") {
      return `Every alternate ${getOrdinalDayFormat(weekDays.indexOf(weekDay))} day of week`;
    } else {
      return `${getOrdinalDayFormat(day)} of the ${month}`;
    }
  } else {
    return "Select start date";
  }
};

const allowedPlanDates = (date, planDates, frequency = null) => {
  if (isEmptyArray(planDates)) {
    return false;
  }

  if (frequency === "day_in_a_week" || frequency === "day_in_a_fortnight") {
    let day = new Date(date).toLocaleString("en-us", { weekday: "long" });
    if (day === "Saturday" || day === "Sunday") {
      return true;
    } else {
      return false;
    }
  } else if (!planDates.includes(date.getDate())) {
    return true;
  } else {
    return false;
  }
};

const getOptionsIndex = (data, value, options) => {
  const isNotArrayOfObjects = options?.isNotArrayOfObjects;
  const customProperty = options?.customProperty || "value";

  return data.findIndex((ele) => {
    if (isNotArrayOfObjects) {
      return ele === value;
    } else {
      return ele[customProperty] === value;
    }
  });
};

const allowedGroupPlanDates = (date, groupPlanDates) => {
  if (groupPlanDates.getDate() !== date.getDate()) {
    return false;
  } else {
    return true;
  }
};

const getFrequencyOptions = (frequency, sipModes = "systematic") => {
  let frequencyOptions = [];
  if (sipModes === "nonSystematic") {
    frequency = {
      daily: {},
      monthly: {},
      quarterly: {},
      day_in_a_fortnight: {},
      day_in_a_week: {},
      four_times_a_month: {},
      twice_a_month: {},
      "half-yearly": {},
      yearly: {},
    };
  }
  Object.keys(frequency).forEach((freq) => {
    switch (freq) {
      case "monthly":
        frequencyOptions.push({ label: "Monthly", value: "monthly", id: 0 });
        break;
      case "quarterly":
        frequencyOptions.push({
          label: "Quarterly",
          value: "quarterly",
          id: 6,
        });
        break;
      case "daily":
        frequencyOptions.push({ label: "Daily", value: "daily", id: 1 });
        break;
      case "day_in_a_fortnight":
        frequencyOptions.push({
          label: "Day in a fortnight",
          value: "day_in_a_fortnight",
          id: 3,
        });
        break;
      case "day_in_a_week":
        frequencyOptions.push({
          label: "Day in a week",
          value: "day_in_a_week",
          id: 2,
        });
        break;
      case "four_times_a_month":
        frequencyOptions.push({
          label: "Four times a month",
          value: "four_times_a_month",
          id: 5,
        });
        break;
      case "twice_a_month":
        frequencyOptions.push({
          label: "Twice a month",
          value: "twice_a_month",
          id: 4,
        });
        break;
      case "half-yearly":
        frequencyOptions.push({
          label: "Half yearly",
          value: "half-yearly",
          id: 7,
        });
        break;
      case "yearly":
        frequencyOptions.push({ label: "Yearly", value: "yearly", id: 8 });
        break;
      default:
    }
  });
  frequencyOptions = frequencyOptions.sort((a, b) => a.id - b.id);
  return frequencyOptions;
};

const frequencyValuesInMonths = {
  four_times_a_month: 0.25,
  twice_a_month: 0.5,
  day_in_a_week: 0.2,
  day_in_a_fortnight: 0.4,
  monthly: 1,
  quarterly: 3,
  "half-yearly": 6,
  yearly: 12,
};

const capitalize = (str) => {
  if (!str) {
    return undefined;
  }

  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
};

const maskEmail = (unmaskedEmail) => {
  if (!unmaskedEmail) {
    return "";
  }

  const EMAIL_MASKING_LENGTH = 5;
  const maskedStr = "*".repeat(EMAIL_MASKING_LENGTH);
  const strStart = unmaskedEmail.charAt(0);
  const strEnd = unmaskedEmail.slice(unmaskedEmail.indexOf("@") - 1);

  return `${strStart}${maskedStr}${strEnd}`;
};

const maskMobile = (unmaskedMobile) => {
  if (!unmaskedMobile) {
    return "";
  }

  const MOBILE_MASKING_LENGTH = 5;
  const maskedStr = "*".repeat(MOBILE_MASKING_LENGTH);
  const strEnd = String(unmaskedMobile).slice(-4);

  return `${maskedStr}${strEnd}`;
};

const scrollToErrorField = (errorFieldName) => {
  if (errorFieldName) {
    const inputField = document.querySelector(`[data-name=${errorFieldName}]`);
    inputField.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
  return;
};

const formatTime = (timeInSeconds) => {
  if (Number(timeInSeconds) || Number(timeInSeconds) === 0) {
    let minutes = parseInt(timeInSeconds / 60, 10);
    let seconds = parseInt(timeInSeconds % 60, 10);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutes}:${seconds}`;
  }
};

const anyCharacterExcluding = (characters) => {
  return new RegExp(`[^${characters}]`, "g");
};

const getCountryNameFromANSI = (countries, ansiCode) => {
  if (countries) {
    if (!ansiCode) {
      return undefined;
    }

    return countries.find((country) => country.ansi_code === ansiCode)?.name;
  }
};

const formatBankName = (bankName) => {
  const abbreviatedBankNames = [...capitalWords];

  if (bankName) {
    const abbreviation = abbreviatedBankNames.find((abbreviatedName) => {
      return bankName.toLowerCase().includes(abbreviatedName.toLowerCase());
    });

    if (abbreviation) {
      return toTitleCase(bankName).replace(abbreviation, abbreviation.toUpperCase());
    } else {
      return toTitleCase(bankName);
    }
  }
};

const isNFOWindow = (NFOStartDate, NFOCloseDate, NFOReopenDate) => {
  if (!NFOStartDate || !NFOCloseDate || !NFOReopenDate) {
    return false;
  } else {
    return Boolean(
      // new Date(NFOStartDate) <= new Date() <= new Date(NFOCloseDate)
      new Date(NFOStartDate) <= new Date() <= new Date(NFOReopenDate)
    );
  }
};

const findNextSalaryAndCutoffGreaterThanGivenDate = (givenDate, salaryDate, cutoffDate) => {
  const nextSalaryDate = new Date(
    salaryDate.getFullYear(),
    salaryDate.getMonth() + 1,
    salaryDate.getDate()
  );
  const nextCutOffSalaryDate = new Date(
    cutoffDate.getFullYear(),
    cutoffDate.getMonth() + 1,
    cutoffDate.getDate()
  );

  if (nextSalaryDate > givenDate && nextCutOffSalaryDate > givenDate) {
    return { nextSalaryDate, nextCutOffSalaryDate };
  } else {
    return findNextSalaryAndCutoffGreaterThanGivenDate(
      givenDate,
      nextSalaryDate,
      nextCutOffSalaryDate
    );
  }
};

const getNextDateWithDayOfMonth = (inputDate, dayOfMonth) => {
  const date = new Date(inputDate);
  const currentDay = date.getDate();
  const daysInCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const targetDay =
    currentDay < dayOfMonth ? dayOfMonth : dayOfMonth + daysInCurrentMonth - currentDay;

  date.setDate(targetDay);
  return date;
};

const shouldScheduleOrderForFutureDate = (orderDate, installment_date) => {
  if (orderDate?.getDate() === installment_date?.getDate()) {
    return false;
  }

  const nearestInstallmentDateFromOrderDate = getNextDateWithDayOfMonth(
    orderDate,
    installment_date.getDate()
  );

  return nearestInstallmentDateFromOrderDate.getMonth() === installment_date.getMonth()
    ? false
    : true;
};

const getGroupOneTimeDates = ({ salaryDay, salaryCutoffDay, NFO, purchaseDate }) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const orderDate = purchaseDate ? new Date(new Date(purchaseDate).setHours(0, 0, 0, 0)) : today;
  const currentSalaryDate = new Date(
    orderDate.getFullYear(),
    orderDate.getMonth(),
    Number(salaryDay)
  );
  const currentCutOffSalaryDate = new Date(
    currentSalaryDate.getFullYear(),
    Number(salaryDay) < Number(salaryCutoffDay)
      ? currentSalaryDate.getMonth() - 1
      : currentSalaryDate.getMonth(),
    Number(salaryCutoffDay)
  );

  if (orderDate >= currentCutOffSalaryDate) {
    const { nextSalaryDate, nextCutOffSalaryDate } = findNextSalaryAndCutoffGreaterThanGivenDate(
      orderDate,
      currentSalaryDate,
      currentCutOffSalaryDate
    );

    const returnData = {
      orderDate,
      scheduled_on: nextCutOffSalaryDate,
      consideredSalaryDate: nextSalaryDate,
      consideredCutOffSalaryDate: nextCutOffSalaryDate,
    };
    return returnData;
  } else {
    const returnData = {
      orderDate,
      scheduled_on: currentCutOffSalaryDate,
      consideredSalaryDate: currentSalaryDate,
      consideredCutOffSalaryDate: currentCutOffSalaryDate,
    };
    return returnData;
  }
};

const getGroupRecurringDate = ({ salaryDay, salaryCutoffDay, NFO, purchaseDate }) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const orderDate = purchaseDate ? new Date(new Date(purchaseDate).setHours(0, 0, 0, 0)) : today;
  const currentSalaryDate = new Date(
    orderDate.getFullYear(),
    orderDate.getMonth(),
    Number(salaryDay)
  );
  const currentCutOffSalaryDate = new Date(
    currentSalaryDate.getFullYear(),
    Number(salaryDay) < Number(salaryCutoffDay)
      ? currentSalaryDate.getMonth() - 1
      : currentSalaryDate.getMonth(),
    Number(salaryCutoffDay)
  );

  if (isNFOWindow(NFO?.start, NFO?.close, NFO?.reopen)) {
    const { nextSalaryDate, nextCutOffSalaryDate } = findNextSalaryAndCutoffGreaterThanGivenDate(
      new Date(NFO?.reopen),
      currentSalaryDate,
      currentCutOffSalaryDate
    );

    const installment_date = nextCutOffSalaryDate;
    const activate_after = shouldScheduleOrderForFutureDate(orderDate, installment_date)
      ? new Date(
          installment_date.getFullYear(),
          installment_date.getMonth(),
          installment_date.getDate() - 2
        )
      : null;

    const returnData = {
      orderDate,
      installment_date,
      activate_after,
      consideredSalaryDate: nextSalaryDate,
      consideredCutOffSalaryDate: nextCutOffSalaryDate,
    };
    return returnData;
  } else {
    // NON-NFO SIP
    if (orderDate >= currentCutOffSalaryDate) {
      const { nextSalaryDate, nextCutOffSalaryDate } = findNextSalaryAndCutoffGreaterThanGivenDate(
        orderDate,
        currentSalaryDate,
        currentCutOffSalaryDate
      );
      const installment_date = nextCutOffSalaryDate;

      const returnData = {
        orderDate,
        installment_date,
        activate_after: null,
        consideredSalaryDate: nextSalaryDate,
        consideredCutOffSalaryDate: nextCutOffSalaryDate,
      };
      return returnData;
    } else {
      const installment_date = currentSalaryDate;
      const activate_after = shouldScheduleOrderForFutureDate(orderDate, installment_date)
        ? new Date(
            installment_date.getFullYear(),
            installment_date.getMonth(),
            installment_date.getDate() - 2
          )
        : null;

      const returnData = {
        orderDate,
        installment_date,
        activate_after,
        consideredSalaryDate: currentSalaryDate,
        consideredCutOffSalaryDate: currentCutOffSalaryDate,
      };
      return returnData;
    }
  }
};

const isMFPPScheduledForActivation = (mf_purchase_plan) => {
  return (
    mf_purchase_plan?.requested_activation_date !== null &&
    // new Date() < new Date(mfpp?.requested_activation_date) &&
    mf_purchase_plan?.activated_at === null &&
    mf_purchase_plan?.state === "created"
  );
};

const getObjectFromId = (id) => {
  const prefix = id.split("_")[0];
  switch (prefix) {
    default:
      return null;
    case "mfp":
      return "mf_purchase";
    case "mfr":
      return "mf_redemption";
    case "mfs":
      return "mf_switch";
    case "mfpp":
      return "mf_purchase_plan";
    case "mfrp":
      return "mf_redemption_plan";
    case "mfsp":
      return "mf_switch_plan";
    case "invp":
      return "investor_profile";
  }
};

const getOrderTypeFromOrderId = (id) => {
  const prefix = id?.split("_")[0];
  switch (prefix) {
    default:
      return null;
    case "mfp":
      return "Purchase";
    case "mfr":
      return "Withdraw";
    case "mfs":
      return "Switch";
    case "mfpp":
      return "Purchase plan";
    case "mfrp":
      return "Withdraw plan";
    case "mfsp":
      return "Switch plan";
  }
};

const getOrderStatusFromOrderState = (state, orderObject) => {
  switch (state) {
    case "pending":
      if (orderObject === "mf_purchase") {
        return "Awaiting payment";
      } else {
        return "Order under process";
      }
    case "cancelled":
      return "Order cancelled";
    case "confirmed":
      return "Order under process";
    case "submitted":
      return "Order under process";
    case "successful":
      return "Order successful";
    case "failed":
      return "Order failed";
    case "reversed":
      return "Order reversed";
    default:
      return "-";
  }
};

const compareDates = (date1, date2) => {
  if (
    new Date(date1).getDate() === new Date(date2).getDate() &&
    new Date(date1).getMonth() === new Date(date2).getMonth() &&
    new Date(date1).getFullYear() === new Date(date2).getFullYear()
  ) {
    return "isEqual";
  } else if (new Date(date1).getTime() > new Date(date2).getTime()) {
    return "isAfter";
  } else if (new Date(date1).getTime() < new Date(date2).getTime()) {
    return "isBefore";
  }
};

const getDifferenceInDays = (date1, date2) => {
  const differenceInTime = new Date(date1) - new Date(date2);
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
  return differenceInDays;
};

const getDifferenceInWeeksBetweenDates = (date2, date1) => {
  let diff = (date2.getTime() - date1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7;
  return Math.round(diff);
};

const getDifferenceInMonthsBetweenDates = (date1, date2) => {
  return date2.getMonth() - date1.getMonth() - 1 + 12 * (date2.getFullYear() - date1.getFullYear());
};

const getPlanEndDateFromStartDate = (start_date, frequency, min_installments) => {
  const today = new Date();
  if (start_date === null) {
    start_date = new Date(today.setDate(today.getDate() + 1));
  }

  if (frequency === "daily") {
    // subtracting 1 as same day start_date and end_date means 1 installment in daily frequcny according to api
    return new Date(new Date(start_date).setDate(start_date.getDate() - 1 + min_installments));
  } else if (
    frequency === "four_times_a_month" ||
    frequency === "twice_a_month" ||
    frequency === "day_in_a_fortnight" ||
    frequency === "day_in_a_week"
  ) {
    let days;
    if (frequency === "four_times_a_month" || frequency === "day_in_a_week") {
      days = 7;
    } else if (frequency === "twice_a_month" || frequency === "day_in_a_fortnight") {
      days = 14;
    }

    //IF CASE- if start date is 28, in both twice and four times, next installments date comes
    //as 1 of next month, so directly taking next month and subtracting 1 installment
    if (
      start_date.getDate() === 28 &&
      (frequency === "twice_a_month" || frequency === "four_times_a_month")
    ) {
      let startDate = new Date(start_date.getFullYear(), start_date.getMonth() + 1, 1);
      return new Date(
        new Date(startDate).setDate(startDate.getDate() + (min_installments - 1) * days)
      );
    } else {
      return new Date(new Date(start_date).setDate(start_date.getDate() + min_installments * days));
    }
  } else if (
    frequency === "monthly" ||
    frequency === "quarterly" ||
    frequency === "half-yearly" ||
    frequency === "yearly"
  ) {
    return new Date(
      new Date(start_date).setMonth(
        start_date.getMonth() + frequencyValuesInMonths[frequency] * min_installments
      )
    );
  }
};

const getTagStatus = (state) => {
  if (state === "pending") {
    return "warning";
  } else if (state === "confirmed" || state === "successful" || state === "submitted") {
    return "success";
  } else if (state === "failed" || state === "reversed" || state === "cancelled") {
    return "error";
  } else {
    return "success";
  }
};

const filterFolioDefaultEmail = (emails, groupEmailDomains) => {
  const emailList = emails?.data;
  const personalEmail = emailList.find((emailObj) => {
    return !groupEmailDomains[emailObj?.email.split("@")[1]] && emailObj?.belongs_to === "self";
  });
  return personalEmail || emailList?.[0];
};

const getNumberOfInstallments = (
  planStartDate,
  planEndDate,
  frequency,
  schemeAllowedDates,
  sipModes
) => {
  let months = (planEndDate.getFullYear() - planStartDate.getFullYear()) * 12;
  months = months - planStartDate.getMonth();
  months = months + planEndDate.getMonth();

  if (frequency === "daily") {
    return getDifferenceInDays(planEndDate, planStartDate) + 1;
  } else if (frequency === "four_times_a_month" || frequency === "twice_a_month") {
    let diffMonths = planEndDate.getMonth() - planStartDate.getMonth();
    diffMonths -= 1;

    diffMonths = frequency === "four_times_a_month" ? diffMonths * 4 : diffMonths * 2;
    let installmentsInStartDate = 0;
    let installmentsInEndDate = 0;

    if (diffMonths <= 1) {
      diffMonths = 0;
    }

    if (sipModes === "nonSystematic") {
      let currentDatesFromStartDate = [planStartDate.getDate()];
      let twoMonthsDates = schemeAllowedDates.concat(schemeAllowedDates);
      if (frequency === "twice_a_month") {
        let indexOfDate = twoMonthsDates.indexOf(currentDatesFromStartDate[0]) + 14;
        currentDatesFromStartDate.push(twoMonthsDates[indexOfDate]);

        //Edge case for twice a month
        if (planStartDate.getDate() === 28) {
          currentDatesFromStartDate = [28, 1];
        }
      } else if (frequency === "four_times_a_month") {
        for (let i = 0; i < 3; i++) {
          let indexOfDate = twoMonthsDates.indexOf(currentDatesFromStartDate[i]) + 7;
          currentDatesFromStartDate.push(twoMonthsDates[indexOfDate]);
        }

        //Edge case for four times a month
        if (planStartDate.getDate() === 28) {
          currentDatesFromStartDate = [28, 7, 14, 21];
        }
      }

      if (new Date(planStartDate).getMonth() <= new Date(planEndDate).getMonth()) {
        currentDatesFromStartDate.forEach((date) => {
          if (date >= new Date(planStartDate).getDate()) {
            installmentsInStartDate =
              //case1: same month, check start month's date and end month's date
              new Date(planEndDate).getMonth() === new Date(planStartDate).getMonth() &&
              date < new Date(planEndDate).getDate()
                ? installmentsInStartDate + 1
                : // case2: not same month, check only month greater or not, no need to check end month's date
                new Date(planEndDate).getMonth() > new Date(planStartDate).getMonth()
                ? installmentsInStartDate + 1
                : installmentsInStartDate;
          }
        });

        if (frequency === "four_times_a_month") {
          installmentsInStartDate = installmentsInStartDate > 4 ? 4 : installmentsInStartDate;
        } else {
          installmentsInStartDate = installmentsInStartDate > 2 ? 2 : installmentsInStartDate;
        }

        if (new Date(planStartDate).getMonth() < new Date(planEndDate).getMonth()) {
          currentDatesFromStartDate.forEach((date) => {
            date <= new Date(planEndDate).getDate() && installmentsInEndDate++;
          });

          if (frequency === "four_times_a_month") {
            installmentsInEndDate = installmentsInEndDate > 4 ? 4 : installmentsInEndDate;
          } else {
            installmentsInEndDate = installmentsInEndDate > 2 ? 2 : installmentsInEndDate;
          }
        }
      }

      return installmentsInStartDate + diffMonths + installmentsInEndDate;
    } else {
      if (new Date(planStartDate).getMonth() <= new Date(planEndDate).getMonth()) {
        schemeAllowedDates.forEach((date) => {
          if (date >= new Date(planStartDate).getDate()) {
            installmentsInStartDate =
              //case1: same month, check start month's date and end month's date
              new Date(planEndDate).getMonth() === new Date(planStartDate).getMonth() &&
              date < new Date(planEndDate).getDate()
                ? installmentsInStartDate + 1
                : // case2: not same month, check only month greater or not, no need to check end month's date
                new Date(planEndDate).getMonth() > new Date(planStartDate).getMonth()
                ? installmentsInStartDate + 1
                : installmentsInStartDate;
          }
        });

        if (frequency === "four_times_a_month") {
          installmentsInStartDate = installmentsInStartDate > 4 ? 4 : installmentsInStartDate;
        } else {
          installmentsInStartDate = installmentsInStartDate > 2 ? 2 : installmentsInStartDate;
        }

        if (new Date(planStartDate).getMonth() < new Date(planEndDate).getMonth()) {
          schemeAllowedDates.forEach((date) => {
            date <= new Date(planEndDate).getDate() && installmentsInEndDate++;
          });

          if (frequency === "four_times_a_month") {
            installmentsInEndDate = installmentsInEndDate > 4 ? 4 : installmentsInEndDate;
          } else {
            installmentsInEndDate = installmentsInEndDate > 2 ? 2 : installmentsInEndDate;
          }
        }
      }

      return installmentsInStartDate + diffMonths + installmentsInEndDate;
    }
  } else if (frequency === "day_in_a_week" || frequency === "day_in_a_fortnight") {
    const weeksDifference = getDifferenceInWeeksBetweenDates(
      new Date(planEndDate),
      new Date(planStartDate)
    );
    return frequency === "day_in_a_week" ? weeksDifference : Math.floor(weeksDifference / 2);
  } else {
    return parseInt(months / frequencyValuesInMonths[frequency]);
  }
};

function getIndexOfNextBiggerElementInArray(arr, target) {
  let start = 0,
    end = arr.length - 1;
  let ans = -1;

  while (start <= end) {
    let mid = parseInt((start + end) / 2, 10);

    if (arr[mid] <= target) {
      start = mid + 1;
    } else {
      ans = mid;
      end = mid - 1;
    }
  }
  return ans;
}

const getInstallmentDayFromStartDate = (date, frequency) => {
  if (frequency === "day_in_a_week" || frequency === "day_in_a_fortnight") {
    let weekArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let installmentDay = new Date(date).toLocaleString("en-us", {
      weekday: "long",
    });

    return weekArray.indexOf(installmentDay);
  } else {
    return date.getDate();
  }
};

const getMaxEndDateFromFrequency = (frequency) => {
  let today = new Date();
  if (frequency === "daily") {
    return new Date(today.getFullYear() + 3, today.getMonth(), today.getDate());
  } else if (frequency === "day_in_a_week") {
    return new Date(today.getFullYear() + 15, today.getMonth(), today.getDate());
  } else if (frequency === "day_in_a_fortnight" || frequency === "twice_a_month") {
    return new Date(today.getFullYear() + 45, today.getMonth(), today.getDate());
  } else if (frequency === "four_times_a_month") {
    return new Date(today.getFullYear() + 22, today.getMonth(), today.getDate());
  } else if (frequency === "yearly") {
    return new Date(2500, 11, 31);
  } else {
    return new Date(2099, 11, 31);
  }
};

const getPlanStartDateFromFrequency = (frequency, planDatesArray) => {
  let today = new Date();
  if (frequency === "daily") {
    return new Date(new Date().setDate(today.getDate() + 2));
  } else if (frequency === "day_in_a_week" || frequency === "day_in_a_fortnight") {
    let nextDate = new Date(new Date().setDate(today.getDate() + 1));
    if (nextDate.toLocaleString("en-us", { weekday: "long" }) === "Sunday") {
      nextDate = new Date(nextDate.setDate(nextDate.getDate() + 1));
    } else if (nextDate.toLocaleString("en-us", { weekday: "long" }) === "Saturday") {
      nextDate = new Date(nextDate.setDate(nextDate.getDate() + 2));
    }
    return nextDate;
  } else {
    if (planDatesArray.includes(new Date(new Date().setDate(today.getDate() + 1)))) {
      return new Date(new Date().setDate(today.getDate() + 1));
    } else {
      let nextDateIndex = getIndexOfNextBiggerElementInArray(planDatesArray, today.getDate());
      if (nextDateIndex !== -1) {
        let nextDate = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          planDatesArray[nextDateIndex]
        );
        return nextDate;
      } else {
        return new Date(new Date().getFullYear(), new Date().getMonth() + 1, planDatesArray[0]);
      }
    }
  }
};

const nonSystematicFrequencyDates = (frequency) => {
  let arrayOfDatesFrom1To28 = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28,
  ];

  switch (frequency) {
    case "monthly":
      return arrayOfDatesFrom1To28;

    case "day_in_a_week":
      return [1, 2, 3, 4, 5];

    case "daily":
      return [];

    case "quarterly":
      return arrayOfDatesFrom1To28;

    case "half-yearly":
      return arrayOfDatesFrom1To28;

    case "yearly":
      return arrayOfDatesFrom1To28;

    case "day_in_a_fortnight":
      return [1, 2, 3, 4, 5];

    case "twice_a_month":
      return arrayOfDatesFrom1To28;

    case "four_times_a_month":
      return arrayOfDatesFrom1To28;

    default:
      return arrayOfDatesFrom1To28;
  }
};

const getMinMaxInvestmentAmountFromFolio = (folio) => {
  if (folio === null) {
    return {
      minInvestmentAmount: "min_initial_investment",
      maxInvestmentAmount: "max_initial_investment",
      investmentAmountMultiples: "initial_investment_multiples",
    };
  } else {
    return {
      minInvestmentAmount: "min_additional_investment",
      maxInvestmentAmount: "max_additional_investment",
      investmentAmountMultiples: "additional_investment_multiples",
    };
  }
};

const getActivateOnDate = (start_date, frequency) => {
  let activate_after_date = null;

  if (start_date === null) {
    return null;
  }

  if (
    getDifferenceInDays(start_date, new Date()) > 30 ||
    (frequency === "daily" && frequency !== "day_in_a_week" && frequency !== "day_in_a_fortnight")
  ) {
    let activateDate = new Date(start_date).setDate(start_date.getDate() - 1);
    return new Date(activateDate);
  } else if (
    (frequency === "day_in_a_week" || frequency === "day_in_a_fortnight") &&
    getDifferenceInDays(start_date, new Date()) >= 8
  ) {
    let activateDate = new Date(start_date).setDate(start_date.getDate() - 1);
    return new Date(activateDate);
  }

  return activate_after_date;
};

const maskFullName = (name) => {
  if (!name || typeof name !== "string") {
    return undefined;
  }

  const splitName = name.split(" ");
  const isFullName = splitName.length > 1;
  const firstName = splitName[0];

  if (isFullName) {
    return firstName;
  } else {
    return name;
  }
};

const maskLastName = (lastName) => {
  if (!lastName || typeof lastName !== "string") {
    return undefined;
  }

  return "****";
};

const maskDateOfBirth = (dateOfBirth) => {
  if (!dateOfBirth || typeof dateOfBirth !== "string") {
    return undefined;
  }

  return "****";
};

const maskPan = (pan) => {
  if (!pan || typeof pan !== "string") {
    return undefined;
  }

  const firstTwoChars = pan.slice(0, 2);
  const lastTwoChars = pan.slice(-2);

  return `${firstTwoChars}*****${lastTwoChars}`;
};

const maskBankAccountNumber = (bankAccountNumber) => {
  if (!bankAccountNumber || typeof bankAccountNumber !== "string") {
    return undefined;
  }

  const lastFourChars = bankAccountNumber.slice(-4);

  return `****${lastFourChars}`;
};

const maskEmailAddress = (email) => {
  if (!email || typeof email !== "string") {
    return undefined;
  }

  const splitEmail = email.split("@");
  const username = splitEmail[0];
  const domain = splitEmail[1];

  const firstTwoCharsOfUsername = username.slice(0, 2);
  let maskedUsername = firstTwoCharsOfUsername;

  if (username.length >= 4) {
    const lastTwoChars = username.slice(-2);
    maskedUsername += `******${lastTwoChars}`;
  } else if (username.length === 3) {
    const lastChar = username.slice(-1);
    maskedUsername += `******${lastChar}`;
  } else {
    maskedUsername += `******`;
  }

  return `${maskedUsername}@${domain}`;
};

const maskMobileNumber = (mobile) => {
  if (!mobile || typeof mobile !== "string") {
    return undefined;
  }

  const lastFourChars = mobile.slice(-4);

  return `****${lastFourChars}`;
};

const maskAddress = (address) => {
  if (!address || typeof address !== "string") {
    return undefined;
  }

  return "*******";
};

const isUpstreamError = (e) => {
  const axiosProperties = {
    data: 1,
    status: 1,
    statusText: 1,
    headers: 1,
    config: 1,
  };

  for (let key in axiosProperties) {
    if (!e.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

const maskErrorData = (data) => {
  const maskingMatrix = {
    name: maskFullName,
    date_of_birth: maskDateOfBirth,
    pan: maskPan,
    account_number: maskBankAccountNumber,
    email: maskEmailAddress,
    number: maskMobileNumber,
    line1: maskAddress,
    line2: maskAddress,
    line3: maskAddress,
    city: maskAddress,
    state: maskAddress,
    postal_code: maskAddress,
    code: maskAddress,
    country: maskAddress,
    lastName: maskLastName,
    mobile_number: maskMobileNumber,
    father_name: maskFullName,
    spouse_name: maskFullName,
    mother_name: maskFullName,
    country_of_birth: maskAddress,
    place_of_birth: maskAddress,
    line_1: maskAddress,
    line_2: maskAddress,
    line_3: maskAddress,
    pincode: maskAddress,
    primary_investor_pan: maskPan,
    second_investor_pan: maskPan,
    third_investor_pan: maskPan,
    bank_account_number: maskBankAccountNumber,
    beneficiary_account_number: maskBankAccountNumber,
    mobile: maskMobileNumber,
    primary_investor_name: maskFullName,
    secondary_investor_name: maskFullName,
    third_investor_name: maskFullName,
    primary_investor_dob: maskDateOfBirth,
    secondary_investor_dob: maskDateOfBirth,
    third_investor_dob: maskDateOfBirth,
    guardian_name: maskFullName,
    guardian_pan: maskPan,
    guardian_dob: maskDateOfBirth,
    dob: maskDateOfBirth,
    email_addresses: maskEmailAddress,
    mobile_numbers: maskMobileNumber,
    email_address: maskEmailAddress,
    phone_number: maskMobileNumber,
    username: maskPan,
  };

  const result = {};

  for (const [key, value] of Object.entries(data)) {
    if (value && Array.isArray(value)) {
      result[key] = value.map((ele) => maskingMatrix[key](ele));
    } else if (value && typeof value === "object") {
      result[key] = maskErrorData(value);
    } else if (key in maskingMatrix) {
      result[key] = maskingMatrix[key](value);
    } else {
      result[key] = value;
    }
  }

  return result;
};

const formatUpstreamErrorLog = (e) => {
  const config = e.config;
  const { Authorization, ...rest } = config.headers;

  const additionalData = {
    data: config.data ? maskErrorData(JSON.parse(config.data)) : null,
    params: config.params,
    headers: rest,
  };

  return additionalData;
};

export {
  wait,
  formatNumber,
  isEmptyObject,
  extractAttributes,
  isEmptyArray,
  formatDate,
  memoize,
  formatSchemeName,
  formatInvestmentOption,
  toTitleCase,
  uniqueId,
  floatSafeRemainder,
  formatINR,
  unFormatINR,
  formatAmount,
  getPaymentOptions,
  formatCustomDateLabel,
  allowedPlanDates,
  getOptionsIndex,
  allowedGroupPlanDates,
  getFrequencyOptions,
  frequencyValuesInMonths,
  capitalize,
  maskEmail,
  maskMobile,
  scrollToErrorField,
  formatTime,
  anyCharacterExcluding,
  getCountryNameFromANSI,
  formatBankName,
  getGroupOneTimeDates,
  getGroupRecurringDate,
  getObjectFromId,
  getOrderTypeFromOrderId,
  TENANT_ENV,
  tenantEnv,
  getOrderStatusFromOrderState,
  compareDates,
  getDifferenceInDays,
  getPlanEndDateFromStartDate,
  getTagStatus,
  getOrdinalDayFormat,
  filterFolioDefaultEmail,
  getDifferenceInMonthsBetweenDates,
  getDifferenceInWeeksBetweenDates,
  isMFPPScheduledForActivation,
  getNumberOfInstallments,
  getIndexOfNextBiggerElementInArray,
  getInstallmentDayFromStartDate,
  getMaxEndDateFromFrequency,
  getPlanStartDateFromFrequency,
  nonSystematicFrequencyDates,
  getMinMaxInvestmentAmountFromFolio,
  getActivateOnDate,
  isUpstreamError,
  formatUpstreamErrorLog,
};
