// core imports
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import UniversalRouter from "universal-router";
import { Loader } from "pixel";
import { FpAppsInit } from "fpapps/FpAppsInit";
import { Checkout } from "fpapps/Checkout";
import { Withdraw } from "fpapps/Withdraw";
import { Statement } from "fpapps/Statement";
import { Status } from "fpapps/Status";
import { GroupCheckout } from "fpapps/GroupCheckout";
import { GroupInvestmentStatus } from "fpapps/GroupInvestmentStatus";
import { ManageBankAccounts } from "fpapps/ManageBankAccounts";
import { ManageMandates } from "fpapps/ManageMandates";
import { ManageKyc } from "fpapps/ManageKyc";
import { ManageNominees } from "fpapps/ManageNominees";
import { ManagePayments } from "fpapps/ManagePayments";
import { ManagePlansList } from "fpapps/ManagePlansList";
import { ManageOrderHistory } from "fpapps/ManageOrderHistory";
import { ManageAssistedKyc } from "fpapps/ManageAssistedKyc";
import { KycStatus } from "fpapps/KycStatus";
import { Folios } from "fpapps/Folios";
import { Support } from "fpapps/Support";
import { NotFoundPage } from "fpapps/NotFoundPage";
import { Redirection } from "fpapps/Redirection";
import { Portfolio } from "fpapps/Portfolio";
import { Simulate } from "fpapps/Simulate";
import { EmployerOnboarding } from "fpapps/EmployerOnboarding";
import { PartnerPortfolio } from "fpapps/PartnerPortfolio";
import { Consent } from "fpapps/Consent";
import { MandateStatus } from "fpapps/MandateStatus";
import { InvestorDetails } from "fpapps/InvestorDetails";
import { AssistedMandates } from "fpapps/AssistedMandates";
import { AssistedOrderHistory } from "fpapps/AssistedOrderHistory";
import { AssistedPlansList } from "fpapps/AssistedPlansList";
import { NotificationWebhook } from "fpapps/NotificationWebhook";
import { PlanUpdate } from "fpapps/PlanUpdate";
import { Onboarding } from "fpapps/Onboarding";
import { TenantManagementServiceOnboard } from "fpapps/TenantManagementServiceOnboard";
import { TenantManagementServiceConfigure } from "fpapps/TenantManagementServiceConfigure";
import { ManageOrderFailureCodes } from "fpapps/ManageOrderFailureCodes";

import MasterLayout from "./components/display/masterlayout";
import LandingPage from "./landing_page";

const queryParams = new URLSearchParams(window.location.search);

const routes = [
  {
    path: "/",
    action: () => (
      <FpAppsInit userType={"UNKNOWN"}>
        <MasterLayoutWrapper userType={"UNKNOWN"}>
          <LandingPage />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/checkout",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Checkout
            isin={queryParams.get("isin")}
            switch_out={queryParams.get("switch_out")}
            isPartner={false}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/employer-onboarding",
    action: () => (
      <FpAppsInit userType={"EMPLOYER"}>
        <MasterLayoutWrapper userType={"EMPLOYER"}>
          <EmployerOnboarding />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-checkout",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <Checkout
            isin={queryParams.get("isin")}
            switch_out={queryParams.get("switch_out")}
            isPartner={true}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/withdraw",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Withdraw isin={queryParams.get("isin")} folio={queryParams.get("folio")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-withdraw",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <Withdraw
            isin={queryParams.get("isin")}
            folio={queryParams.get("folio")}
            isPartner={true}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/status/purchase",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Status order_id={queryParams.get("id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/status/withdrawal",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Status order_id={queryParams.get("id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/status/group-investment/purchase",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <GroupInvestmentStatus order_id={queryParams.get("id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/status/mandate",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <MandateStatus mandate_id={queryParams.get("id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/statement",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Statement />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/bank-accounts",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManageBankAccounts />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/mandates",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManageMandates mandate_id={queryParams.get("id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/nominees",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManageNominees />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/folios",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Folios />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/group-checkout",
    action: () => {
      const employer = queryParams.get("employer");
      if (employer) {
        return (
          <FpAppsInit userType={"INVESTOR_CIAM"}>
            <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
              <GroupCheckout isin={queryParams.get("isin")} employer={employer} />
            </MasterLayoutWrapper>
          </FpAppsInit>
        );
      } else {
        return (
          <FpAppsInit userType={"INVESTOR_CIAM"}>
            <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
              <NotFoundPage />
            </MasterLayoutWrapper>
          </FpAppsInit>
        );
      }
    },
  },
  {
    path: "/plans-list",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManagePlansList
            isGroupInvestment={queryParams.get("isGroupInvestment")}
            mandate_id={queryParams.get("mandate_id")}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/payment",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManagePayments order_id={queryParams.get("order_id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted_kyc",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <ManageAssistedKyc />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/order-history",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManageOrderHistory />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/kyc",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <ManageKyc />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/kyc-status",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <KycStatus kycRequestId={queryParams.get("kycr_id")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/support",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Support />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/redirection",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Redirection />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/portfolio",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Portfolio />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/simulate",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Simulate
            type={queryParams.get("type")}
            id={queryParams.get("id")}
            name={queryParams.get("name")}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-portfolio",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <PartnerPortfolio />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/consents",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Consent
            object_type={queryParams.get("object_type")}
            action={queryParams.get("action")}
            id={queryParams.get("id")}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/profile-details",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <InvestorDetails />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-profile-details",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <InvestorDetails isPartner={true} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/onboarding",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <Onboarding
            isPartner={queryParams.get("isPartner") === "true" ? true : false}
            isGroupInvestment={queryParams.get("isGroupInvestment") === "true" ? true : false}
            redirectURL={queryParams.get("redirectURL")}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-onboarding",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <Onboarding isPartner={true} redirectURL={queryParams.get("redirectURL")} />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-mandates",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <AssistedMandates />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-order-history",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <AssistedOrderHistory />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/assisted-plans-list",
    action: () => (
      <FpAppsInit userType={"PARTNER"}>
        <MasterLayoutWrapper userType={"PARTNER"}>
          <AssistedPlansList
            isGroupInvestment={queryParams.get("isGroupInvestment")}
            mandate_id={queryParams.get("mandate_id")}
            isPartner={true}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/notification-webhook",
    action: () => (
      <FpAppsInit userType={"ADMIN"}>
        <MasterLayoutWrapper userType={"ADMIN"}>
          <NotificationWebhook />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/plan-update",
    action: () => (
      <FpAppsInit userType={"INVESTOR_CIAM"}>
        <MasterLayoutWrapper userType={"INVESTOR_CIAM"}>
          <PlanUpdate
            type={queryParams.get("type")}
            id={queryParams.get("id")}
            editField={queryParams.get("edit_field")}
          />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/onboard/customer",
    action: () => (
      <FpAppsInit userType={"INTERNAL_ADMIN"}>
        <MasterLayoutWrapper userType={"INTERNAL_ADMIN"}>
          <TenantManagementServiceOnboard />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/fp-account",
    action: () => (
      <FpAppsInit userType={"ADMIN"}>
        <MasterLayoutWrapper userType={"ADMIN"}>
          <TenantManagementServiceConfigure />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "/order-failure-codes",
    action: () => (
      <FpAppsInit userType={"ADMIN"}>
        <MasterLayoutWrapper userType={"ADMIN"}>
          <ManageOrderFailureCodes />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
  {
    path: "(.*)",
    action: () => (
      <FpAppsInit userType={"UNKNOWN"}>
        <MasterLayoutWrapper userType={"UNKNOWN"}>
          <NotFoundPage />
        </MasterLayoutWrapper>
      </FpAppsInit>
    ),
  },
];

const MasterLayoutWrapper = ({ userType, children }) => {
  return (
    <MasterLayout employer={queryParams.get("employer")} userType={userType}>
      {children}
    </MasterLayout>
  );
};

const router = new UniversalRouter(routes);

const container = document.getElementById("root");
const root = createRoot(container);

router.resolve({ pathname: window.location.pathname }).then((component) => {
  root.render(<Suspense fallback={<Loader variant="fullpage" />}>{component}</Suspense>);
});
