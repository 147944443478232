const Ce = Object.prototype.toString;
function rt(e) {
  switch (Ce.call(e)) {
    case "[object Error]":
    case "[object Exception]":
    case "[object DOMException]":
      return !0;
    default:
      return z(e, Error);
  }
}
function C(e, t) {
  return Ce.call(e) === `[object ${t}]`;
}
function vn(e) {
  return C(e, "ErrorEvent");
}
function On(e) {
  return C(e, "DOMError");
}
function Nn(e) {
  return C(e, "DOMException");
}
function j(e) {
  return C(e, "String");
}
function st(e) {
  return typeof e == "object" && e !== null && "__sentry_template_string__" in e && "__sentry_template_values__" in e;
}
function An(e) {
  return e === null || st(e) || typeof e != "object" && typeof e != "function";
}
function ie(e) {
  return C(e, "Object");
}
function ot(e) {
  return typeof Event < "u" && z(e, Event);
}
function it(e) {
  return typeof Element < "u" && z(e, Element);
}
function at(e) {
  return C(e, "RegExp");
}
function ae(e) {
  return !!(e && e.then && typeof e.then == "function");
}
function ct(e) {
  return ie(e) && "nativeEvent" in e && "preventDefault" in e && "stopPropagation" in e;
}
function ut(e) {
  return typeof e == "number" && e !== e;
}
function z(e, t) {
  try {
    return e instanceof t;
  } catch {
    return !1;
  }
}
function ke(e) {
  return !!(typeof e == "object" && e !== null && (e.__isVue || e._isVue));
}
function P(e, t = 0) {
  return typeof e != "string" || t === 0 || e.length <= t ? e : `${e.slice(0, t)}...`;
}
function Cn(e, t) {
  if (!Array.isArray(e))
    return "";
  const n = [];
  for (let r = 0; r < e.length; r++) {
    const s = e[r];
    try {
      ke(s) ? n.push("[VueViewModel]") : n.push(String(s));
    } catch {
      n.push("[value cannot be serialized]");
    }
  }
  return n.join(t);
}
function lt(e, t, n = !1) {
  return j(e) ? at(t) ? t.test(e) : j(t) ? n ? e === t : e.includes(t) : !1 : !1;
}
function kn(e, t = [], n = !1) {
  return t.some((r) => lt(e, r, n));
}
function U(e) {
  return e && e.Math == Math ? e : void 0;
}
const _ = typeof globalThis == "object" && U(globalThis) || // eslint-disable-next-line no-restricted-globals
typeof window == "object" && U(window) || typeof self == "object" && U(self) || typeof global == "object" && U(global) || /* @__PURE__ */ function() {
  return this;
}() || {};
function ft() {
  return _;
}
function Fe(e, t, n) {
  const r = n || _, s = r.__SENTRY__ = r.__SENTRY__ || {};
  return s[e] || (s[e] = t());
}
const A = ft(), dt = 80;
function gt(e, t = {}) {
  if (!e)
    return "<unknown>";
  try {
    let n = e;
    const r = 5, s = [];
    let o = 0, i = 0;
    const a = " > ", c = a.length;
    let u;
    const l = Array.isArray(t) ? t : t.keyAttrs, f = !Array.isArray(t) && t.maxStringLength || dt;
    for (; n && o++ < r && (u = ht(n, l), !(u === "html" || o > 1 && i + s.length * c + u.length >= f)); )
      s.push(u), i += u.length, n = n.parentNode;
    return s.reverse().join(a);
  } catch {
    return "<unknown>";
  }
}
function ht(e, t) {
  const n = e, r = [];
  let s, o, i, a, c;
  if (!n || !n.tagName)
    return "";
  if (A.HTMLElement && n instanceof HTMLElement && n.dataset && n.dataset.sentryComponent)
    return n.dataset.sentryComponent;
  r.push(n.tagName.toLowerCase());
  const u = t && t.length ? t.filter((f) => n.getAttribute(f)).map((f) => [f, n.getAttribute(f)]) : null;
  if (u && u.length)
    u.forEach((f) => {
      r.push(`[${f[0]}="${f[1]}"]`);
    });
  else if (n.id && r.push(`#${n.id}`), s = n.className, s && j(s))
    for (o = s.split(/\s+/), c = 0; c < o.length; c++)
      r.push(`.${o[c]}`);
  const l = ["aria-label", "type", "name", "title", "alt"];
  for (c = 0; c < l.length; c++)
    i = l[c], a = n.getAttribute(i), a && r.push(`[${i}="${a}"]`);
  return r.join("");
}
function Fn() {
  try {
    return A.document.location.href;
  } catch {
    return "";
  }
}
function Pn(e) {
  return A.document && A.document.querySelector ? A.document.querySelector(e) : null;
}
function Rn(e) {
  if (!A.HTMLElement)
    return null;
  let t = e;
  const n = 5;
  for (let r = 0; r < n; r++) {
    if (!t)
      return null;
    if (t instanceof HTMLElement && t.dataset.sentryComponent)
      return t.dataset.sentryComponent;
    t = t.parentNode;
  }
  return null;
}
const ce = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, pt = "Sentry Logger ", ge = [
  "debug",
  "info",
  "warn",
  "error",
  "log",
  "assert",
  "trace"
], he = {};
function Pe(e) {
  if (!("console" in _))
    return e();
  const t = _.console, n = {}, r = Object.keys(he);
  r.forEach((s) => {
    const o = he[s];
    n[s] = t[s], t[s] = o;
  });
  try {
    return e();
  } finally {
    r.forEach((s) => {
      t[s] = n[s];
    });
  }
}
function _t() {
  let e = !1;
  const t = {
    enable: () => {
      e = !0;
    },
    disable: () => {
      e = !1;
    },
    isEnabled: () => e
  };
  return ce ? ge.forEach((n) => {
    t[n] = (...r) => {
      e && Pe(() => {
        _.console[n](`${pt}[${n}]:`, ...r);
      });
    };
  }) : ge.forEach((n) => {
    t[n] = () => {
    };
  }), t;
}
const b = _t();
function Ln(e, t, n) {
  if (!(t in e))
    return;
  const r = e[t], s = n(r);
  typeof s == "function" && mt(s, r), e[t] = s;
}
function Re(e, t, n) {
  try {
    Object.defineProperty(e, t, {
      // enumerable: false, // the default, so we can save on bundle size by not explicitly setting it
      value: n,
      writable: !0,
      configurable: !0
    });
  } catch {
    ce && b.log(`Failed to add non-enumerable property "${t}" to object`, e);
  }
}
function mt(e, t) {
  try {
    const n = t.prototype || {};
    e.prototype = t.prototype = n, Re(e, "__sentry_original__", t);
  } catch {
  }
}
function $n(e) {
  return e.__sentry_original__;
}
function Un(e) {
  return Object.keys(e).map((t) => `${encodeURIComponent(t)}=${encodeURIComponent(e[t])}`).join("&");
}
function Le(e) {
  if (rt(e))
    return {
      message: e.message,
      name: e.name,
      stack: e.stack,
      ..._e(e)
    };
  if (ot(e)) {
    const t = {
      type: e.type,
      target: pe(e.target),
      currentTarget: pe(e.currentTarget),
      ..._e(e)
    };
    return typeof CustomEvent < "u" && z(e, CustomEvent) && (t.detail = e.detail), t;
  } else
    return e;
}
function pe(e) {
  try {
    return it(e) ? gt(e) : Object.prototype.toString.call(e);
  } catch {
    return "<unknown>";
  }
}
function _e(e) {
  if (typeof e == "object" && e !== null) {
    const t = {};
    for (const n in e)
      Object.prototype.hasOwnProperty.call(e, n) && (t[n] = e[n]);
    return t;
  } else
    return {};
}
function Bn(e, t = 40) {
  const n = Object.keys(Le(e));
  if (n.sort(), !n.length)
    return "[object has no keys]";
  if (n[0].length >= t)
    return P(n[0], t);
  for (let r = n.length; r > 0; r--) {
    const s = n.slice(0, r).join(", ");
    if (!(s.length > t))
      return r === n.length ? s : P(s, t);
  }
  return "";
}
function T(e) {
  return ne(e, /* @__PURE__ */ new Map());
}
function ne(e, t) {
  if (yt(e)) {
    const n = t.get(e);
    if (n !== void 0)
      return n;
    const r = {};
    t.set(e, r);
    for (const s of Object.keys(e))
      typeof e[s] < "u" && (r[s] = ne(e[s], t));
    return r;
  }
  if (Array.isArray(e)) {
    const n = t.get(e);
    if (n !== void 0)
      return n;
    const r = [];
    return t.set(e, r), e.forEach((s) => {
      r.push(ne(s, t));
    }), r;
  }
  return e;
}
function yt(e) {
  if (!ie(e))
    return !1;
  try {
    const t = Object.getPrototypeOf(e).constructor.name;
    return !t || t === "Object";
  } catch {
    return !0;
  }
}
const $e = 50, me = /\(error: (.*)\)/, ye = /captureMessage|captureException/;
function bt(...e) {
  const t = e.sort((n, r) => n[0] - r[0]).map((n) => n[1]);
  return (n, r = 0) => {
    const s = [], o = n.split(`
`);
    for (let i = r; i < o.length; i++) {
      const a = o[i];
      if (a.length > 1024)
        continue;
      const c = me.test(a) ? a.replace(me, "$1") : a;
      if (!c.match(/\S*Error: /)) {
        for (const u of t) {
          const l = u(c);
          if (l) {
            s.push(l);
            break;
          }
        }
        if (s.length >= $e)
          break;
      }
    }
    return St(s);
  };
}
function Gn(e) {
  return Array.isArray(e) ? bt(...e) : e;
}
function St(e) {
  if (!e.length)
    return [];
  const t = Array.from(e);
  return /sentryWrapped/.test(t[t.length - 1].function || "") && t.pop(), t.reverse(), ye.test(t[t.length - 1].function || "") && (t.pop(), ye.test(t[t.length - 1].function || "") && t.pop()), t.slice(0, $e).map((n) => ({
    ...n,
    filename: n.filename || t[t.length - 1].filename,
    function: n.function || "?"
  }));
}
const Q = "<anonymous>";
function Dt(e) {
  try {
    return !e || typeof e != "function" ? Q : e.name || Q;
  } catch {
    return Q;
  }
}
function d() {
  const e = _, t = e.crypto || e.msCrypto;
  let n = () => Math.random() * 16;
  try {
    if (t && t.randomUUID)
      return t.randomUUID().replace(/-/g, "");
    t && t.getRandomValues && (n = () => {
      const r = new Uint8Array(1);
      return t.getRandomValues(r), r[0];
    });
  } catch {
  }
  return ("10000000100040008000" + 1e11).replace(
    /[018]/g,
    (r) => (
      // eslint-disable-next-line no-bitwise
      (r ^ (n() & 15) >> r / 4).toString(16)
    )
  );
}
function Ue(e) {
  return e.exception && e.exception.values ? e.exception.values[0] : void 0;
}
function jn(e) {
  const { message: t, event_id: n } = e;
  if (t)
    return t;
  const r = Ue(e);
  return r ? r.type && r.value ? `${r.type}: ${r.value}` : r.type || r.value || n || "<unknown>" : n || "<unknown>";
}
function Yn(e, t, n) {
  const r = e.exception = e.exception || {}, s = r.values = r.values || [], o = s[0] = s[0] || {};
  o.value || (o.value = t || ""), o.type || (o.type = "Error");
}
function wt(e, t) {
  const n = Ue(e);
  if (!n)
    return;
  const r = { type: "generic", handled: !0 }, s = n.mechanism;
  if (n.mechanism = { ...r, ...s, ...t }, t && "data" in t) {
    const o = { ...s && s.data, ...t.data };
    n.mechanism.data = o;
  }
}
function Hn(e) {
  if (e && e.__sentry_captured__)
    return !0;
  try {
    Re(e, "__sentry_captured__", !0);
  } catch {
  }
  return !1;
}
function Et(e) {
  return Array.isArray(e) ? e : [e];
}
function xt() {
  const e = typeof WeakSet == "function", t = e ? /* @__PURE__ */ new WeakSet() : [];
  function n(s) {
    if (e)
      return t.has(s) ? !0 : (t.add(s), !1);
    for (let o = 0; o < t.length; o++)
      if (t[o] === s)
        return !0;
    return t.push(s), !1;
  }
  function r(s) {
    if (e)
      t.delete(s);
    else
      for (let o = 0; o < t.length; o++)
        if (t[o] === s) {
          t.splice(o, 1);
          break;
        }
  }
  return [n, r];
}
function x(e, t = 100, n = 1 / 0) {
  try {
    return re("", e, t, n);
  } catch (r) {
    return { ERROR: `**non-serializable** (${r})` };
  }
}
function Tt(e, t = 3, n = 100 * 1024) {
  const r = x(e, t);
  return Ot(r) > n ? Tt(e, t - 1, n) : r;
}
function re(e, t, n = 1 / 0, r = 1 / 0, s = xt()) {
  const [o, i] = s;
  if (t == null || // this matches null and undefined -> eqeq not eqeqeq
  ["number", "boolean", "string"].includes(typeof t) && !ut(t))
    return t;
  const a = It(e, t);
  if (!a.startsWith("[object "))
    return a;
  if (t.__sentry_skip_normalization__)
    return t;
  const c = typeof t.__sentry_override_normalization_depth__ == "number" ? t.__sentry_override_normalization_depth__ : n;
  if (c === 0)
    return a.replace("object ", "");
  if (o(t))
    return "[Circular ~]";
  const u = t;
  if (u && typeof u.toJSON == "function")
    try {
      const h = u.toJSON();
      return re("", h, c - 1, r, s);
    } catch {
    }
  const l = Array.isArray(t) ? [] : {};
  let f = 0;
  const g = Le(t);
  for (const h in g) {
    if (!Object.prototype.hasOwnProperty.call(g, h))
      continue;
    if (f >= r) {
      l[h] = "[MaxProperties ~]";
      break;
    }
    const I = g[h];
    l[h] = re(h, I, c - 1, r, s), f++;
  }
  return i(t), l;
}
function It(e, t) {
  try {
    if (e === "domain" && t && typeof t == "object" && t._events)
      return "[Domain]";
    if (e === "domainEmitter")
      return "[DomainEmitter]";
    if (typeof global < "u" && t === global)
      return "[Global]";
    if (typeof window < "u" && t === window)
      return "[Window]";
    if (typeof document < "u" && t === document)
      return "[Document]";
    if (ke(t))
      return "[VueViewModel]";
    if (ct(t))
      return "[SyntheticEvent]";
    if (typeof t == "number" && t !== t)
      return "[NaN]";
    if (typeof t == "function")
      return `[Function: ${Dt(t)}]`;
    if (typeof t == "symbol")
      return `[${String(t)}]`;
    if (typeof t == "bigint")
      return `[BigInt: ${String(t)}]`;
    const n = Mt(t);
    return /^HTML(\w*)Element$/.test(n) ? `[HTMLElement: ${n}]` : `[object ${n}]`;
  } catch (n) {
    return `**non-serializable** (${n})`;
  }
}
function Mt(e) {
  const t = Object.getPrototypeOf(e);
  return t ? t.constructor.name : "null prototype";
}
function vt(e) {
  return ~-encodeURI(e).split(/%..|./).length;
}
function Ot(e) {
  return vt(JSON.stringify(e));
}
var S;
(function(e) {
  e[e.PENDING = 0] = "PENDING";
  const n = 1;
  e[e.RESOLVED = n] = "RESOLVED";
  const r = 2;
  e[e.REJECTED = r] = "REJECTED";
})(S || (S = {}));
function Jn(e) {
  return new y((t) => {
    t(e);
  });
}
function zn(e) {
  return new y((t, n) => {
    n(e);
  });
}
class y {
  constructor(t) {
    y.prototype.__init.call(this), y.prototype.__init2.call(this), y.prototype.__init3.call(this), y.prototype.__init4.call(this), this._state = S.PENDING, this._handlers = [];
    try {
      t(this._resolve, this._reject);
    } catch (n) {
      this._reject(n);
    }
  }
  /** JSDoc */
  then(t, n) {
    return new y((r, s) => {
      this._handlers.push([
        !1,
        (o) => {
          if (!t)
            r(o);
          else
            try {
              r(t(o));
            } catch (i) {
              s(i);
            }
        },
        (o) => {
          if (!n)
            s(o);
          else
            try {
              r(n(o));
            } catch (i) {
              s(i);
            }
        }
      ]), this._executeHandlers();
    });
  }
  /** JSDoc */
  catch(t) {
    return this.then((n) => n, t);
  }
  /** JSDoc */
  finally(t) {
    return new y((n, r) => {
      let s, o;
      return this.then(
        (i) => {
          o = !1, s = i, t && t();
        },
        (i) => {
          o = !0, s = i, t && t();
        }
      ).then(() => {
        if (o) {
          r(s);
          return;
        }
        n(s);
      });
    });
  }
  /** JSDoc */
  __init() {
    this._resolve = (t) => {
      this._setResult(S.RESOLVED, t);
    };
  }
  /** JSDoc */
  __init2() {
    this._reject = (t) => {
      this._setResult(S.REJECTED, t);
    };
  }
  /** JSDoc */
  __init3() {
    this._setResult = (t, n) => {
      if (this._state === S.PENDING) {
        if (ae(n)) {
          n.then(this._resolve, this._reject);
          return;
        }
        this._state = t, this._value = n, this._executeHandlers();
      }
    };
  }
  /** JSDoc */
  __init4() {
    this._executeHandlers = () => {
      if (this._state === S.PENDING)
        return;
      const t = this._handlers.slice();
      this._handlers = [], t.forEach((n) => {
        n[0] || (this._state === S.RESOLVED && n[1](this._value), this._state === S.REJECTED && n[2](this._value), n[0] = !0);
      });
    };
  }
}
const Be = 1e3;
function W() {
  return Date.now() / Be;
}
function Nt() {
  const { performance: e } = _;
  if (!e || !e.now)
    return W;
  const t = Date.now() - e.now(), n = e.timeOrigin == null ? t : e.timeOrigin;
  return () => (n + e.now()) / Be;
}
const ue = Nt(), Wn = (() => {
  const { performance: e } = _;
  if (!e || !e.now)
    return;
  const t = 3600 * 1e3, n = e.now(), r = Date.now(), s = e.timeOrigin ? Math.abs(e.timeOrigin + n - r) : t, o = s < t, i = e.timing && e.timing.navigationStart, c = typeof i == "number" ? Math.abs(i + n - r) : t, u = c < t;
  return o || u ? s <= c ? e.timeOrigin : i : r;
})(), Vn = "baggage", Ge = "sentry-", At = /^sentry-/, Ct = 8192;
function kt(e) {
  if (!j(e) && !Array.isArray(e))
    return;
  let t = {};
  if (Array.isArray(e))
    t = e.reduce((r, s) => {
      const o = be(s);
      for (const i of Object.keys(o))
        r[i] = o[i];
      return r;
    }, {});
  else {
    if (!e)
      return;
    t = be(e);
  }
  const n = Object.entries(t).reduce((r, [s, o]) => {
    if (s.match(At)) {
      const i = s.slice(Ge.length);
      r[i] = o;
    }
    return r;
  }, {});
  if (Object.keys(n).length > 0)
    return n;
}
function Xn(e) {
  if (!e)
    return;
  const t = Object.entries(e).reduce(
    (n, [r, s]) => (s && (n[`${Ge}${r}`] = s), n),
    {}
  );
  return Ft(t);
}
function be(e) {
  return e.split(",").map((t) => t.split("=").map((n) => decodeURIComponent(n.trim()))).reduce((t, [n, r]) => (t[n] = r, t), {});
}
function Ft(e) {
  if (Object.keys(e).length !== 0)
    return Object.entries(e).reduce((t, [n, r], s) => {
      const o = `${encodeURIComponent(n)}=${encodeURIComponent(r)}`, i = s === 0 ? o : `${t},${o}`;
      return i.length > Ct ? (ce && b.warn(
        `Not adding key: ${n} with val: ${r} to baggage header due to exceeding baggage size limits.`
      ), t) : i;
    }, "");
}
const Pt = new RegExp(
  "^[ \\t]*([0-9a-f]{32})?-?([0-9a-f]{16})?-?([01])?[ \\t]*$"
  // whitespace
);
function Rt(e) {
  if (!e)
    return;
  const t = e.match(Pt);
  if (!t)
    return;
  let n;
  return t[3] === "1" ? n = !0 : t[3] === "0" && (n = !1), {
    traceId: t[1],
    parentSampled: n,
    parentSpanId: t[2]
  };
}
function Kn(e, t) {
  const n = Rt(e), r = kt(t), { traceId: s, parentSpanId: o, parentSampled: i } = n || {};
  return n ? {
    traceId: s || d(),
    parentSpanId: o || d().substring(16),
    spanId: d().substring(16),
    sampled: i,
    dsc: r || {}
    // If we have traceparent data but no DSC it means we are not head of trace and we must freeze it
  } : {
    traceId: s || d(),
    spanId: d().substring(16)
  };
}
function Lt(e = d(), t = d().substring(16), n) {
  let r = "";
  return n !== void 0 && (r = n ? "-1" : "-0"), `${e}-${t}${r}`;
}
const G = typeof __SENTRY_DEBUG__ > "u" || __SENTRY_DEBUG__, V = "production";
function le() {
  return Fe("globalEventProcessors", () => []);
}
function Qn(e) {
  le().push(e);
}
function Y(e, t, n, r = 0) {
  return new y((s, o) => {
    const i = e[r];
    if (t === null || typeof i != "function")
      s(t);
    else {
      const a = i({ ...t }, n);
      G && i.id && a === null && b.log(`Event processor "${i.id}" dropped event`), ae(a) ? a.then((c) => Y(e, c, n, r + 1).then(s)).then(null, o) : Y(e, a, n, r + 1).then(s).then(null, o);
    }
  });
}
function je(e) {
  const t = ue(), n = {
    sid: d(),
    init: !0,
    timestamp: t,
    started: t,
    duration: 0,
    status: "ok",
    errors: 0,
    ignoreDuration: !1,
    toJSON: () => $t(n)
  };
  return e && $(n, e), n;
}
function $(e, t = {}) {
  if (t.user && (!e.ipAddress && t.user.ip_address && (e.ipAddress = t.user.ip_address), !e.did && !t.did && (e.did = t.user.id || t.user.email || t.user.username)), e.timestamp = t.timestamp || ue(), t.abnormal_mechanism && (e.abnormal_mechanism = t.abnormal_mechanism), t.ignoreDuration && (e.ignoreDuration = t.ignoreDuration), t.sid && (e.sid = t.sid.length === 32 ? t.sid : d()), t.init !== void 0 && (e.init = t.init), !e.did && t.did && (e.did = `${t.did}`), typeof t.started == "number" && (e.started = t.started), e.ignoreDuration)
    e.duration = void 0;
  else if (typeof t.duration == "number")
    e.duration = t.duration;
  else {
    const n = e.timestamp - e.started;
    e.duration = n >= 0 ? n : 0;
  }
  t.release && (e.release = t.release), t.environment && (e.environment = t.environment), !e.ipAddress && t.ipAddress && (e.ipAddress = t.ipAddress), !e.userAgent && t.userAgent && (e.userAgent = t.userAgent), typeof t.errors == "number" && (e.errors = t.errors), t.status && (e.status = t.status);
}
function Ye(e, t) {
  let n = {};
  e.status === "ok" && (n = { status: "exited" }), $(e, n);
}
function $t(e) {
  return T({
    sid: `${e.sid}`,
    init: e.init,
    // Make sure that sec is converted to ms for date constructor
    started: new Date(e.started * 1e3).toISOString(),
    timestamp: new Date(e.timestamp * 1e3).toISOString(),
    status: e.status,
    errors: e.errors,
    did: typeof e.did == "number" || typeof e.did == "string" ? `${e.did}` : void 0,
    duration: e.duration,
    abnormal_mechanism: e.abnormal_mechanism,
    attrs: {
      release: e.release,
      environment: e.environment,
      ip_address: e.ipAddress,
      user_agent: e.userAgent
    }
  });
}
const Zn = 0, Ut = 1;
function Bt(e) {
  const { spanId: t, traceId: n } = e.spanContext(), { data: r, op: s, parent_span_id: o, status: i, tags: a, origin: c } = L(e);
  return T({
    data: r,
    op: s,
    parent_span_id: o,
    span_id: t,
    status: i,
    tags: a,
    trace_id: n,
    origin: c
  });
}
function qn(e) {
  const { traceId: t, spanId: n } = e.spanContext(), r = He(e);
  return Lt(t, n, r);
}
function er(e) {
  return typeof e == "number" ? Se(e) : Array.isArray(e) ? e[0] + e[1] / 1e9 : e instanceof Date ? Se(e.getTime()) : ue();
}
function Se(e) {
  return e > 9999999999 ? e / 1e3 : e;
}
function L(e) {
  return Gt(e) ? e.getSpanJSON() : typeof e.toJSON == "function" ? e.toJSON() : {};
}
function Gt(e) {
  return typeof e.getSpanJSON == "function";
}
function He(e) {
  const { traceFlags: t } = e.spanContext();
  return !!(t & Ut);
}
function tr(e, t, n, r, s, o) {
  const { normalizeDepth: i = 3, normalizeMaxBreadth: a = 1e3 } = e, c = {
    ...t,
    event_id: t.event_id || n.event_id || d(),
    timestamp: t.timestamp || W()
  }, u = n.integrations || e.integrations.map((m) => m.name);
  jt(c, e), Jt(c, u), t.type === void 0 && Yt(c, e.stackParser);
  const l = Wt(r, n.captureContext);
  n.mechanism && wt(c, n.mechanism);
  const f = s && s.getEventProcessors ? s.getEventProcessors() : [], g = cn().getScopeData();
  if (o) {
    const m = o.getScopeData();
    we(g, m);
  }
  if (l) {
    const m = l.getScopeData();
    we(g, m);
  }
  const h = [...n.attachments || [], ...g.attachments];
  h.length && (n.attachments = h), Ve(c, g);
  const I = [
    ...f,
    // eslint-disable-next-line deprecation/deprecation
    ...le(),
    // Run scope event processors _after_ all other processors
    ...g.eventProcessors
  ];
  return Y(I, c, n).then((m) => (m && Ht(m), typeof i == "number" && i > 0 ? zt(m, i, a) : m));
}
function jt(e, t) {
  const { environment: n, release: r, dist: s, maxValueLength: o = 250 } = t;
  "environment" in e || (e.environment = "environment" in t ? n : V), e.release === void 0 && r !== void 0 && (e.release = r), e.dist === void 0 && s !== void 0 && (e.dist = s), e.message && (e.message = P(e.message, o));
  const i = e.exception && e.exception.values && e.exception.values[0];
  i && i.value && (i.value = P(i.value, o));
  const a = e.request;
  a && a.url && (a.url = P(a.url, o));
}
const De = /* @__PURE__ */ new WeakMap();
function Yt(e, t) {
  const n = _._sentryDebugIds;
  if (!n)
    return;
  let r;
  const s = De.get(t);
  s ? r = s : (r = /* @__PURE__ */ new Map(), De.set(t, r));
  const o = Object.keys(n).reduce((i, a) => {
    let c;
    const u = r.get(a);
    u ? c = u : (c = t(a), r.set(a, c));
    for (let l = c.length - 1; l >= 0; l--) {
      const f = c[l];
      if (f.filename) {
        i[f.filename] = n[a];
        break;
      }
    }
    return i;
  }, {});
  try {
    e.exception.values.forEach((i) => {
      i.stacktrace.frames.forEach((a) => {
        a.filename && (a.debug_id = o[a.filename]);
      });
    });
  } catch {
  }
}
function Ht(e) {
  const t = {};
  try {
    e.exception.values.forEach((r) => {
      r.stacktrace.frames.forEach((s) => {
        s.debug_id && (s.abs_path ? t[s.abs_path] = s.debug_id : s.filename && (t[s.filename] = s.debug_id), delete s.debug_id);
      });
    });
  } catch {
  }
  if (Object.keys(t).length === 0)
    return;
  e.debug_meta = e.debug_meta || {}, e.debug_meta.images = e.debug_meta.images || [];
  const n = e.debug_meta.images;
  Object.keys(t).forEach((r) => {
    n.push({
      type: "sourcemap",
      code_file: r,
      debug_id: t[r]
    });
  });
}
function Jt(e, t) {
  t.length > 0 && (e.sdk = e.sdk || {}, e.sdk.integrations = [...e.sdk.integrations || [], ...t]);
}
function zt(e, t, n) {
  if (!e)
    return null;
  const r = {
    ...e,
    ...e.breadcrumbs && {
      breadcrumbs: e.breadcrumbs.map((s) => ({
        ...s,
        ...s.data && {
          data: x(s.data, t, n)
        }
      }))
    },
    ...e.user && {
      user: x(e.user, t, n)
    },
    ...e.contexts && {
      contexts: x(e.contexts, t, n)
    },
    ...e.extra && {
      extra: x(e.extra, t, n)
    }
  };
  return e.contexts && e.contexts.trace && r.contexts && (r.contexts.trace = e.contexts.trace, e.contexts.trace.data && (r.contexts.trace.data = x(e.contexts.trace.data, t, n))), e.spans && (r.spans = e.spans.map((s) => {
    const o = L(s).data;
    return o && (s.data = x(o, t, n)), s;
  })), r;
}
function Wt(e, t) {
  if (!t)
    return e;
  const n = e ? e.clone() : new D();
  return n.update(t), n;
}
function Vt(e) {
  if (e)
    return Xt(e) ? { captureContext: e } : Qt(e) ? {
      captureContext: e
    } : e;
}
function Xt(e) {
  return e instanceof D || typeof e == "function";
}
const Kt = [
  "user",
  "level",
  "extra",
  "contexts",
  "tags",
  "fingerprint",
  "requestSession",
  "propagationContext"
];
function Qt(e) {
  return Object.keys(e).some((t) => Kt.includes(t));
}
function Zt(e, t) {
  return w().captureException(e, Vt(t));
}
function nr(e, t) {
  return w().captureEvent(e, t);
}
function rr(e, t) {
  w().addBreadcrumb(e, t);
}
function sr(e, t) {
  w().setTag(e, t);
}
function or(e) {
  w().setUser(e);
}
function ir(...e) {
  const t = w();
  if (e.length === 2) {
    const [n, r] = e;
    return n ? t.withScope(() => (t.getStackTop().scope = n, r(n))) : t.withScope(r);
  }
  return t.withScope(e[0]);
}
function fe() {
  return w().getClient();
}
function X() {
  return w().getScope();
}
function ar(e) {
  const t = fe(), n = de(), r = X(), { release: s, environment: o = V } = t && t.getOptions() || {}, { userAgent: i } = _.navigator || {}, a = je({
    release: s,
    environment: o,
    user: r.getUser() || n.getUser(),
    ...i && { userAgent: i },
    ...e
  }), c = n.getSession();
  return c && c.status === "ok" && $(c, { status: "exited" }), Je(), n.setSession(a), r.setSession(a), a;
}
function Je() {
  const e = de(), t = X(), n = t.getSession() || e.getSession();
  n && Ye(n), ze(), e.setSession(), t.setSession();
}
function ze() {
  const e = de(), t = X(), n = fe(), r = t.getSession() || e.getSession();
  r && n && n.captureSession && n.captureSession(r);
}
function cr(e = !1) {
  if (e) {
    Je();
    return;
  }
  ze();
}
function We(e) {
  return e.transaction;
}
function qt(e, t, n) {
  const r = t.getOptions(), { publicKey: s } = t.getDsn() || {}, { segment: o } = n && n.getUser() || {}, i = T({
    environment: r.environment || V,
    release: r.release,
    user_segment: o,
    public_key: s,
    trace_id: e
  });
  return t.emit && t.emit("createDsc", i), i;
}
function en(e) {
  const t = fe();
  if (!t)
    return {};
  const n = qt(L(e).trace_id || "", t, X()), r = We(e);
  if (!r)
    return n;
  const s = r && r._frozenDynamicSamplingContext;
  if (s)
    return s;
  const { sampleRate: o, source: i } = r.metadata;
  o != null && (n.sample_rate = `${o}`);
  const a = L(r);
  return i && i !== "url" && (n.transaction = a.description), n.sampled = String(He(r)), t.emit && t.emit("createDsc", n), n;
}
function Ve(e, t) {
  const { fingerprint: n, span: r, breadcrumbs: s, sdkProcessingMetadata: o } = t;
  tn(e, t), r && sn(e, r), on(e, n), nn(e, s), rn(e, o);
}
function we(e, t) {
  const {
    extra: n,
    tags: r,
    user: s,
    contexts: o,
    level: i,
    sdkProcessingMetadata: a,
    breadcrumbs: c,
    fingerprint: u,
    eventProcessors: l,
    attachments: f,
    propagationContext: g,
    // eslint-disable-next-line deprecation/deprecation
    transactionName: h,
    span: I
  } = t;
  k(e, "extra", n), k(e, "tags", r), k(e, "user", s), k(e, "contexts", o), k(e, "sdkProcessingMetadata", a), i && (e.level = i), h && (e.transactionName = h), I && (e.span = I), c.length && (e.breadcrumbs = [...e.breadcrumbs, ...c]), u.length && (e.fingerprint = [...e.fingerprint, ...u]), l.length && (e.eventProcessors = [...e.eventProcessors, ...l]), f.length && (e.attachments = [...e.attachments, ...f]), e.propagationContext = { ...e.propagationContext, ...g };
}
function k(e, t, n) {
  if (n && Object.keys(n).length) {
    e[t] = { ...e[t] };
    for (const r in n)
      Object.prototype.hasOwnProperty.call(n, r) && (e[t][r] = n[r]);
  }
}
function tn(e, t) {
  const {
    extra: n,
    tags: r,
    user: s,
    contexts: o,
    level: i,
    // eslint-disable-next-line deprecation/deprecation
    transactionName: a
  } = t, c = T(n);
  c && Object.keys(c).length && (e.extra = { ...c, ...e.extra });
  const u = T(r);
  u && Object.keys(u).length && (e.tags = { ...u, ...e.tags });
  const l = T(s);
  l && Object.keys(l).length && (e.user = { ...l, ...e.user });
  const f = T(o);
  f && Object.keys(f).length && (e.contexts = { ...f, ...e.contexts }), i && (e.level = i), a && (e.transaction = a);
}
function nn(e, t) {
  const n = [...e.breadcrumbs || [], ...t];
  e.breadcrumbs = n.length ? n : void 0;
}
function rn(e, t) {
  e.sdkProcessingMetadata = {
    ...e.sdkProcessingMetadata,
    ...t
  };
}
function sn(e, t) {
  e.contexts = { trace: Bt(t), ...e.contexts };
  const n = We(t);
  if (n) {
    e.sdkProcessingMetadata = {
      dynamicSamplingContext: en(t),
      ...e.sdkProcessingMetadata
    };
    const r = L(n).description;
    r && (e.tags = { transaction: r, ...e.tags });
  }
}
function on(e, t) {
  e.fingerprint = e.fingerprint ? Et(e.fingerprint) : [], t && (e.fingerprint = e.fingerprint.concat(t)), e.fingerprint && !e.fingerprint.length && delete e.fingerprint;
}
const an = 100;
let Z;
class D {
  /** Flag if notifying is happening. */
  /** Callback for client to receive scope changes. */
  /** Callback list that will be called after {@link applyToEvent}. */
  /** Array of breadcrumbs. */
  /** User */
  /** Tags */
  /** Extra */
  /** Contexts */
  /** Attachments */
  /** Propagation Context for distributed tracing */
  /**
   * A place to stash data which is needed at some point in the SDK's event processing pipeline but which shouldn't get
   * sent to Sentry
   */
  /** Fingerprint */
  /** Severity */
  // eslint-disable-next-line deprecation/deprecation
  /**
   * Transaction Name
   */
  /** Span */
  /** Session */
  /** Request Mode Session Status */
  /** The client on this scope */
  // NOTE: Any field which gets added here should get added not only to the constructor but also to the `clone` method.
  constructor() {
    this._notifyingListeners = !1, this._scopeListeners = [], this._eventProcessors = [], this._breadcrumbs = [], this._attachments = [], this._user = {}, this._tags = {}, this._extra = {}, this._contexts = {}, this._sdkProcessingMetadata = {}, this._propagationContext = Ee();
  }
  /**
   * Inherit values from the parent scope.
   * @deprecated Use `scope.clone()` and `new Scope()` instead.
   */
  static clone(t) {
    return t ? t.clone() : new D();
  }
  /**
   * Clone this scope instance.
   */
  clone() {
    const t = new D();
    return t._breadcrumbs = [...this._breadcrumbs], t._tags = { ...this._tags }, t._extra = { ...this._extra }, t._contexts = { ...this._contexts }, t._user = this._user, t._level = this._level, t._span = this._span, t._session = this._session, t._transactionName = this._transactionName, t._fingerprint = this._fingerprint, t._eventProcessors = [...this._eventProcessors], t._requestSession = this._requestSession, t._attachments = [...this._attachments], t._sdkProcessingMetadata = { ...this._sdkProcessingMetadata }, t._propagationContext = { ...this._propagationContext }, t._client = this._client, t;
  }
  /** Update the client on the scope. */
  setClient(t) {
    this._client = t;
  }
  /**
   * Get the client assigned to this scope.
   *
   * It is generally recommended to use the global function `Sentry.getClient()` instead, unless you know what you are doing.
   */
  getClient() {
    return this._client;
  }
  /**
   * Add internal on change listener. Used for sub SDKs that need to store the scope.
   * @hidden
   */
  addScopeListener(t) {
    this._scopeListeners.push(t);
  }
  /**
   * @inheritDoc
   */
  addEventProcessor(t) {
    return this._eventProcessors.push(t), this;
  }
  /**
   * @inheritDoc
   */
  setUser(t) {
    return this._user = t || {
      email: void 0,
      id: void 0,
      ip_address: void 0,
      segment: void 0,
      username: void 0
    }, this._session && $(this._session, { user: t }), this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getUser() {
    return this._user;
  }
  /**
   * @inheritDoc
   */
  getRequestSession() {
    return this._requestSession;
  }
  /**
   * @inheritDoc
   */
  setRequestSession(t) {
    return this._requestSession = t, this;
  }
  /**
   * @inheritDoc
   */
  setTags(t) {
    return this._tags = {
      ...this._tags,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setTag(t, n) {
    return this._tags = { ...this._tags, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtras(t) {
    return this._extra = {
      ...this._extra,
      ...t
    }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setExtra(t, n) {
    return this._extra = { ...this._extra, [t]: n }, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setFingerprint(t) {
    return this._fingerprint = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setLevel(t) {
    return this._level = t, this._notifyScopeListeners(), this;
  }
  /**
   * Sets the transaction name on the scope for future events.
   */
  setTransactionName(t) {
    return this._transactionName = t, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  setContext(t, n) {
    return n === null ? delete this._contexts[t] : this._contexts[t] = n, this._notifyScopeListeners(), this;
  }
  /**
   * Sets the Span on the scope.
   * @param span Span
   * @deprecated Instead of setting a span on a scope, use `startSpan()`/`startSpanManual()` instead.
   */
  setSpan(t) {
    return this._span = t, this._notifyScopeListeners(), this;
  }
  /**
   * Returns the `Span` if there is one.
   * @deprecated Use `getActiveSpan()` instead.
   */
  getSpan() {
    return this._span;
  }
  /**
   * Returns the `Transaction` attached to the scope (if there is one).
   * @deprecated You should not rely on the transaction, but just use `startSpan()` APIs instead.
   */
  getTransaction() {
    const t = this._span;
    return t && t.transaction;
  }
  /**
   * @inheritDoc
   */
  setSession(t) {
    return t ? this._session = t : delete this._session, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getSession() {
    return this._session;
  }
  /**
   * @inheritDoc
   */
  update(t) {
    if (!t)
      return this;
    const n = typeof t == "function" ? t(this) : t;
    if (n instanceof D) {
      const r = n.getScopeData();
      this._tags = { ...this._tags, ...r.tags }, this._extra = { ...this._extra, ...r.extra }, this._contexts = { ...this._contexts, ...r.contexts }, r.user && Object.keys(r.user).length && (this._user = r.user), r.level && (this._level = r.level), r.fingerprint.length && (this._fingerprint = r.fingerprint), n.getRequestSession() && (this._requestSession = n.getRequestSession()), r.propagationContext && (this._propagationContext = r.propagationContext);
    } else if (ie(n)) {
      const r = t;
      this._tags = { ...this._tags, ...r.tags }, this._extra = { ...this._extra, ...r.extra }, this._contexts = { ...this._contexts, ...r.contexts }, r.user && (this._user = r.user), r.level && (this._level = r.level), r.fingerprint && (this._fingerprint = r.fingerprint), r.requestSession && (this._requestSession = r.requestSession), r.propagationContext && (this._propagationContext = r.propagationContext);
    }
    return this;
  }
  /**
   * @inheritDoc
   */
  clear() {
    return this._breadcrumbs = [], this._tags = {}, this._extra = {}, this._user = {}, this._contexts = {}, this._level = void 0, this._transactionName = void 0, this._fingerprint = void 0, this._requestSession = void 0, this._span = void 0, this._session = void 0, this._notifyScopeListeners(), this._attachments = [], this._propagationContext = Ee(), this;
  }
  /**
   * @inheritDoc
   */
  addBreadcrumb(t, n) {
    const r = typeof n == "number" ? n : an;
    if (r <= 0)
      return this;
    const s = {
      timestamp: W(),
      ...t
    }, o = this._breadcrumbs;
    return o.push(s), this._breadcrumbs = o.length > r ? o.slice(-r) : o, this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  getLastBreadcrumb() {
    return this._breadcrumbs[this._breadcrumbs.length - 1];
  }
  /**
   * @inheritDoc
   */
  clearBreadcrumbs() {
    return this._breadcrumbs = [], this._notifyScopeListeners(), this;
  }
  /**
   * @inheritDoc
   */
  addAttachment(t) {
    return this._attachments.push(t), this;
  }
  /**
   * @inheritDoc
   * @deprecated Use `getScopeData()` instead.
   */
  getAttachments() {
    return this.getScopeData().attachments;
  }
  /**
   * @inheritDoc
   */
  clearAttachments() {
    return this._attachments = [], this;
  }
  /** @inheritDoc */
  getScopeData() {
    const {
      _breadcrumbs: t,
      _attachments: n,
      _contexts: r,
      _tags: s,
      _extra: o,
      _user: i,
      _level: a,
      _fingerprint: c,
      _eventProcessors: u,
      _propagationContext: l,
      _sdkProcessingMetadata: f,
      _transactionName: g,
      _span: h
    } = this;
    return {
      breadcrumbs: t,
      attachments: n,
      contexts: r,
      tags: s,
      extra: o,
      user: i,
      level: a,
      fingerprint: c || [],
      eventProcessors: u,
      propagationContext: l,
      sdkProcessingMetadata: f,
      transactionName: g,
      span: h
    };
  }
  /**
   * Applies data from the scope to the event and runs all event processors on it.
   *
   * @param event Event
   * @param hint Object containing additional information about the original exception, for use by the event processors.
   * @hidden
   * @deprecated Use `applyScopeDataToEvent()` directly
   */
  applyToEvent(t, n = {}, r = []) {
    Ve(t, this.getScopeData());
    const s = [
      ...r,
      // eslint-disable-next-line deprecation/deprecation
      ...le(),
      ...this._eventProcessors
    ];
    return Y(s, t, n);
  }
  /**
   * Add data which will be accessible during event processing but won't get sent to Sentry
   */
  setSDKProcessingMetadata(t) {
    return this._sdkProcessingMetadata = { ...this._sdkProcessingMetadata, ...t }, this;
  }
  /**
   * @inheritDoc
   */
  setPropagationContext(t) {
    return this._propagationContext = t, this;
  }
  /**
   * @inheritDoc
   */
  getPropagationContext() {
    return this._propagationContext;
  }
  /**
   * Capture an exception for this scope.
   *
   * @param exception The exception to capture.
   * @param hint Optinal additional data to attach to the Sentry event.
   * @returns the id of the captured Sentry event.
   */
  captureException(t, n) {
    const r = n && n.event_id ? n.event_id : d();
    if (!this._client)
      return b.warn("No client configured on scope - will not capture exception!"), r;
    const s = new Error("Sentry syntheticException");
    return this._client.captureException(
      t,
      {
        originalException: t,
        syntheticException: s,
        ...n,
        event_id: r
      },
      this
    ), r;
  }
  /**
   * Capture a message for this scope.
   *
   * @param message The message to capture.
   * @param level An optional severity level to report the message with.
   * @param hint Optional additional data to attach to the Sentry event.
   * @returns the id of the captured message.
   */
  captureMessage(t, n, r) {
    const s = r && r.event_id ? r.event_id : d();
    if (!this._client)
      return b.warn("No client configured on scope - will not capture message!"), s;
    const o = new Error(t);
    return this._client.captureMessage(
      t,
      n,
      {
        originalException: t,
        syntheticException: o,
        ...r,
        event_id: s
      },
      this
    ), s;
  }
  /**
   * Captures a manually created event for this scope and sends it to Sentry.
   *
   * @param exception The event to capture.
   * @param hint Optional additional data to attach to the Sentry event.
   * @returns the id of the captured event.
   */
  captureEvent(t, n) {
    const r = n && n.event_id ? n.event_id : d();
    return this._client ? (this._client.captureEvent(t, { ...n, event_id: r }, this), r) : (b.warn("No client configured on scope - will not capture event!"), r);
  }
  /**
   * This will be called on every set call.
   */
  _notifyScopeListeners() {
    this._notifyingListeners || (this._notifyingListeners = !0, this._scopeListeners.forEach((t) => {
      t(this);
    }), this._notifyingListeners = !1);
  }
}
function cn() {
  return Z || (Z = new D()), Z;
}
function Ee() {
  return {
    traceId: d(),
    spanId: d().substring(16)
  };
}
const un = "7.107.0", Xe = parseFloat(un), ln = 100;
class Ke {
  /** Is a {@link Layer}[] containing the client and scope */
  /** Contains the last event id of a captured event.  */
  /**
   * Creates a new instance of the hub, will push one {@link Layer} into the
   * internal stack on creation.
   *
   * @param client bound to the hub.
   * @param scope bound to the hub.
   * @param version number, higher number means higher priority.
   *
   * @deprecated Instantiation of Hub objects is deprecated and the constructor will be removed in version 8 of the SDK.
   *
   * If you are currently using the Hub for multi-client use like so:
   *
   * ```
   * // OLD
   * const hub = new Hub();
   * hub.bindClient(client);
   * makeMain(hub)
   * ```
   *
   * instead initialize the client as follows:
   *
   * ```
   * // NEW
   * Sentry.withIsolationScope(() => {
   *    Sentry.setCurrentClient(client);
   *    client.init();
   * });
   * ```
   *
   * If you are using the Hub to capture events like so:
   *
   * ```
   * // OLD
   * const client = new Client();
   * const hub = new Hub(client);
   * hub.captureException()
   * ```
   *
   * instead capture isolated events as follows:
   *
   * ```
   * // NEW
   * const client = new Client();
   * const scope = new Scope();
   * scope.setClient(client);
   * scope.captureException();
   * ```
   */
  constructor(t, n, r, s = Xe) {
    this._version = s;
    let o;
    n ? o = n : (o = new D(), o.setClient(t));
    let i;
    r ? i = r : (i = new D(), i.setClient(t)), this._stack = [{ scope: o }], t && this.bindClient(t), this._isolationScope = i;
  }
  /**
   * Checks if this hub's version is older than the given version.
   *
   * @param version A version number to compare to.
   * @return True if the given version is newer; otherwise false.
   *
   * @deprecated This will be removed in v8.
   */
  isOlderThan(t) {
    return this._version < t;
  }
  /**
   * This binds the given client to the current scope.
   * @param client An SDK client (client) instance.
   *
   * @deprecated Use `initAndBind()` directly, or `setCurrentClient()` and/or `client.init()` instead.
   */
  bindClient(t) {
    const n = this.getStackTop();
    n.client = t, n.scope.setClient(t), t && t.setupIntegrations && t.setupIntegrations();
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `withScope` instead.
   */
  pushScope() {
    const t = this.getScope().clone();
    return this.getStack().push({
      // eslint-disable-next-line deprecation/deprecation
      client: this.getClient(),
      scope: t
    }), t;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `withScope` instead.
   */
  popScope() {
    return this.getStack().length <= 1 ? !1 : !!this.getStack().pop();
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.withScope()` instead.
   */
  withScope(t) {
    const n = this.pushScope();
    let r;
    try {
      r = t(n);
    } catch (s) {
      throw this.popScope(), s;
    }
    return ae(r) ? r.then(
      (s) => (this.popScope(), s),
      (s) => {
        throw this.popScope(), s;
      }
    ) : (this.popScope(), r);
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.getClient()` instead.
   */
  getClient() {
    return this.getStackTop().client;
  }
  /**
   * Returns the scope of the top stack.
   *
   * @deprecated Use `Sentry.getCurrentScope()` instead.
   */
  getScope() {
    return this.getStackTop().scope;
  }
  /**
   * @deprecated Use `Sentry.getIsolationScope()` instead.
   */
  getIsolationScope() {
    return this._isolationScope;
  }
  /**
   * Returns the scope stack for domains or the process.
   * @deprecated This will be removed in v8.
   */
  getStack() {
    return this._stack;
  }
  /**
   * Returns the topmost scope layer in the order domain > local > process.
   * @deprecated This will be removed in v8.
   */
  getStackTop() {
    return this._stack[this._stack.length - 1];
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.captureException()` instead.
   */
  captureException(t, n) {
    const r = this._lastEventId = n && n.event_id ? n.event_id : d(), s = new Error("Sentry syntheticException");
    return this.getScope().captureException(t, {
      originalException: t,
      syntheticException: s,
      ...n,
      event_id: r
    }), r;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use  `Sentry.captureMessage()` instead.
   */
  captureMessage(t, n, r) {
    const s = this._lastEventId = r && r.event_id ? r.event_id : d(), o = new Error(t);
    return this.getScope().captureMessage(t, n, {
      originalException: t,
      syntheticException: o,
      ...r,
      event_id: s
    }), s;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.captureEvent()` instead.
   */
  captureEvent(t, n) {
    const r = n && n.event_id ? n.event_id : d();
    return t.type || (this._lastEventId = r), this.getScope().captureEvent(t, { ...n, event_id: r }), r;
  }
  /**
   * @inheritDoc
   *
   * @deprecated This will be removed in v8.
   */
  lastEventId() {
    return this._lastEventId;
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `Sentry.addBreadcrumb()` instead.
   */
  addBreadcrumb(t, n) {
    const { scope: r, client: s } = this.getStackTop();
    if (!s) return;
    const { beforeBreadcrumb: o = null, maxBreadcrumbs: i = ln } = s.getOptions && s.getOptions() || {};
    if (i <= 0) return;
    const c = { timestamp: W(), ...t }, u = o ? Pe(() => o(c, n)) : c;
    u !== null && (s.emit && s.emit("beforeAddBreadcrumb", u, n), r.addBreadcrumb(u, i));
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setUser()` instead.
   */
  setUser(t) {
    this.getScope().setUser(t), this.getIsolationScope().setUser(t);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setTags()` instead.
   */
  setTags(t) {
    this.getScope().setTags(t), this.getIsolationScope().setTags(t);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setExtras()` instead.
   */
  setExtras(t) {
    this.getScope().setExtras(t), this.getIsolationScope().setExtras(t);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setTag()` instead.
   */
  setTag(t, n) {
    this.getScope().setTag(t, n), this.getIsolationScope().setTag(t, n);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setExtra()` instead.
   */
  setExtra(t, n) {
    this.getScope().setExtra(t, n), this.getIsolationScope().setExtra(t, n);
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.setContext()` instead.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setContext(t, n) {
    this.getScope().setContext(t, n), this.getIsolationScope().setContext(t, n);
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use `getScope()` directly.
   */
  configureScope(t) {
    const { scope: n, client: r } = this.getStackTop();
    r && t(n);
  }
  /**
   * @inheritDoc
   */
  run(t) {
    const n = xe(this);
    try {
      t(this);
    } finally {
      xe(n);
    }
  }
  /**
   * @inheritDoc
   * @deprecated Use `Sentry.getClient().getIntegrationByName()` instead.
   */
  getIntegration(t) {
    const n = this.getClient();
    if (!n) return null;
    try {
      return n.getIntegration(t);
    } catch {
      return G && b.warn(`Cannot retrieve integration ${t.id} from the current Hub`), null;
    }
  }
  /**
   * Starts a new `Transaction` and returns it. This is the entry point to manual tracing instrumentation.
   *
   * A tree structure can be built by adding child spans to the transaction, and child spans to other spans. To start a
   * new child span within the transaction or any span, call the respective `.startChild()` method.
   *
   * Every child span must be finished before the transaction is finished, otherwise the unfinished spans are discarded.
   *
   * The transaction must be finished with a call to its `.end()` method, at which point the transaction with all its
   * finished child spans will be sent to Sentry.
   *
   * @param context Properties of the new `Transaction`.
   * @param customSamplingContext Information given to the transaction sampling function (along with context-dependent
   * default values). See {@link Options.tracesSampler}.
   *
   * @returns The transaction which was just started
   *
   * @deprecated Use `startSpan()`, `startSpanManual()` or `startInactiveSpan()` instead.
   */
  startTransaction(t, n) {
    const r = this._callExtensionMethod("startTransaction", t, n);
    return G && !r && (this.getClient() ? b.warn(`Tracing extension 'startTransaction' has not been added. Call 'addTracingExtensions' before calling 'init':
Sentry.addTracingExtensions();
Sentry.init({...});
`) : b.warn(
      "Tracing extension 'startTransaction' is missing. You should 'init' the SDK before calling 'startTransaction'"
    )), r;
  }
  /**
   * @inheritDoc
   * @deprecated Use `spanToTraceHeader()` instead.
   */
  traceHeaders() {
    return this._callExtensionMethod("traceHeaders");
  }
  /**
   * @inheritDoc
   *
   * @deprecated Use top level `captureSession` instead.
   */
  captureSession(t = !1) {
    if (t)
      return this.endSession();
    this._sendSessionUpdate();
  }
  /**
   * @inheritDoc
   * @deprecated Use top level `endSession` instead.
   */
  endSession() {
    const n = this.getStackTop().scope, r = n.getSession();
    r && Ye(r), this._sendSessionUpdate(), n.setSession();
  }
  /**
   * @inheritDoc
   * @deprecated Use top level `startSession` instead.
   */
  startSession(t) {
    const { scope: n, client: r } = this.getStackTop(), { release: s, environment: o = V } = r && r.getOptions() || {}, { userAgent: i } = _.navigator || {}, a = je({
      release: s,
      environment: o,
      user: n.getUser(),
      ...i && { userAgent: i },
      ...t
    }), c = n.getSession && n.getSession();
    return c && c.status === "ok" && $(c, { status: "exited" }), this.endSession(), n.setSession(a), a;
  }
  /**
   * Returns if default PII should be sent to Sentry and propagated in ourgoing requests
   * when Tracing is used.
   *
   * @deprecated Use top-level `getClient().getOptions().sendDefaultPii` instead. This function
   * only unnecessarily increased API surface but only wrapped accessing the option.
   */
  shouldSendDefaultPii() {
    const t = this.getClient(), n = t && t.getOptions();
    return !!(n && n.sendDefaultPii);
  }
  /**
   * Sends the current Session on the scope
   */
  _sendSessionUpdate() {
    const { scope: t, client: n } = this.getStackTop(), r = t.getSession();
    r && n && n.captureSession && n.captureSession(r);
  }
  /**
   * Calls global extension method and binding current instance to the function call
   */
  // @ts-expect-error Function lacks ending return statement and return type does not include 'undefined'. ts(2366)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _callExtensionMethod(t, ...n) {
    const s = K().__SENTRY__;
    if (s && s.extensions && typeof s.extensions[t] == "function")
      return s.extensions[t].apply(this, n);
    G && b.warn(`Extension method ${t} couldn't be found, doing nothing.`);
  }
}
function K() {
  return _.__SENTRY__ = _.__SENTRY__ || {
    extensions: {},
    hub: void 0
  }, _;
}
function xe(e) {
  const t = K(), n = se(t);
  return Qe(t, e), n;
}
function w() {
  const e = K();
  if (e.__SENTRY__ && e.__SENTRY__.acs) {
    const t = e.__SENTRY__.acs.getCurrentHub();
    if (t)
      return t;
  }
  return fn(e);
}
function de() {
  return w().getIsolationScope();
}
function fn(e = K()) {
  return (!dn(e) || // eslint-disable-next-line deprecation/deprecation
  se(e).isOlderThan(Xe)) && Qe(e, new Ke()), se(e);
}
function dn(e) {
  return !!(e && e.__SENTRY__ && e.__SENTRY__.hub);
}
function se(e) {
  return Fe("hub", () => new Ke(), e);
}
function Qe(e, t) {
  if (!e) return !1;
  const n = e.__SENTRY__ = e.__SENTRY__ || {};
  return n.hub = t, !0;
}
const q = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
], ur = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
var Ne;
const M = (Ne = process.env) == null ? void 0 : Ne.REACT_APP_TENANT_ENV;
var Ae;
const lr = (Ae = process.env) == null ? void 0 : Ae.PUBLIC_URL, R = {
  isQa: M === "qa",
  isStaging: M === "staging",
  isSandbox: M === "sandbox",
  isProduction: M === "production",
  isQaOrStaging: [
    "qa",
    "staging"
  ].includes(M),
  isStagingOrSandbox: [
    "staging",
    "sandbox"
  ].includes(M)
}, gn = [
  [
    100,
    0,
    0
  ],
  [
    50,
    50,
    0
  ],
  [
    33,
    33,
    34
  ]
], fr = (e) => e ? `${e[0].toUpperCase()}${e.slice(1).toLowerCase()}` : "", B = (e) => {
  const t = -e.getTimezoneOffset(), n = t >= 0 ? "+" : "-", r = (s) => (s < 10 ? "0" : "") + s;
  return e.getFullYear() + "-" + r(e.getMonth() + 1) + "-" + r(e.getDate()) + "T" + r(e.getHours()) + ":" + r(e.getMinutes()) + ":" + r(e.getSeconds()) + n + r(Math.floor(Math.abs(t) / 60)) + ":" + r(Math.abs(t) % 60);
}, hn = (e, t) => {
  if (e instanceof Date)
    switch (t) {
      case "yyyy-mm-dd":
        return Object.prototype.toString.call(e) === "[object Date]" ? B(e).split("T")[0] : B(/* @__PURE__ */ new Date()).split("T")[0];
      case "dd-MMM-yyyy":
        if (Object.prototype.toString.call(e) === "[object Date]") {
          const n = new Intl.DateTimeFormat("en", {
            year: "numeric"
          }).format(e), r = new Intl.DateTimeFormat("en", {
            month: "short"
          }).format(e);
          return `${new Intl.DateTimeFormat("en", {
            day: "2-digit"
          }).format(e)}-${r}-${n}`;
        } else {
          const n = /* @__PURE__ */ new Date(), r = new Intl.DateTimeFormat("en", {
            year: "numeric"
          }).format(n), s = new Intl.DateTimeFormat("en", {
            month: "short"
          }).format(n);
          return `${new Intl.DateTimeFormat("en", {
            day: "2-digit"
          }).format(n)}-${s}-${r}`;
        }
      case "dd-MM-yyyy":
        return e ? Object.prototype.toString.call(e) === "[object Date]" ? e.toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata"
        }).replaceAll("/", "-") : new Date(e).toLocaleDateString("indian", {
          timeZone: "Asia/Kolkata"
        }).replaceAll("/", "-") : void 0;
      default:
        return Object.prototype.toString.call(e) === "[object Date]" ? B(e).split("T")[0] : B(/* @__PURE__ */ new Date()).split("T")[0];
    }
  else
    return e;
}, dr = (e, t) => {
  var n;
  if (e)
    return t ? (n = e.find((r) => r.ansi_code === t)) == null ? void 0 : n.name : void 0;
}, gr = (e) => typeof e == "object" && !Array.isArray(e) && e !== null ? Object.keys(e).length === 0 : !0;
function H(e = "") {
  if (!e || e === void 0)
    return;
  const t = e.split(" ");
  return t.length ? t.filter((s) => s).map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(" ") : e.charAt(0).toUpperCase() + e.substring(1).toLowerCase();
}
const hr = (e) => new Promise((t) => setTimeout(t, e)), Ze = [
  "Dhfl",
  "Lic",
  "Icici",
  "Sbi",
  "Boi",
  "Axa",
  "Elss",
  "Idfc",
  "Hdfc",
  "L&t",
  "Dsp",
  "Uti"
], Te = (e, t = 0) => {
  const n = e;
  return e ? n.toLocaleString("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: t,
    style: "currency",
    currency: "INR"
  }).split("₹")[1] : 0;
}, pr = (e) => {
  if (e) {
    e = e.replace("-", ""), e = e.split(" ").map((t) => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()).join(" ");
    for (const t of Ze)
      e = e.replace(t, t.toUpperCase());
    e = e.trim();
  }
  return e;
}, _r = (e) => (e && (e = e.split("_").map((t) => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase()).join(" "), e = e.replace("Div", "Dividend"), e = e.trim()), e), pn = (e) => !(Array.isArray(e) && e.length), mr = (e) => e, yr = (e) => {
  let t, n;
  return (r) => (r !== t && (t = r, n = e(r)), n);
}, br = () => {
  const e = new Uint32Array(8);
  window.crypto.getRandomValues(e);
  let t = "";
  for (let n = 0; n < e.length; n++)
    t += (n < 2 || n > 5 ? "" : "-") + e[n].toString(16).slice(-4);
  return t;
}, Sr = (e) => {
  const n = e.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"), r = decodeURIComponent(window.atob(n).split("").map((s) => "%" + ("00" + s.charCodeAt(0).toString(16)).slice(-2)).join(""));
  return JSON.parse(r);
};
function Dr(e, t) {
  if (!e || !t) return 0;
  const n = (e.toString().split(".")[1] || "").length, r = (t.toString().split(".")[1] || "").length, s = n > r ? n : r, o = parseInt(parseFloat(e).toFixed(s).replace(".", "")), i = parseInt(parseFloat(t).toFixed(s).replace(".", ""));
  return o % i / Math.pow(10, s);
}
const _n = (e) => {
  const t = Number(e);
  return t ? t.toLocaleString("en-IN", {
    // maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: "currency",
    currency: "INR"
  }).split("₹")[1] : "";
}, mn = (e) => e.replace(/,/g, ""), wr = (e) => _n(Number(mn(e))), Er = (e) => {
  const t = [];
  return e.forEach((n) => {
    switch (n) {
      case "netbanking":
        t.push({
          option: "Netbanking",
          icon: "Netbanking",
          value: "netbanking",
          label: "Netbanking"
        });
        break;
      case "upi":
        t.push({
          option: "UPI",
          icon: "Upi",
          value: "upi",
          label: "UPI"
        });
        break;
      case "salary":
        t.push({
          option: "Netbanking",
          icon: "Netbanking",
          value: "salary",
          label: "Salary"
        });
        break;
      case "mandate":
        t.push({
          option: "mandate",
          icon: "Emandate",
          value: "mandate",
          label: "Mandate"
        });
        break;
    }
  }), t;
}, v = (e) => {
  if (e === null) return "-";
  if (e > 3 && e < 21) return `${e}th`;
  switch (e % 10) {
    case 1:
      return `${e}st`;
    case 2:
      return `${e}nd`;
    case 3:
      return `${e}rd`;
    default:
      return `${e}th`;
  }
}, xr = (e, t, n = "Select start date") => {
  const r = /* @__PURE__ */ new Date(), s = r.getFullYear(), o = r.getMonth(), i = new Date(s, o, e);
  if (e !== null) {
    const a = new Date(i), c = a.getDate(), u = a.toLocaleString("default", {
      month: "long"
    }), l = a.toLocaleString("en-us", {
      weekday: "long"
    }), f = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    return t === "daily" ? v(c) + " onwards" : t === "monthly" ? v(c) + " of every month" : t === "quarterly" || t === "yearly" || t === "half-yearly" ? v(c) + ` of the ${u}` : t === "day_in_a_week" ? `Every ${v(f.indexOf(l))} day of week` : t === "day_in_a_fortnight" ? `Every alternate ${v(f.indexOf(l))} day of week` : `${v(c)} of the ${u}`;
  } else
    return "";
}, Tr = (e, t, n) => {
  if (pn(t))
    return !1;
  if (n === "day_in_a_week" || n === "day_in_a_fortnight") {
    const r = new Date(e).toLocaleString("en-us", {
      weekday: "long"
    });
    return r === "Saturday" || r === "Sunday";
  } else return !t.includes(e.getDate());
}, Ir = (e, t, n) => {
  const r = n == null ? void 0 : n.isNotArrayOfObjects, s = (n == null ? void 0 : n.customProperty) || "value";
  return e.findIndex((o) => {
    if (r)
      return o === t;
    if (s.includes(".")) {
      const i = s.split(".");
      let a;
      for (const c of i)
        a ? a = a[c] : a = o[c];
      return a === t;
    }
    return o[s] === t;
  });
}, Mr = (e, t) => t.getDate() === e.getDate(), vr = (e, t = "systematic") => {
  let n = [];
  return t === "nonSystematic" && (e = {
    daily: {},
    monthly: {},
    quarterly: {},
    day_in_a_fortnight: {},
    day_in_a_week: {},
    four_times_a_month: {},
    twice_a_month: {},
    "half-yearly": {},
    yearly: {}
  }), Object.keys(e).forEach((r) => {
    switch (r) {
      case "monthly":
        n.push({
          label: "Monthly",
          value: "monthly",
          id: 0
        });
        break;
      case "quarterly":
        n.push({
          label: "Quarterly",
          value: "quarterly",
          id: 6
        });
        break;
      case "daily":
        n.push({
          label: "Daily",
          value: "daily",
          id: 1
        });
        break;
      case "day_in_a_fortnight":
        n.push({
          label: "Day in a fortnight",
          value: "day_in_a_fortnight",
          id: 3
        });
        break;
      case "day_in_a_week":
        n.push({
          label: "Day in a week",
          value: "day_in_a_week",
          id: 2
        });
        break;
      case "four_times_a_month":
        n.push({
          label: "Four times a month",
          value: "four_times_a_month",
          id: 5
        });
        break;
      case "twice_a_month":
        n.push({
          label: "Twice a month",
          value: "twice_a_month",
          id: 4
        });
        break;
      case "half-yearly":
        n.push({
          label: "Half yearly",
          value: "half-yearly",
          id: 7
        });
        break;
      case "yearly":
        n.push({
          label: "Yearly",
          value: "yearly",
          id: 8
        });
        break;
    }
  }), n = n.sort((r, s) => r.id - s.id), n;
}, qe = {
  four_times_a_month: 0.25,
  twice_a_month: 0.5,
  day_in_a_week: 0.2,
  day_in_a_fortnight: 0.4,
  monthly: 1,
  quarterly: 3,
  "half-yearly": 6,
  yearly: 12
}, Or = (e) => {
  if (!e)
    return "";
  const t = e.lastIndexOf("@");
  if (t !== -1) {
    const n = e == null ? void 0 : e.substring(0, t), r = e == null ? void 0 : e.substring(t + 1);
    return `${n[0] + "*".repeat((n == null ? void 0 : n.length) - 1)}@${r}`;
  }
  return e;
}, Nr = (e) => {
  if (!e)
    return "";
  const n = "*".repeat(5), r = String(e).slice(-4);
  return `${n}${r}`;
}, Ar = (e) => {
  if (e) {
    const t = document.querySelector(`[data-name=${e}]`);
    t && t.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
  }
}, Cr = (e) => {
  if (Number(e) || Number(e) === 0) {
    let t = Math.floor(e / 60), n = e % 60;
    return t = t < 10 ? `0${t}` : t, n = n < 10 ? `0${n}` : n, `${t}:${n}`;
  }
}, kr = (e) => new RegExp(`[^${e}]`, "g"), Fr = (e) => {
  const t = [
    ...Ze
  ];
  if (e) {
    const n = t.find((r) => e.toLowerCase().includes(r.toLowerCase()));
    return H(n ? e.replace(n, n.toUpperCase()) : e);
  }
  return "";
}, yn = (e, t, n) => !e || !t || !n ? !1 : new Date(e) <= /* @__PURE__ */ new Date() && /* @__PURE__ */ new Date() <= new Date(n), J = (e, t, n) => {
  const r = new Date(t.getFullYear(), t.getMonth() + 1, t.getDate()), s = new Date(n.getFullYear(), n.getMonth() + 1, n.getDate());
  return r > e && s > e ? {
    nextSalaryDate: r,
    nextCutOffSalaryDate: s
  } : J(e, r, s);
}, bn = (e, t) => {
  const n = new Date(e), r = n.getDate(), s = new Date(n.getFullYear(), n.getMonth() + 1, 0).getDate(), o = r < t ? t : t + s - r;
  return n.setDate(o), n;
}, Ie = (e, t) => (e == null ? void 0 : e.getDate()) === (t == null ? void 0 : t.getDate()) ? !1 : bn(e, t.getDate()).getMonth() !== t.getMonth(), Pr = ({ salaryDay: e, salaryCutoffDay: t, purchaseDate: n }) => {
  const r = new Date((/* @__PURE__ */ new Date()).setHours(0, 0, 0, 0)), s = n ? new Date(new Date(n).setHours(0, 0, 0, 0)) : r, o = new Date(s.getFullYear(), s.getMonth(), Number(e)), i = new Date(o.getFullYear(), Number(e) < Number(t) ? o.getMonth() - 1 : o.getMonth(), Number(t));
  if (s >= i) {
    const { nextSalaryDate: a, nextCutOffSalaryDate: c } = J(s, o, i);
    return {
      orderDate: s,
      scheduled_on: c,
      consideredSalaryDate: a,
      consideredCutOffSalaryDate: c
    };
  } else
    return {
      orderDate: s,
      scheduled_on: i,
      consideredSalaryDate: o,
      consideredCutOffSalaryDate: i
    };
}, Rr = ({ salaryDay: e, salaryCutoffDay: t, NFO: n, purchaseDate: r }) => {
  const s = new Date((/* @__PURE__ */ new Date()).setHours(0, 0, 0, 0)), o = r ? new Date(new Date(r).setHours(0, 0, 0, 0)) : s, i = new Date(o.getFullYear(), o.getMonth(), Number(e)), a = new Date(i.getFullYear(), Number(e) < Number(t) ? i.getMonth() - 1 : i.getMonth(), Number(t));
  if (yn(n == null ? void 0 : n.start, n == null ? void 0 : n.close, n == null ? void 0 : n.reopen)) {
    const { nextSalaryDate: c, nextCutOffSalaryDate: u } = J(new Date(n == null ? void 0 : n.reopen), i, a), l = u, f = Ie(o, l) ? new Date(l.getFullYear(), l.getMonth(), l.getDate() - 2) : null;
    return {
      orderDate: o,
      installment_date: l,
      activate_after: f,
      consideredSalaryDate: c,
      consideredCutOffSalaryDate: u
    };
  } else if (o >= a) {
    const { nextSalaryDate: c, nextCutOffSalaryDate: u } = J(o, i, a);
    return {
      orderDate: o,
      installment_date: u,
      activate_after: null,
      consideredSalaryDate: c,
      consideredCutOffSalaryDate: u
    };
  } else {
    const c = i, u = Ie(o, c) ? new Date(c.getFullYear(), c.getMonth(), c.getDate() - 2) : null;
    return {
      orderDate: o,
      installment_date: c,
      activate_after: u,
      consideredSalaryDate: i,
      consideredCutOffSalaryDate: a
    };
  }
}, Lr = (e) => (e == null ? void 0 : e.requested_activation_date) !== null && // new Date() < new Date(mfpp?.requested_activation_date) &&
(e == null ? void 0 : e.activated_at) === null && (e == null ? void 0 : e.state) === "created", $r = (e) => {
  switch (e.split("_")[0]) {
    default:
      return null;
    case "mfp":
      return "mf_purchase";
    case "mfr":
      return "mf_redemption";
    case "mfs":
      return "mf_switch";
    case "mfpp":
      return "mf_purchase_plan";
    case "mfrp":
      return "mf_redemption_plan";
    case "mfsp":
      return "mf_switch_plan";
    case "invp":
      return "investor_profile";
  }
}, Ur = (e) => {
  switch (e == null ? void 0 : e.split("_")[0]) {
    default:
      return null;
    case "mfp":
      return "Purchase";
    case "mfr":
      return "Withdraw";
    case "mfs":
      return "Switch";
    case "mfpp":
      return "Purchase plan";
    case "mfrp":
      return "Withdraw plan";
    case "mfsp":
      return "Switch plan";
  }
}, Br = (e) => {
  switch (e == null ? void 0 : e.split("_")[0]) {
    default:
      return null;
    case "mfp":
      return "Purchase";
    case "mfr":
      return "Withdrawal";
    case "mfs":
      return "Switch";
    case "mfpp":
      return "Purchase";
    case "mfrp":
      return "Withdrawal";
    case "mfsp":
      return "Switch";
  }
}, Gr = (e, t, n) => {
  switch (e) {
    case "pending":
      return t === "mf_purchase" ? "Awaiting payment" : "Under process";
    case "cancelled":
      return "Cancelled";
    case "confirmed":
      return "Under process";
    case "submitted":
      return "Under process";
    case "successful":
      return "Successful";
    case "failed":
      return t === "mf_purchase" && n === "payment_failure" ? "Payment failed" : "Failed";
    case "reversed":
      return "Reversed";
    default:
      return "-";
  }
}, jr = (e, t) => new Date(e).getDate() === new Date(t).getDate() && new Date(e).getMonth() === new Date(t).getMonth() && new Date(e).getFullYear() === new Date(t).getFullYear() ? "isEqual" : new Date(e).getTime() > new Date(t).getTime() ? "isAfter" : new Date(e).getTime() < new Date(t).getTime() ? "isBefore" : "", oe = (e, t) => {
  const n = Number(new Date(e)) - Number(new Date(t));
  return Math.ceil(n / (1e3 * 60 * 60 * 24));
}, Sn = (e, t) => {
  let n = (e.getTime() - t.getTime()) / 1e3;
  return n /= 60 * 60 * 24 * 7, Math.round(n);
}, Yr = (e, t) => t.getMonth() - e.getMonth() - 1 + 12 * (t.getFullYear() - e.getFullYear()), Hr = (e, t, n) => {
  const r = /* @__PURE__ */ new Date();
  if (e === null && (e = new Date(r.setDate(r.getDate() + 1))), t === "daily")
    return new Date(new Date(e).setDate(e.getDate() - 1 + n));
  if (t === "four_times_a_month" || t === "twice_a_month" || t === "day_in_a_fortnight" || t === "day_in_a_week") {
    let s = 1;
    if (t === "four_times_a_month" || t === "day_in_a_week" ? s = 7 : (t === "twice_a_month" || t === "day_in_a_fortnight") && (s = 14), e.getDate() === 28 && (t === "twice_a_month" || t === "four_times_a_month")) {
      const o = new Date(e.getFullYear(), e.getMonth() + 1, 1);
      return new Date(new Date(o).setDate(o.getDate() + (n - 1) * s));
    } else
      return new Date(new Date(e).setDate(e.getDate() + n * s));
  } else if (t === "monthly" || t === "quarterly" || t === "half-yearly" || t === "yearly")
    return new Date(new Date(e).setMonth(e.getMonth() - 1 + qe[t] * n));
  return /* @__PURE__ */ new Date();
}, Jr = (e) => e === "pending" ? "warning" : e === "confirmed" || e === "successful" || e === "submitted" ? "success" : e === "failed" || e === "reversed" || e === "cancelled" ? "error" : "success", zr = (e, t) => {
  const n = e == null ? void 0 : e.data;
  let r = null;
  return t && (r = n.find((s) => !t[s == null ? void 0 : s.email.split("@")[1]] && (s == null ? void 0 : s.belongs_to) === "self")), r || (n == null ? void 0 : n[0]);
}, Wr = (e, t, n, r, s) => {
  let o = (t.getFullYear() - e.getFullYear()) * 12;
  if (o = o - e.getMonth(), o = o + t.getMonth(), n === "daily")
    return oe(t, e) + 1;
  if (n === "four_times_a_month" || n === "twice_a_month") {
    let i = t.getMonth() - e.getMonth();
    i -= 1, i = n === "four_times_a_month" ? i * 4 : i * 2;
    let a = 0, c = 0;
    if (i <= 1 && (i = 0), s === "nonSystematic") {
      let u = [
        e.getDate()
      ];
      const l = r.concat(r);
      if (n === "twice_a_month") {
        const f = l.indexOf(u[0]) + 14;
        u.push(l[f]), e.getDate() === 28 && (u = [
          28,
          1
        ]);
      } else if (n === "four_times_a_month") {
        for (let f = 0; f < 3; f++) {
          const g = l.indexOf(u[f]) + 7;
          u.push(l[g]);
        }
        e.getDate() === 28 && (u = [
          28,
          7,
          14,
          21
        ]);
      }
      return new Date(e).getMonth() <= new Date(t).getMonth() && (u.forEach((f) => {
        f >= new Date(e).getDate() && (a = //case1: same month, check start month's date and end month's date
        new Date(t).getMonth() === new Date(e).getMonth() && f < new Date(t).getDate() || new Date(t).getMonth() > new Date(e).getMonth() ? a + 1 : a);
      }), n === "four_times_a_month" ? a = a > 4 ? 4 : a : a = a > 2 ? 2 : a, new Date(e).getMonth() < new Date(t).getMonth() && (u.forEach((f) => {
        f <= new Date(t).getDate() && c++;
      }), n === "four_times_a_month" ? c = c > 4 ? 4 : c : c = c > 2 ? 2 : c)), a + i + c;
    } else
      return new Date(e).getMonth() <= new Date(t).getMonth() && (r.forEach((u) => {
        u >= new Date(e).getDate() && (a = //case1: same month, check start month's date and end month's date
        new Date(t).getMonth() === new Date(e).getMonth() && u < new Date(t).getDate() || new Date(t).getMonth() > new Date(e).getMonth() ? a + 1 : a);
      }), n === "four_times_a_month" ? a = a > 4 ? 4 : a : a = a > 2 ? 2 : a, new Date(e).getMonth() < new Date(t).getMonth() && (r.forEach((u) => {
        u <= new Date(t).getDate() && c++;
      }), n === "four_times_a_month" ? c = c > 4 ? 4 : c : c = c > 2 ? 2 : c)), a + i + c;
  } else if (n === "day_in_a_week" || n === "day_in_a_fortnight") {
    const i = Sn(new Date(t), new Date(e));
    return n === "day_in_a_week" ? i : Math.floor(i / 2);
  } else
    return Math.round(o / qe[n]);
};
function Dn(e, t) {
  let n = 0, r = e.length - 1, s = -1;
  for (; n <= r; ) {
    const o = Math.round((n + r) / 2);
    e[o] <= t ? n = o + 1 : (s = o, r = o - 1);
  }
  return s;
}
const Vr = (e, t) => {
  if (t === "day_in_a_week" || t === "day_in_a_fortnight") {
    const n = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ], r = new Date(e).toLocaleString("en-us", {
      weekday: "long"
    });
    return n.indexOf(r);
  } else
    return e.getDate();
}, Xr = (e) => {
  const t = /* @__PURE__ */ new Date();
  return e === "daily" ? new Date(t.getFullYear() + 3, t.getMonth(), t.getDate()) : e === "day_in_a_week" ? new Date(t.getFullYear() + 15, t.getMonth(), t.getDate()) : e === "day_in_a_fortnight" || e === "twice_a_month" ? new Date(t.getFullYear() + 45, t.getMonth(), t.getDate()) : e === "four_times_a_month" ? new Date(t.getFullYear() + 22, t.getMonth(), t.getDate()) : e === "yearly" ? new Date(2500, 11, 31) : new Date(2099, 11, 31);
}, Kr = (e, t) => {
  const n = /* @__PURE__ */ new Date();
  if (e === "daily")
    return new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 2));
  if (e === "day_in_a_week" || e === "day_in_a_fortnight") {
    let r = new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 1));
    return r.toLocaleString("en-us", {
      weekday: "long"
    }) === "Sunday" ? r = new Date(r.setDate(r.getDate() + 1)) : r.toLocaleString("en-us", {
      weekday: "long"
    }) === "Saturday" && (r = new Date(r.setDate(r.getDate() + 2))), r;
  } else {
    if (t.includes(new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 1))))
      return new Date((/* @__PURE__ */ new Date()).setDate(n.getDate() + 1));
    {
      const r = Dn(t, n.getDate());
      return r !== -1 ? new Date((/* @__PURE__ */ new Date()).getFullYear(), (/* @__PURE__ */ new Date()).getMonth(), t[r]) : new Date((/* @__PURE__ */ new Date()).getFullYear(), (/* @__PURE__ */ new Date()).getMonth() + 1, t[0]);
    }
  }
}, Qr = (e) => {
  const t = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28
  ];
  switch (e) {
    case "monthly":
      return t;
    case "day_in_a_week":
      return [
        1,
        2,
        3,
        4,
        5
      ];
    case "daily":
      return [];
    case "quarterly":
      return t;
    case "half-yearly":
      return t;
    case "yearly":
      return t;
    case "day_in_a_fortnight":
      return [
        1,
        2,
        3,
        4,
        5
      ];
    case "twice_a_month":
      return t;
    case "four_times_a_month":
      return t;
    default:
      return t;
  }
}, Zr = (e) => e === null ? {
  minInvestmentAmount: "min_initial_investment",
  maxInvestmentAmount: "max_initial_investment",
  investmentAmountMultiples: "initial_investment_multiples"
} : {
  minInvestmentAmount: "min_additional_investment",
  maxInvestmentAmount: "max_additional_investment",
  investmentAmountMultiples: "additional_investment_multiples"
}, qr = (e, t) => {
  const r = /* @__PURE__ */ new Date();
  if (r.setDate(r.getDate() + 1), e === null || t === "daily" && (e == null ? void 0 : e.toDateString()) === (r == null ? void 0 : r.toDateString()))
    return null;
  if (oe(e, /* @__PURE__ */ new Date()) > 30 || t === "daily" && t !== "day_in_a_week" && t !== "day_in_a_fortnight") {
    const s = new Date(e).setDate(e.getDate() - 1);
    return new Date(s);
  } else if ((t === "day_in_a_week" || t === "day_in_a_fortnight") && oe(e, /* @__PURE__ */ new Date()) >= 8) {
    const s = new Date(e).setDate(e.getDate() - 1);
    return new Date(s);
  }
  return null;
}, E = (e) => {
  if (!e || typeof e != "string")
    return;
  const t = e.split(" "), n = t.length > 1, r = t[0];
  return n ? r : e;
}, wn = (e) => {
  if (!(!e || typeof e != "string"))
    return "****";
}, O = (e) => {
  if (!(!e || typeof e != "string"))
    return "****";
}, N = (e) => {
  if (!e || typeof e != "string")
    return;
  const t = e.slice(0, 2), n = e.slice(-2);
  return `${t}*****${n}`;
}, ee = (e) => !e || typeof e != "string" ? void 0 : `****${e.slice(-4)}`, te = (e) => {
  if (!e || typeof e != "string")
    return;
  const t = e.split("@"), n = t[0], r = t[1];
  let o = n.slice(0, 2);
  if (n.length >= 4) {
    const i = n.slice(-2);
    o += `******${i}`;
  } else if (n.length === 3) {
    const i = n.slice(-1);
    o += `******${i}`;
  } else
    o += "******";
  return `${o}@${r}`;
}, F = (e) => !e || typeof e != "string" ? void 0 : `****${e.slice(-4)}`, p = (e) => {
  if (!(!e || typeof e != "string"))
    return "*******";
}, et = (e) => {
  const t = {
    data: 1,
    status: 1,
    statusText: 1,
    headers: 1,
    config: 1
  };
  for (const n in t)
    if (!Object.prototype.hasOwnProperty.call(e, n))
      return !1;
  return !0;
}, tt = (e) => {
  const t = {
    name: E,
    date_of_birth: O,
    pan: N,
    account_number: ee,
    email: te,
    number: F,
    line1: p,
    line2: p,
    line3: p,
    city: p,
    state: p,
    postal_code: p,
    code: p,
    country: p,
    lastName: wn,
    mobile_number: F,
    father_name: E,
    spouse_name: E,
    mother_name: E,
    country_of_birth: p,
    place_of_birth: p,
    line_1: p,
    line_2: p,
    line_3: p,
    pincode: p,
    primary_investor_pan: N,
    second_investor_pan: N,
    third_investor_pan: N,
    bank_account_number: ee,
    beneficiary_account_number: ee,
    mobile: F,
    primary_investor_name: E,
    secondary_investor_name: E,
    third_investor_name: E,
    primary_investor_dob: O,
    secondary_investor_dob: O,
    third_investor_dob: O,
    guardian_name: E,
    guardian_pan: N,
    guardian_dob: O,
    dob: O,
    email_addresses: te,
    mobile_numbers: F,
    email_address: te,
    phone_number: F,
    username: N
  }, n = {};
  if (!e)
    return n;
  for (const [r, s] of Object.entries(e))
    s && Array.isArray(s) && r in t ? n[r] = s.map((o) => t[r](o)) : s && typeof s == "object" ? n[r] = tt(s) : r in t ? n[r] = t[r](s) : n[r] = s;
  return n;
}, Me = (e, t) => {
  R.isQaOrStaging || Zt(e, {
    contexts: {
      support_data: t
    }
  });
}, En = (e) => {
  R.isQaOrStaging || (et(e) ? Me(e == null ? void 0 : e.data, xn(e)) : Me(e));
}, xn = (e) => {
  const t = e.config, { Authorization: n, ...r } = t.headers;
  let s;
  try {
    s = JSON.parse(t.data);
  } catch (i) {
    En(i);
  }
  return {
    data: t.data ? tt(s) : null,
    params: t.params,
    headers: r
  };
}, es = (e) => {
  if (e)
    return Math.abs(Number(/* @__PURE__ */ new Date()) - Number(new Date(e))) > 24 * 60 * 60 * 1e3;
}, ts = (e) => {
  if (e)
    return Number(/* @__PURE__ */ new Date()) > Number(new Date(e));
}, ns = (e) => {
  const t = e.length;
  if (!t)
    return [];
  const n = [];
  for (let r = 0; r < e.length; r++)
    n.push({
      id: e[r].id,
      allocationPercentage: gn[t - 1][r]
    });
  return n;
};
function rs([e, t], n, { changeValue: r }) {
  r(n, e, () => t);
}
function Tn(e, t) {
  var s, o, i;
  let n = {};
  const r = [
    "ipv_video"
  ];
  if (((s = t == null ? void 0 : t.verification) == null ? void 0 : s.status) === "rejected" && ((o = t == null ? void 0 : t.verification) != null && o.details))
    for (const a in (i = t == null ? void 0 : t.verification) == null ? void 0 : i.details)
      a.includes(".") ? r.push(a.split(".")[1]) : r.push(a);
  for (const a in t)
    typeof t[a] == "object" && [
      "mobile",
      "address",
      "bank_account"
    ].includes(a) ? n = {
      ...n,
      ...Tn(e, {
        ...t[a],
        verification: t == null ? void 0 : t.verification
      })
    } : a in e && (r.includes(a) || (n[e[a].initFieldName] = e[a].initFieldValue));
  return n;
}
function ss(e) {
  var r, s, o, i, a, c;
  if (!e)
    return;
  const t = structuredClone(e), n = [
    "ipv_video",
    "geolocation",
    "object",
    "status",
    "created_at",
    "expires_at",
    "otp",
    "requirements",
    "redirect_url",
    "verification",
    "updated_at",
    "esign_required_at",
    "submitted_at",
    "successful_at",
    "rejected_at"
  ];
  if ((r = t == null ? void 0 : t.address) != null && r.proof.includes("iddoc") && !n.includes("address") && n.push("address"), (s = t == null ? void 0 : t.address) != null && s.proof_expiry_date && new Date((o = t == null ? void 0 : t.address) == null ? void 0 : o.proof_expiry_date) < /* @__PURE__ */ new Date() && !n.includes("address") && n.push("address"), ((i = t == null ? void 0 : t.verification) == null ? void 0 : i.status) === "rejected" && ((a = t == null ? void 0 : t.verification) != null && a.details))
    for (const u in (c = t == null ? void 0 : t.verification) == null ? void 0 : c.details)
      if (u.includes(".")) {
        const l = u.split(".")[0];
        !n.includes(l) && n.push(l);
      } else
        !n.includes(u) && n.push(u);
  for (const u in t)
    (n.includes(u) || !t[u]) && delete t[u];
  return t;
}
const os = (e) => e.father_name ? "father" : e.spouse_name ? "spouse" : void 0, is = (e) => e.father_name ? e.father_name : e.spouse_name ? e.spouse_name : void 0, as = (e, t) => {
  var r, s, o, i;
  let n = [];
  return e && ((r = t == null ? void 0 : t.groupInvestment) != null && r.allowedEmployerSchemes) && ((s = t == null ? void 0 : t.groupInvestment) != null && s.allowedEmployerSchemes[e]) && ((o = t == null ? void 0 : t.groupInvestment) != null && o.allowedEmployerSchemes[e].length) && (n = (i = t == null ? void 0 : t.groupInvestment) == null ? void 0 : i.allowedEmployerSchemes[e].map((a) => a.isin)), n;
}, cs = (e) => {
  const n = new URLSearchParams(e).get("employer");
  return n ? "employer=" + n : "";
}, us = (e, t) => {
  const r = [
    {
      value: 1e3,
      symbol: "K"
    },
    {
      value: 1e5,
      symbol: "L"
    },
    {
      value: 1e7,
      symbol: "Cr"
    }
  ].reverse().find((s) => e >= s.value);
  return r ? Te(e / r.value, t) + r.symbol : Te(e, t);
}, ls = (e = "", t = "", n = "") => [
  e,
  t,
  n
].filter(Boolean).join(", "), fs = (e, t) => {
  const n = e == null ? void 0 : e.find((s) => {
    const o = (s == null ? void 0 : s.email) && s.email.split("@")[1];
    return !t[o];
  });
  return (n == null ? void 0 : n.email) || "";
};
function ds(e, t) {
  var r;
  const n = [];
  for (let s = 0; s < e.length; ++s)
    ((r = e[s]) == null ? void 0 : r.value) !== t && n.push(s);
  return n;
}
function gs() {
  return window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: "smooth"
  });
}
const hs = (e) => {
  var r;
  const t = {}, n = (r = e == null ? void 0 : e.requirements) == null ? void 0 : r.fields_needed;
  return n && [
    "identity_proof",
    "address",
    "bank_account",
    "occupation_type",
    "aadhaar_number",
    "photo",
    "signature",
    "ipv_video",
    "geolocation"
  ].forEach((o) => {
    n.includes(o) || (t[o] = !0);
  }), t;
}, ps = (e, t) => {
  if (!(!e || !t || t < 1))
    return e % t === 0;
};
function _s(e, t) {
  const n = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth"
  ];
  return t === 1 ? "Nominee" : n[e] + " nominee";
}
const ms = {
  isRedirected() {
    return !!sessionStorage.getItem("isRedirected");
  },
  saveRedirectionState() {
    sessionStorage.setItem("isRedirected", "true");
  },
  removeRedirectionState() {
    sessionStorage.removeItem("isRedirected");
  }
}, ys = (e) => {
  switch (e.toLowerCase()) {
    case "netbanking":
      return "Net banking";
    case "upi":
      return "UPI";
    case "emandate":
      return "Mandate";
    default:
      return e;
  }
}, bs = (e) => {
  const t = new Date(new Date(e).getTime() + 18e5);
  return (/* @__PURE__ */ new Date()).getTime() > t.getTime();
};
function Ss(e, t) {
  const n = /* @__PURE__ */ new Date();
  return n.setMonth(n.getMonth() - t), new Date(e) < n;
}
const Ds = (e) => {
  const t = new Date(e).getFullYear();
  return Number(t) >= 2099 ? "Until you stop" : hn(new Date(e), "dd-MMM-yyyy");
}, ws = (e, t) => {
  if (!e || !(t != null && t.length))
    return;
  const n = t == null ? void 0 : t.find((r) => {
    var i, a;
    const s = r == null ? void 0 : r.email, o = (a = (i = s == null ? void 0 : s.split("@")) == null ? void 0 : i[1]) == null ? void 0 : a.toLowerCase();
    return !(e != null && e[o]);
  });
  return n == null ? void 0 : n.email;
}, Es = (e) => {
  var t, n, r, s;
  return ((n = (t = e == null ? void 0 : e.data) == null ? void 0 : t.error) == null ? void 0 : n.status) === 403 && ((s = (r = e == null ? void 0 : e.data) == null ? void 0 : r.error) == null ? void 0 : s.message) === "Kyc Request Expired";
}, xs = (e) => {
  let t = e == null ? void 0 : e.primary_investor_occupation;
  return t != null && t.includes(" ") && (t = t == null ? void 0 : t.replaceAll(" ", "_")), t == null ? void 0 : t.toLowerCase();
}, Ts = (e, t) => {
  if (!t)
    return e;
  try {
    const n = new URL(e), r = t, s = n.hostname.split(".");
    s.unshift(r);
    const o = s.join(".");
    return n.hostname = o, n.toString();
  } catch {
    return e;
  }
}, Is = (e) => e == null ? void 0 : e.includes("*"), Ms = (e, t = "Something went wrong") => {
  var n, r;
  return et(e) ? ((r = (n = e == null ? void 0 : e.data) == null ? void 0 : n.error) == null ? void 0 : r.message) ?? t : e.message ?? t;
}, vs = (e) => Object.fromEntries(Object.entries(e).filter(([t, n]) => n === 0 ? !0 : !!n)), Os = (e) => e.meta.error && e.meta.touched ? "error" : void 0, Ns = (e, t = "-") => e || t, As = (e) => {
  const t = R != null && R.isStagingOrSandbox ? ".sandbox.fpapps.io" : ".fpapps.io";
  return "https://" + e + t;
}, Cs = (e) => {
  if (navigator != null && navigator.clipboard)
    navigator.clipboard.writeText(e).then().catch((t) => console.error(t.name, t.message));
  else {
    const t = document.createElement("textarea");
    t.value = e, t.style.opacity = "0", document.body.appendChild(t), t.focus(), t.select();
    try {
      const n = document.execCommand("copy");
    } catch (n) {
      n instanceof DOMException ? console.error("DOMException:", n.name, n.message) : console.error("Unknown Error:", n);
    }
    document.body.removeChild(t);
  }
}, ks = () => {
  var n, r;
  const e = process.env.REACT_APP_X_TENANT_NAME;
  return e || ((r = (n = window == null ? void 0 : window.location) == null ? void 0 : n.host) == null ? void 0 : r.split(".")[0]);
}, Fs = {
  get() {
    return JSON.parse(sessionStorage.getItem("partnerSelectedFolio") || "{}");
  },
  set(e) {
    sessionStorage.setItem("partnerSelectedFolio", JSON.stringify(e));
  },
  remove() {
    sessionStorage.removeItem("partnerSelectedFolio");
  }
}, Ps = {
  get() {
    return JSON.parse(sessionStorage.getItem("partnerSelectedScheme") || "{}");
  },
  set(e) {
    sessionStorage.setItem("partnerSelectedScheme", JSON.stringify(e));
  },
  remove() {
    sessionStorage.removeItem("partnerSelectedScheme");
  }
}, Rs = {
  get() {
    return sessionStorage.getItem("selectedPurchaseTab");
  },
  set(e) {
    sessionStorage.setItem("selectedPurchaseTab", e);
  },
  remove() {
    sessionStorage.removeItem("selectedPurchaseTab");
  }
}, Ls = (e, t) => e === "monthly" ? t * 12 : e === "quarterly" ? t * 4 : e === "half-yearly" ? t * 2 : t, nt = (e) => {
  if (e > 3 && e < 21) return `${e}th`;
  switch (e % 10) {
    case 1:
      return `${e}st`;
    case 2:
      return `${e}nd`;
    case 3:
      return `${e}rd`;
    default:
      return `${e}th`;
  }
};
function In(e) {
  const t = new Date(e), n = t.getDate(), r = nt(n);
  return {
    dayOfWeek: t == null ? void 0 : t.toLocaleString("default", {
      weekday: "long"
    }),
    dayOfMonth: r,
    monthName: t == null ? void 0 : t.toLocaleString("default", {
      month: "long"
    })
  };
}
function ve(e, t) {
  const r = new Date(e).getDate(), s = 28, o = t === "twice_a_month" ? 14 : 7, i = t === "twice_a_month" ? 2 : 4;
  return Array.from({
    length: i
  }, (u, l) => {
    const f = r + l * o;
    return f > s ? f % s || s : f;
  }).map((u) => {
    const l = u <= s ? u : s;
    return nt(l);
  });
}
function Oe(e, t) {
  const r = new Date(e).getMonth();
  return t === 3 ? Array.from({
    length: 4
  }, (o, i) => {
    const a = (r + i * 3) % 12;
    return q[a];
  }) : t === 2 ? [
    q[r],
    q[(r + 6) % 12]
  ] : null;
}
const $s = (e, t) => {
  var o, i;
  if (!e)
    return "";
  if (!t)
    return (o = H(e)) == null ? void 0 : o.replace(/_/g, " ");
  const { dayOfMonth: n, dayOfWeek: r, monthName: s } = In(t);
  switch (e) {
    case "daily":
      return "Every working day (excludes market holidays)";
    case "day_in_a_week":
      return `Every ${r}`;
    case "four_times_a_month":
      return `${ve(t, "four_times_a_month")} of every month`;
    case "day_in_a_fortnight":
      return `Alternate ${r}`;
    case "twice_a_month":
      return `${ve(t, "twice_a_month")} of every month`;
    case "monthly":
      return `${n} of every month`;
    case "quarterly":
      return `${n} of every ${Oe(t, 3)}`;
    case "half-yearly":
      return `${n} of every ${Oe(t, 2)}`;
    case "yearly":
      return `${n} of every ${s}`;
    default:
      return (i = H(e)) == null ? void 0 : i.replace(/_/g, " ");
  }
}, Us = {
  get() {
    return JSON.parse(sessionStorage.getItem("isFOTOrder") || "false");
  },
  set(e) {
    sessionStorage.setItem("isFOTOrder", JSON.stringify(e));
  },
  remove() {
    sessionStorage.removeItem("isFOTOrder");
  }
}, Bs = (e) => {
  const t = new Date(e);
  return t.getDay() === 5 ? 3 : t.getDay() === 6 ? 2 : 1;
};
export {
  qe as $,
  Es as A,
  lr as B,
  Te as C,
  Gr as D,
  _n as E,
  Jr as F,
  bs as G,
  As as H,
  os as I,
  is as J,
  ds as K,
  ss as L,
  es as M,
  zr as N,
  us as O,
  v as P,
  Ds as Q,
  Or as R,
  Cr as S,
  gn as T,
  ys as U,
  Cs as V,
  Pr as W,
  mn as X,
  vr as Y,
  Rr as Z,
  Xr as _,
  as as a,
  qn as a$,
  ps as a0,
  Zr as a1,
  Qr as a2,
  xr as a3,
  Os as a4,
  Ls as a5,
  Rs as a6,
  Er as a7,
  ws as a8,
  Is as a9,
  _ as aA,
  ge as aB,
  Ln as aC,
  he as aD,
  Re as aE,
  d as aF,
  ft as aG,
  j as aH,
  zn as aI,
  y as aJ,
  Jn as aK,
  T as aL,
  x as aM,
  W as aN,
  w as aO,
  G as aP,
  fe as aQ,
  X as aR,
  de as aS,
  en as aT,
  He as aU,
  er as aV,
  ue as aW,
  Ut as aX,
  Zn as aY,
  We as aZ,
  L as a_,
  ls as aa,
  gs as ab,
  xs as ac,
  Dr as ad,
  Tr as ae,
  Hr as af,
  Wr as ag,
  qr as ah,
  Vr as ai,
  Kr as aj,
  cs as ak,
  Ms as al,
  vs as am,
  Ns as an,
  Fs as ao,
  Ps as ap,
  Ss as aq,
  Us as ar,
  $s as as,
  ns as at,
  z as au,
  P as av,
  Pe as aw,
  ce as ax,
  b as ay,
  Dt as az,
  Ur as b,
  hs as b$,
  Bt as b0,
  ut as b1,
  K as b2,
  Un as b3,
  Et as b4,
  Qn as b5,
  Hn as b6,
  An as b7,
  $ as b8,
  tr as b9,
  Tt as bA,
  Bn as bB,
  bt as bC,
  rr as bD,
  Cn as bE,
  nr as bF,
  Fn as bG,
  Gn as bH,
  ar as bI,
  cr as bJ,
  M as bK,
  sr as bL,
  et as bM,
  xn as bN,
  or as bO,
  hr as bP,
  br as bQ,
  wr as bR,
  Mr as bS,
  Nr as bT,
  jr as bU,
  oe as bV,
  Yr as bW,
  Sn as bX,
  Lr as bY,
  Dn as bZ,
  fs as b_,
  qt as ba,
  ae as bb,
  ie as bc,
  st as bd,
  un as be,
  jn as bf,
  kn as bg,
  $n as bh,
  Wn as bi,
  gt as bj,
  Rn as bk,
  Lt as bl,
  Xn as bm,
  Vn as bn,
  Kn as bo,
  Pn as bp,
  mt as bq,
  ir as br,
  Yn as bs,
  wt as bt,
  Zt as bu,
  vn as bv,
  On as bw,
  Nn as bx,
  rt as by,
  ot as bz,
  Bs as c,
  _s as c0,
  ur as c1,
  Ts as c2,
  In as c3,
  nt as c4,
  Br as d,
  $r as e,
  pn as f,
  ks as g,
  dr as h,
  gr as i,
  H as j,
  pr as k,
  fr as l,
  hn as m,
  _r as n,
  kr as o,
  Sr as p,
  ts as q,
  ms as r,
  Ar as s,
  R as t,
  Ir as u,
  Tn as v,
  rs as w,
  yr as x,
  Fr as y,
  mr as z
};
