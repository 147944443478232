import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as f, useState as n, useEffect as _ } from "react";
import { P as c, a as G, D as M } from "./index.esm-DT3FM8Og.js";
import { AppContext as N } from "./AppContext.js";
import { LoginContext as k } from "./LoginContext.js";
import { i as r } from "./index-Bb24DwqC.js";
import { F as I } from "./provider-DPPRdC-N.js";
import { r as V } from "./sentry-CbZOQ4Ya.js";
import { W as X } from "./Welcome-CCMlcQ8T.js";
import { O as Y } from "./OrderHistoryListing-Cx9IAJEQ.js";
import { A as q } from "./Auth-tZKY7y12.js";
function nt() {
  const { loggedInUser: e } = f(k), { fpapi: i } = f(I), [v, y] = n({}), [P, A] = n(!1), [S, a] = n(!0), [C, g] = n({}), { AppState: { showWelcomePage: m, tenant: F }, setShowWelcomePage: L } = f(N), [b, l] = n(p), J = [
    {
      step: 1,
      content: r._({
        id: "SYXp7g"
      })
    },
    {
      step: 2,
      content: r._({
        id: "EI9fU0"
      })
    }
  ];
  _(() => {
    (async () => {
      var u, d, x, h;
      if (i && e)
        try {
          a(!0);
          const o = await i.fetchUser((u = e == null ? void 0 : e.profile) == null ? void 0 : u.preferred_username, F);
          if (o != null && o[0].annexure.isonboarded && (o == null ? void 0 : o[0].annexure.isonboarded) === "false")
            return;
          const H = ((x = (d = e == null ? void 0 : e.profile) == null ? void 0 : d.preferred_username) == null ? void 0 : x.toUpperCase()) ?? "", R = i.getInvestorProfile((h = e == null ? void 0 : e.profile) == null ? void 0 : h.fp_identifier), O = i.fpClient.mf_investment_accounts().fetchAll({
            investor: H
          }), [s, T] = await Promise.all([
            R,
            O
          ]);
          s != null && s.employer && A(!0);
          const j = T.data.filter((D) => {
            var w;
            return D.primary_investor === ((w = e == null ? void 0 : e.profile) == null ? void 0 : w.fp_identifier);
          });
          if (!j.length)
            return;
          y(s), g(j[0]);
        } catch (o) {
          window.debug.error(o), V(o);
        } finally {
          a(!1);
        }
      else
        a(!1);
    })();
  }, []), _(() => {
    l(p());
  }, [
    m
  ]);
  function p() {
    return m ? "welcome" : "transactionHistory";
  }
  function W() {
    L(!1), l("transactionHistory");
  }
  function E() {
    return b === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(c, {
          title: r._({
            id: "ol5JJc"
          })
        }),
        /* @__PURE__ */ t.jsx(X, {
          onAck: W,
          welcomeSteps: J
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx("div", {
        className: "mb-20",
        children: S ? /* @__PURE__ */ t.jsx(G, {
          variant: "fullpage"
        }) : e ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(c, {
              title: r._({
                id: "ol5JJc"
              })
            }),
            /* @__PURE__ */ t.jsx(M, {
              type: "text",
              text: r._({
                id: "/iaRLN"
              })
            }),
            /* @__PURE__ */ t.jsx(Y, {
              isGroupInvestment: P,
              investorProfile: v,
              mfInvestmentAccount: C
            })
          ]
        }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(c, {
              title: r._({
                id: "+VJ95o"
              })
            }),
            /* @__PURE__ */ t.jsx(q, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              showTestPan: {
                onboarded: !0
              }
            })
          ]
        })
      })
    });
  }
  return E();
}
export {
  nt as ManageOrderHistory
};
