import { v as Y, a as Z, b as $, c as R, d as W, e as tt } from "./validators-BArvL7cB.js";
const et = (a) => {
  const { profile: s, line1: n, line2: r, line3: t, country: e, postal_code: l } = a;
  return !(!s || !n || !e || !l || Y("INVALID_POSTAL_CODE")(l));
}, at = (a) => {
  const { profile: s, isd: n, number: r, belongs_to: t } = a;
  return !(!s || !n || !r || !t || Z("INVALID_PHONE")(r));
}, nt = (a) => {
  const { profile: s, email: n, belongs_to: r } = a;
  return !(!s || !n || !r || $("INVALID_EMAIL")(n));
}, st = (a) => {
  const { profile: s, primary_account_holder_name: n, account_number: r, type: t, ifsc_code: e } = a;
  return !(!s || !n || !r || !t || !e || R("INVALID_BANK")(r) || W("INVALID_NAME")(n));
}, rt = (a) => {
  const { id: s, name: n, date_of_birth: r, occupation: t } = a;
  return !(!s || !n || !r || !t || tt("INVALID_DATE")(r) || W("INVALID_NAME")(n));
}, it = async (a, s, n, r) => {
  var p, D, I, b, N, y, v, V, h, L, E, k, C, T, P, g, M, O, B, w, x, S, F, H, K, U, j, q, z, G, J, Q;
  const t = r[0], e = n[0];
  if ((p = e == null ? void 0 : e.folio_defaults) != null && p.communication_mobile_number && ((D = e == null ? void 0 : e.folio_defaults) != null && D.communication_email_address) && ((I = e == null ? void 0 : e.folio_defaults) != null && I.payout_bank_account) && ((b = e == null ? void 0 : e.folio_defaults) != null && b.communication_address))
    return {
      status: !1
    };
  const l = (s == null ? void 0 : s.profile) || null, _ = {
    profile: l,
    line1: ((v = (y = (N = t.contact_details) == null ? void 0 : N[0]) == null ? void 0 : y.address) == null ? void 0 : v.line1) || null,
    line2: ((h = (V = t.contact_details[0]) == null ? void 0 : V.address) == null ? void 0 : h.line2) || null,
    line3: ((E = (L = t.contact_details[0]) == null ? void 0 : L.address) == null ? void 0 : E.line3) || null,
    country: ((C = (k = t.contact_details[0]) == null ? void 0 : k.address) == null ? void 0 : C.country_ansi_code) || "IN",
    postal_code: ((P = (T = t.contact_details[0]) == null ? void 0 : T.address) == null ? void 0 : P.postal_code) || null
  };
  if (!et(_))
    return {
      status: !1,
      reason: "INVALID_ADDRESS_DATA"
    };
  const d = {
    profile: l,
    isd: "91",
    number: ((M = (g = t == null ? void 0 : t.mobile_numbers[0]) == null ? void 0 : g.replace(/\s/g, "")) == null ? void 0 : M.slice(-10)) || null,
    belongs_to: "self"
  };
  if (!at(d))
    return {
      status: !1,
      reason: "INVALID_PHONE_DATA"
    };
  const f = {
    profile: l,
    email: ((O = t == null ? void 0 : t.email_addresses) == null ? void 0 : O[0]) || null,
    belongs_to: "self"
  };
  if (!nt(f))
    return {
      status: !1,
      reason: "INVALID_EMAIL_DATA"
    };
  const m = {
    profile: l,
    primary_account_holder_name: (t == null ? void 0 : t.primary_investor_name) || null,
    account_number: ((x = (w = (B = t == null ? void 0 : t.payout_details) == null ? void 0 : B[0]) == null ? void 0 : w.bank_account) == null ? void 0 : x.number) || null,
    type: ((H = (F = (S = t == null ? void 0 : t.payout_details) == null ? void 0 : S[0]) == null ? void 0 : F.bank_account) == null ? void 0 : H.account_type) || null,
    ifsc_code: ((j = (U = (K = t == null ? void 0 : t.payout_details) == null ? void 0 : K[0]) == null ? void 0 : U.bank_account) == null ? void 0 : j.ifsc) || null
  };
  if (!st(m))
    return {
      status: !1,
      reason: "INVALID_BANK_DATA"
    };
  const A = {
    id: l,
    // nonUpdatable
    tax_status: "resident_individual",
    date_of_birth: (t == null ? void 0 : t.primary_investor_dob) || null,
    place_of_birth: "IN",
    country_of_birth: "IN",
    name: (t == null ? void 0 : t.primary_investor_name) || null,
    occupation: "others",
    source_of_wealth: "others",
    income_slab: "above_1lakh_upto_5lakh",
    use_default_tax_residences: !0
  };
  if (!rt(A))
    return {
      status: !1,
      reason: "INVALID_FATCA_DATA"
    };
  try {
    const [i, o, c, u] = await Promise.all([
      (q = a.fpClient) == null ? void 0 : q.addresses().create(_),
      (z = a.fpClient) == null ? void 0 : z.phone_numbers().create(d),
      (G = a.fpClient) == null ? void 0 : G.email_addresses().create(f),
      (J = a.fpClient) == null ? void 0 : J.bank_accounts().create(m),
      (Q = a.fpClient) == null ? void 0 : Q.investor_profiles().update(A)
    ]), X = {
      id: e == null ? void 0 : e.id,
      primary_investor: l,
      folio_defaults: {
        communication_mobile_number: (o == null ? void 0 : o.id) || null,
        communication_email_address: (c == null ? void 0 : c.id) || null,
        communication_address: (i == null ? void 0 : i.id) || null,
        payout_bank_account: (u == null ? void 0 : u.id) || null
      }
    };
    await a.fpClient.mf_investment_accounts().update(X);
  } catch {
    return {
      status: !1
    };
  }
  //! Handle error {status: false} v2 where partiallyOnboardMigratedUser is used.
  return {
    status: !0
  };
};
export {
  it as p
};
