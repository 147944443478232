import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as c, useMemo as ve, useContext as Q, useEffect as de } from "react";
import { a as ce, e as ie, T as ye, M as V, s as Ae, B as te, k as Ke, l as Re, R as Ye, q as Fe, b as be, P as Se, S as qe, c as se, D as Pe } from "./index.esm-DT3FM8Og.js";
import { AppContext as Ie } from "./AppContext.js";
import { u as xe } from "./useConfiguration-B-lyaBB9.js";
import { F as ue } from "./provider-DPPRdC-N.js";
import { r as K } from "./sentry-CbZOQ4Ya.js";
import { LoginContext as we } from "./LoginContext.js";
import { i as z, C as X, a0 as Ge, E as $e, X as Te, o as ke, ad as Me, Y as et, u as Ue, $ as tt, _ as nt, a3 as at, ae as it, m as _e, af as Be, ag as Ee, ah as rt, ai as st, aj as ot, B as Ce, ak as he, f as He } from "./utils-DbnU7rHI.js";
import { i as t } from "./index-Bb24DwqC.js";
import { c as We } from "./checkMigratedUser-D-TLMNz-.js";
import { p as ze } from "./partiallyOnboardMigratedUser-Do2b2Ofg.js";
import { H as Ve } from "./Holdings-CGuIyOTM.js";
import { ErrorPage as oe } from "./ErrorPage.js";
import { O as Qe } from "./OrderStatus-B-hp1hY6.js";
import { F as De } from "./FolioListing-Cx9PHuoW.js";
import { A as Je } from "./Auth-tZKY7y12.js";
import { P as lt } from "./PartnerLogin-D2kCurmZ.js";
import { f as fe, r as ge } from "./validators-BArvL7cB.js";
import { R as Ne, F as re } from "./react-final-form.es-ZoroCK1B.js";
import { u as Oe } from "./useMigratedUser-BZ45fxKo.js";
import { R as dt, a as ct } from "./RedemptionPlanConsent-EMmVhvAC.js";
import { P as Le } from "./Product-lMhZHKhI.js";
import { W as ut } from "./Welcome-CCMlcQ8T.js";
function mt({ scheme_details: r, investment_account_id: n, user: v, holdings: k, selectedFolio: h, isPartner: _, onSuccess: x }) {
  const [O, M] = c(!1), [P, R] = c("initial"), [b, s] = c(!1), D = ve(() => J(k), [
    k
  ]), { fpapi: I } = Q(ue);
  xe();
  const { loggedInPartner: p } = Q(we), { isMigratedUser: H } = Oe({
    user: v
  }), [q, B] = c(!1), F = (g) => {
    var i, S;
    if (H) {
      const u = (i = k == null ? void 0 : k.folios) == null ? void 0 : i.filter((C) => C.folio_number === g), d = u.length ? (S = u[0].schemes) == null ? void 0 : S.find((C) => C.isin === r.isin) : [];
      z(d) ? B(!0) : B(!1);
    }
  };
  de(() => {
    F(h);
  }, [
    h,
    H
  ]);
  function J(g) {
    if (g) {
      const i = {};
      return g.folios.forEach((S) => {
        S.schemes.forEach((u) => {
          u.isin === r.isin && (i[S.folio_number] = u);
        });
      }), i;
    }
  }
  async function A({ amount: g }) {
    M(!0);
    try {
      const i = {
        mf_investment_account: n,
        scheme: r.isin,
        amount: b ? null : g,
        folio_number: h,
        order_gateway: "rta"
      };
      _ && p && (i.partner = p);
      const S = await I.fpClient.mf_redemptions().create(i);
      M(!1), x({
        createdOrder: S,
        shouldOnboardMigratedUser: q
      });
    } catch (i) {
      M(!1), R("error"), K(i);
    }
  }
  const U = (g) => {
    g.stopPropagation(), s(!b);
  };
  function L() {
    return /* @__PURE__ */ e.jsx(Ne, {
      onSubmit: A,
      validate: (g) => {
        const i = {};
        return b || (g.amount || (i.amount = t._({
          id: "7rkFUv"
        })), g.amount && g.amount < r.min_withdrawal_amount && (i.amount = t._({
          id: "u4n9vI",
          values: {
            0: X(r.min_withdrawal_amount)
          }
        })), g.amount && g.amount > r.max_withdrawal_amount && (i.amount = t._({
          id: "uMQeWd",
          values: {
            0: X(r.max_withdrawal_amount)
          }
        })), g.amount && D && h && g.amount > D[h].market_value.redeemable_amount && (i.amount = t._({
          id: "IJDIUX",
          values: {
            0: X(D[h].market_value.redeemable_amount)
          }
        })), Ge(g.amount, r.withdrawal_multiples) === !1 && (i.amount = t._({
          id: "k9cLeP",
          values: {
            0: X(r.withdrawal_multiples)
          }
        }))), i;
      },
      children: (g) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: g.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ie, {
                message: t._({
                  id: "KqeLJ1"
                })
              }),
              /* @__PURE__ */ e.jsx(re, {
                name: "amount",
                children: (i) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ye, {
                      id: i.input.name,
                      autoComplete: "off",
                      type: "text",
                      name: i.input.name,
                      value: b ? "" : $e(i.input.value),
                      onChange: (S) => i.input.onChange(Te(S.target.value)),
                      onInput: (S) => {
                        S.target.value = S.target.value.replace(ke("0-9"), "");
                      },
                      placeholder: t._({
                        id: "fkzBBC"
                      }),
                      prefix: "₹",
                      disabled: b,
                      status: i.meta.error && i.meta.touched ? "error" : void 0
                    }),
                    i.meta.error && i.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(V, {
                        type: "error",
                        variant: "field",
                        title: i.meta.error
                      })
                    }) : h && /* @__PURE__ */ e.jsx(V, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "AfhYy2",
                        values: {
                          0: X(Number(D && D[h].market_value.redeemable_amount))
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(Ae, {
            id: "redeem-all-id",
            checked: b,
            onChange: U,
            children: t._({
              id: "GAUkI4"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: (!h || D && D[h].market_value.redeemable_amount <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function j() {
    switch (P) {
      case "initial":
        return L();
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (g) => R("initial")
        });
      default:
        return L();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: O ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: j()
    })
  });
}
function ft({ scheme_details: r, investment_account_id: n, user: v, holdings: k, selectedFolio: h, onSuccess: _, isPartner: x }) {
  const [O, M] = c(!1), [P, R] = c("initial"), [b, s] = c(!1), D = ve(() => U(), [
    k
  ]), { fpapi: I } = Q(ue);
  xe();
  const { loggedInPartner: p } = Q(we), { isMigratedUser: H, migratedMfiaList: q, migratedFolioList: B } = Oe({
    user: v
  }), [F, J] = c(!1), A = (u) => {
    var d, C;
    if (H) {
      const l = (d = k == null ? void 0 : k.folios) == null ? void 0 : d.filter((y) => y.folio_number === u), o = l.length ? (C = l[0].schemes) == null ? void 0 : C.find((y) => y.isin === r.isin) : [];
      z(o) ? J(!0) : J(!1);
    }
  };
  de(() => {
    A(h);
  }, [
    h,
    H
  ]);
  function U(u) {
    if (k) {
      const d = {};
      return k.folios.forEach((C) => {
        C.schemes.forEach((l) => {
          l.isin === r.isin && (d[C.folio_number] = l);
        });
      }), d;
    }
  }
  async function L({ units: u }) {
    M(!0);
    try {
      const d = {
        mf_investment_account: n,
        scheme: r.isin,
        units: b ? null : u,
        folio_number: h,
        order_gateway: "rta"
      };
      x && p && (d.partner = p);
      const C = await I.fpClient.mf_redemptions().create(d);
      M(!1), _({
        createdOrder: C,
        shouldOnboardMigratedUser: F
      });
    } catch (d) {
      M(!1), R("error"), K(d);
    }
  }
  const j = (u) => {
    u.stopPropagation(), s(!b);
  }, g = (u, d) => {
    const C = u.target.value;
    Me(C, r.withdrawal_multiples_units) !== 0 ? u.preventDefault() : d.input.onChange(C);
  };
  function i() {
    return /* @__PURE__ */ e.jsx(Ne, {
      onSubmit: L,
      validate: (u) => {
        const d = {};
        return b || (u.units || (d.units = t._({
          id: "7rkFUv"
        })), u.units && u.units < r.min_withdrawal_units && (d.units = t._({
          id: "8Drd8Q",
          values: {
            0: r.min_withdrawal_units
          }
        })), u.units && u.units > r.max_withdrawal_units && (d.units = t._({
          id: "qpXVn6",
          values: {
            0: r.max_withdrawal_units
          }
        })), u.units && D && h && u.units > D[h].holdings.redeemable_units && (d.units = t._({
          id: "XuD/KQ",
          values: {
            0: D[h].holdings.redeemable_units
          }
        })), u.units && Me(u.units, r.withdrawal_multiples_units) !== 0 && (d.units = t._({
          id: "Cj+4Fk",
          values: {
            0: r.withdrawal_multiples_units.toString().split(".")[1].length
          }
        }))), d;
      },
      children: (u) => /* @__PURE__ */ e.jsxs("form", {
        id: "orderForm",
        onSubmit: u.handleSubmit,
        children: [
          /* @__PURE__ */ e.jsxs("div", {
            className: "mb-8",
            children: [
              /* @__PURE__ */ e.jsx(ie, {
                message: t._({
                  id: "6ssn5V"
                })
              }),
              /* @__PURE__ */ e.jsx(re, {
                name: "units",
                children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                  children: [
                    /* @__PURE__ */ e.jsx(ye, {
                      id: d.input.name,
                      autoComplete: "off",
                      type: "number",
                      step: "any",
                      name: d.input.name,
                      value: b ? "" : d.input.value,
                      onChange: (C) => g(C, d),
                      placeholder: t._({
                        id: "wRfQ3q"
                      }),
                      disabled: b,
                      status: d.meta.error && d.meta.touched ? "error" : void 0
                    }),
                    d.meta.error && d.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                      className: "mb-2 mt-2",
                      children: /* @__PURE__ */ e.jsx(V, {
                        type: "error",
                        variant: "field",
                        title: d.meta.error
                      })
                    }) : h && /* @__PURE__ */ e.jsx(V, {
                      type: "helper",
                      variant: "field",
                      title: t._({
                        id: "+fgc+n",
                        values: {
                          0: D ? D[h].holdings.redeemable_units : 0
                        }
                      })
                    })
                  ]
                })
              })
            ]
          }),
          /* @__PURE__ */ e.jsx(Ae, {
            id: "redeem-all-id",
            checked: b,
            onChange: j,
            children: t._({
              id: "GAUkI4"
            })
          }),
          /* @__PURE__ */ e.jsx("div", {
            className: "pt-8",
            children: /* @__PURE__ */ e.jsx(te, {
              type: "submit",
              variant: (!h || D && D[h].holdings.redeemable_units <= 0) && "disabled",
              fullwidth: !0,
              children: t._({
                id: "pZ49hE"
              })
            })
          })
        ]
      })
    });
  }
  function S() {
    switch (P) {
      case "initial":
        return i();
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (u) => R("initial")
        });
      default:
        return i();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: O ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: S()
    })
  });
}
const je = [
  {
    label: t._({
      id: "4JqC6N"
    }),
    value: "perpetual"
  },
  {
    label: t._({
      id: "/VDmvv"
    }),
    value: "installments"
  },
  {
    label: t._({
      id: "IUSFui"
    }),
    value: "fixed"
  }
];
function gt({ selectedFolio: r, scheme_details: n, investment_account_id: v, onSuccess: k, isPartner: h, investorProfile: _, holdings: x, user: O }) {
  const [M, P] = c("initial"), R = /* @__PURE__ */ new Date(), b = et(n == null ? void 0 : n.swp_frequency_specific_data), [s, D] = c(b[0].value), [I, p] = c(!1), { fpapi: H } = Q(ue), { tenantConfig: q } = xe(), { loggedInPartner: B } = Q(we);
  ve(() => L(), [
    x
  ]);
  const { isMigratedUser: F } = Oe({
    user: O
  }), [J, A] = c(!1), U = (l) => {
    var o, y;
    if (F) {
      const a = (o = x == null ? void 0 : x.folios) == null ? void 0 : o.filter(($) => $.folio_number === l), f = a.length ? (y = a[0].schemes) == null ? void 0 : y.find(($) => $.isin === n.isin) : [];
      z(f) ? A(!0) : A(!1);
    }
  };
  de(() => {
    U(r);
  }, [
    r,
    F
  ]);
  function L(l) {
    if (x) {
      const o = {};
      return x.folios.forEach((y) => {
        y.schemes.forEach((a) => {
          a.isin === n.isin && (o[y.folio_number] = a);
        });
      }), o;
    }
  }
  const j = ve(() => {
    var l;
    return {
      frequency: b[0],
      swp_instl_type: je[Ue(je, "perpetual")] || null,
      swp_start_date: new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + 1)),
      swp_start_max_date: new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + (((l = q == null ? void 0 : q.app_constants) == null ? void 0 : l.plan_max_start_days) || 0))),
      swp_end_date: s === "daily" ? new Date((/* @__PURE__ */ new Date()).setDate(R.getDate() + 2)) : new Date(R.getFullYear(), R.getMonth() + tt[s] * (n == null ? void 0 : n.swp_frequency_specific_data[s].min_installments), R.getDate() + 1),
      swp_end_max_date: nt(s),
      swp_max_date: new Date(2099, 11, 31)
    };
  }, [
    s,
    b,
    n,
    R
  ]);
  async function g({ amount: l, frequency: o, swp_start_date: y, swp_instl_type: a, installments: f, swp_end_date: $ }) {
    (a == null ? void 0 : a.value) === "perpetual" ? f = 1200 : (a == null ? void 0 : a.value) === "fixed" && (f = Ee(y, $, o.value, n == null ? void 0 : n.swp_frequency_specific_data[o.value].dates));
    let ee = rt(y, o.value);
    ee !== null && (ee = _e(ee)), await H.fetchEmailAndPhone(_);
    const Y = {
      mf_investment_account: v,
      scheme: n.isin,
      frequency: o.value,
      folio_number: r,
      amount: l,
      systematic: !0,
      installment_day: o.value === "daily" ? null : st(y, o.value),
      activate_after: ee,
      number_of_installments: +f,
      order_gateway: "rta"
    };
    if (h && B) {
      p(!0);
      const G = {
        ...Y,
        partner: B
      };
      try {
        const ne = await H.fpClient.mf_redemption_plans().create(G);
        p(!1), k({
          createdOrder: ne,
          shouldOnboardMigratedUser: J
        });
      } catch (ne) {
        window.debug.error(ne), p(!1), P("error"), K(ne);
      }
    } else {
      p(!0);
      try {
        const G = await H.fpClient.mf_redemption_plans().create(Y);
        k({
          createdOrder: G,
          shouldOnboardMigratedUser: J
        }), p(!1);
      } catch (G) {
        window.debug.error(G), p(!1), P("error"), p(!1), K(G);
      }
    }
  }
  const i = ([l, o], y, { changeValue: a }) => {
    o[0] = o[0] === null ? j.swp_start_date : o[0], o[0] = Be(o[0], o[1], n == null ? void 0 : n.swp_frequency_specific_data[o[1]].min_installments), a(y, l, () => o[0]);
  }, S = ([l, o], y, { changeValue: a }) => {
    o[0] !== null && (o[0] = ot(o[1], n == null ? void 0 : n.swp_frequency_specific_data[o[1]].dates)), a(y, l, () => o[0]);
  }, u = (l, o) => Ee(l || j.swp_start_date, o, s, n == null ? void 0 : n.swp_frequency_specific_data[s].dates) <= n.swp_frequency_specific_data[s].min_installments;
  function d() {
    return /* @__PURE__ */ e.jsx(Ne, {
      onSubmit: g,
      mutators: {
        updateSwpEndDate_mutator: i,
        updateSwpStartDate_mutator: S
      },
      validate: (l) => {
        var y;
        const o = {};
        return l.amount || (o.amount = t._({
          id: "7rkFUv"
        })), l.amount && Me(l.amount, (y = n == null ? void 0 : n.swp_frequency_specific_data[s]) == null ? void 0 : y.amount_multiples) !== 0 && (o.amount = t._({
          id: "k9cLeP",
          values: {
            0: n.swp_frequency_specific_data[s].amount_multiples
          }
        })), l.amount && l.amount < (n == null ? void 0 : n.swp_frequency_specific_data[s].min_installment_amount) && (o.amount = t._({
          id: "xegGnh",
          values: {
            0: n.swp_frequency_specific_data[s].min_installment_amount
          }
        })), l.amount && r && l.amount > (n == null ? void 0 : n.swp_frequency_specific_data[s].max_installment_amount) && (o.amount = t._({
          id: "lR8aoB",
          values: {
            0: X(Number(n == null ? void 0 : n.swp_frequency_specific_data[s].max_installment_amount))
          }
        })), l.installments < (n == null ? void 0 : n.swp_frequency_specific_data[s].min_installments) && (o.installments = t._({
          id: "MpguFb",
          values: {
            0: n == null ? void 0 : n.swp_frequency_specific_data[s].min_installments
          }
        })), l.installments > 1200 && (o.installments = t._({
          id: "j7AzBN"
        })), l.swp_end_date < l.swp_start_date && (o.swp_end_date = t._({
          id: "T31Ev8"
        })), o;
      },
      initialValues: j,
      subscription: {
        submitting: !0,
        values: !0
      },
      children: (l) => {
        var o, y;
        return /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: l.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "ydIU1z"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "frequency",
                  initialValue: j.frequency,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Ke, {
                        name: a.input.name,
                        value: a.input.value,
                        onChange: (f) => {
                          const $ = [
                            l.values.swp_start_date,
                            f.value,
                            l.values.swp_end_date
                          ];
                          l.form.mutators.updateSwpStartDate_mutator("swp_start_date", $), l.form.mutators.updateSwpEndDate_mutator("swp_end_date", $), D(f.value), a.input.onChange(f);
                        },
                        options: b
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(V, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      }),
                      a.input.value.value === "daily" && /* @__PURE__ */ e.jsx(V, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "pKTj3U"
                        }),
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "/DJBcB"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "amount",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ye, {
                        type: "text",
                        name: a.input.name,
                        value: $e(a.input.value),
                        onChange: (f) => a.input.onChange(Te(f.target.value)),
                        onInput: (f) => {
                          f.target.value = f.target.value.replace(ke("0-9"), "");
                        },
                        placeholder: t._({
                          id: "fkzBBC"
                        }),
                        prefix: "₹",
                        autoComplete: "off",
                        status: a.meta.error && a.meta.touched ? "error" : void 0
                      }),
                      a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(V, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        })
                      }) : r && /* @__PURE__ */ e.jsx(V, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "ZdOgV8",
                          values: {
                            0: X(Number(n.swp_frequency_specific_data[s].min_installment_amount), 2)
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "jvN2aB"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "swp_start_date",
                  initialValue: null,
                  validate: fe(ge),
                  allowNull: !0,
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Re, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (f) => f.preventDefault(),
                        disableFuture: !1,
                        name: a.input.name,
                        value: a.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: j.swp_start_date,
                        maxDate: j.swp_start_max_date,
                        selectedDate: a.input.value || null,
                        error: a.meta.error && a.meta.touched,
                        views: [
                          "date"
                        ],
                        placeholder: "Select start date",
                        labelFunc: (f) => at(f, s),
                        shouldDisableDate: (f) => it(f, n == null ? void 0 : n.swp_frequency_specific_data[s].dates, s),
                        handleDateChange: (f) => {
                          u(f, l.values.swp_end_date || j.swp_end_date) && l.form.mutators.updateSwpEndDate_mutator("swp_end_date", [
                            f,
                            s
                          ]), a.input.onChange(f);
                        }
                      }),
                      a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(V, {
                        type: "error",
                        variant: "field",
                        title: a.meta.error
                      }),
                      a.input.value && /* @__PURE__ */ e.jsx(V, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "GLAJXE",
                          values: {
                            0: _e(a.input.value, "dd-MMM-yyyy")
                          }
                        }),
                        customClass: "sm"
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "G7dHtg"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "swp_instl_type",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => {
                    var f, $;
                    return /* @__PURE__ */ e.jsx(Ye, {
                      name: a.input.name,
                      value: a.input.value,
                      group: je,
                      size: "sm",
                      checked: Ue(je, ($ = (f = a == null ? void 0 : a.input) == null ? void 0 : f.value) == null ? void 0 : $.value),
                      onChange: (ee, Y) => {
                        a.input.onChange(Y);
                      }
                    });
                  }
                })
              ]
            }),
            ((o = l.values.swp_instl_type) == null ? void 0 : o.value) === "installments" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "ZS3Gyi"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "installments",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: fe(ge),
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(ye, {
                        id: a.input.name,
                        autoComplete: "off",
                        name: a.input.name,
                        onChange: a.input.onChange,
                        placeholder: t._({
                          id: "M1QsOZ"
                        }),
                        status: a.meta.error && a.meta.touched ? "error" : void 0,
                        onInput: (f) => {
                          f.target.value = f.target.value.replace(ke("0-9"), "");
                        },
                        inputMode: "numeric"
                      }),
                      a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(V, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        })
                      }) : r && /* @__PURE__ */ e.jsx(V, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "gRc0h2",
                          values: {
                            0: n == null ? void 0 : n.swp_frequency_specific_data[s].min_installments
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            ((y = l.values.swp_instl_type) == null ? void 0 : y.value) === "fixed" && /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ie, {
                  message: t._({
                    id: "BrsP9r"
                  })
                }),
                /* @__PURE__ */ e.jsx(re, {
                  name: "swp_end_date",
                  initialValue: null,
                  allowNull: !0,
                  children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Re, {
                        variant: "keyboardInputDOB",
                        onKeyDown: (f) => f.preventDefault(),
                        disableFuture: !1,
                        name: a.input.name,
                        value: a.input.value,
                        format: "dd-MMM-yyyy",
                        minDate: Be(l.values.swp_start_date, s, n == null ? void 0 : n.swp_frequency_specific_data[s].min_installments),
                        maxDate: j.swp_end_max_date,
                        selectedDate: a.input.value,
                        labelFunc: (f) => f ? _e(f, "dd-MMM-yyyy") : "Select end date",
                        error: a.meta.error && a.meta.touched,
                        views: [
                          "date"
                        ],
                        handleDateChange: (f) => {
                          a.input.onChange(f);
                        }
                      }),
                      a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(V, {
                          type: "error",
                          variant: "field",
                          title: a.meta.error
                        })
                      }) : r && /* @__PURE__ */ e.jsx(V, {
                        type: "helper",
                        variant: "field",
                        title: t._({
                          id: "gRc0h2",
                          values: {
                            0: n == null ? void 0 : n.swp_frequency_specific_data[s].min_installments
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-8",
              children: /* @__PURE__ */ e.jsx(te, {
                type: "submit",
                variant: r ? "primary" : "disabled",
                fullwidth: !0,
                children: t._({
                  id: "pZ49hE"
                })
              })
            })
          ]
        });
      }
    });
  }
  function C() {
    switch (M) {
      case "initial":
        return d();
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "0sDqRB"
          }),
          description: t._({
            id: "44jgUg"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "Oem4yT"
          }),
          actionHandler: (l) => {
            D(b[0].value), P("initial");
          }
        });
      default:
        return d();
    }
  }
  return I ? /* @__PURE__ */ e.jsx(ce, {
    variant: "fullpage"
  }) : C();
}
function Xe(r) {
  const { tenantConfig: n } = xe(), v = r.delivery_mode, k = () => /* @__PURE__ */ e.jsx(gt, {
    ...r
  }), h = () => {
    const x = () => /* @__PURE__ */ e.jsx(mt, {
      ...r
    }), O = () => /* @__PURE__ */ e.jsx(ft, {
      ...r
    });
    return v === "Demat" ? O() : /* @__PURE__ */ e.jsx(Fe, {
      variant: "pills",
      group: [
        {
          title: t._({
            id: "9eWti6"
          }),
          content: x()
        },
        {
          title: t._({
            id: "3saf/Z"
          }),
          content: O()
        }
      ]
    });
  }, _ = () => {
    var M, P, R, b, s, D;
    const x = (R = (P = (M = n == null ? void 0 : n.app_constants) == null ? void 0 : M.disabled_orders_mode) == null ? void 0 : P.withdraw) == null ? void 0 : R.one_time, O = (D = (s = (b = n == null ? void 0 : n.app_constants) == null ? void 0 : b.disabled_orders_mode) == null ? void 0 : s.withdraw) == null ? void 0 : D.recurring;
    return v === "Demat" ? [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: h()
      }
    ] : x && O ? [] : x ? [
      {
        title: t._({
          id: "15ZJyn"
        }),
        content: k()
      }
    ] : O ? [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: h()
      }
    ] : [
      {
        title: t._({
          id: "56LtkR"
        }),
        content: h()
      },
      {
        title: t._({
          id: "15ZJyn"
        }),
        content: k()
      }
    ];
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: /* @__PURE__ */ e.jsx(Fe, {
      group: _()
    })
  });
}
function _t({ scheme_details: r, folio: n, isPartner: v }) {
  const { AppState: { tenant: k } } = Q(Ie), [h, _] = c(() => sessionStorage.getItem("redemption_workflow_stage") || "folio_listing"), [x, O] = c(null), [M, P] = c(null), [R, b] = c(null), [s, D] = c(null), [I, p] = c(null), [H, q] = c(""), [B, F] = c(!1), [J, A] = c(""), [U, L] = c(() => {
    const m = sessionStorage.getItem("redemption_workflow_order_id");
    return m ? JSON.parse(m) : null;
  }), [j, g] = c(!1), [i, S] = c(n), [u, d] = c(null), [C, l] = c(null), { fpapi: o } = Q(ue), { loggedInPartner: y } = Q(we), [a, f] = c(null);
  de(() => {
    (async () => v && await $())();
  }, [
    C
  ]);
  async function $() {
    if (!y)
      _("login");
    else if (!C)
      _("investor_pan_input");
    else {
      g(!0);
      let m;
      try {
        const w = await o.fpClient.investor_profiles().fetchAll({
          pan: C
        });
        m = w == null ? void 0 : w.data[0], O(m);
        const W = await o.fetchUser(m == null ? void 0 : m.pan, k), Z = W == null ? void 0 : W[0], { isMigratedUser: me, migratedMfiaList: pe, migratedFolioList: Ze } = await We(Z, o);
        if (me && await ze(o, Z, pe, Ze), f(Z), !m) {
          g(!1), q(t._({
            id: "Pe37pS"
          })), A(t._({
            id: "S40aCP"
          })), _("error");
          return;
        }
      } catch (w) {
        q(t._({
          id: "BnnSJR"
        })), A(t._({
          id: "NHmBXI"
        })), _("error"), g(!1), K(w);
        return;
      }
      if (z(r)) {
        g(!1), _("holdings");
        return;
      }
      const E = {};
      try {
        const w = await o.fpClient.mf_investment_accounts().fetchAll({
          investor: C
        });
        if (!w.data || !w.data.length) {
          g(!1), q(t._({
            id: "Pe37pS"
          })), A(t._({
            id: "S40aCP"
          })), _("error");
          return;
        }
        P(w.data);
        const W = await o.fpClient.investor_reports().fetchHoldings({
          investment_account_id: w.data[0].old_id
        });
        let Z = !1;
        if (W.folios.forEach((me) => {
          me.folio_number === n && (Z = !0), me.schemes.forEach((pe) => {
            pe.isin === r.isin && (E[me.folio_number] = pe);
          });
        }), Z || (d(null), S(null)), z(E)) {
          g(!1), _("no_holdings");
          return;
        }
        b(W), D(E);
      } catch (w) {
        g(!1), q(t._({
          id: "BnnSJR"
        })), A(t._({
          id: "rAP48C"
        })), _("error"), K(w);
        return;
      }
      if (U != null && U.id) {
        if (U.object === "mf_redemption")
          try {
            const w = await o.fpClient.mf_redemptions().fetch(U == null ? void 0 : U.id);
            p(w), S(w.folio_number);
          } catch (w) {
            window.debug.error(w), K(w);
          }
        else if (U.object === "mf_redemption_plan")
          try {
            const w = await o.fpClient.mf_redemption_plans().fetch(U == null ? void 0 : U.id);
            p(w), S(w.folio_number);
          } catch (w) {
            window.debug.error(w), K(w);
          }
      }
      g(!1);
    }
  }
  function ee(m) {
    const { createdOrder: E, shouldOnboardMigratedUser: w } = m, W = {
      id: E.id,
      object: E.object
    };
    if (sessionStorage.setItem("redemption_workflow_order_id", JSON.stringify(W)), L(W), p(E), w) {
      p(E), _("onboard_migrated_user");
      return;
    }
    sessionStorage.setItem("redemption_workflow_stage", "finished"), _("finished");
  }
  function Y() {
    sessionStorage.removeItem("redemption_workflow_order_id"), sessionStorage.removeItem("redemption_workflow_stage");
    const E = window.location.href.replace("withdraw", "checkout");
    window.location.href = E;
  }
  function G() {
    sessionStorage.removeItem("redemption_workflow_order_id"), sessionStorage.setItem("redemption_workflow_stage", "order");
  }
  function ne({ pan: m }) {
    v && (_("folio_listing"), l(m));
  }
  const N = () => {
    var m;
    return !z(u) && (u != null && u.dp_id) || !He(M) && ((m = M[0].folio_defaults) != null && m.demat_account) ? "Demat" : "Physical";
  };
  function T() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        ae(),
        !z(s) && /* @__PURE__ */ e.jsx(Xe, {
          investorProfile: x,
          scheme_details: r,
          investment_account_id: M && M[0].id,
          delivery_mode: N(),
          holdings: R,
          user: a,
          selectedFolio: i,
          onSuccess: ee,
          isPartner: v
        })
      ]
    });
  }
  function ae() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        i ? /* @__PURE__ */ e.jsxs(qe, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "t6MpeI"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: i || "Please choose a folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(te, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (m) => {
                    m.preventDefault(), F(!B);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "ez01Pf"
                    }),
                    value: s && i ? `₹ ${X(s[i].invested_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "jlk/lh"
                    }),
                    value: s && i ? s[i].holdings.units : "-"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "aoh6Eh"
                    }),
                    value: s && i ? `₹ ${X(s[i].market_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: s && i ? t._({
                      id: "/glBYd",
                      values: {
                        0: _e(new Date(s[i].nav.as_on), "dd-MMM-yyyy")
                      }
                    }) : t._({
                      id: "iD9R83"
                    }),
                    value: s && i ? `₹ ${X(s[i].nav.value)}` : "-"
                  })
                })
              ]
            })
          ]
        }) : /* @__PURE__ */ e.jsx(Pe, {
          type: "borderedNotes",
          text: t._({
            id: "oytUdD"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            onClick: (m) => {
              m.preventDefault(), F(!B);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        x && /* @__PURE__ */ e.jsx(be, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "845eUq"
          }),
          isOpen: B,
          toggleModal: F,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(De, {
            investorProfile: x,
            scheme_details: r,
            isPartner: v,
            handleFolioSelection: (m) => {
              d(m), S(m.number), F(!B);
            },
            assistedWorkflowPAN: C
          })
        })
      ]
    });
  }
  function le() {
    switch (h) {
      case "order":
        return T();
      case "login":
        return /* @__PURE__ */ e.jsx(lt, {
          context: `${window.location.pathname}${window.location.search}`
        });
      case "investor_pan_input":
        return /* @__PURE__ */ e.jsx(Je, {
          onPreviewData: ne,
          allowRegistration: !0,
          isPartner: v,
          redirectURL: window.location.href,
          showTestPan: {
            onboarded: !0
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: H,
          description: J
        });
      case "folio_listing":
        return x && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(be, {
            customClass: "md:max-w-29",
            variant: "fullPage",
            title: t._({
              id: "aBn5c7"
            }),
            isOpen: !0,
            toggleModal: () => {
              _("initial"), F(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(De, {
              investorProfile: x,
              scheme_details: r,
              isPartner: v,
              handleFolioSelection: (m) => {
                F(!1), d(m), S(m.number), _("order");
              },
              assistedWorkflowPAN: C
            })
          })
        });
      case "finished":
        return !z(r) && !z(I) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(Qe, {
            scheme: r,
            mf_order: I,
            isPartner: v,
            handleInvestAgain: Y,
            handleRetry: G
          })
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "lfdF62"
          }),
          description: t._({
            id: "hfWrIS"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "xuG8bK"
          }),
          actionHandler: Y
        });
      case "holdings":
        return /* @__PURE__ */ e.jsx(Ve, {
          investorProfile: x,
          onSchemeSelection: (m) => {
            var E;
            return window.location.href = window.location.origin + window.location.pathname + `?isin=${m}${(E = he(window.location.search)) != null && E.length ? "&" + he(window.location.search) : ""}`;
          },
          isPartner: v,
          assistedWorkflowPAN: C
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(ht, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Ce}/onboarding?isPartner=${v}&redirectURL=${encodeURIComponent(`${window.location.origin + Ce}/assisted-withdraw/?isin=${r.isin}&isPartner=${v}`)}`;
          }
        });
      default:
        return T();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: j ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: le()
    })
  });
}
const ht = ({ onContinue: r }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Se, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => r(),
        children: "Continue"
      })
    })
  ]
});
function xt({ scheme_details: r, folio: n, isGroupInvestment: v }) {
  const { AppState: { tenant: k } } = Q(Ie), [h, _] = c("folio_listing"), [x, O] = c(null), [M, P] = c(null), [R, b] = c(null), [s, D] = c(null), [I, p] = c(null), [H, q] = c(""), [B, F] = c(!1), [J, A] = c(""), [U, L] = c(!1), [j, g] = c(n), [i, S] = c(null), { fpapi: u } = Q(ue), { loggedInUser: d } = Q(we), [C, l] = c(null), y = new URLSearchParams(window.location.search).get("employer");
  de(() => {
    async function N() {
      var T;
      if (!d)
        _("login");
      else {
        L(!0);
        let ae;
        try {
          ae = await u.getInvestorProfile((T = d == null ? void 0 : d.profile) == null ? void 0 : T.fp_identifier), O(ae);
          const m = await u.fetchUser(d.profile.preferred_username, k), E = m == null ? void 0 : m[0], { isMigratedUser: w, migratedMfiaList: W, migratedFolioList: Z } = await We(E, u);
          w && await ze(u, E, W, Z), l(E);
        } catch (m) {
          q(t._({
            id: "XHIfHi"
          })), A(t._({
            id: "ycsXEB"
          })), _("error"), L(!1), K(m);
          return;
        }
        if (z(r)) {
          L(!1), _("holdings");
          return;
        }
        const le = {};
        try {
          const m = await u.fpClient.mf_investment_accounts().fetchAll({
            investor: ae.pan
          });
          if (!m.data || !m.data.length) {
            L(!1), q(t._({
              id: "qmRkny"
            })), A(t._({
              id: "0f0Xqs"
            })), _("error");
            return;
          }
          P(m.data);
          const E = await u.fpClient.investor_reports().fetchHoldings({
            investment_account_id: m.data[0].old_id
          });
          let w = !1;
          if (E.folios.forEach((W) => {
            W.folio_number === n && (w = !0), W.schemes.forEach((Z) => {
              Z.isin === r.isin && (le[W.folio_number] = Z);
            });
          }), w || (S(null), g(null)), z(le)) {
            L(!1), _("no_holdings");
            return;
          }
          b(E), D(le);
        } catch (m) {
          L(!1), q(t._({
            id: "XHIfHi"
          })), A(t._({
            id: "6p5tt4"
          })), _("error"), K(m);
          return;
        }
        L(!1);
      }
    }
    N();
  }, [
    d,
    u,
    r,
    n
  ]);
  const a = (N) => {
    p(N), _("finished");
  };
  function f(N) {
    const { createdOrder: T, shouldOnboardMigratedUser: ae } = N;
    if (ae) {
      p(T), _("onboard_migrated_user");
      return;
    }
    T.object === "mf_redemption" ? (p(T), _("redeem_confirmation")) : T.object === "mf_redemption_plan" && (p(T), _("redeem_plan_confirmation"));
  }
  function $() {
    const T = he(window.location.search) ? "group-checkout" : "checkout", le = window.location.href.replace("withdraw", T);
    window.location.href = le;
  }
  const ee = () => {
    var N;
    return !z(i) && (i != null && i.dp_id) || !He(M) && ((N = M[0].folio_defaults) != null && N.demat_account) ? "Demat" : "Physical";
  };
  function Y() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        G(),
        !z(s) && /* @__PURE__ */ e.jsx(Xe, {
          investorProfile: x,
          scheme_details: r,
          investment_account_id: M && M[0].id,
          delivery_mode: ee(),
          holdings: R,
          user: C,
          selectedFolio: j,
          onSuccess: f
        })
      ]
    });
  }
  function G() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        j ? /* @__PURE__ */ e.jsxs(qe, {
          header: {
            title: /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    /* @__PURE__ */ e.jsx("span", {
                      className: "mr-1",
                      children: t._({
                        id: "5WmZx5"
                      })
                    }),
                    /* @__PURE__ */ e.jsx("span", {
                      className: "primary-DN0 font-open-sans font-size-14 ml-2 font-semibold",
                      children: j ?? "Please choose a folio"
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(te, {
                  customClass: "text-size-12",
                  variant: "text",
                  onClick: (N) => {
                    N.preventDefault(), F(!B);
                  },
                  children: t._({
                    id: "ShFidp"
                  })
                })
              ]
            })
          },
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center justify-between",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "ez01Pf"
                    }),
                    value: s && j ? `₹ ${X(s[j].invested_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "jlk/lh"
                    }),
                    value: s && j ? s[j].holdings.units : "-"
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: t._({
                      id: "aoh6Eh"
                    }),
                    value: s && j ? `₹ ${X(s[j].market_value.amount)}` : "-"
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(se, {
                    label: s && j ? t._({
                      id: "/glBYd",
                      values: {
                        0: _e(new Date(s[j].nav.as_on), "dd-MMM-yyyy")
                      }
                    }) : t._({
                      id: "iD9R83"
                    }),
                    value: s && j ? `₹ ${X(s[j].nav.value)}` : "-"
                  })
                })
              ]
            })
          ]
        }) : /* @__PURE__ */ e.jsx(Pe, {
          type: "borderedNotes",
          text: t._({
            id: "oytUdD"
          }),
          customClass: "mb-8",
          children: /* @__PURE__ */ e.jsx(te, {
            variant: "text",
            onClick: (N) => {
              N.preventDefault(), F(!B);
            },
            children: t._({
              id: "ShFidp"
            })
          })
        }),
        x && /* @__PURE__ */ e.jsx(be, {
          customClass: "md:max-w-29",
          variant: "fullPage",
          title: t._({
            id: "845eUq"
          }),
          isOpen: B,
          toggleModal: F,
          contentHeightMax: !1,
          children: /* @__PURE__ */ e.jsx(De, {
            investorProfile: x,
            scheme_details: r,
            handleFolioSelection: (N) => {
              S(N), g(N.number), F(!B);
            }
          })
        })
      ]
    });
  }
  function ne() {
    switch (h) {
      case "order":
        return Y();
      case "login":
        return /* @__PURE__ */ e.jsx(Je, {
          redirectURL: window.location.href,
          allowRegistration: !1,
          showTestPan: {
            onboarded: !0
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(oe, {
          title: H,
          description: J
        });
      case "redeem_confirmation":
        return /* @__PURE__ */ e.jsx(ct, {
          investorProfile: x,
          id: I == null ? void 0 : I.id,
          showSchemeDetails: !1,
          onSuccess: a,
          action: "confirm",
          objectType: I == null ? void 0 : I.object
        });
      case "redeem_plan_confirmation":
        return /* @__PURE__ */ e.jsx(dt, {
          investorProfile: x,
          id: I == null ? void 0 : I.id,
          onSuccess: a,
          showSchemeDetails: !1,
          action: "confirm"
        });
      case "folio_listing":
        return x && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(be, {
            customClass: "md:max-w-29",
            variant: "fullPage",
            title: t._({
              id: "aBn5c7"
            }),
            isOpen: !0,
            toggleModal: () => {
              _("initial"), F(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ e.jsx(De, {
              investorProfile: x,
              scheme_details: r,
              handleFolioSelection: (N) => {
                F(!1), S(N), g(N.number), _("order");
              },
              workflow: "withdraw"
            })
          })
        });
      case "finished":
        return !z(r) && !z(I) && /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(Qe, {
            scheme: r,
            mf_order: I,
            handleInvestAgain: $,
            handleRetry: () => _("order")
          })
        });
      case "no_holdings":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "idb6Q+"
          }),
          description: t._({
            id: "t7nOza"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: t._({
            id: "xuG8bK"
          }),
          actionHandler: $
        });
      case "holdings":
        return /* @__PURE__ */ e.jsx(Ve, {
          investorProfile: x,
          onSchemeSelection: (N) => {
            var T;
            return window.location.href = window.location.origin + window.location.pathname + `?isin=${N}${(T = he(window.location.search)) != null && T.length ? "&" + he(window.location.search) : ""}`;
          }
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(wt, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Ce}/onboarding?${v ? `isGroupInvestment=${v}` : ""}${v ? `&employer=${y}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + Ce}/${v ? "group-checkout" : "checkout"}/?isin=${r.isin}${v ? `&employer=${y}` : ""}`)}`;
          }
        });
      default:
        return Y();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: U ? /* @__PURE__ */ e.jsx(ce, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: ne()
    })
  });
}
const wt = ({ onContinue: r }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Se, {
      title: t._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(te, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => r(),
        children: "Continue"
      })
    })
  ]
});
function Ht({ isin: r, folio: n, isPartner: v, isGroupInvestment: k }) {
  const { fpapi: h } = Q(ue), { AppState: { showWelcomePage: _ }, setShowWelcomePage: x } = Q(Ie), { tenantConfig: O, amcs: M } = xe(), P = O == null ? void 0 : O.fallbackLogo, R = O == null ? void 0 : O.showSchemeLogo, [b, s] = c("initial"), [D, I] = c(L), [p, H] = c(null), [q, B] = c(!1), F = v ? [
    {
      step: 1,
      content: t._({
        id: "RkEWr9"
      })
    },
    {
      step: 2,
      content: t._({
        id: "qc1MEz"
      })
    },
    {
      step: 3,
      content: t._({
        id: "4U14ei"
      })
    },
    {
      step: 4,
      content: t._({
        id: "86z+ns"
      })
    },
    {
      step: 5,
      content: t._({
        id: "/QrtaQ"
      })
    }
  ] : [
    {
      step: 1,
      content: t._({
        id: "RkEWr9"
      })
    },
    {
      step: 2,
      content: t._({
        id: "4U14ei"
      })
    },
    {
      step: 3,
      content: t._({
        id: "86z+ns"
      })
    },
    {
      step: 4,
      content: t._({
        id: "/QrtaQ"
      })
    }
  ];
  de(() => {
    r && (B(!0), h.fpClient.master_data().fetchScheme(r).then((i) => {
      J(i);
    }).catch((i) => {
      window.debug.log(i), B(!1), K(i);
    }));
  }, []);
  function J(i) {
    if (!i)
      s("schemeNotFound");
    else {
      const S = R ? {
        ...i,
        schemeLogo: M[i == null ? void 0 : i.amc_id] || P
      } : {
        ...i
      };
      H(S);
    }
    B(!1);
  }
  function A() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(Se, {
          title: p ? t._({
            id: "YCamr4"
          }) : t._({
            id: "2eWKIz"
          })
        }),
        /* @__PURE__ */ e.jsx(Le, {
          scheme: p
        }),
        v ? /* @__PURE__ */ e.jsx(_t, {
          scheme_details: p,
          folio: n,
          isPartner: v,
          isGroupInvestment: k
        }) : /* @__PURE__ */ e.jsx(xt, {
          scheme_details: p,
          folio: n,
          isGroupInvestment: k
        })
      ]
    });
  }
  function U() {
    switch (b) {
      case "initial":
        return A();
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(oe, {
          title: t._({
            id: "1f+uYj"
          }),
          description: t._({
            id: "3MzgnE"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return A();
    }
  }
  de(() => {
    I(L());
  }, [
    _
  ]);
  function L() {
    return _ ? "welcome" : "folio";
  }
  function j() {
    x(!1), I("withdraw");
  }
  function g() {
    return D === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(Se, {
          title: p ? t._({
            id: "YCamr4"
          }) : t._({
            id: "2eWKIz"
          })
        }),
        /* @__PURE__ */ e.jsx(Le, {
          scheme: p
        }),
        /* @__PURE__ */ e.jsx(ut, {
          onAck: j,
          welcomeSteps: F
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: q ? /* @__PURE__ */ e.jsx(ce, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: U()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: g()
  });
}
export {
  Ht as Withdraw
};
