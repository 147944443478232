import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as b, useRef as V, useEffect as U, useCallback as oe, useContext as q } from "react";
import { a as k, P as J, B as L, V as G } from "./index.esm-DT3FM8Og.js";
import { F as re } from "./provider-DPPRdC-N.js";
import { b as z, r as B } from "./sentry-CbZOQ4Ya.js";
import { A as ie, z as ne } from "./utils-DbnU7rHI.js";
import { i as o } from "./index-Bb24DwqC.js";
import { AppContext as se } from "./AppContext.js";
import { ErrorPage as T } from "./ErrorPage.js";
import { u as ae } from "./Auth-tZKY7y12.js";
const de = ({ otp: n, handleComplete: r, handleStageChange: f, handleGoBack: N }) => {
  const [x, y] = b("preview"), [K, c] = b(""), [S, M] = b(null), [A, E] = b(!1), [F, g] = b(null), [w, I] = b(null), u = V(null), P = V(null);
  U(() => () => {
    var a;
    (a = w == null ? void 0 : w.getTracks()) == null || a.forEach(function(i) {
      i.stop();
    });
  }, [
    w
  ]);
  const t = ae(), l = 15e3, s = ((a) => {
    const i = [];
    return a.forEach((d) => {
      MediaRecorder.isTypeSupported(d) && i.push(d);
    }), i.length === 0 ? void 0 : i[0];
  })([
    "video/mp4",
    "video/webm"
  ]), m = s, R = s, j = s == null ? void 0 : s.split("/")[1], h = {
    preview: o._({
      id: "Kgkj6V"
    }),
    recording: o._({
      id: "Kgkj6V"
    }),
    recorded: o._({
      id: "Kgkj6V"
    })
  }, v = oe(async () => {
    var a;
    if ((a = navigator == null ? void 0 : navigator.mediaDevices) != null && a.getUserMedia)
      try {
        E(!0), c(h.preview);
        const i = await navigator.mediaDevices.getUserMedia({
          video: {
            width: {
              ideal: 640,
              max: 640
            },
            height: {
              ideal: 480,
              max: 480
            },
            facingMode: "user",
            frameRate: {
              ideal: 15,
              max: 30
            }
          },
          audio: !0
        });
        I(i), u.current && (u.current.srcObject = i, u.current.onloadedmetadata = () => {
          E(!1);
        });
      } catch (i) {
        i.name === "NotAllowedError" ? g(o._({
          id: "ObxCLl"
        })) : i.name === "NotFoundError" ? g(o._({
          id: "C2qjNv"
        })) : g(o._({
          id: "SAyi7R"
        })), window.debug.log(i), z(i);
      }
    else
      g(o._({
        id: "uChDDi"
      }));
  }, [
    h.preview
  ]);
  U(() => {
    v();
  }, [
    v
  ]);
  const W = (a) => new Promise((i) => setTimeout(i, a)), Q = async (a, i) => {
    const d = new MediaRecorder(a, {
      mimeType: m,
      videoBitsPerSecond: 25e5
    }), _ = [];
    d.ondataavailable = (C) => {
      _.push(C.data);
    }, d.start(), t.start(i / 1e3);
    const $ = await W(i);
    d.state === "recording" && (d.stop(), t.stop());
    const H = new Promise((C, ee) => {
      d.onstop = C, d.onerror = (te) => {
        t.stop(), ee(te);
      };
    });
    try {
      return await Promise.all([
        H,
        $
      ]), _;
    } catch (C) {
      t.stop(), g(o._({
        id: "AB2zpr"
      })), window.debug.log(C), z(C);
    }
  }, Z = () => {
    var a, i;
    if (((a = u.current) == null ? void 0 : a.srcObject) instanceof MediaStream) {
      const d = (i = u.current) == null ? void 0 : i.srcObject;
      d == null || d.getTracks().forEach((_) => _.stop());
    }
  }, X = async () => {
    var a, i;
    try {
      if (y("recording"), c(h.recording), ((a = u.current) == null ? void 0 : a.srcObject) instanceof MediaStream) {
        const d = await Q((i = u.current) == null ? void 0 : i.srcObject, l), _ = new Blob(d, {
          type: R
        }), $ = new File([
          _
        ], `ipv_video.${j}`, {
          type: j
        }), H = URL.createObjectURL(_);
        P.current && (P.current.src = H), y("recorded"), c(h.recorded), Z(), M($);
      }
    } catch (d) {
      O(), window.debug.log(d), z(d);
    }
  }, Y = () => {
    f("uploading"), r(S);
  }, O = () => {
    S && M(null), g(null), y("preview"), v();
  }, D = () => {
    switch (x) {
      case "preview":
        return /* @__PURE__ */ e.jsx(ce, {
          handleStartRecording: X
        });
      case "recording":
        return /* @__PURE__ */ e.jsx(le, {
          countDown: t.value
        });
      case "recorded":
        return /* @__PURE__ */ e.jsx(ue, {
          handleRetry: O,
          handleVideoUpload: Y
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: F ? /* @__PURE__ */ e.jsx(T, {
      title: "Error",
      description: F,
      showAction: !0,
      actionText: "Retry",
      actionHandler: O
    }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        A && /* @__PURE__ */ e.jsx(k, {
          variant: "fullpage"
        }),
        /* @__PURE__ */ e.jsx(J, {
          title: o._({
            id: "ZObM54"
          }),
          icon: N && "Back",
          onClick: N
        }),
        /* @__PURE__ */ e.jsxs("div", {
          className: "flex flex-1 flex-col",
          children: [
            /* @__PURE__ */ e.jsx("div", {
              children: /* @__PURE__ */ e.jsx("p", {
                className: "text-size-14 text-neutral-dark-5 dark:text-neutral-40",
                children: K
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              children: /* @__PURE__ */ e.jsx(L, {
                type: "button",
                variant: "text",
                fullwidth: !0,
                customClass: "mt-1 text-size-14 font-semibold",
                onClick: () => {
                  var a;
                  (a = window == null ? void 0 : window.open("https://docs.fintechprimitives.com/identity/required-information/#in-person-verification-video", "_blank", "noopener")) == null || a.focus();
                },
                children: o._({
                  id: "ePbiPC"
                })
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "text-size-32 text-neutral-110 dark:text-neutral-40 mb-5 text-center",
              style: {
                letterSpacing: "0.4em"
              },
              children: n
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "relative flex flex-1 flex-col",
              children: [
                /* @__PURE__ */ e.jsx(G, {
                  ref: u,
                  portrait: !0,
                  autoPlay: !0,
                  playsInline: !0,
                  muted: !0,
                  customClass: `flex-1 rounded-5 ${x !== "recorded" ? "block" : "hidden"} `
                }),
                /* @__PURE__ */ e.jsx(G, {
                  ref: P,
                  portrait: !0,
                  controls: !0,
                  playsInline: !0,
                  customClass: `flex-1 rounded-5 ${x === "recorded" ? "block" : "hidden"} `
                }),
                D()
              ]
            })
          ]
        })
      ]
    })
  });
}, ce = ({ handleStartRecording: n }) => /* @__PURE__ */ e.jsx("div", {
  className: "footer absolute bottom-0 left-0 right-0 mt-2 px-7",
  children: /* @__PURE__ */ e.jsx(L, {
    onClick: n,
    customClass: "w-full mb-6",
    children: o._({
      id: "4h2ziw"
    })
  })
}), le = ({ countDown: n }) => /* @__PURE__ */ e.jsx("div", {
  className: "footer gradient-black-to-white absolute bottom-0 left-0 right-0 mt-2 px-7 py-5",
  children: /* @__PURE__ */ e.jsxs("div", {
    className: "flex justify-between",
    children: [
      /* @__PURE__ */ e.jsxs("span", {
        className: "rounded-5 flex items-center",
        children: [
          /* @__PURE__ */ e.jsx("i", {
            className: "bg-error mr-2 block h-4 w-4 rounded-full"
          }),
          /* @__PURE__ */ e.jsxs("span", {
            className: "font-opensans text-neutral-0 block font-semibold",
            children: [
              o._({
                id: "dQY3EW"
              }),
              " "
            ]
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("span", {
        className: "font-opensans text-neutral-0 block font-semibold",
        children: [
          "00:",
          n < 10 ? `0${n}` : n
        ]
      })
    ]
  })
}), ue = ({ handleRetry: n, handleVideoUpload: r }) => /* @__PURE__ */ e.jsxs("div", {
  className: "bg-neutral-0 dark:bg-neutral-dark-5 rounded-5 mt-2 px-7 py-7 text-center",
  children: [
    /* @__PURE__ */ e.jsx(L, {
      variant: "text",
      customClass: "mb-6",
      onClick: n,
      children: o._({
        id: "JJNhLH"
      })
    }),
    /* @__PURE__ */ e.jsx(L, {
      customClass: "w-full",
      onClick: r,
      children: o._({
        id: "xOFKrI"
      })
    })
  ]
}), Ce = ({ user: n, kycDetails: r, onCompletion: f, isKycOnly: N }) => {
  const { fpapi: x } = q(re), { AppState: { tenant: y } } = q(se), [K, c] = b(""), [S, M] = b(null);
  U(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const A = async (t) => x.fpClient.kyc_requests().update(r == null ? void 0 : r.id, t), E = async () => {
    const t = n.id, p = {
      ...ne(n == null ? void 0 : n.annexure),
      completed_step: "kyc_ipv"
    }, s = await x.updateUser(t, p, null, y);
    if (s)
      return s;
    throw new Error(s);
  }, F = () => {
    function t(s) {
      (async () => {
        var j;
        const m = s.coords.latitude, R = s.coords.longitude;
        if (((j = r == null ? void 0 : r.address) == null ? void 0 : j.proof_type) === "aadhaar") {
          const h = {
            geolocation: {
              latitude: Number(m),
              longitude: Number(R)
            }
          };
          try {
            const v = await A(h);
            n && await E(), f({
              updatedKycDetails: v
            });
          } catch (v) {
            window.debug.error(v), c("geoLocationError"), B(v);
          }
        } else
          M({
            latitude: m,
            longitude: R
          }), c("recorder");
      })();
    }
    function l(s) {
      window.debug.error(s), c("geoLocationError"), B(s);
    }
    const p = {
      enableHighAccuracy: !0,
      maximumAge: 3e4,
      timeout: 27e3
    };
    window.navigator.geolocation ? navigator.geolocation.getCurrentPosition(t, l, p) : c("geoLocationNotSupported");
  };
  U(() => {
    c("permissionsInfo");
  }, []);
  const g = () => {
    var t;
    if (c("navigatorPermissions"), ((t = r == null ? void 0 : r.address) == null ? void 0 : t.proof_type) !== "aadhaar") {
      if (!(window != null && window.MediaRecorder)) {
        w("invalidBrowser");
        return;
      }
      if (![
        "video/mp4",
        "video/webm"
      ].some((s) => {
        var m;
        return (m = window == null ? void 0 : window.MediaRecorder) == null ? void 0 : m.isTypeSupported(s);
      })) {
        w("invalidBrowser");
        return;
      }
    }
    F();
  }, w = (t) => {
    c(t);
  }, I = async (t) => {
    try {
      const l = r == null ? void 0 : r.pan, p = new FormData(), s = "ipv", m = t.type;
      p.append("file", t, `${s}_${l}.${m}`), p.append("purpose", s);
      const j = {
        ipv_video: (await x.fpClient.files().create(p)).id,
        geolocation: {
          latitude: Number(S.latitude),
          longitude: Number(S.longitude)
        }
      }, h = await A(j);
      n && await E(), f({
        updatedKycDetails: h
      });
    } catch (l) {
      c("uploadFail"), window.debug.error(l), B(l), ie(l) && window.location.reload();
    }
  }, u = async () => {
    try {
      c("loading");
      const t = n.id, l = n == null ? void 0 : n.annexure;
      l.completed_step = "nomination_consent", await x.updateUser(t, l, null, y), f();
    } catch (t) {
      window.debug.error(t), B(t);
    }
  }, P = () => {
    var t;
    switch (K) {
      case "permissionsInfo":
        return /* @__PURE__ */ e.jsx(me, {
          handleInstructions: g,
          isIpvPermissionRequired: (t = r == null ? void 0 : r.requirements) == null ? void 0 : t.fields_needed.includes("ipv_video"),
          handleGoBack: N ? void 0 : u
        });
      case "invalidBrowser":
        return /* @__PURE__ */ e.jsx(T, {
          title: o._({
            id: "dC4JWH"
          }),
          description: o._({
            id: "67gCg8"
          })
        });
      case "recorder":
        return /* @__PURE__ */ e.jsx(de, {
          otp: r.otp,
          handleComplete: I,
          handleStageChange: w,
          handleGoBack: N ? void 0 : u
        });
      case "uploading":
        return /* @__PURE__ */ e.jsx(pe, {});
      case "uploadFail":
        return /* @__PURE__ */ e.jsx(T, {
          title: o._({
            id: "WZaT3Q"
          }),
          description: o._({
            id: "RxIw8D"
          }),
          showAction: !0,
          actionText: o._({
            id: "90uUa5"
          }),
          actionHandler: () => {
            c("recorder");
          }
        });
      case "geoLocationError":
        return /* @__PURE__ */ e.jsx(T, {
          title: o._({
            id: "xXPtGs"
          }),
          description: o._({
            id: "LabCcv"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: o._({
            id: "90uUa5"
          }),
          actionHandler: () => {
            F();
          }
        });
      case "geoLocationNotSupported":
        return /* @__PURE__ */ e.jsx(T, {
          title: o._({
            id: "KKhj9L"
          }),
          description: o._({
            id: "0vT0gH"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "navigatorPermissions":
      default:
        return /* @__PURE__ */ e.jsx(k, {
          variant: "fullpage"
        });
    }
  };
  return r ? /* @__PURE__ */ e.jsx(e.Fragment, {
    children: P()
  }) : /* @__PURE__ */ e.jsx(k, {
    variant: "fullpage"
  });
}, pe = () => /* @__PURE__ */ e.jsx(k, {
  variant: "fullpage",
  title: o._({
    id: "eezk5l"
  }),
  msg: "Please wait.."
}), me = ({ handleInstructions: n, isIpvPermissionRequired: r, handleGoBack: f }) => /* @__PURE__ */ e.jsx(e.Fragment, {
  children: /* @__PURE__ */ e.jsxs("div", {
    children: [
      /* @__PURE__ */ e.jsx(J, {
        title: o._({
          id: "xE1DHm"
        }),
        icon: f ? "Back" : null,
        onClick: f,
        customClass: "mb-4"
      }),
      /* @__PURE__ */ e.jsx("div", {
        className: "flex",
        children: /* @__PURE__ */ e.jsxs("p", {
          className: "dark:text-neutral-dark-110 mb-5 text-center",
          children: [
            `For identity verification, you will need to provide ${r ? "your current geolocation and record a video of yourself. For the video recording you will receive a unique code to read out or display the code written on a white paper. " : "your current geolocation."}`,
            /* @__PURE__ */ e.jsx("br", {}),
            /* @__PURE__ */ e.jsx("br", {}),
            /* @__PURE__ */ e.jsx("strong", {
              className: "font-bold",
              children: `Grant us permissions for ${r ? "video, microphone, and location" : "location"} to proceed with the verification.`
            })
          ]
        })
      }),
      /* @__PURE__ */ e.jsx("div", {
        className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
        children: /* @__PURE__ */ e.jsx(L, {
          fullwidth: !0,
          customClass: "w-full mb-6",
          onClick: () => n(),
          children: "Continue"
        })
      })
    ]
  })
});
export {
  Ce as default
};
