import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { i as p } from "./index-Bb24DwqC.js";
import { a as N, P as T } from "./index.esm-DT3FM8Og.js";
import { useState as c, useContext as f, useEffect as U } from "react";
import { AppContext as I } from "./AppContext.js";
import { F as R } from "./provider-DPPRdC-N.js";
import { LoginContext as D } from "./LoginContext.js";
import { r as B } from "./sentry-CbZOQ4Ya.js";
import { ErrorPage as M } from "./ErrorPage.js";
import { O as Z } from "./Onboard-CEJ2xQwF.js";
import { S as q } from "./StatusMessage-PK7bu2oh.js";
import { A as h } from "./Auth-tZKY7y12.js";
import { P as z } from "./PartnerLogin-D2kCurmZ.js";
import { W as G } from "./Welcome-CCMlcQ8T.js";
function ce({ isPartner: t, redirectURL: a, isGroupInvestment: l, employer: x }) {
  const [_, o] = c(""), { AppState: { showWelcomePage: g, tenant: b }, setShowWelcomePage: j, setEmployer: A } = f(I), { loggedInUser: w, loggedInPartner: P } = f(D), [y, n] = c(!1), { fpapi: C } = f(R), [O, m] = c(null), [d, S] = c(null);
  U(() => {
    k();
  }, [
    g,
    d
  ]);
  const k = async () => {
    var s;
    if (g)
      o("welcome"), n(!0);
    else {
      const u = t ? P : w;
      if (l && A({
        id: x
      }), u === void 0)
        n(!0), o("login");
      else if (t && !d)
        n(!0), o("assisted_investor_login");
      else
        try {
          const r = t ? d : w.profile.preferred_username, [F] = await Promise.all([
            C.fetchUser(r, b)
          ]), i = F[0];
          ((s = i == null ? void 0 : i.annexure) == null ? void 0 : s.isonboarded) === "true" ? a ? window.location.href = a : o("show_onboarding_complete") : o("onboarding"), m(i), n(!0);
        } catch (r) {
          n(!1), o("error_page"), B(r);
        }
    }
  }, W = [
    {
      step: 1,
      content: p._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: p._({
        id: "j7omc2"
      })
    }
  ], v = () => {
    j(!1);
  }, L = (s, u, r) => {
    m(r), a ? window.location.href = a : o("show_onboarding_complete");
  }, $ = ({ pan: s }) => {
    S(s);
  };
  function E() {
    switch (_) {
      case "welcome":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(T, {
              title: p._({
                id: "4dtw/O"
              })
            }),
            /* @__PURE__ */ e.jsx(G, {
              onAck: v,
              welcomeSteps: W
            })
          ]
        });
      case "login":
        return t ? /* @__PURE__ */ e.jsx(z, {
          context: `${window.location.pathname}${window.location.search}`
        }) : /* @__PURE__ */ e.jsx(h, {
          redirectURL: `${window.location.pathname}${window.location.search}`,
          allowRegistration: !1,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: l
        });
      case "assisted_investor_login":
        return /* @__PURE__ */ e.jsx(h, {
          onPreviewData: $,
          allowRegistration: !1,
          isPartner: !0,
          isGroupInvestment: !1,
          redirectURL: `${window.location.pathname}${window.location.search}`,
          showTestPan: {
            onboarded: !0
          }
        });
      case "show_onboarding_complete":
        return /* @__PURE__ */ e.jsx(e.Fragment, {
          children: /* @__PURE__ */ e.jsx(q, {
            title: "Onboarding successful",
            description: "Onboarding is completed successfully",
            iconName: "successInfo"
          })
        });
      case "onboarding":
        return /* @__PURE__ */ e.jsx(Z, {
          user: O,
          setUser: m,
          onBoardingComplete: L,
          isPartner: t,
          isGroupInvestment: l
        });
      case "error_page":
        return /* @__PURE__ */ e.jsx(M, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
      default:
        return;
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: y ? E() : /* @__PURE__ */ e.jsx(N, {
      variant: "fullpage"
    })
  });
}
export {
  ce as Onboarding
};
