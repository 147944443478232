import { j as u } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as W, useEffect as Z } from "react";
import { S as z, c, i as B } from "./index.esm-DT3FM8Og.js";
import { D as I, m as R, i as g, k as M, E as p, C as m, F as H, d as A, b as C, G as O } from "./utils-DbnU7rHI.js";
import { u as Q } from "./useConfiguration-B-lyaBB9.js";
import { i as l } from "./index-Bb24DwqC.js";
const ss = ({ order: s, currentPlanObject: v, isInstallmentListing: b, handlePayment: h, scheme: f, switchOutScheme: _, isGroupInvestment: $, isPartner: k, paymentDetails: i }) => {
  const { tenantConfig: t, amcs: T } = Q(), F = t == null ? void 0 : t.fallbackLogo, w = t == null ? void 0 : t.showSchemeLogo, [V, q] = W(""), U = (s == null ? void 0 : s.object) === "mf_switch" ? "Switch into" : "Scheme name";
  Z(() => {
    let a;
    (s == null ? void 0 : s.object) === "mf_purchase" ? a = (s == null ? void 0 : s.state) === "pending" && (i == null ? void 0 : i.status) === "INITIATED" ? "Payment processing" : I(s == null ? void 0 : s.state, s == null ? void 0 : s.object, s == null ? void 0 : s.failure_code) : a = I(s == null ? void 0 : s.state, s == null ? void 0 : s.object, s == null ? void 0 : s.failure_code), q(a);
  }, [
    s
  ]);
  const G = (a) => {
    const j = [
      "SUCCESS",
      "INITIATED",
      "APPROVED",
      "SUBMITTED"
    ], x = [
      "FAILED",
      "TIMEDOUT"
    ];
    let N = !1, n = !1, E = !1, P = !1;
    g(i) || (j.includes(i == null ? void 0 : i.status) && (N = !0), x.includes(i == null ? void 0 : i.status) && (n = !0), (i == null ? void 0 : i.status) === "APPROVED" && (E = !0), (i == null ? void 0 : i.status) === "INITIATED" && (P = !0));
    const J = (a == null ? void 0 : a.state) === "pending" && !N, L = (a == null ? void 0 : a.state) === "failed" && (a == null ? void 0 : a.failure_code) === "payment_failure" && !N && n;
    return {
      isPaymentPending: J,
      isPaymentRetry: L,
      isPaymentSuccessful: E,
      isPaymentInitiated: P
    };
  }, K = (a) => {
    if (!$ && (a == null ? void 0 : a.object) === "mf_purchase" && (!(v != null && v.payment_method) && b || !(a != null && a.plan) && !b)) {
      const { isPaymentPending: j, isPaymentRetry: x } = G(a);
      return k && (j || x) ? {
        buttonText: l._({
          id: "WnGhts"
        })
      } : j ? {
        buttonText: l._({
          id: "mtFRI7"
        })
      } : x ? O(i == null ? void 0 : i.created_at) ? {
        buttonText: l._({
          id: "vgDUPm"
        })
      } : {
        title: /* @__PURE__ */ u.jsx(u.Fragment, {
          children: /* @__PURE__ */ u.jsx("div", {
            className: "text-center",
            children: l._({
              id: "7XOaxV"
            })
          })
        })
      } : void 0;
    } else
      return;
  }, X = (a) => !(a != null && a.amount) && !(a != null && a.units) ? "All" : a != null && a.units ? m(a == null ? void 0 : a.units, 2) : "-", S = (a) => a === "mf_purchase" ? "purchased_price" : a === "mf_redemption" ? "redeemed_price" : "switched_in_price";
  return /* @__PURE__ */ u.jsx(z, {
    customClass: "mb-8",
    header: {
      title: /* @__PURE__ */ u.jsx("span", {
        className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
        children: l._({
          id: "moFZxg",
          values: {
            0: R(new Date(s == null ? void 0 : s.created_at), "dd-MMM-yyyy")
          }
        })
      })
    },
    footer: K(s),
    onClick: () => h && h(s, "details_page"),
    children: /* @__PURE__ */ u.jsxs("div", {
      className: "-mr-2 -ml-2 flex flex-wrap items-center",
      children: [
        (s == null ? void 0 : s.object) === "mf_switch" ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "6Xqu8f"
            }),
            value: g(_) ? "-" : M(_ == null ? void 0 : _.name),
            logo: w ? T[_ == null ? void 0 : _.amc_id] || F : ""
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        b ? /* @__PURE__ */ u.jsx(u.Fragment, {}) : /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-full pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "RhtSGs",
              values: {
                schemeTitle: U
              }
            }),
            value: g(f) ? "-" : M(f == null ? void 0 : f.name),
            logo: w ? T[f == null ? void 0 : f.amc_id] || F : ""
          })
        }),
        (s == null ? void 0 : s.object) === "mf_redemption" || (s == null ? void 0 : s.object) === "mf_switch" ? /* @__PURE__ */ u.jsxs(u.Fragment, {
          children: [
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "cNfDE7"
                }),
                value: X(s)
              })
            }),
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "pN9mLH"
                }),
                value: s != null && s.amount ? `₹ ${p(s == null ? void 0 : s.amount)}` : "-"
              })
            }),
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "Q9PC60",
                  values: {
                    0: (s == null ? void 0 : s.object) === "mf_redemption" ? "withdrawn" : "switched"
                  }
                }),
                value: (s == null ? void 0 : s.object) === "mf_redemption" ? s != null && s.redeemed_units ? m(s == null ? void 0 : s.redeemed_units, 2) : "-" : s != null && s.switched_out_units ? m(s == null ? void 0 : s.switched_out_units, 2) : "-"
              })
            }),
            /* @__PURE__ */ u.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ u.jsx(c, {
                label: l._({
                  id: "pv2sFw",
                  values: {
                    0: (s == null ? void 0 : s.object) === "mf_redemption" ? "withdrawn" : "switched"
                  }
                }),
                value: (s == null ? void 0 : s.object) === "mf_redemption" ? s != null && s.redeemed_amount ? `₹ ${p(s == null ? void 0 : s.redeemed_amount)}` : "-" : s != null && s.switched_out_amount ? `₹ ${p(s == null ? void 0 : s.switched_out_amount)}` : "-"
              })
            })
          ]
        }) : /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: s != null && s.amount ? l._({
              id: "KqeLJ1"
            }) : l._({
              id: "6ssn5V"
            }),
            value: s != null && s.amount ? `₹ ${p(s == null ? void 0 : s.amount)}` : s != null && s.units ? s == null ? void 0 : s.units : "-"
          })
        }),
        /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "o9DqK+"
            }),
            value: s != null && s.allotted_units ? m(s == null ? void 0 : s.allotted_units, 2) : "-"
          })
        }),
        (s == null ? void 0 : s.object) === "mf_purchase" ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "TK3bTJ"
            }),
            value: s != null && s.confirmed_at ? R(new Date(s == null ? void 0 : s.confirmed_at), "dd-MMM-yyyy") : "-"
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "e7LnWq"
            }),
            value: s != null && s.state ? /* @__PURE__ */ u.jsx(B, {
              variant: "outline",
              message: V,
              status: H(s == null ? void 0 : s.state)
            }) : "-"
          })
        }),
        b ? /* @__PURE__ */ u.jsx(u.Fragment, {}) : /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "cXcPd1"
            }),
            value: s != null && s.folio_number ? s == null ? void 0 : s.folio_number : "New folio"
          })
        }),
        b ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "uoyZIo",
              values: {
                0: A(s == null ? void 0 : s.id),
                1: (s == null ? void 0 : s.object) === "mf_switch" ? "in " : ""
              }
            }),
            value: s[S(s == null ? void 0 : s.object)] ? `₹ ${m(s[S(s == null ? void 0 : s.object)], 2)}` : "-"
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        b && (s == null ? void 0 : s.object) === "mf_switch" ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: l._({
              id: "42mR8Y",
              values: {
                0: A(s == null ? void 0 : s.id)
              }
            }),
            value: s.switched_out_price ? `₹ ${m(s.switched_out_price, 2)}` : "-"
          })
        }) : /* @__PURE__ */ u.jsx(u.Fragment, {}),
        /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: `${C(s == null ? void 0 : s.id)} ref`,
            value: (s == null ? void 0 : s.id) || "-"
          })
        }),
        s != null && s.plan && !b ? /* @__PURE__ */ u.jsx("div", {
          className: "mb-3 basis-1/2 pr-2 pl-2",
          children: /* @__PURE__ */ u.jsx(c, {
            label: `${C(s == null ? void 0 : s.plan)} ref`,
            value: s == null ? void 0 : s.plan
          })
        }) : null
      ]
    })
  });
};
export {
  ss as D
};
