import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as a, useContext as W, useEffect as U, useLayoutEffect as K } from "react";
import { a as X, D as M, S as k, c, P as Q } from "./index.esm-DT3FM8Og.js";
import { AppContext as V } from "./AppContext.js";
import { F as B } from "./provider-DPPRdC-N.js";
import { LoginContext as q } from "./LoginContext.js";
import { W as G } from "./Welcome-CCMlcQ8T.js";
import { i as Y, O as j, k as Z } from "./utils-DbnU7rHI.js";
import { i as s } from "./index-Bb24DwqC.js";
import { r as O } from "./sentry-CbZOQ4Ya.js";
import { u as R } from "./useConfiguration-B-lyaBB9.js";
import { ErrorPage as H } from "./ErrorPage.js";
import { A as C } from "./Auth-tZKY7y12.js";
function ee({ investorProfile: x, mfInvestmentAccount: o }) {
  const [L, u] = a(!1), [y, f] = a("initial"), [n, E] = a([]), [t, I] = a(null), [d, z] = a([]), [l, T] = a(null), [v, $] = a(null), { tenantConfig: m, amcs: P } = R(), A = m == null ? void 0 : m.fallbackLogo, b = m == null ? void 0 : m.showSchemeLogo, { fpapi: g } = W(B);
  U(() => {
    async function i() {
      try {
        if (Y(o)) {
          f("no_portfolio");
          return;
        }
        u(!0);
        const h = o.id, _ = await g.fpClient.investor_reports().fetchInvestmentAccountWiseReturns({
          mf_investment_account: h
        });
        if (_.data.rows[0][1] === 0) {
          u(!1), f("no_portfolio");
          return;
        }
        const w = await g.fpClient.investor_reports().fetchSchemewiseReturns({
          mf_investment_account: h
        }), S = {};
        _.data.columns.forEach((p, D) => {
          S[p] = D;
        });
        const r = {};
        w.data.columns.forEach((p, D) => {
          r[p] = D;
        }), await N(w, r), I(S), E(_.data.rows[0]), z(w.data.rows), T(r), u(!1);
      } catch (h) {
        window.debug.error(h), u(!1), f("error"), O(h);
      }
    }
    i();
  }, [
    g,
    x
  ]);
  async function N(i, h) {
    const _ = {}, w = i.data.rows.map((r) => r[h.isin]), S = [];
    w.forEach((r) => {
      const p = g.fpClient.master_data().fetchScheme(r);
      S.push(p);
    }), Promise.all(S).then((r) => {
      r.forEach((p) => {
        _[p.isin] = {
          ...p
        };
      }), $(_);
    }).catch((r) => {
      window.debug.error(r), u(!1), f("error"), O(r);
    });
  }
  function F() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(M, {
          type: "text",
          text: s._({
            id: "eW8IR1"
          })
        }),
        /* @__PURE__ */ e.jsx(k, {
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "ez01Pf"
                  }),
                  value: n[t == null ? void 0 : t.invested_amount] !== null ? `₹ ${j(n[t == null ? void 0 : t.invested_amount], 2)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "aoh6Eh"
                  }),
                  value: n[t == null ? void 0 : t.current_value] !== null ? `₹ ${j(n[t == null ? void 0 : t.current_value], 2)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "fI+F9X"
                  }),
                  value: n[t == null ? void 0 : t.unrealized_gain] !== null ? `₹ ${j(n[t == null ? void 0 : t.unrealized_gain], 2)}` : "-",
                  icon: n[t == null ? void 0 : t.unrealized_gain] === 0 ? "" : n[t == null ? void 0 : t.unrealized_gain] > 0 ? "TrendingUp" : "TrendingDown"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2 text-green-700",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "QFsROw"
                  }),
                  value: n[t == null ? void 0 : t.absolute_return] !== null ? `${n[t == null ? void 0 : t.absolute_return]} %` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "tzJCPh"
                  }),
                  value: n[t == null ? void 0 : t.cagr] !== null ? `${n[t == null ? void 0 : t.cagr]} %` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "Rt2L/z"
                  }),
                  value: n[t == null ? void 0 : t.xirr] !== null ? `${n[t == null ? void 0 : t.xirr]} %` : "-"
                })
              })
            ]
          })
        }),
        d.length ? /* @__PURE__ */ e.jsx(M, {
          type: "text",
          text: s._({
            id: "pIA/jh"
          }),
          customClass: "mb-4"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
        d.length ? d.map((i) => /* @__PURE__ */ e.jsx(k, {
          customClass: "mb-6",
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-full pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  value: Z(i[l.scheme_name]),
                  logo: b && v ? P[v[i[l.isin]].amc_id] || A : ""
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "ez01Pf"
                  }),
                  value: `₹ ${j(i[l.invested_amount], 2)}`
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "S0BC0N"
                  }),
                  value: `₹ ${j(i[l.current_value], 2)}`
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/3 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(c, {
                  label: s._({
                    id: "fI+F9X"
                  }),
                  value: `₹ ${j(i[l.unrealized_gain], 2)}`,
                  icon: i[l.unrealized_gain] === 0 ? "" : i[l.unrealized_gain] > 0 ? "TrendingUp" : "TrendingDown"
                })
              })
            ]
          })
        }, i[l.isin])) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      ]
    });
  }
  function J() {
    switch (y) {
      case "initial":
        return F();
      case "error":
        return /* @__PURE__ */ e.jsx(H, {
          title: s._({
            id: "KnE1fM"
          }),
          description: s._({
            id: "uHsknt"
          }),
          showAction: !0,
          actionText: s._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            f("initial");
          }
        });
      case "no_portfolio":
        return /* @__PURE__ */ e.jsx(H, {
          title: s._({
            id: "/aXNoC"
          }),
          description: s._({
            id: "aojshS"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return F();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: L ? /* @__PURE__ */ e.jsx(X, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: J()
    })
  });
}
function fe() {
  const { fpapi: x } = W(B), { loggedInUser: o } = W(q), { AppState: { showWelcomePage: L, tenant: u }, setShowWelcomePage: y } = W(V), [f, n] = a({}), [E, t] = a(v), [I, d] = a(!0), [z, l] = a({}), T = [
    {
      step: 1,
      content: "Login"
    },
    {
      step: 2,
      content: "View portfolio"
    }
  ];
  K(() => {
    x && o ? (d(!0), x.fetchUser(o.profile.preferred_username, u).then((P) => {
      var A;
      if (P[0].annexure.isonboarded && P[0].annexure.isonboarded === "false") {
        d(!1);
        return;
      }
      x.getInvestorProfile((A = o == null ? void 0 : o.profile) == null ? void 0 : A.fp_identifier).then((b) => {
        b && x.fpClient.mf_investment_accounts().fetchAll({
          investor: b.pan
        }).then((g) => {
          const N = g.data.filter((F) => F.primary_investor === o.profile.fp_identifier);
          if (!N.length) {
            d(!1);
            return;
          }
          n(b), l(N[0]), d(!1);
        });
      });
    })) : d(!1);
  }, []), U(() => {
    t(v());
  }, [
    L
  ]);
  function v() {
    return L ? "welcome" : "portfolio";
  }
  function $() {
    y(!1), t("portfolio");
  }
  function m() {
    return E === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(G, {
        onAck: $,
        welcomeSteps: T
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: I ? /* @__PURE__ */ e.jsx(X, {
        variant: "fullpage"
      }) : o ? /* @__PURE__ */ e.jsx(ee, {
        investorProfile: f,
        mfInvestmentAccount: z
      }) : /* @__PURE__ */ e.jsx(C, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Q, {
        title: "Portfolio"
      }),
      m()
    ]
  });
}
export {
  fe as Portfolio
};
