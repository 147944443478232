import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useState as h, useContext as be, useMemo as ut, useCallback as ot, useEffect as tt, useLayoutEffect as Ct } from "react";
import { a as st, e as J, T as oe, M as X, R as vt, c as pt, B as lt, b as We, k as Dt, I as qt, r as It, s as Lt, q as Tt, P as $t } from "./index.esm-DT3FM8Og.js";
import { u as rt } from "./useConfiguration-B-lyaBB9.js";
import { F as mt } from "./provider-DPPRdC-N.js";
import { LoginContext as ft } from "./LoginContext.js";
import { r as ge } from "./sentry-CbZOQ4Ya.js";
import { W as At, i as A, f as wt, C as xe, E as jt, X as St, o as ye, m as _t, Y as Et, Z as Ht, j as Ot, _ as Ut, $ as yt, a0 as xt, a1 as Be, a2 as Rt, a3 as Bt, a4 as Wt, u as Kt, a5 as Yt, a6 as dt, B as gt } from "./utils-DbnU7rHI.js";
import { i } from "./index-Bb24DwqC.js";
import { AppContext as ht } from "./AppContext.js";
import { c as zt } from "./checkMigratedUser-D-TLMNz-.js";
import { p as Xt } from "./partiallyOnboardMigratedUser-Do2b2Ofg.js";
import { ErrorPage as P } from "./ErrorPage.js";
import { K as Jt } from "./KycCheckStatus-DlzH9NoH.js";
import { S as Vt, a as Qt } from "./SwitchPlanConsent-bbeB76nY.js";
import { g as $e, a as Zt, s as it, b as bt, c as ct, d as Ae, e as Ee, f as Gt, O as Pt } from "./OrderStatus-B-hp1hY6.js";
import { b as ea, P as ta, a as aa } from "./PurchasePlanConsent-1E5SElFh.js";
import { R as Ft, F as Z } from "./react-final-form.es-ZoroCK1B.js";
import { u as Mt } from "./useMigratedUser-BZ45fxKo.js";
import { C as G } from "./StatusMessage-PK7bu2oh.js";
import { FolioDetails as kt } from "./FolioDetails.js";
import { F as Nt } from "./FolioListing-Cx9PHuoW.js";
import { P as na } from "./PurchaseOrderList-DoQhJuAG.js";
import { f as se, r as le } from "./validators-BArvL7cB.js";
import { M as ia } from "./MandateListing-BBhvpNKP.js";
import { B as sa } from "./BankAccountListing-maa4N6b9.js";
import { O as la } from "./Onboard-CEJ2xQwF.js";
import { A as da } from "./Auth-tZKY7y12.js";
function ra({ scheme: n, investment_account_id: U, investorProfile: S, onSuccess: ee, isGroupInvestment: o, isPartner: m, investment_accounts: T, user: _e, holdings: Y, assistedWorkflowPAN: ve, assistedWorkflowFolioNumber: de }) {
  const [_, te] = h(null), [D, B] = h(!1), [re, R] = h(!1), [me, ce] = h(!1), [fe, we] = h(null), [je, ae] = h(null), [Ke, He] = h(null), [ne, Ne] = h([]), { fpapi: q } = be(mt), { tenantConfig: f } = rt(), { loggedInPartner: $ } = be(ft), [he, Ce] = h(null), [Oe, Ue] = h(!1), [Re, V] = h(null), [pe, at] = h(!1), [Ye, ze] = h("initial"), { isMigratedUser: Se } = Mt({
    user: _e
  }), [Fe, Me] = h(!1), { AppState: { employer: ie } } = be(ht), H = ut(() => {
    var c, x, w, E, F, K;
    const l = (x = (c = f == null ? void 0 : f.fundList) == null ? void 0 : c.find((ke) => ke.isin === n.isin)) == null ? void 0 : x.nfo;
    return ((w = f == null ? void 0 : f.groupInvestment) == null ? void 0 : w.days) && ((E = f == null ? void 0 : f.groupInvestment) == null ? void 0 : E.cutOffDays) && At({
      salaryDay: (F = f == null ? void 0 : f.groupInvestment) == null ? void 0 : F.days[0],
      salaryCutoffDay: (K = f == null ? void 0 : f.groupInvestment) == null ? void 0 : K.cutOffDays[0],
      NFO: l,
      purchaseDate: null
    });
  }, [
    n
  ]), W = f == null ? void 0 : f.groupInvestment, k = ot((l, c) => {
    const x = [];
    return l.data.forEach((w) => {
      w.amc === c && x.push(w);
    }), x;
  }, []), De = ot((l) => {
    const c = [];
    return l.data.forEach((x) => {
      x.number === de && c.push(x);
    }), c;
  }, []);
  tt(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []), tt(() => {
    var l;
    if (!o && Re) {
      const c = Re.filter((x) => {
        const w = x.scheme === n.isin && !x.plan && (x.state === "pending" || x.state === "failed" && x.failure_code === "payment_failure");
        return fe ? w && x.folio_number === fe : w;
      });
      Ue(!!c.length);
    }
    (l = n == null ? void 0 : n.delivery_mode) != null && l.includes(z().toUpperCase()) || ze("scheme_not_allowed");
  }, [
    fe
  ]), tt(() => {
    (async () => {
      ce(!0);
      try {
        const l = q.fpClient.mf_folios().fetchAll({
          mf_investment_account: U
        }), c = q.fpClient.master_data().fetchAmcs();
        let x;
        o || (x = q.fpClient.mf_purchases().fetchAll({
          mf_investment_account: U,
          states: "pending,failed"
        }));
        const w = await Promise.all([
          l,
          c,
          x
        ]), E = w[1].amcs.find((K) => K.amc_id === n.amc_id);
        let F;
        if (m ? F = De(w[0]) : F = k(w[0], E.amc_code), !o) {
          const K = w[2].data.filter((e) => {
            const d = e.scheme === n.isin && !e.plan && (e.state === "pending" || e.state === "failed" && e.failure_code === "payment_failure");
            return F.length ? d && e.folio_number === F[0].number : d;
          }), ke = K.length ? K[0] : null;
          Ue(!!ke), V(w[2].data);
        }
        Ne(F), He(E.amc_code), Ce(E == null ? void 0 : E.amc_id), F.length ? (ae(F[0]), we(F[0].number), qe(F[0])) : Se && Me(!0);
      } catch (l) {
        ge(l);
      } finally {
        ce(!1);
      }
    })();
  }, [
    Se,
    Y
  ]);
  const z = () => {
    var l;
    return !A(je) && (je != null && je.dp_id) || !wt(T) && ((l = T[0].folio_defaults) != null && l.demat_account) ? "Demat" : "Physical";
  }, qe = (l) => {
    var c, x;
    if (Se) {
      const w = (c = Y == null ? void 0 : Y.folios) == null ? void 0 : c.filter((F) => F.folio_number === (l == null ? void 0 : l.number)), E = w.length ? (x = w[0].schemes) == null ? void 0 : x.find((F) => F.isin === n.isin) : [];
      A(E) ? Me(!0) : Me(!1);
    }
  };
  async function y({ amount: l }) {
    var x, w, E;
    const c = {
      mf_investment_account: U,
      scheme: n == null ? void 0 : n.isin,
      amount: +l,
      folio_number: fe,
      order_gateway: "rta"
    };
    o && (H != null && H.scheduled_on) && (c.scheduled_on = _t(H == null ? void 0 : H.scheduled_on, "yyyy-mm-dd")), m && $ && (c.partner = $), o && !A((x = f == null ? void 0 : f.groupInvestment) == null ? void 0 : x.employerPartner) && ((w = f == null ? void 0 : f.groupInvestment) != null && w.employerPartner[ie == null ? void 0 : ie.id]) && (c.partner = (E = f == null ? void 0 : f.groupInvestment) == null ? void 0 : E.employerPartner[ie == null ? void 0 : ie.id]), q.fpClient.mf_purchases().create(c).then((F) => {
      ee({
        mf_order: F,
        shouldOnboardMigratedUser: Fe
      });
    }).catch(function(F) {
      te(i._({
        id: "HbQ3GE"
      })), ge(F);
    });
  }
  function b() {
    return /* @__PURE__ */ t.jsx(We, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${i._({
        id: "50eZGd"
      })}`,
      isOpen: re,
      toggleModal: R,
      contentHeightMax: !0,
      children: /* @__PURE__ */ t.jsx(kt, {
        investorProfile: S,
        investment_accounts: T
      })
    });
  }
  function Ie() {
    return /* @__PURE__ */ t.jsx(We, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${i._({
        id: "845eUq"
      })}`,
      isOpen: D,
      toggleModal: B,
      contentHeightMax: !1,
      children: /* @__PURE__ */ t.jsx(Nt, {
        investorProfile: S,
        amc_code: Ke,
        amcId: he,
        handleFolioSelection: (l) => {
          qe(l), ae(l), we(l.number), B(!D);
        },
        handleFolioCreation: () => {
          Se && Me(!0), we(null), ae(null), B(!D);
        },
        scheme_details: void 0,
        isPartner: m,
        assistedWorkflowPAN: ve
      })
    });
  }
  function u() {
    return /* @__PURE__ */ t.jsx(We, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${i._({
        id: "ohPawJ"
      })}`,
      isOpen: pe,
      toggleModal: at,
      contentHeightMax: !0,
      children: /* @__PURE__ */ t.jsx(na, {
        isGroupInvestment: o,
        investor: S,
        mfInvestmentAccount: T == null ? void 0 : T[0],
        isPartner: m,
        searchParams: {
          type: "purchase",
          status: "pending,failed",
          folio_number: fe,
          isin: n.isin
        }
      })
    });
  }
  const s = () => _ ? /* @__PURE__ */ t.jsx(P, {
    title: _,
    description: `${i._({
      id: "I4jL42"
    })}`,
    showDetails: !1,
    showAction: !0,
    actionText: `${i._({
      id: "Oem4yT"
    })}`,
    actionHandler: () => {
      te(null);
    }
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(Ft, {
        onSubmit: y,
        validate: (l) => {
          const c = {};
          return l.amount || (c.amount = i._({
            id: "c0D/2W"
          })), l.amount && l.amount < n.min_initial_investment && (c.amount = i._({
            id: "9LeqUy",
            values: {
              0: xe(n.min_initial_investment)
            }
          })), l.amount && l.amount > n.max_initial_investment && (c.amount = i._({
            id: "DJzP4H",
            values: {
              0: xe(n.max_initial_investment)
            }
          })), c;
        },
        children: (l) => /* @__PURE__ */ t.jsxs("form", {
          id: "orderForm",
          onSubmit: l.handleSubmit,
          children: [
            /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(J, {
                  message: `${i._({
                    id: "4NN3aK"
                  })}`
                }),
                /* @__PURE__ */ t.jsx(Z, {
                  name: "amount",
                  children: (c) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(oe, {
                        id: c.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: c.input.name,
                        value: jt(c.input.value),
                        onChange: (x) => c.input.onChange(St(x.target.value)),
                        onInput: (x) => {
                          x.target.value = x.target.value.replace(ye("0-9"), "");
                        },
                        placeholder: `${i._({
                          id: "cRSGbK"
                        })}`,
                        prefix: "₹",
                        status: c.meta.error && c.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      c.meta.error && c.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ t.jsx(X, {
                          type: "error",
                          variant: "field",
                          title: c.meta.error
                        })
                      }) : /* @__PURE__ */ t.jsx(X, {
                        type: "helper",
                        variant: "field",
                        title: i._({
                          id: "bYw+YE",
                          values: {
                            0: xe(Number(n.min_initial_investment))
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ t.jsx(G, {
              check: o,
              children: /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(J, {
                    message: `${i._({
                      id: "5vGD9a"
                    })}`
                  }),
                  /* @__PURE__ */ t.jsxs("div", {
                    className: "flex items-start",
                    children: [
                      /* @__PURE__ */ t.jsx(vt, {
                        variant: "iconBased",
                        name: "paymentMethodRadio",
                        group: [
                          {
                            icon: "Salary",
                            label: "Salary"
                          }
                        ],
                        disabled: []
                      }),
                      /* @__PURE__ */ t.jsx(pt, {
                        border: !0,
                        symbol: (W == null ? void 0 : W.groupPartnerLogo) || (f == null ? void 0 : f.fallbackLogo),
                        label: W == null ? void 0 : W.groupPartnerName,
                        value: `${i._({
                          id: "yFOTgq",
                          values: {
                            0: H != null && H.consideredSalaryDate ? ` in ${new Date(H == null ? void 0 : H.consideredSalaryDate).toLocaleString("default", {
                              month: "long"
                            })} ${new Date(H == null ? void 0 : H.consideredSalaryDate).getFullYear()}` : ""
                          }
                        })}`,
                        customClass: "ml-3 flex-1"
                      })
                    ]
                  })
                ]
              })
            }),
            /* @__PURE__ */ t.jsx("div", {
              className: "pt-8",
              children: /* @__PURE__ */ t.jsx(lt, {
                type: "submit",
                fullwidth: !0,
                children: !o && !m ? i._({
                  id: "Ffad/b"
                }) : i._({
                  id: "H/E6fZ"
                })
              })
            })
          ]
        })
      }),
      Ie(),
      b(),
      u()
    ]
  }), v = () => {
    switch (Ye) {
      case "initial":
        return s();
      case "scheme_not_allowed":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "WddszM"
          }),
          description: i._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: i._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return s();
    }
  };
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: me ? /* @__PURE__ */ t.jsx(st, {
      variant: "fullpage"
    }) : v()
  });
}
function ca({ scheme: n, investment_account_id: U, onSuccess: S, investorProfile: ee, isGroupInvestment: o, isPartner: m, investment_accounts: T, user: _e, holdings: Y, assistedWorkflowPAN: ve }) {
  var F, K, ke;
  const { fpapi: de } = be(mt), { tenantConfig: _ } = rt(), { loggedInPartner: te } = be(ft), { AppState: { employer: D } } = be(ht), { isMigratedUser: B } = Mt({
    user: _e
  }), [re, R] = h(!1), [me, ce] = h(""), [fe, we] = h(null), [je, ae] = h("initial"), [Ke, He] = h(!1), [ne, Ne] = h(!1), [q, f] = h(!1), [$, he] = h(""), [Ce, Oe] = h(null), [Ue, Re] = h(null), [V, pe] = h(!1), [at, Ye] = h([]), [ze, Se] = h(!1), Fe = (e) => {
    Se(e);
  }, Me = /* @__PURE__ */ new Date(), ie = new Date((/* @__PURE__ */ new Date()).setDate(Me.getDate())).getDate(), [H, W] = h($e(ie, !1)), k = (F = _ == null ? void 0 : _.app_constants) == null ? void 0 : F.plans_mode, De = ((K = _ == null ? void 0 : _.app_constants) == null ? void 0 : K.non_systematic_min_installments) || 3;
  let z = Et(n.sip_frequency_specific_data, k);
  const qe = ((ke = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : ke.frequencyOptions) || [], y = ut(() => {
    var e, d, g, M, O, Q;
    if (o) {
      const Le = (d = (e = _ == null ? void 0 : _.fundList) == null ? void 0 : e.find((Te) => Te.isin === n.isin)) == null ? void 0 : d.nfo;
      return ((g = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : g.days) && ((M = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : M.cutOffDays) && Ht({
        salaryDay: (O = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : O.days[0],
        salaryCutoffDay: (Q = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : Q.cutOffDays[0],
        NFO: Le,
        purchaseDate: null
      });
    }
  }, [
    n
  ]);
  if (o) {
    const e = [];
    k === "systematic" ? (Object.keys(n == null ? void 0 : n.sip_frequency_specific_data).forEach((d, g) => {
      var M;
      qe.includes(d) && e.push({
        id: g,
        label: ((M = Ot(d)) == null ? void 0 : M.replace(/_/g, " ")) ?? "",
        value: d
      });
    }), z = e) : k === "nonSystematic" && (Object.values(z).forEach((d) => {
      qe.includes(d == null ? void 0 : d.value) && e.push(d);
    }), z = e);
  }
  tt(() => {
    var e;
    if (!((e = n == null ? void 0 : n.delivery_mode) != null && e.includes(Zt(Ce, T).toUpperCase()))) {
      ae("scheme_not_allowed");
      return;
    }
    !A(Ce) && u(Ce), l();
  }, [
    B
  ]);
  const [b, Ie] = h(z[0].value), u = (e) => {
    var d, g;
    if (B) {
      const M = (d = Y == null ? void 0 : Y.folios) == null ? void 0 : d.filter((Q) => Q.folio_number === (e == null ? void 0 : e.number)), O = M.length ? (g = M[0].schemes) == null ? void 0 : g.find((Q) => Q.isin === n.isin) : [];
      A(O) ? R(!0) : R(!1);
    }
  }, s = ut(() => {
    var e;
    return {
      frequency: b ? z == null ? void 0 : z.filter((d) => (d == null ? void 0 : d.value) === b)[0] : z[0],
      sip_start_date: ie,
      sip_instl_type: (e = it(b)) == null ? void 0 : e[0],
      sip_end_max_date: Ut(b),
      group_sip_start_date: y == null ? void 0 : y.installment_date,
      group_sip_end_date: k === "nonSystematic" ? new Date(y == null ? void 0 : y.installment_date.getFullYear(), (y == null ? void 0 : y.installment_date.getMonth()) + yt[b] * De, y == null ? void 0 : y.installment_date.getDate()) : new Date(y == null ? void 0 : y.installment_date.getFullYear(), (y == null ? void 0 : y.installment_date.getMonth()) + yt[b] * (n == null ? void 0 : n.sip_frequency_specific_data[b].min_installments), y == null ? void 0 : y.installment_date.getDate())
    };
  }, [
    b
  ]), v = ot((e, d) => {
    const g = [];
    return e.forEach((M) => {
      M.amc === d && g.push(M);
    }), g;
  }, []), l = async () => {
    try {
      const e = await de.fpClient.mf_folios().fetchAll({
        mf_investment_account: U
      }), g = (await de.fpClient.master_data().fetchAmcs()).amcs.find((O) => O.amc_id === n.amc_id), M = v(e.data, g.amc_code);
      Ye(M), Re(g.amc_code), M.length ? (Oe(M[0]), he(M[0].number)) : B && R(!0);
    } catch (e) {
      window.debug.log(e), ge(e);
    }
  }, c = (e, d, g) => {
    e.stopPropagation(), W($e(d, e.target.checked, g)), pe(!V);
  };
  async function x({ amount: e, frequency: d, installments: g, sip_start_date: M, sip_instl_type: O, number_of_days: Q, number_of_weeks: Le, number_of_installments: Te, number_of_months: Xe, number_of_quarters: Je, number_of_years: Ve }) {
    var Qe, Ze, Ge;
    ae("loading"), (O == null ? void 0 : O.value) === "perpetual" ? g = 1200 : Q || Le || Te || Xe || Je ? g = Q ?? Le ?? Te ?? Xe ?? Je : Ve && (g = Yt(d.value, Ve));
    try {
      const ue = {
        mf_investment_account: U.toString(),
        scheme: n.isin,
        frequency: d.value,
        folio_number: $,
        amount: +e,
        installment_day: d.value === "daily" ? void 0 : +M,
        number_of_installments: +g,
        systematic: k === "systematic",
        generate_first_installment_now: V,
        auto_generate_installments: !0,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null
      };
      if ((d == null ? void 0 : d.value) === "daily" && (M > (/* @__PURE__ */ new Date()).getDate() + 1 || M < (/* @__PURE__ */ new Date()).getDate())) {
        const et = new Date(Gt(+M, !1)), nt = et.setDate(et.getDate() - 1);
        ue.activate_after = _t(new Date(nt), "yyyy-mm-dd");
      }
      m && te && (ue.partner = te), o && !A((Qe = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : Qe.employerPartner) && ((Ze = _ == null ? void 0 : _.groupInvestment) != null && Ze.employerPartner[D == null ? void 0 : D.id]) && (ue.partner = (Ge = _ == null ? void 0 : _.groupInvestment) == null ? void 0 : Ge.employerPartner[D == null ? void 0 : D.id]), o && (y != null && y.activate_after) && (ue.activate_after = _t(y == null ? void 0 : y.activate_after, "yyy-mm-dd"));
      const Pe = await de.fpClient.mf_purchase_plans().create(ue);
      V && !o ? (ae("initial"), S({
        mf_order: Pe,
        isGenerateFirstInstallment: V,
        shouldOnboardMigratedUser: re
      })) : (ae("initial"), S({
        mf_order: Pe,
        shouldOnboardMigratedUser: re
      }));
    } catch (ue) {
      ae("initial"), ce(i._({
        id: "HbQ3GE"
      })), ge(ue);
    }
  }
  const w = () => /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(G, {
        check: !!me,
        children: /* @__PURE__ */ t.jsx(P, {
          title: me,
          description: `${i._({
            id: "mLEhv8"
          })}`,
          showDetails: !1,
          showAction: !0,
          actionText: `${i._({
            id: "RvsoP+"
          })}`,
          actionHandler: () => {
            ce(""), Ie(z[0].value);
          }
        })
      }),
      /* @__PURE__ */ t.jsx(G, {
        check: !me,
        children: /* @__PURE__ */ t.jsx(Ft, {
          keepDirtyOnReinitialize: !0,
          onSubmit: x,
          validate: (e) => {
            var M, O;
            const d = {}, g = (M = n == null ? void 0 : n.sip_frequency_specific_data[b]) == null ? void 0 : M.min_installments;
            return e.amount || (d.amount = i._({
              id: "c0D/2W"
            })), k === "systematic" && e.amount && e.amount < n.sip_frequency_specific_data[b].min_installment_amount && (d.amount = i._({
              id: "9LeqUy",
              values: {
                0: xe(n.sip_frequency_specific_data[b].min_installment_amount)
              }
            })), k === "systematic" && e.amount && e.amount > n.sip_frequency_specific_data[b].max_installment_amount && (d.amount = i._({
              id: "DJzP4H",
              values: {
                0: xe(n.sip_frequency_specific_data[b].max_installment_amount)
              }
            })), k === "systematic" && xt(e.amount, (O = n == null ? void 0 : n.sip_frequency_specific_data[b]) == null ? void 0 : O.amount_multiples) === !1 && (d.amount = i._({
              id: "O/Jkev",
              values: {
                0: n.sip_frequency_specific_data[b].amount_multiples
              }
            })), k === "systematic" && e.installments && Number(e.installments) < n.sip_frequency_specific_data[b].min_installments && (d.installments = i._({
              id: "NeTEIk",
              values: {
                0: n.sip_frequency_specific_data[b].min_installments
              }
            })), k === "systematic" && e.number_of_days && Number(e.number_of_days) < g && (d.number_of_days = i._({
              id: "Hs2tma",
              values: {
                currentSchemeMinimumInstallments: g
              }
            })), k === "systematic" && e.number_of_weeks && Number(e.number_of_weeks) < g && (d.number_of_weeks = i._({
              id: "5ZYiKc",
              values: {
                currentSchemeMinimumInstallments: g
              }
            })), k === "systematic" && e.number_of_installments && Number(e.number_of_installments) < g && (d.number_of_installments = i._({
              id: "JFGyQW",
              values: {
                currentSchemeMinimumInstallments: g
              }
            })), k === "systematic" && e.number_of_months && Number(e.number_of_months) < g && (d.number_of_months = i._({
              id: "iuifCd",
              values: {
                currentSchemeMinimumInstallments: g
              }
            })), k === "systematic" && e.number_of_quarters && Number(e.number_of_quarters) < g && (d.number_of_quarters = i._({
              id: "8NKzOT",
              values: {
                currentSchemeMinimumInstallments: g
              }
            })), k === "systematic" && e.number_of_years && Number(e == null ? void 0 : e.number_of_years) < bt(e.frequency.value, g) && (d.number_of_years = i._({
              id: "joH0Rd",
              values: {
                0: bt(e.frequency.value, g)
              }
            })), k === "systematic" && e.number_of_years && Number(e == null ? void 0 : e.number_of_years) > 100 && (d.number_of_years = i._({
              id: "LJwidR"
            })), e.installments > 1200 && (d.installments = i._({
              id: "kX+aWa"
            })), e.number_of_days > 1200 && (d.number_of_days = i._({
              id: "kX+aWa"
            })), e.number_of_months > 1200 && (d.number_of_months = i._({
              id: "kX+aWa"
            })), e.number_of_quarters > 1200 && (d.number_of_quarters = i._({
              id: "kX+aWa"
            })), e.number_of_weeks > 1200 && (d.number_of_weeks = i._({
              id: "kX+aWa"
            })), e.number_of_installments > 1200 && (d.number_of_installments = i._({
              id: "kX+aWa"
            })), k === "systematic" && o && !(n != null && n.sip_frequency_specific_data[b].dates.includes(y == null ? void 0 : y.installment_date.getDate())) && (d.frequency = i._({
              id: "S+LyGb"
            })), k === "nonSystematic" && e.amount && e.amount < Number(n[Be($).minInvestmentAmount]) && (d.amount = i._({
              id: "9LeqUy",
              values: {
                0: xe(Number(n[Be($).minInvestmentAmount]))
              }
            })), k === "nonSystematic" && e.amount && e.amount > Number(n[Be($).maxInvestmentAmount]) && (d.amount = i._({
              id: "DJzP4H",
              values: {
                0: xe(Number(n[Be($).maxInvestmentAmount]))
              }
            })), k === "nonSystematic" && xt(e.amount, Number(n[Be($).investmentAmountMultiples])) === !1 && (d.amount = i._({
              id: "O/Jkev",
              values: {
                0: Number(n[Be($).investmentAmountMultiples])
              }
            })), k === "nonSystematic" && e.installments && Number(e.installments) < De && (d.installments = i._({
              id: "fY6D71",
              values: {
                nonSipMinimumInstallments: De
              }
            })), k === "nonSystematic" && o && !Rt(b).includes(y == null ? void 0 : y.installment_date.getDate()) && (d.frequency = i._({
              id: "S+LyGb"
            })), d;
          },
          initialValues: s,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (e) => {
            var d, g, M, O, Q, Le, Te, Xe, Je, Ve, Qe, Ze, Ge, ue, Pe, et, nt;
            return /* @__PURE__ */ t.jsxs("form", {
              id: "recurringOrderForm",
              onSubmit: e.handleSubmit,
              children: [
                /* @__PURE__ */ t.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ t.jsx(J, {
                      message: `${i._({
                        id: "MznISI"
                      })}`
                    }),
                    /* @__PURE__ */ t.jsx(Z, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: se(le),
                      children: (a) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                        children: [
                          /* @__PURE__ */ t.jsx(oe, {
                            id: a.input.name,
                            autoComplete: "off",
                            type: "text",
                            name: a.input.name,
                            value: jt(a.input.value),
                            onChange: (r) => a.input.onChange(St(r.target.value)),
                            onInput: (r) => {
                              r.target.value = r.target.value.replace(ye("0-9"), "");
                            },
                            placeholder: `${i._({
                              id: "fK0Zpc"
                            })}`,
                            prefix: "₹",
                            status: a.meta.error && a.meta.touched ? "error" : void 0,
                            autoFocus: !0
                          }),
                          a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                            className: "mb-2 mt-2",
                            children: /* @__PURE__ */ t.jsx(X, {
                              type: "error",
                              variant: "field",
                              title: a.meta.error
                            })
                          }) : /* @__PURE__ */ t.jsx(X, {
                            type: "helper",
                            variant: "field",
                            title: `${i._({
                              id: "4NTS7E",
                              values: {
                                0: k === "systematic" ? `₹${xe(Number(n.sip_frequency_specific_data[b].min_installment_amount))}` : `₹${xe(Number(n.min_initial_investment))}`
                              }
                            })}`
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ t.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ t.jsx(J, {
                      message: `${i._({
                        id: "n30BLK"
                      })}`
                    }),
                    /* @__PURE__ */ t.jsx(Z, {
                      name: "frequency",
                      initialValue: s.frequency,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: se(le),
                      children: (a) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                        children: [
                          /* @__PURE__ */ t.jsx(Dt, {
                            name: a.input.name,
                            value: a.input.value,
                            onChange: (r) => {
                              var j, C, N, p;
                              Ie(r == null ? void 0 : r.value), e.form.change("sip_instl_type", it(r == null ? void 0 : r.value)[0]), (r == null ? void 0 : r.value) === "daily" && (ct((j = e == null ? void 0 : e.values) == null ? void 0 : j.sip_start_date, "daily") ? W($e((N = e == null ? void 0 : e.values) == null ? void 0 : N.sip_start_date, V, r == null ? void 0 : r.value)) : (pe(!1), W($e((C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_start_date, !1, r == null ? void 0 : r.value)))), (r == null ? void 0 : r.value) !== "daily" && W($e((p = e == null ? void 0 : e.values) == null ? void 0 : p.sip_start_date, V, r == null ? void 0 : r.value)), a.input.onChange(r);
                            },
                            options: z
                          }),
                          a.meta.error && a.meta.touched && /* @__PURE__ */ t.jsx(X, {
                            type: "error",
                            variant: "field",
                            title: a.meta.error
                          }),
                          a.input.value.value === "daily" && /* @__PURE__ */ t.jsx(X, {
                            type: "helper",
                            variant: "field",
                            title: `${i._({
                              id: "EsyhvE"
                            })}`,
                            customClass: "sm"
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ t.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ t.jsx(J, {
                      message: `${i._({
                        id: "onTXId"
                      })}`
                    }),
                    /* @__PURE__ */ t.jsx(Z, {
                      name: "sip_start_date",
                      validate: se(le),
                      allowNull: !0,
                      children: (a) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                        children: [
                          /* @__PURE__ */ t.jsxs(G, {
                            check: !o,
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                type: "text",
                                placeholder: "Select the day",
                                value: a.input.value !== null ? Bt(a.input.value, b) : "",
                                onChange: () => null,
                                suffix: /* @__PURE__ */ t.jsx(lt, {
                                  type: "button",
                                  variant: "text",
                                  onClick: () => Fe(!0),
                                  children: /* @__PURE__ */ t.jsx(qt, {
                                    name: "Date"
                                  })
                                })
                              }),
                              /* @__PURE__ */ t.jsx(It, {
                                name: a.input.name,
                                value: a.input.value,
                                isOpen: ze,
                                toggleDaySelector: Fe,
                                onSelectDay: (r) => {
                                  var j, C, N, p, I, L;
                                  W($e(r, V, (C = (j = e == null ? void 0 : e.values) == null ? void 0 : j.frequency) == null ? void 0 : C.value)), ct(r, (p = (N = e == null ? void 0 : e.values) == null ? void 0 : N.frequency) == null ? void 0 : p.value) || (W($e(r, !1, (L = (I = e == null ? void 0 : e.values) == null ? void 0 : I.frequency) == null ? void 0 : L.value)), pe(!1)), a.input.onChange(r);
                                },
                                selectedDay: a.input.value,
                                title: "Select SIP day"
                              }),
                              Wt(a) && /* @__PURE__ */ t.jsx(X, {
                                type: "error",
                                variant: "field",
                                title: a.meta.error
                              })
                            ]
                          }),
                          a.input.value && /* @__PURE__ */ t.jsx(X, {
                            type: "helper",
                            variant: "field",
                            title: H,
                            customClass: "sm"
                          })
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ t.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ t.jsx(J, {
                      message: `${i._({
                        id: "kLHce5"
                      })}`
                    }),
                    /* @__PURE__ */ t.jsx(Z, {
                      name: "sip_instl_type",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: se(le),
                      children: (a) => {
                        var r, j;
                        return /* @__PURE__ */ t.jsx(vt, {
                          name: a.input.name,
                          value: a.input.value,
                          group: it(b),
                          size: "sm",
                          checked: Kt(it(b), (j = (r = a == null ? void 0 : a.input) == null ? void 0 : r.value) == null ? void 0 : j.value),
                          onChange: (C, N) => {
                            a.input.onChange(N);
                          }
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((g = (d = e == null ? void 0 : e.values) == null ? void 0 : d.sip_instl_type) == null ? void 0 : g.value) === "installments",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "qG3axk"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "installments",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "installments")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((O = (M = e == null ? void 0 : e.values) == null ? void 0 : M.sip_instl_type) == null ? void 0 : O.value) === "number_of_days",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "QLtITp"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "number_of_days",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "number_of_days")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((Le = (Q = e == null ? void 0 : e.values) == null ? void 0 : Q.sip_instl_type) == null ? void 0 : Le.value) === "number_of_weeks",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "625YnO"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "number_of_weeks",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "number_of_weeks")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((Xe = (Te = e == null ? void 0 : e.values) == null ? void 0 : Te.sip_instl_type) == null ? void 0 : Xe.value) === "number_of_installments",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "YlD69/"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "number_of_installments",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "number_of_installments")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((Ve = (Je = e == null ? void 0 : e.values) == null ? void 0 : Je.sip_instl_type) == null ? void 0 : Ve.value) === "number_of_months",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "7nn6WE"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "number_of_months",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "number_of_months")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((Ze = (Qe = e == null ? void 0 : e.values) == null ? void 0 : Qe.sip_instl_type) == null ? void 0 : Ze.value) === "number_of_quarters",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "zSNgXM"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "number_of_quarters",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "number_of_quarters")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ((ue = (Ge = e == null ? void 0 : e.values) == null ? void 0 : Ge.sip_instl_type) == null ? void 0 : ue.value) === "number_of_years",
                  children: /* @__PURE__ */ t.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ t.jsx(J, {
                        message: `${i._({
                          id: "6hxDe3"
                        })}`
                      }),
                      /* @__PURE__ */ t.jsx(Z, {
                        name: "number_of_years",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: se(le),
                        children: (a) => {
                          var r, j, C, N, p, I;
                          return /* @__PURE__ */ t.jsxs(t.Fragment, {
                            children: [
                              /* @__PURE__ */ t.jsx(oe, {
                                id: a.input.name,
                                autoComplete: "off",
                                name: a.input.name,
                                onChange: a.input.onChange,
                                placeholder: Ae((j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value, (N = (C = e == null ? void 0 : e.values) == null ? void 0 : C.sip_instl_type) == null ? void 0 : N.value),
                                maxLength: 3,
                                status: a.meta.error && a.meta.touched ? "error" : void 0,
                                onInput: (L) => {
                                  L.target.value = L.target.value.replace(ye("0-9"), "");
                                },
                                inputMode: "numeric",
                                autoFocus: !0
                              }),
                              a.meta.error && a.meta.touched ? /* @__PURE__ */ t.jsx("div", {
                                className: "mb-2 mt-2",
                                children: /* @__PURE__ */ t.jsx(X, {
                                  type: "error",
                                  variant: "field",
                                  title: a.meta.error
                                })
                              }) : Ee(n.sip_frequency_specific_data[b].min_installments, (I = (p = e == null ? void 0 : e.values) == null ? void 0 : p.frequency) == null ? void 0 : I.value, "number_of_years")
                            ]
                          });
                        }
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ t.jsx(G, {
                  check: ct((Pe = e == null ? void 0 : e.values) == null ? void 0 : Pe.sip_start_date, (nt = (et = e == null ? void 0 : e.values) == null ? void 0 : et.frequency) == null ? void 0 : nt.value),
                  children: /* @__PURE__ */ t.jsx(Lt, {
                    variant: "cardType",
                    description: i._({
                      id: "ia1tKm",
                      values: {
                        0: m ? "Investor" : "You"
                      }
                    }),
                    id: "1",
                    checked: V,
                    onChange: (a) => {
                      var r, j;
                      return c(a, e.values.sip_start_date, (j = (r = e == null ? void 0 : e.values) == null ? void 0 : r.frequency) == null ? void 0 : j.value);
                    },
                    children: /* @__PURE__ */ t.jsx("span", {
                      className: "dark:text-primary-DN110",
                      children: "Start first installment today "
                    })
                  })
                }),
                /* @__PURE__ */ t.jsx("div", {
                  className: "pt-4",
                  children: /* @__PURE__ */ t.jsx(lt, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: "Confirm"
                  })
                })
              ]
            });
          }
        })
      }),
      /* @__PURE__ */ t.jsx(We, {
        variant: "fullPage",
        title: "Select mandate",
        isOpen: Ke,
        toggleModal: () => {
          He(!1);
        },
        contentHeightMax: !0,
        children: /* @__PURE__ */ t.jsx(ia, {
          investorProfile: ee,
          isPartner: m,
          handleMandateSelection: (e) => {
            we(e), He(!1);
          }
        })
      }),
      /* @__PURE__ */ t.jsx(We, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: ne,
        toggleModal: () => {
          Ne(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ t.jsx(Nt, {
          investorProfile: ee,
          amc_code: Ue,
          handleFolioSelection: (e) => {
            u(e), Oe(e), he(e.number), Ne(!ne);
          },
          handleFolioCreation: () => {
            B && R(!0), he(""), Oe(null), Ne(!ne);
          },
          assistedWorkflowPAN: ve,
          isPartner: m
        })
      }),
      /* @__PURE__ */ t.jsx(We, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: q,
        toggleModal: f,
        contentHeightMax: !0,
        children: /* @__PURE__ */ t.jsx(kt, {
          investorProfile: ee,
          investment_accounts: T
        })
      })
    ]
  });
  return (() => {
    switch (je) {
      case "initial":
        return w();
      case "loading":
        return /* @__PURE__ */ t.jsx(st, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "WddszM"
          }),
          description: i._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: i._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return w();
    }
  })();
}
const ua = (n, U) => Number(dt.get()) ? Number(dt.get()) : n && U ? null : n ? 1 : 0;
function oa(n) {
  var _e, Y, ve, de, _, te;
  const { tenantConfig: U } = rt(), { scheme: S } = n, ee = ((ve = (Y = (_e = U == null ? void 0 : U.app_constants) == null ? void 0 : _e.disabled_orders_mode) == null ? void 0 : Y.purchase) == null ? void 0 : ve.one_time) || !(S != null && S.purchase_allowed), o = ((te = (_ = (de = U == null ? void 0 : U.app_constants) == null ? void 0 : de.disabled_orders_mode) == null ? void 0 : _.purchase) == null ? void 0 : te.recurring) || !(S != null && S.sip_allowed), m = ua(ee, o);
  return (() => {
    const D = () => /* @__PURE__ */ t.jsx(ra, {
      ...n
    }), B = () => /* @__PURE__ */ t.jsx(ca, {
      ...n
    }), re = () => /* @__PURE__ */ t.jsx(P, {
      title: i._({
        id: "JHDCYv"
      }),
      description: i._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), R = () => /* @__PURE__ */ t.jsx(P, {
      title: i._({
        id: "FL10+5"
      }),
      description: i._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), me = () => ee && o ? [] : ee ? [
      {
        title: i._({
          id: "DUs4hi"
        }),
        content: n.scheme.purchase_allowed ? D() : re(),
        disabled: !0,
        disabledMessage: "One time is not allowed in this scheme"
      },
      {
        title: i._({
          id: "1VbNN2"
        }),
        content: n.scheme.sip_allowed ? B() : R()
      }
    ] : o ? [
      {
        title: i._({
          id: "DUs4hi"
        }),
        content: n.scheme.purchase_allowed ? D() : re()
      },
      {
        title: i._({
          id: "1VbNN2"
        }),
        content: n.scheme.sip_allowed ? B() : R(),
        disabled: !0,
        disabledMessage: "SIP is not allowed in this scheme"
      }
    ] : [
      {
        title: i._({
          id: "DUs4hi"
        }),
        content: n.scheme.purchase_allowed ? D() : re()
      },
      {
        title: i._({
          id: "1VbNN2"
        }),
        content: n.scheme.sip_allowed ? B() : R()
      }
    ];
    return /* @__PURE__ */ t.jsx(Tt, {
      selected: m,
      onChange: (ce) => {
        ce === 0 ? dt.set("0") : dt.set("1");
      },
      group: me()
    });
  })();
}
function Ra({ scheme: n, switch_out: U, isGroupInvestment: S }) {
  var y, b, Ie;
  const [ee, o] = h("order"), [m, T] = h(null), [_e, Y] = h(null), [ve, de] = h(null), [_, te] = h(null), [D, B] = h(null), [re, R] = h(!1), [me, ce] = h("purchase"), [fe, we] = h(null), [je, ae] = h(!1), [Ke, He] = h(null), { AppState: { employer: ne, tenant: Ne } } = be(ht), { fpapi: q } = be(mt), { loggedInUser: f } = be(ft), { tenantConfig: $ } = rt(), he = (y = $ == null ? void 0 : $.groupInvestment) == null ? void 0 : y.allowedEmailDomains;
  (b = $ == null ? void 0 : $.app_constants) != null && b.kyc_refetch_interval_in_months;
  const Ce = n != null && n.purchase_allowed ? ((Ie = $ == null ? void 0 : $.app_constants) == null ? void 0 : Ie.plans_mode) || "" : "systematic";
  tt(() => {
    pe();
  }, [
    $,
    f,
    n
  ]), Ct(() => {
    const u = sessionStorage.getItem("product"), s = n == null ? void 0 : n.isin;
    s && (sessionStorage.setItem("product", s), u !== s && Fe());
  }, []);
  const Oe = (u) => A(u) ? {
    success: !1,
    stageToRender: "scheme_not_found"
  } : !(u != null && u.active) || !(u != null && u.purchase_allowed) && !(u != null && u.sip_allowed) ? {
    success: !1,
    stageToRender: "purchase_not_allowed"
  } : {
    success: !0
  }, Ue = ({ tenantConfig: u, user: s, investorProfile: v }) => {
    var c;
    return (he == null ? void 0 : he[(c = s == null ? void 0 : s.email_address) == null ? void 0 : c.split("@")[1]]) ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "invalid_email_domain"
    };
  }, Re = ({ kycCheckObj: u, user: s, mfia: v }) => {
    var w, E;
    const l = ((w = s == null ? void 0 : s.annexure) == null ? void 0 : w.isonboarded) === "true";
    return !l || !(v != null && v.primary_investor) ? {
      success: !1,
      stageToRender: "onboard"
    } : l && !u.status ? {
      success: !1,
      stageToRender: "kyc_non_compliant"
    } : (E = v == null ? void 0 : v.folio_defaults) != null && E.payout_bank_account ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "bank_verification"
    };
  }, V = async (u) => {
    try {
      const s = await q.fpClient.investor_reports().fetchHoldings({
        investment_account_id: u == null ? void 0 : u.old_id
      });
      He(s);
    } catch (s) {
      window.debug.log(s, "Error");
    }
  }, pe = async () => {
    var u, s, v, l;
    try {
      if (R(!0), !f) {
        o("auth");
        return;
      }
      const c = Oe(n);
      if (!c.success) {
        o(c.stageToRender);
        return;
      }
      const x = [
        q.fetchUser(f.profile.preferred_username, Ne),
        q.getInvestorProfile((u = f == null ? void 0 : f.profile) == null ? void 0 : u.fp_identifier),
        q.fpClient.mf_investment_accounts().fetchAll({
          investor: (v = (s = f == null ? void 0 : f.profile) == null ? void 0 : s.preferred_username) == null ? void 0 : v.toUpperCase()
        })
      ], [w, E, F] = await Promise.all(x);
      if (!E) {
        window.debug.error("Investor profile is not present"), o("error_page");
        return;
      }
      const K = w == null ? void 0 : w[0], ke = (l = F == null ? void 0 : F.data) == null ? void 0 : l[0];
      if (S) {
        const O = Ue({
          tenantConfig: $,
          user: K,
          investorProfile: E
        });
        if (!O.success) {
          o(O.stageToRender);
          return;
        }
      }
      const [e, { isMigratedUser: d, migratedMfiaList: g, migratedFolioList: M }] = await Promise.all([
        z(K),
        zt(K, q)
      ]);
      d ? (V(ke), await Xt(q, K, g, M), e.status ? o("order") : o("onboard")) : o("order"), Y(K), te(E), B(F == null ? void 0 : F.data), we(e);
    } catch (c) {
      o("error_page"), window.debug.error(c), ge(c);
    } finally {
      R(!1);
    }
  };
  async function at(u, s) {
    if (A(s)) {
      o("finished"), T(u);
      return;
    }
    R(!0);
    try {
      const v = await q.fpClient.mf_purchases().fetch(s == null ? void 0 : s.id);
      T(v);
    } catch (v) {
      window.debug.log(v), ge(v);
    }
    o("payment"), R(!1);
  }
  const Ye = (u) => {
    A(u) || (S ? o("finished") : (m == null ? void 0 : m.object) === "mf_purchase" && (T(m), o("payment")));
  }, ze = async (u, s) => {
    if (u) {
      if (S)
        o("finished");
      else if (m.object === "mf_purchase_plan") {
        if (!A(s) && Ce === "nonSystematic") {
          const { emailData: v, phoneData: l } = await q.fetchEmailAndPhone(_);
          await q.fpClient.mf_purchases().update({
            id: s == null ? void 0 : s.id,
            consent: {
              email: v.email,
              isd_code: l.isd,
              mobile: l.number
            }
          });
        }
        at(m, s);
      }
    }
  };
  function Se(u) {
    const { mf_order: s, isGenerateFirstInstallment: v, shouldOnboardMigratedUser: l } = u;
    if (l) {
      T(s), o("onboard_migrated_user");
      return;
    }
    if ((s == null ? void 0 : s.object) === "mf_purchase") {
      T(s), o("purchase_confirmation");
      return;
    }
    if ((s == null ? void 0 : s.object) === "mf_purchase_plan") {
      T(s), ae(v), o("purchase_plan_confirmation");
      return;
    }
    (s == null ? void 0 : s.object) === "mf_switch" && (T(s), o("switch_confirmation")), (s == null ? void 0 : s.object) === "mf_switch_plan" && (T(s), o("switch_plan_confirmation"));
  }
  function Fe() {
    m && (m.object === "mf_purchase" || m.object === "mf_purchase_plan" ? ce("purchase") : ce("switch")), T({}), o("order");
  }
  function Me() {
    S ? o("order") : m.object === "mf_purchase" && q.fpClient.mf_purchases().retry(m == null ? void 0 : m.id).then(() => {
      window.debug.log("Order Retry successful"), o("payment");
    }).catch((u) => {
      Fe(), ge(u);
    });
  }
  function ie({ success: u, isWindowBlocked: s, paymentId: v }) {
    (async () => {
      if (v)
        try {
          const l = await q.fpClient.payments().fetch(Number(v));
          de(l);
          let c;
          m.plan ? c = await q.fpClient.mf_purchase_plans().fetch(m == null ? void 0 : m.plan) : c = await q.fpClient.mf_purchases().fetch(m.id), T(c);
        } catch (l) {
          window.debug.log(l), ge(l);
        }
      o(u && !s || !u && !s ? "finished" : "finished_error");
    })();
  }
  const H = async (u, s, v) => {
    try {
      R(!0);
      const l = await z(v), c = Re({
        kycCheckObj: l,
        user: v,
        mfia: s == null ? void 0 : s[0]
      });
      c.success ? o("order") : o(c.stageToRender), Y(v), we(l), te(u), B(s);
    } catch (l) {
      window.debug.error(l), ge(l), o("error_page");
    } finally {
      R(!1);
    }
  }, W = (u) => {
    T(u), o("finished");
  }, k = () => {
    var u;
    (u = q == null ? void 0 : q.userAuthService) == null || u.initiateLogout(window.location.href).then((s) => {
      window.location.href = s.userState;
    });
  }, De = async (u) => {
    var s;
    if (D != null && D.length)
      try {
        const v = (s = D == null ? void 0 : D[0]) == null ? void 0 : s.id, l = await q.fpClient.mf_investment_accounts().update({
          id: v,
          folio_defaults: {
            payout_bank_account: u == null ? void 0 : u.id
          }
        }), c = D.map((x) => {
          if (x.id === l.id) {
            const w = x;
            return w.folio_defaults.payout_bank_account = l.folio_defaults.payout_bank_account, w;
          } else
            return x;
        });
        B(c), o("order");
      } catch (v) {
        window.debug.error(v);
      }
  }, z = async (u) => {
    const l = `${(/* @__PURE__ */ new Date()).toISOString().split(".")[0]}+05:30`;
    return {
      id: "5620fd1f-eb14-442e-b0ee-da96a6c305c0",
      source_ref_id: null,
      pan: "BNBPP9384M",
      entity_details: {
        name: "Tony Soprano"
      },
      status: !0,
      constraints: null,
      sources: [
        {
          name: "kra-cams",
          fetched_at: l
        }
      ],
      created_at: l,
      updated_at: l,
      action: null,
      reason: null
    };
  };
  function qe() {
    switch (ee) {
      default:
        return /* @__PURE__ */ t.jsx(st, {
          variant: "fullpage"
        });
      case "auth":
        return /* @__PURE__ */ t.jsx(da, {
          redirectURL: window.location.href,
          allowRegistration: !0,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: S
        });
      case "onboard":
        return /* @__PURE__ */ t.jsx(la, {
          user: _e,
          isGroupInvestment: S || !1,
          setUser: Y,
          onBoardingComplete: H,
          isPartner: !1
        });
      case "bank_verification":
        return /* @__PURE__ */ t.jsx(sa, {
          investorProfile: _,
          handleBankSelection: De,
          customPageSubtitle: i._({
            id: "KM15wf"
          })
        });
      case "order":
        return wt(D) ? /* @__PURE__ */ t.jsx(t.Fragment, {}) : /* @__PURE__ */ t.jsx(oa, {
          scheme: n,
          switch_out: U,
          investment_account_id: D[0].id,
          investment_accounts: D,
          defaultInvestmentType: me,
          investorProfile: _,
          isGroupInvestment: S,
          onSuccess: Se,
          user: _e,
          holdings: Ke
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ t.jsx(_a, {
          onContinue: () => {
            window.location.href = `${window.location.origin + gt}/onboarding?${S ? `isGroupInvestment=${S}` : ""}${S ? `&employer=${ne == null ? void 0 : ne.id}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + gt}/${S ? "group-checkout" : "checkout"}/?isin=${n == null ? void 0 : n.isin}${S ? `&employer=${ne == null ? void 0 : ne.id}` : ""}`)}`;
          }
        });
      case "purchase_confirmation":
        return !A(_) && !A(m) ? /* @__PURE__ */ t.jsx(aa, {
          investorProfile: _,
          id: m.id,
          showSchemeDetails: !1,
          isGroupInvestment: S,
          onSuccess: Ye,
          action: "confirm"
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "purchase_plan_confirmation":
        return !A(_) && !A(m) ? /* @__PURE__ */ t.jsx(ta, {
          investorProfile: _,
          id: m.id,
          showSchemeDetails: !1,
          isGroupInvestment: S,
          onSuccess: (u, s) => ze(u, s),
          isFirstInstallmentPayment: je,
          action: "confirm"
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "payment":
        return !A(_) && !A(m) ? /* @__PURE__ */ t.jsx(ea, {
          investorProfile: _,
          mf_purchase: m,
          onCompletion: ie
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "finished":
        return !A(n) && !A(m) ? /* @__PURE__ */ t.jsx(Pt, {
          investorProfile: _,
          scheme: n,
          mf_order: m,
          payment: ve,
          isGroupInvestment: S,
          handleRetry: Me,
          handleInvestAgain: Fe
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "finished_error":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "sS+OUW"
          }),
          showAction: !0,
          actionText: i._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            o("payment");
          }
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "YzYf5v"
          }),
          description: i._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: i._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "scheme_not_found":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "+PjKpk"
          }),
          description: i._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: i._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "switch_confirmation":
        return !A(n) && !A(m) ? /* @__PURE__ */ t.jsx(Qt, {
          id: m == null ? void 0 : m.id,
          investorProfile: _,
          showSchemeDetails: !1,
          onSuccess: W,
          action: "confirm"
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "switch_plan_confirmation":
        return !A(n) && !A(m) ? /* @__PURE__ */ t.jsx(Vt, {
          id: m.id,
          investorProfile: _,
          onSuccess: W,
          showSchemeDetails: !1,
          action: "confirm"
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "invalid_email_domain":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "ZXjii8"
          }),
          description: i._({
            id: "mpazQQ"
          }),
          showDetails: !1,
          actionText: i._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: k
        });
      case "invalid_employer":
        return /* @__PURE__ */ t.jsx(P, {
          title: i._({
            id: "8gLo60"
          }),
          description: i._({
            id: "REewWf"
          }),
          showDetails: !1,
          actionText: i._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: k
        });
      case "kyc_non_compliant":
        return fe ? /* @__PURE__ */ t.jsx(Jt, {
          kycCheckObject: fe,
          customMessage: {
            unverified: i._({
              id: "V2UMyt"
            })
          }
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "error_page":
        return /* @__PURE__ */ t.jsx(P, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return re ? /* @__PURE__ */ t.jsx(st, {
    variant: "fullpage"
  }) : qe();
}
const _a = ({ onContinue: n }) => /* @__PURE__ */ t.jsxs("div", {
  children: [
    /* @__PURE__ */ t.jsx($t, {
      title: i._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ t.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ t.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ t.jsx(lt, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => n(),
        children: "Continue"
      })
    })
  ]
});
export {
  Ra as I,
  oa as O
};
