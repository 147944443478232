import { useAuth } from "fpapps/useAuth";
import { Icon, Dropdown } from "pixel";

export default function OauthLogout() {
  const { isLoading, isAuthenticated, logout } = useAuth();

  return !isLoading && isAuthenticated ? (
    <li className="flex items-center pl-4">
      <Dropdown
        variant="withAvatar"
        placement="bottomRight"
        dropdownOptions={[
          {
            id: "1",
            label: "Logout",
            clicked: () => {
              logout();
            },
          },
        ]}
      >
        <Icon name="Avatar" />
      </Dropdown>
    </li>
  ) : null;
}
