import { j as s } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as z, useState as u, useMemo as he, useLayoutEffect as Me, useEffect as re } from "react";
import { a as ue, M as G, B as p, O as ae, S as Ce, p as Ne, C as V, c as B, E as Fe, G as qe, H as Ae } from "./index.esm-DT3FM8Og.js";
import { u as be } from "./useConfiguration-B-lyaBB9.js";
import { F as xe } from "./provider-DPPRdC-N.js";
import { r as le } from "./sentry-CbZOQ4Ya.js";
import { W as Oe, H as ee, G as Ue, C as h, m as J, U as Ye, j as fe, an as de, as as _e, Q as We, i as Te, f as Ze, ar as ke, Z as Xe, B as ze, ak as Ge, c as He, k as Ie, n as Ee } from "./utils-DbnU7rHI.js";
import { i as t } from "./index-Bb24DwqC.js";
import { AppContext as ie } from "./AppContext.js";
import { C as te } from "./CopyLinkButton-DfiX-NPG.js";
import { S as K, C as Se } from "./StatusMessage-PK7bu2oh.js";
import { m as me, M as Qe } from "./MandateListing-BBhvpNKP.js";
const Ve = [
  "pending",
  "cancelled",
  "confirmed",
  "submitted",
  "successful",
  "failed",
  "reversed"
], Je = [
  "pending",
  "success",
  "failed",
  "timedout",
  "initiated",
  "approved",
  "rejected",
  "submitted",
  ""
], Ke = [
  [
    "awaitingPayment",
    "underProcess",
    "failed",
    "",
    "awaitingPayment",
    "",
    "",
    "",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "",
    "reversed"
  ]
], Re = [
  [
    "awaitingPayment",
    "underProcess",
    "",
    "",
    "awaitingPayment",
    "",
    "",
    "underProcess",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed"
  ]
], Pe = (a) => {
  const e = `Your purchase reference is ${a}`;
  return {
    paymentNotFound: {
      title: "Awaiting payment",
      description: e,
      buttonText: "Complete payment",
      icon: "Alert",
      helpText: "Your order will be processed after payment completion",
      helpTextType: "alert"
    },
    awaitingPayment: {
      title: "Payment processing",
      description: e,
      buttonText: "Invest again",
      icon: "Alert",
      helpText: "We are waiting for payment confirmation from your bank. You will be able to complete payment after 10 minutes if the payment is not successful.",
      helpTextType: "alert"
    },
    underProcess: {
      title: "Under process",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    successful: {
      title: "Successful",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    cancelled: {
      title: "Cancelled",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    reversed: {
      title: "Reversed",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    paymentUnsuccessful: {
      title: "Payment failed",
      description: e,
      buttonText: "Retry payment",
      icon: "Error",
      helpText: "The amount debited from your bank account will be refunded within 5 working days. You can retry payment after 30 mins to complete this order.",
      helpTextType: "alert"
    },
    expired: {
      title: "Expired",
      description: e,
      buttonText: "Invest again",
      icon: "Error",
      helpText: "Order expired due to pending payment",
      helpTextType: "important"
    },
    failed: {
      title: "Failed",
      description: e,
      buttonText: "Invest again",
      icon: "Error"
    }
  };
};
function Le({ investorProfile: a, scheme: e, mf_purchase: i, payment: o, handleInvestAgain: l, handleRetry: b, showSchemeName: c, isGroupInvestment: d, isPartner: n, isFotOrder: C }) {
  var M;
  const { AppState: { tenant: f } } = z(ie), g = i.state.toLowerCase(), _ = o ? (M = o == null ? void 0 : o.status) == null ? void 0 : M.toLowerCase() : "", I = i.failure_code, [L, w] = u(t._({
    id: "s0Estp"
  })), [$, S] = u(""), [r, E] = u("Error"), [N, O] = u(t._({
    id: "wd9ucl"
  })), [Q, H] = u(""), [q, R] = u(""), [A, j] = u(null), [U, F] = u(!1), [se, ne] = u(null), { fpapi: oe } = z(xe), { tenantConfig: k, banks: Y, amcs: ye } = be(), W = he(() => {
    var y, v, D, m, X, P;
    const x = (v = (y = k == null ? void 0 : k.fundList) == null ? void 0 : y.find((Z) => Z.isin === e.isin)) == null ? void 0 : v.nfo;
    return ((D = k == null ? void 0 : k.groupInvestment) == null ? void 0 : D.days) && ((m = k == null ? void 0 : k.groupInvestment) == null ? void 0 : m.cutOffDays) && Oe({
      salaryDay: (X = k == null ? void 0 : k.groupInvestment) == null ? void 0 : X.days[0],
      salaryCutoffDay: (P = k == null ? void 0 : k.groupInvestment) == null ? void 0 : P.cutOffDays[0],
      NFO: x,
      purchaseDate: i.created_at
    });
  }, [
    e
  ]);
  Me(() => {
    d ? je() : ge();
  }, []);
  const ve = (x) => d ? "Salary" : x ? Ye(x) : "-";
  async function ge() {
    if (o && a) {
      F(!0);
      try {
        const { data: m } = await oe.fpClient.bank_accounts().fetchAll({
          profile: a == null ? void 0 : a.id
        }), X = m.find((P) => P.old_id === (o == null ? void 0 : o.from_bank_account_id));
        ne(X);
      } catch (m) {
        le(m);
      } finally {
        F(!1);
      }
    }
    const x = Ve.indexOf(g), y = Je.indexOf(_);
    if (x === -1 || y === -1) return;
    let v;
    (o == null ? void 0 : o.method) === "EMANDATE" ? v = Re[x][y] : v = Ke[x][y], v === "failed" && I === "order_expiry" && (v = "expired");
    const D = Pe(i == null ? void 0 : i.id)[v];
    D && (w(D.title), S(n ? t._({
      id: "znLBZG",
      values: {
        0: i.id
      }
    }) : t._({
      id: "sNrEAc",
      values: {
        0: i.id
      }
    })), O(n ? t._({
      id: "quOB9l"
    }) : D.buttonText), E(D.icon), H(n ? t._({
      id: "5SyDtU"
    }) : D.helpText), R(D.helpTextType));
  }
  async function je() {
    F(!0);
    try {
      const x = await oe.fpClient.mf_settlement_details().fetchAll({
        mf_purchase: i.id
      });
      j(x);
    } catch (x) {
      window.debug.error(x), le(x);
    }
    F(!1), g === "created" || g === "pending" || g === "submitted" || g === "confirmed" ? (S(`Your purchase reference is ${i.id}`), w(t._({
      id: "oiliCI"
    })), O(t._({
      id: "quOB9l"
    })), E("successInfo")) : g === "successful" ? (w(t._({
      id: "EbM80r"
    })), S(t._({
      id: "sNrEAc",
      values: {
        0: i.id
      }
    })), O(t._({
      id: "quOB9l"
    })), E("successInfo")) : g === "failed" && (w(t._({
      id: "mVq3N+"
    })), S(t._({
      id: "WX839d"
    })), O(t._({
      id: "quOB9l"
    })), E("Error"));
  }
  function De() {
    return d ? g === "failed" ? b : l : n ? l : g === "pending" && !_ || g === "failed" && _ === "failed" ? b : l;
  }
  function we() {
    return !!(d && !A && g === "pending");
  }
  function ce(x, y, v) {
    var m;
    const D = [
      {
        label: t._({
          id: "4Zu577"
        }),
        value: `₹ ${h(x.amount)}`
      },
      {
        label: t._({
          id: "rs9/UI"
        }),
        value: "One time"
      },
      {
        label: t._({
          id: "ItmT+q"
        }),
        value: x.created_at ? J(new Date(x.created_at), "dd-MMM-yyyy") : "-"
      },
      {
        label: t._({
          id: "cXcPd1"
        }),
        value: x.folio_number ? x.folio_number : "New folio"
      },
      !n && {
        label: t._({
          id: "lFCwug"
        }),
        value: ve(y == null ? void 0 : y.method)
      },
      !n && {
        label: t._({
          id: "0EjvnG"
        }),
        value: d ? A ? A.id : "-" : y != null && y.id ? y.id : "-"
      }
    ];
    return v && !d && D.push({
      label: t._({
        id: "/dl6Or"
      }),
      colSpan: "basis-full",
      value: v ? `${v.bank_name} (*****${v.account_number.slice(-4)})` : "-",
      logo: Y[(m = v == null ? void 0 : v.ifsc_code) == null ? void 0 : m.slice(0, 4)] || (k == null ? void 0 : k.fallbackLogo)
    }), D;
  }
  function T() {
    return /* @__PURE__ */ s.jsx(ae, {
      title: c && e.name,
      tableData: ce(i, o, se)
    });
  }
  return U ? /* @__PURE__ */ s.jsx(ue, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      " ",
      /* @__PURE__ */ s.jsx(K, {
        title: L,
        description: $,
        iconName: r,
        children: T()
      }),
      we() && /* @__PURE__ */ s.jsx(G, {
        type: "helper",
        variant: "flash",
        title: t._({
          id: "Gi+PAV",
          values: {
            0: W != null && W.consideredSalaryDate ? `in ${new Date(W == null ? void 0 : W.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(W == null ? void 0 : W.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      Q && /* @__PURE__ */ s.jsx(G, {
        type: q,
        variant: "flash",
        title: Q,
        customClass: "mb-5"
      }),
      n && !C && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(f) + `/consents?object_type=mf_purchase&action=confirm&id=${i.id}`,
        buttonText: "consent & payment link",
        customClass: "mb-2"
      }),
      (l || b && Ue(o == null ? void 0 : o.created_at)) && /* @__PURE__ */ s.jsx(p, {
        variant: "secondary",
        fullwidth: !0,
        onClick: De(),
        customClass: "mb-2",
        children: N
      })
    ]
  });
}
const pe = (a, e) => {
  var C;
  const i = /* @__PURE__ */ new Date(), o = (/* @__PURE__ */ new Date()).getDate(), l = i.getFullYear(), b = i.getMonth(), c = new Date(l, b, a), d = new Date(l, b + 1, a);
  let n;
  return a ? (a <= o ? n = new Date(d) : a > o ? n = new Date(c) : n = new Date(c), e && (n = /* @__PURE__ */ new Date()), n == null ? void 0 : n.toString()) : (C = /* @__PURE__ */ new Date()) == null ? void 0 : C.toString();
}, Dt = (a, e, i, o, l) => {
  var b;
  return /* @__PURE__ */ s.jsxs("div", {
    style: {
      marginTop: "-20px"
    },
    children: [
      /* @__PURE__ */ s.jsx(Ce, {
        customClass: "mb-8",
        children: /* @__PURE__ */ s.jsxs(Ne, {
          children: [
            /* @__PURE__ */ s.jsx(V, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(B, {
                label: t._({
                  id: "4Zu577"
                }),
                value: a != null && a.amount ? `₹ ${h(a == null ? void 0 : a.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(V, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(B, {
                label: t._({
                  id: "UMXPdU"
                }),
                value: a != null && a.frequency ? (b = fe(a == null ? void 0 : a.frequency)) == null ? void 0 : b.replace(/_/g, " ") : "-"
              })
            }),
            /* @__PURE__ */ s.jsx(V, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(B, {
                label: t._({
                  id: "cXcPd1"
                }),
                value: a != null && a.folio_number ? a == null ? void 0 : a.folio_number : "New folio"
              })
            }),
            /* @__PURE__ */ s.jsx(V, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(B, {
                label: t._({
                  id: "ZS3Gyi"
                }),
                value: de(a == null ? void 0 : a.number_of_installments)
              })
            }),
            /* @__PURE__ */ s.jsx(V, {
              span: 6,
              children: /* @__PURE__ */ s.jsx(B, {
                label: t._({
                  id: "cki4uJ"
                }),
                value: _e(a == null ? void 0 : a.frequency, pe(a == null ? void 0 : a.installment_day, l))
              })
            }),
            /* @__PURE__ */ s.jsx(Se, {
              check: !!(a != null && a.end_date),
              children: /* @__PURE__ */ s.jsx(V, {
                span: 6,
                children: /* @__PURE__ */ s.jsx(B, {
                  label: t._({
                    id: "gqWUiZ"
                  }),
                  value: a != null && a.end_date ? We(a == null ? void 0 : a.end_date) : "-"
                })
              })
            }),
            /* @__PURE__ */ s.jsxs(Se, {
              check: !!e,
              children: [
                /* @__PURE__ */ s.jsx(V, {
                  span: 6,
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "6SUdWB"
                    }),
                    value: de(e == null ? void 0 : e.name)
                  })
                }),
                /* @__PURE__ */ s.jsx(V, {
                  span: 6,
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "cCU8Ub"
                    }),
                    value: de(e == null ? void 0 : e.license_code)
                  })
                })
              ]
            })
          ]
        })
      }),
      /* @__PURE__ */ s.jsx(Se, {
        check: !!l,
        children: /* @__PURE__ */ s.jsx(G, {
          type: "important",
          variant: "flash",
          title: t._({
            id: "JbUeSI"
          }),
          customClass: "mb-5"
        })
      })
    ]
  });
}, et = ({ mandate: a, banks: e, fallbackLogo: i }) => {
  var b, c, d, n;
  const [o, l] = u(!1);
  return a ? /* @__PURE__ */ s.jsx("div", {
    className: "mb-4",
    children: /* @__PURE__ */ s.jsxs(Fe, {
      expanded: o,
      onChange: () => l(!o),
      variant: "panel",
      children: [
        /* @__PURE__ */ s.jsx(qe, {
          active: !0,
          variant: "panel",
          children: /* @__PURE__ */ s.jsx("div", {
            className: "flex items-baseline",
            children: /* @__PURE__ */ s.jsx("span", {
              className: "mr-2",
              children: "Mandate details"
            })
          })
        }),
        /* @__PURE__ */ s.jsx(Ae, {
          variant: "panel",
          children: /* @__PURE__ */ s.jsx(Ce, {
            children: /* @__PURE__ */ s.jsxs(Ne, {
              children: [
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "JVRi6u"
                    }),
                    value: `${(b = a.bank) == null ? void 0 : b.bank_name} (*****${(c = a.bank) == null ? void 0 : c.number.slice(-4)})`,
                    logo: e[(n = (d = a.bank) == null ? void 0 : d.ifsc) == null ? void 0 : n.slice(0, 4)] || i
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "OzNl8x"
                    }),
                    value: a ? me[a.mandate_status].value : "-",
                    icon: a ? me[a.mandate_status].icon : "",
                    iconPlacement: "left"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "nlXCcY"
                    }),
                    value: "NACH"
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "Zp6kam"
                    }),
                    value: `₹ ${h(a.mandate_limit)}`
                  })
                }),
                /* @__PURE__ */ s.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ s.jsx(B, {
                    label: t._({
                      id: "0OJ5oD"
                    }),
                    value: J(new Date(a.valid_from), "dd-MMM-yyyy")
                  })
                })
              ]
            })
          }, a.id)
        })
      ]
    })
  }) : /* @__PURE__ */ s.jsx(s.Fragment, {});
}, wt = (a) => {
  switch (a) {
    case "daily":
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "4UB9pl"
          }),
          value: "number_of_days"
        }
      ];
    case "day_in_a_week":
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "cpH9Hi"
          }),
          value: "number_of_weeks"
        }
      ];
    case "four_times_a_month":
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "0A81Au"
          }),
          value: "number_of_instalments"
        }
      ];
    case "half-yearly":
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "cpH9Hi"
          }),
          value: "number_of_weeks"
        },
        {
          label: t._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    case "monthly":
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "fBXQWJ"
          }),
          value: "number_of_months"
        },
        {
          label: t._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    case "quarterly":
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "UpUWDH"
          }),
          value: "number_of_quarters"
        },
        {
          label: t._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    default:
      return [
        {
          label: t._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: t._({
            id: "vEYjLl"
          }),
          value: "installments"
        }
      ];
  }
}, St = (a, e) => {
  var i;
  return !Te(a) && (a != null && a.dp_id) || !Ze(e) && ((i = e[0].folio_defaults) != null && i.demat_account) ? "Demat" : "Physical";
}, $e = (a, e) => {
  const i = /* @__PURE__ */ new Date(), o = i.getFullYear(), l = i.getMonth(), b = (/* @__PURE__ */ new Date()).getDate(), c = new Date(o, l + 1, a), d = new Date(o, l, a);
  let n;
  return a <= b ? n = new Date(c) : a > b ? n = new Date(d) : n = new Date(d), e && (n = /* @__PURE__ */ new Date()), n;
}, Tt = (a, e, i) => {
  let o = $e(a, e);
  const l = /* @__PURE__ */ new Date();
  l.setDate(a);
  const b = new Date(l.setDate(l.getDate() + 1));
  return i === "daily" && !e && a === (/* @__PURE__ */ new Date()).getDate() && (o = b), t._({
    id: "wGedtw",
    values: {
      0: J(o, "dd-MMM-yyyy")
    }
  });
}, Mt = (a, e) => {
  const i = (/* @__PURE__ */ new Date()).getDate(), o = new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() + 2)).getDate();
  return !((a < i || a >= o) && e === "daily");
};
function tt(a, e) {
  const i = (/* @__PURE__ */ new Date()).getFullYear(), l = i % 4 === 0 && i % 100 !== 0 || i % 400 === 0 ? 366 : 365, b = {
    daily: l,
    day_in_a_week: l / 7,
    four_times_a_month: 4 * 12,
    day_in_a_fortnight: l / 14,
    twice_a_month: 2 * 12,
    monthly: 12,
    quarterly: 4,
    "half-yearly": 2,
    yearly: 1
  };
  return Math.ceil(e / b[a]);
}
const kt = (a, e, i) => {
  let o = `Minimum number of installments is ${a}`;
  const l = tt("monthly", a);
  return e === "daily" ? o = `The minimum duration for creating a SIP is ${h(a)} ${a > 1 ? "days" : "day"}` : e === "monthly" && i === "number_of_months" ? o = `The minimum duration for creating a SIP is ${h(a)} months` : e === "monthly" && i === "number_of_years" ? o = `The minimum duration for creating a SIP is ${h(l)} ${l > 1 ? "years" : "year"}` : e === "quarterly" && i === "number_of_quarters" ? o = `The minimum duration for creating a SIP is ${h(a)} quarters` : e === "quarterly" && i === "number_of_years" ? o = `The minimum duration for creating a SIP is ${h(l)} ${l > 1 ? "years" : "year"}` : e === "day_in_a_week" && i === "number_of_weeks" ? o = `The minimum duration for creating a SIP is ${h(a)} weeks` : e === "half-yearly" && i === "number_of_installments" ? o = `Minimum number of installment is ${h(a)}` : e === "half-yearly" && i === "number_of_years" ? o = `The minimum duration for creating a SIP is ${h(l)} ${l > 1 ? "years" : "year"}` : e === "four_times_a_month" && i === "number_of_installments" ? o = `Minimum number of installment is ${h(a)}` : e === "twice_a_month" && i === "number_of_installments" ? o = `Minimum number of installment is ${h(a)}` : e === "day_in_a_fortnight" && i === "number_of_installments" ? o = `Minimum number of installment is ${h(a)}` : o = `Minimum number of installment is ${h(a)}`, /* @__PURE__ */ s.jsx(G, {
    type: "helper",
    variant: "field",
    title: t._({
      id: "L6QJuZ",
      values: {
        helperMessage: o
      }
    })
  });
}, mt = (a, e) => {
  const i = `${a}_${e}`, o = {
    daily_number_of_days: "Enter number of days",
    monthly_number_of_months: "Enter number of months",
    quarterly_number_of_years: "Enter number of years",
    "half-yearly_number_of_years": "Enter number of years",
    monthly_number_of_years: "Enter number of years",
    quarterly_number_of_quarters: "Enter number of quarters",
    day_in_a_week_number_of_weeks: "Enter number of weeks",
    "half-yearly_number_of_installments": "Enter number of installments",
    four_times_a_month_number_of_installments: "Enter number of installments",
    twice_a_month_number_of_installments: "Enter number of installments",
    day_in_a_fortnight_number_of_installments: "Enter number of installments"
  };
  return o[i] ? t._({
    id: "ev7haI",
    values: {
      0: o[i]
    }
  }) : t._({
    id: "LQjwJ/"
  });
};
function st({ scheme: a, mf_purchase_plan: e, payment: i, handleInvestAgain: o, handleRetry: l, showSchemeName: b, isGroupInvestment: c, isPartner: d, investorProfile: n, isFotOrder: C }) {
  var ce;
  let f = e.state.toLowerCase();
  const g = i != null && i.status ? (ce = i == null ? void 0 : i.status) == null ? void 0 : ce.toLowerCase() : "", [_, I] = u(""), [L, w] = u(""), [$, S] = u(""), [r, E] = u(t._({
    id: "wd9ucl"
  })), [N, O] = u(null), [Q, H] = u(!1), [q, R] = u(!1), { fpapi: A } = z(xe), { tenantConfig: j, banks: U } = be(), F = j == null ? void 0 : j.fallbackLogo, se = ke.get(), { AppState: { tenant: ne } } = z(ie), [oe, k] = u(t._({
    id: "bjf3K2"
  })), Y = he(() => {
    var T, M, x, y, v, D;
    if (c) {
      const m = (M = (T = j == null ? void 0 : j.fundList) == null ? void 0 : T.find((X) => X.isin === a.isin)) == null ? void 0 : M.nfo;
      return ((x = j == null ? void 0 : j.groupInvestment) == null ? void 0 : x.days) && ((y = j == null ? void 0 : j.groupInvestment) == null ? void 0 : y.cutOffDays) && Xe({
        salaryDay: (v = j == null ? void 0 : j.groupInvestment) == null ? void 0 : v.days[0],
        salaryCutoffDay: (D = j == null ? void 0 : j.groupInvestment) == null ? void 0 : D.cutOffDays[0],
        NFO: m,
        purchaseDate: e == null ? void 0 : e.created_at
      });
    }
  }, [
    a
  ]);
  re(() => {
    c ? ve() : je();
  }, []), re(() => {
    ye(q) && k("SIP activates once the investor sets up a payment method and gives consent");
  }, [
    q
  ]);
  const ye = (T) => !!(T && (T.mandate_status.toUpperCase() === "CREATED" || T.mandate_status.toUpperCase() === "RECEIVED")), W = async (T) => {
    var M, x, y;
    if (T)
      try {
        const v = await A.fpClient.mandates().fetch(Number(T)), D = await A.fpClient.bank_accounts().fetchAll({
          profile: n == null ? void 0 : n.id
        }), m = D == null ? void 0 : D.data;
        return m.length ? {
          ...v,
          bank: {
            bank_name: (M = m[0]) == null ? void 0 : M.bank_name,
            number: (x = m[0]) == null ? void 0 : x.account_number,
            ifsc: (y = m[0]) == null ? void 0 : y.ifsc_code
          }
        } : v;
      } catch (v) {
        window.debug.log(v), le(v);
      }
  }, ve = () => {
    f === "created" || f === "active" || f === "completed" ? (I("SIP registered"), w(t._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), E(t._({
      id: "quOB9l"
    })), S("successInfo")) : f === "failed" && (I("SIP Failed"), w(t._({
      id: "tNBTdB"
    })), E(t._({
      id: "quOB9l"
    })), S("Error"));
  }, ge = async (T) => {
    var X, P;
    let M;
    try {
      M = await A.fpClient.mf_purchases().fetchAll({
        plan: T == null ? void 0 : T.id
      });
    } catch (Z) {
      window.debug.log(Z), le(Z);
    }
    if (!((X = M == null ? void 0 : M.data) != null && X.length))
      return null;
    let x;
    (P = M == null ? void 0 : M.data) != null && P.length && (x = M.data.filter((Z) => Z.state === "confirmed" || Z.state === "successful" || Z.state === "submitted" || Z.state === "pending").sort((Z, Be) => new Date(Z.scheduled_on).getTime() - new Date(Be.scheduled_on).getTime())[0]);
    const y = new Date(T == null ? void 0 : T.created_at), v = new Date(new Date(y).setDate(y.getDate() + 1)), D = new Date(new Date(y).setDate(y.getDate() + He(y))), m = new Date(x == null ? void 0 : x.scheduled_on);
    return m.toDateString() === y.toDateString() || m.toDateString() === v.toDateString() || m.toDateString() === D.toDateString() ? x : null;
  }, je = async () => {
    H(!0), f = e.state.toLowerCase();
    const T = e == null ? void 0 : e.payment_source, M = await ge(e);
    !Te(M) && !Te(i) ? (O(M), f === "created" || f === "active" || f === "completed" ? (I(t._({
      id: "c6uuOj"
    })), E(t._({
      id: "quOB9l"
    })), w(d ? t._({
      id: "8d5Ob9",
      values: {
        0: e.id
      }
    }) : t._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), S("successInfo")) : f === "failed" && (I(t._({
      id: "uXDSGu"
    })), w(t._({
      id: "tNBTdB"
    })), E(t._({
      id: "quOB9l"
    })), S("Error"))) : f === "created" || f === "active" || f === "completed" ? (I(t._({
      id: "c6uuOj"
    })), E(t._({
      id: "quOB9l"
    })), w(d ? t._({
      id: "8d5Ob9",
      values: {
        0: e.id
      }
    }) : t._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), S("successInfo")) : f === "failed" && (I(t._({
      id: "uXDSGu"
    })), w(t._({
      id: "tNBTdB"
    })), E(t._({
      id: "quOB9l"
    })), S("Error"));
    const x = await W(T);
    R(x), g === "failed" && E(t._({
      id: "vgDUPm"
    })), H(!1);
  };
  function De() {
    g === "failed" ? l() : o();
  }
  const we = () => {
    var x, y;
    return [
      {
        label: t._({
          id: "rd49u+"
        }),
        value: `₹ ${h(e.amount)}`
      },
      {
        label: t._({
          id: "UMXPdU"
        }),
        value: d ? (x = fe(e.frequency)) == null ? void 0 : x.replace(/_/g, " ") : _e(e == null ? void 0 : e.frequency, (y = $e(e == null ? void 0 : e.installment_day, !!N)) == null ? void 0 : y.toString())
      },
      e != null && e.start_date ? {
        label: t._({
          id: "NdlT2Q"
        }),
        value: de(J(new Date(e == null ? void 0 : e.start_date), "dd-MMM-yyyy"))
      } : {},
      {
        label: t._({
          id: "ZS3Gyi"
        }),
        value: (e == null ? void 0 : e.number_of_installments) >= 1200 ? "Ongoing until stopped" : e == null ? void 0 : e.number_of_installments
      },
      {
        label: t._({
          id: "cXcPd1"
        }),
        value: e.folio_number ? e.folio_number : "New folio"
      }
    ].filter((v) => {
      var D;
      return ((D = Object.keys(v)) == null ? void 0 : D.length) > 0;
    });
  };
  return Q ? /* @__PURE__ */ s.jsx(ue, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(K, {
        title: _,
        description: L,
        iconName: $,
        message: oe && d ? /* @__PURE__ */ s.jsx(G, {
          type: "alert",
          variant: "flash",
          title: oe,
          customClass: "mb-5"
        }) : null,
        children: /* @__PURE__ */ s.jsx(ae, {
          title: b && a.name,
          tableData: we()
        })
      }),
      /* @__PURE__ */ s.jsx(et, {
        mandate: q,
        banks: U,
        fallbackLogo: F
      }),
      N && !d && /* @__PURE__ */ s.jsxs(s.Fragment, {
        children: [
          /* @__PURE__ */ s.jsx(B, {
            value: t._({
              id: "R2xBSf"
            }),
            background: !0,
            customClass: "mb-4 items-center"
          }),
          /* @__PURE__ */ s.jsx(Le, {
            scheme: a,
            mf_purchase: N,
            isFotOrder: C,
            showSchemeName: b,
            isPartner: d,
            payment: i
          })
        ]
      }),
      ye(q) ? /* @__PURE__ */ s.jsx("div", {
        style: {
          marginBottom: "-4rem"
        },
        children: /* @__PURE__ */ s.jsx(K, {
          title: "Mandate details",
          iconName: "",
          children: /* @__PURE__ */ s.jsx(Qe, {
            investorProfile: n,
            mandate_id: e == null ? void 0 : e.payment_source,
            status: [
              ""
            ],
            limit: null,
            isPartner: d
          })
        })
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {}),
      c && /* @__PURE__ */ s.jsx(G, {
        type: "helper",
        variant: "flash",
        title: t._({
          id: "/tWtLh",
          values: {
            0: Y != null && Y.consideredSalaryDate ? ` ${new Date(Y == null ? void 0 : Y.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(Y == null ? void 0 : Y.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      d && !C && !se && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(ne) + `/consents?object_type=mf_purchase_plan&action=confirm&id=${e.id}`,
        buttonText: "activation link",
        customClass: "mb-2"
      }),
      d && (C || se) && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(ne) + `/payment?order_id=${e.id}`,
        buttonText: "activation & payment link",
        customClass: "mb-2"
      }),
      (o || l) && /* @__PURE__ */ s.jsxs("div", {
        className: "flex items-center",
        children: [
          /* @__PURE__ */ s.jsx(p, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              De(), ke.remove();
            },
            customClass: "flex-1 px-6 mb-4",
            children: r
          }),
          c && /* @__PURE__ */ s.jsx(p, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              window.location.href = window.location.origin + ze + `/plans-list?isGroupInvestment=true&${Ge(window.location.search)}`;
            },
            customClass: "flex-1 ml-2 mb-4",
            children: t._({
              id: "Pk/Rga"
            })
          })
        ]
      })
    ]
  });
}
function at({ scheme: a, mf_redemption_plan: e, handleRetry: i, handleInvestAgain: o, showSchemeName: l, isPartner: b }) {
  var r;
  const { AppState: { tenant: c } } = z(ie), d = e.state.toLowerCase(), [n, C] = u(""), [f, g] = u(""), [_, I] = u(""), [L, w] = u(""), [$] = u("Plan will be activated after investor provides consent");
  re(() => {
    d === "active" || d === "pending" || d === "confirmed" || d === "submitted" || d === "successful" || d === "created" ? (C(t._({
      id: "M0nG+B"
    })), g(b ? t._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : t._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), w(t._({
      id: "K7WMZD"
    })), I("successInfo")) : d === "failed" && (C(t._({
      id: "jcgQHg"
    })), g(b ? t._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : t._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), w(t._({
      id: "JGhVxc"
    })), I("Error"));
  }, []);
  function S() {
    return d === "failed" ? i : o;
  }
  return /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(K, {
        title: n,
        description: f,
        iconName: _,
        children: /* @__PURE__ */ s.jsx(ae, {
          title: l && a.name,
          tableData: [
            {
              label: t._({
                id: "uzz6vZ"
              }),
              value: e != null && e.amount ? `₹ ${h(e.amount)}` : "-"
            },
            {
              label: t._({
                id: "Sgxaf5"
              }),
              value: e.frequency ? `${(r = fe(e.frequency)) == null ? void 0 : r.replace(/_/g, " ")}` : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: t._({
                id: "osv0u0"
              }),
              value: e != null && e.created_at ? `${J(new Date(e.created_at), "dd-MMM-yyyy")}` : "-"
            },
            e != null && e.end_date ? {
              label: t._({
                id: "w3AOkt"
              }),
              value: e != null && e.end_date && (e != null && e.end_date) ? new Date(e == null ? void 0 : e.end_date).getFullYear() >= 2099 ? "Until you stop" : J(new Date(e == null ? void 0 : e.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      $ && b && /* @__PURE__ */ s.jsx(G, {
        type: "alert",
        variant: "flash",
        title: $,
        customClass: "mb-5"
      }),
      b && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(c) + `/consents?object_type=mf_redemption_plan&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      (i || o) && /* @__PURE__ */ s.jsxs(p, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: S(),
        children: [
          L,
          " "
        ]
      })
    ]
  });
}
function it({ scheme: a, mf_redemption: e, handleInvestAgain: i, showSchemeName: o, isPartner: l }) {
  const { AppState: { tenant: b } } = z(ie), c = e.state.toLowerCase(), [d, n] = u(""), [C, f] = u(""), [g, _] = u(""), [I, L] = u(""), [w] = u("Order will be processed after investor provides consent");
  return re(() => {
    c === "pending" || c === "confirmed" || c === "submitted" || c === "successful" ? (n(t._({
      id: "9CS0ZY"
    })), f(l ? t._({
      id: "lKqNAW",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : t._({
      id: "/8rqV/",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), L(t._({
      id: "K7WMZD"
    })), _("successInfo")) : c === "failed" && (n(t._({
      id: "4qtFW3"
    })), f(l ? t._({
      id: "FvapA5"
    }) : t._({
      id: "CsBuck"
    })), L(t._({
      id: "K7WMZD"
    })), _("Error"));
  }, []), /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(K, {
        title: d,
        description: C,
        iconName: g,
        children: /* @__PURE__ */ s.jsx(ae, {
          title: o && a.name,
          tableData: [
            {
              label: e != null && e.amount ? t._({
                id: "uzz6vZ"
              }) : t._({
                id: "7P4OtI"
              }),
              value: e.amount ? `₹ ${h(e.amount)}` : e.units ? e.units : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: t._({
                id: "1/YYB1"
              }),
              value: e.created_at ? J(new Date(e.created_at), "dd-MMM-yyyy") : "-"
            }
          ]
        })
      }),
      w && l && /* @__PURE__ */ s.jsx(G, {
        type: "alert",
        variant: "flash",
        title: w,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(b) + `/consents?object_type=mf_redemption&action=confirm&id=${e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      i && /* @__PURE__ */ s.jsx(p, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: i,
        children: I
      })
    ]
  });
}
function ot({ scheme: a, mf_switch: e, handleInvestAgain: i, showSchemeName: o, isPartner: l }) {
  const { AppState: { tenant: b } } = z(ie), c = e.state.toLowerCase(), [d, n] = u(""), [C, f] = u(""), [g, _] = u(""), [I, L] = u(!1), [w, $] = u(""), { fpapi: S } = z(xe), [r, E] = u({}), { tenantConfig: N, amcs: O } = be(), Q = N == null ? void 0 : N.fallbackLogo, H = N == null ? void 0 : N.showSchemeLogo, [q] = u("Order will be processed after investor provides consent"), R = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return Me(() => {
    async function A() {
      L(!0);
      try {
        const j = await S.fpClient.master_data().fetchScheme(e.switch_in_scheme), U = await S.fpClient.master_data().fetchScheme(e.switch_out_scheme), F = H && O[U == null ? void 0 : U.amc_id] || Q;
        E({
          ...e,
          schemeLogo: F,
          switch_in_scheme_name: j.name,
          switch_out_scheme_name: U.name
        }), c === "pending" || c === "confirmed" || c === "submitted" || c === "successful" ? (n(t._({
          id: "9CS0ZY"
        })), f(l ? t._({
          id: "QZntO/",
          values: {
            0: e == null ? void 0 : e.id
          }
        }) : t._({
          id: "7q2PJy",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), $(t._({
          id: "K7WMZD"
        })), _("successInfo")) : c === "failed" && (n(t._({
          id: "4qtFW3"
        })), f(t._({
          id: "h3ZVI0"
        })), $(t._({
          id: "Oem4yT"
        })), _("Error")), L(!1);
      } catch (j) {
        n(t._({
          id: "LnNz4Q"
        })), f(t._({
          id: "F05jOJ"
        })), $(t._({
          id: "Oem4yT"
        })), _("Error"), le(j);
      }
    }
    A();
  }, [
    S,
    e,
    c
  ]), I ? /* @__PURE__ */ s.jsx(ue, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(K, {
        title: d,
        description: C,
        iconName: g,
        children: /* @__PURE__ */ s.jsx(ae, {
          title: o && a.name,
          tableData: [
            {
              label: t._({
                id: "6Xqu8f"
              }),
              value: r != null && r.switch_out_scheme_name ? /* @__PURE__ */ s.jsx(s.Fragment, {
                children: /* @__PURE__ */ s.jsxs("div", {
                  className: "mb-2 flex",
                  children: [
                    /* @__PURE__ */ s.jsx("img", {
                      src: r == null ? void 0 : r.schemeLogo,
                      alt: "logo",
                      className: R
                    }),
                    /* @__PURE__ */ s.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ s.jsx("div", {
                          children: `${Ie(r.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ s.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: a.investment_option && Ee(a.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: r.amount ? t._({
                id: "TSMEuV"
              }) : t._({
                id: "hKtDqN"
              }),
              value: r.amount ? `₹ ${h(r.amount)}` : r.units ? r.units : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: r.folio_number ? r.folio_number : "New folio"
            }
          ]
        })
      }),
      q && l && /* @__PURE__ */ s.jsx(G, {
        type: "alert",
        variant: "flash",
        title: q,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(b) + `/consents?object_type=mf_switch&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      i && /* @__PURE__ */ s.jsx(p, {
        variant: "secondary",
        fullwidth: !0,
        onClick: i,
        customClass: "mt-2 mb-4",
        children: w
      })
    ]
  });
}
function lt({ scheme: a, mf_switch: e, handleInvestAgain: i, showSchemeName: o, isPartner: l }) {
  var A;
  const { AppState: { tenant: b } } = z(ie), c = e.state.toLowerCase(), [d, n] = u(""), [C, f] = u(""), [g, _] = u(""), [I, L] = u(!1), [w, $] = u(""), { fpapi: S } = z(xe), [r, E] = u({}), { tenantConfig: N, amcs: O } = be(), Q = N == null ? void 0 : N.fallbackLogo, H = N == null ? void 0 : N.showSchemeLogo, [q] = u("Plan will be activated after investor provides consent"), R = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return Me(() => {
    async function j() {
      L(!0);
      try {
        const U = await S.fpClient.master_data().fetchScheme(e.switch_in_scheme), F = await S.fpClient.master_data().fetchScheme(e.switch_out_scheme), se = H && O[F == null ? void 0 : F.amc_id] || Q;
        E({
          ...e,
          schemeLogo: se,
          switch_in_scheme_name: U.name,
          switch_out_scheme_name: F.name
        }), c === "active" || c === "created" || c === "pending" || c === "confirmed" || c === "submitted" || c === "successful" ? (n(t._({
          id: "PAOIq9"
        })), f(t._({
          id: "1DWHoM",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), $(t._({
          id: "K7WMZD"
        })), _("successInfo")) : c === "failed" && (n(t._({
          id: "rP1ZG1"
        })), f(t._({
          id: "jLiWEA"
        })), $(t._({
          id: "Oem4yT"
        })), _("Error")), L(!1);
      } catch {
        n(t._({
          id: "LnNz4Q"
        })), f(t._({
          id: "F05jOJ"
        })), $(t._({
          id: "Oem4yT"
        })), _("Error");
      }
    }
    j();
  }, [
    S,
    e,
    c
  ]), I ? /* @__PURE__ */ s.jsx(ue, {
    variant: "fullpage"
  }) : /* @__PURE__ */ s.jsxs(s.Fragment, {
    children: [
      /* @__PURE__ */ s.jsx(K, {
        title: d,
        description: C,
        iconName: g,
        children: /* @__PURE__ */ s.jsx(ae, {
          title: o && a.name,
          tableData: [
            {
              label: t._({
                id: "6Xqu8f"
              }),
              value: r != null && r.switch_out_scheme_name ? /* @__PURE__ */ s.jsx(s.Fragment, {
                children: /* @__PURE__ */ s.jsxs("div", {
                  className: "flex",
                  children: [
                    /* @__PURE__ */ s.jsx("img", {
                      src: r == null ? void 0 : r.schemeLogo,
                      alt: "logo",
                      className: R
                    }),
                    /* @__PURE__ */ s.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ s.jsx("div", {
                          children: `${Ie(r.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ s.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: a.investment_option && Ee(a.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: t._({
                id: "TSMEuV"
              }),
              value: r.amount ? `₹ ${h(r.amount)}` : "-"
            },
            {
              label: t._({
                id: "12iDzS"
              }),
              value: r.frequency ? (A = fe(r.frequency)) == null ? void 0 : A.replace(/_/g, " ") : "-"
            },
            {
              label: t._({
                id: "cXcPd1"
              }),
              value: r.folio_number ? r.folio_number : "New folio"
            },
            r.end_date ? {
              label: t._({
                id: "w3AOkt"
              }),
              value: r.end_date && (r != null && r.end_date) ? new Date(r.end_date).getFullYear() >= 2099 ? "Until you stop" : J(new Date(r.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      q && l && /* @__PURE__ */ s.jsx(G, {
        type: "alert",
        variant: "flash",
        title: q,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ s.jsx(te, {
        hyperlink: ee(b) + `/consents?object_type=mf_switch_plan&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      i ? /* @__PURE__ */ s.jsx(p, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: i,
        children: w
      }) : /* @__PURE__ */ s.jsx(s.Fragment, {})
    ]
  });
}
function ht({ investorProfile: a, scheme: e, mf_order: i, payment: o, showSchemeName: l, isGroupInvestment: b, handleInvestAgain: c, handleRetry: d, isPartner: n, isFotOrder: C }) {
  const f = i == null ? void 0 : i.object;
  function g() {
    switch (f) {
      case "mf_purchase":
        return /* @__PURE__ */ s.jsx(Le, {
          investorProfile: a,
          scheme: e,
          mf_purchase: i,
          payment: o,
          handleInvestAgain: c,
          handleRetry: d,
          showSchemeName: l,
          isGroupInvestment: b,
          isPartner: n
        });
      case "mf_switch":
        return /* @__PURE__ */ s.jsx(ot, {
          scheme: e,
          mf_switch: i,
          handleInvestAgain: c,
          showSchemeName: l,
          isPartner: n
        });
      case "mf_redemption":
        return /* @__PURE__ */ s.jsx(it, {
          scheme: e,
          mf_redemption: i,
          handleInvestAgain: c,
          showSchemeName: l,
          isPartner: n
        });
      case "mf_purchase_plan":
        return /* @__PURE__ */ s.jsx(st, {
          scheme: e,
          mf_purchase_plan: i,
          payment: o,
          handleInvestAgain: c,
          handleRetry: d,
          showSchemeName: l,
          isGroupInvestment: b,
          isPartner: n,
          investorProfile: a,
          isFotOrder: C
        });
      case "mf_switch_plan":
        return /* @__PURE__ */ s.jsx(lt, {
          scheme: e,
          mf_switch: i,
          handleInvestAgain: c,
          showSchemeName: l,
          isPartner: n
        });
      case "mf_redemption_plan":
        return /* @__PURE__ */ s.jsx(at, {
          scheme: e,
          mf_redemption_plan: i,
          handleInvestAgain: c,
          handleRetry: d,
          showSchemeName: l,
          isPartner: n
        });
      default:
        return /* @__PURE__ */ s.jsx(s.Fragment, {
          children: /* @__PURE__ */ s.jsx(K, {
            title: t._({
              id: "1nq6/U"
            }),
            description: t._({
              id: "AHVSVV"
            }),
            iconName: "Alert"
          })
        });
    }
  }
  return g();
}
export {
  et as M,
  ht as O,
  St as a,
  tt as b,
  Mt as c,
  mt as d,
  kt as e,
  $e as f,
  Tt as g,
  Dt as r,
  wt as s
};
