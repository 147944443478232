import { j as t } from "./jsx-runtime-C8rcB1Lr.js";
import { useContext as w, useState as g, useEffect as O } from "react";
import { F as T } from "./provider-DPPRdC-N.js";
import { u as F } from "./useInterval-9AWKrr3w.js";
import { a as h, P as W, M as L, B as M } from "./index.esm-DT3FM8Og.js";
import { r as p } from "./sentry-CbZOQ4Ya.js";
import { r as N, B as H, z as Y } from "./utils-DbnU7rHI.js";
import { o as z } from "./redirection-rPP3A4Qb.js";
import { i as a } from "./index-Bb24DwqC.js";
import { AppContext as k } from "./AppContext.js";
import { ErrorPage as c } from "./ErrorPage.js";
function nt({ kycDetails: n, user: e = null, onCompletion: r, isKycOnly: j = !1 }) {
  const { fpapi: u } = w(T), { AppState: { tenant: x } } = w(k), [R, o] = g(null), [l, y] = g(""), [v, d] = g(!1), A = 15 * 1e3;
  F(() => {
    (async () => await m())();
  }, v ? A : null), O(() => {
    N.removeRedirectionState(), (async () => await m())();
  }, []);
  const E = async () => {
    const i = e == null ? void 0 : e.id, s = e != null && e.annexure ? e == null ? void 0 : e.annexure : {}, I = {
      ...Y(s),
      completed_step: "kyc_esign"
    }, _ = await u.updateUser(i, I, null, x);
    if (_)
      return _;
    throw new Error(_);
  }, q = async (i) => {
    try {
      e && (await E(), r == null || r({
        updatedKycDetails: i
      }));
    } catch (s) {
      window.debug.error(s), y(a._({
        id: "NKzNR1"
      })), p(s);
    }
  }, m = async () => {
    try {
      let i = null;
      n && (i = {
        ...await u.fpClient.kyc_requests().fetch(n == null ? void 0 : n.id)
      });
      const s = i == null ? void 0 : i.status;
      switch (o(s), s) {
        case "pending": {
          d(!0);
          break;
        }
        case "esign_required": {
          d(!1), o("redirection_info");
          break;
        }
        case "submitted":
        case "successful": {
          d(!1), await q(i);
          break;
        }
        case "expired":
        case "rejected": {
          d(!1), r == null || r({
            updatedKycDetails: i
          });
          return;
        }
        default:
          d(!1);
      }
    } catch (i) {
      window.debug.log(i), p(i);
    }
  }, S = () => {
    o("esign_pop_up_closed");
  }, K = () => {
    o("esign_pop_up_blocked");
  }, f = async (i) => {
    try {
      const s = await u.fpClient.esigns().create({
        kyc_request: i,
        postback_url: `${window.location.origin + H}/redirection`
      });
      z(s.redirect_url, "kyc-identity-document", S, K), o("esign_pop_up_initiated");
    } catch (s) {
      window.debug.log(s), p(s);
    }
  }, B = async () => {
    try {
      if (e) {
        const i = e.id, s = e == null ? void 0 : e.annexure;
        s.completed_step = "kyc_file_uploads", await u.updateUser(i, s, null, x), r == null || r({
          updatedKycDetails: n
        });
      }
    } catch (i) {
      window.debug.error(i), p(i);
    }
  }, P = () => {
    switch (R) {
      case "pending":
        return /* @__PURE__ */ t.jsx(b, {
          title: a._({
            id: "1K5A7q"
          }),
          msg: a._({
            id: "S3nWyy"
          })
        });
      case "redirection_info":
        return /* @__PURE__ */ t.jsx(G, {
          handleGoBack: j ? void 0 : B,
          onContinue: () => f(n == null ? void 0 : n.id)
        });
      case "esign_required":
        return /* @__PURE__ */ t.jsx(b, {
          title: a._({
            id: "IYKbMU"
          }),
          msg: a._({
            id: "Y3oq2l"
          })
        });
      case "esign_pop_up_blocked":
        return /* @__PURE__ */ t.jsx(c, {
          title: a._({
            id: "sS+OUW"
          }),
          description: l,
          showAction: !0,
          actionText: a._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            f(n == null ? void 0 : n.id);
          }
        });
      case "esign_pop_up_closed":
        return /* @__PURE__ */ t.jsx(c, {
          title: a._({
            id: "E/e632"
          }),
          description: l,
          showAction: !0,
          actionText: a._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            f(n == null ? void 0 : n.id);
          }
        });
      case "expired":
        return /* @__PURE__ */ t.jsx(c, {
          title: a._({
            id: "B7L3hv"
          })
        });
      case "rejected":
        return /* @__PURE__ */ t.jsx(c, {
          title: a._({
            id: "oqO8hM"
          })
        });
      case "esign_pop_up_initiated":
      case "submitted":
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {
          children: /* @__PURE__ */ t.jsx(h, {
            variant: "fullpage"
          })
        });
    }
  };
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: l ? /* @__PURE__ */ t.jsx(c, {
      title: a._({
        id: "AsbZJy"
      }),
      description: l,
      showAction: !0,
      actionText: a._({
        id: "90uUa5"
      }),
      actionHandler: () => window.location.reload()
    }) : P()
  });
}
const b = ({ title: n, msg: e }) => /* @__PURE__ */ t.jsx(h, {
  variant: "fullpage",
  title: n,
  msg: e
}), G = ({ onContinue: n, handleGoBack: e }) => /* @__PURE__ */ t.jsx(t.Fragment, {
  children: /* @__PURE__ */ t.jsxs("div", {
    children: [
      /* @__PURE__ */ t.jsx(W, {
        title: a._({
          id: "pKARbt"
        }),
        icon: e ? "Back" : null,
        onClick: e,
        customClass: "mb-4"
      }),
      /* @__PURE__ */ t.jsx("p", {
        className: "dark:text-neutral-dark-110 mb-5 text-center",
        children: "You need to e-sign your identity details for verification. An OTP to your aadhaar linked mobile number will be sent to complete this process."
      }),
      /* @__PURE__ */ t.jsx(L, {
        type: "alert",
        variant: "flash",
        title: "Identity details cannot be modified after e-sign",
        customClass: "mb-5"
      }),
      /* @__PURE__ */ t.jsx("div", {
        className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
        children: /* @__PURE__ */ t.jsx(M, {
          fullwidth: !0,
          customClass: "w-full mb-6",
          onClick: () => n(),
          children: "Continue"
        })
      })
    ]
  })
});
export {
  nt as default
};
