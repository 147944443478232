/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import { Footer, Header, Loader, Selector, Tag } from "pixel";
import FooterLogo from "../utils/images/fp-badge.svg";

import { AppContext } from "fpapps/AppContext";
import { ConfigurationContext } from "fpapps/ConfigurationContext";
import { FpApiContext } from "fpapps/FpApiContext";
import { LocaleProviderContext } from "fpapps/LocaleProviderContext";
import { LocaleSelector } from "fpapps/LocaleSelector";
import { LoginContext } from "fpapps/LoginContext";
import { getOptionsIndex, isEmptyObject, tenantEnv } from "../utils/utils";

//Fpapps imports
import InvestorLogout from "./investorLogout";
import OauthLogout from "./OauthLogout";

const getLogo = (settings) => {
  if (window.location.hostname === "localhost") {
    return "/logo.svg";
  }

  return settings["logo"] || settings["fallbackLogo"];
};

export default function MasterLayout({ children, employer, userType }) {
  const [settings, setSettings] = useState(undefined);
  const [theme, setTheme] = useState(null);
  const [themeIcon, setThemeIcon] = useState(undefined);

  const { fpapi } = useContext(FpApiContext);
  const { loggedInUser, loggedInPartner } = useContext(LoginContext);
  const {
    AppState: { employerBeingOnboarded },
    setShowWelcomePage,
  } = useContext(AppContext);
  const { tenantConfig } = useContext(ConfigurationContext);
  const { locale, updateLocale } = useContext(LocaleProviderContext);

  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)");
  const userTheme = localStorage.getItem("theme");

  const rootElement = document.documentElement; //<html>
  const rootElementClassList = rootElement.classList;

  const themeOptions = [
    { label: "Lite", value: "light", icon: "Light" },
    { label: "Dark", value: "dark", icon: "Dark" },
  ];

  useEffect(() => {
    if (userTheme === "dark" || (!userTheme && systemTheme.matches)) {
      //if userTheme is dark or userTheme is not present and systemTheme is dark
      setTheme(themeOptions[getOptionsIndex(themeOptions, "dark")]);
    } else {
      setTheme(themeOptions[getOptionsIndex(themeOptions, "light")]);
    }

    // track systemTheme changes and update
    systemTheme.onchange = (e) => {
      const updatedSystemTheme = e.matches ? "dark" : "light";
      setTheme(themeOptions[getOptionsIndex(themeOptions, updatedSystemTheme)]);
    };
  }, []);

  const saveUserTheme = (theme) => {
    localStorage.setItem("theme", theme);
  };

  useEffect(() => {
    if (theme?.value === "dark") {
      rootElementClassList.add("dark");
      setThemeIcon("Dark");
      saveUserTheme("dark");
    } else if (theme?.value === "light") {
      rootElementClassList.remove("dark");
      setThemeIcon("Light");
      saveUserTheme("light");
    }
  }, [theme]);

  useEffect(() => {
    setSettings(tenantConfig);
  }, [tenantConfig]);

  const handleThemeChange = (option) => {
    setTheme(option);
  };

  const renderLogout = () => {
    switch (userType) {
      case "EMPLOYER":
        return <OauthLogout />;
      case "INVESTOR_CIAM":
        return <OauthLogout />;
      case "INVESTOR":
      case "PARTNER":
      default:
        return <InvestorLogout />;
    }
  };

  const shouldShowLogoSubtitle =
    (!isEmptyObject(tenantConfig?.groupInvestment) && employer) || employerBeingOnboarded;

  return (
    <>
      {settings ? (
        <>
          <Header
            logo={getLogo(settings)}
            onClick={() => {
              setShowWelcomePage(true);
            }}
          >
            <div
              className={`flex ${tenantEnv.isStagingOrSandbox ? "justify-between" : "justify-end"}`}
            >
              <div className={`${shouldShowLogoSubtitle && "ml-2 border-l pl-1"}`}>
                {tenantEnv.isStagingOrSandbox ? (
                  <Tag variant="pill" message="Sandbox" customClass="ml-1" />
                ) : null}
                {shouldShowLogoSubtitle ? (
                  <span
                    className={`text-size-10 text-neutral-60 ml-1 block ${
                      tenantEnv.isProduction ? "mt-1" : ""
                    }`}
                  >
                    {userType === "EMPLOYER"
                      ? employerBeingOnboarded
                      : tenantConfig?.groupInvestment?.groupPartnerName}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <ul className="flex items-center">
                <li className="flex items-center pl-4">
                  <Selector
                    variant="iconBased"
                    value={theme}
                    iconName={themeIcon}
                    options={themeOptions}
                    onChange={handleThemeChange}
                  />
                </li>

                {renderLogout()}
              </ul>
            </div>
          </Header>
          <section className="flex flex-1 flex-col px-8">
            <>{children}</>
          </section>
          {tenantConfig?.app_constants?.showFPBranding ? (
            <Footer text="Powered by" logo={FooterLogo}>
              <LocaleSelector selectedLanguage={locale} updateLocale={updateLocale} />
            </Footer>
          ) : (
            <Footer>
              <LocaleSelector selectedLanguage={locale} updateLocale={updateLocale} />
            </Footer>
          )}
        </>
      ) : (
        <Loader variant="fullpage" />
      )}
    </>
  );
}
